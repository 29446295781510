import Styles from "./UnpackCard.module.scss"
import React from "react";
import P1 from "../../typography/P1";
import P3 from "../../typography/P3";
import classNames from "classnames";

export const UnpackPaintingCard = (
	{
		img,
		name,
		author,
	}: {
		img: string,
		name: string,
		author: string,
	}
) => (
	<div className={classNames(Styles.panting_card, Styles.card)}>
		<div className={Styles.img}><img src={img} alt={name}/></div>
		<div className={Styles.info}>
			<div className={Styles.text}>
				<P1 className={Styles.name} isBold>{name}</P1>
				<P3>{author}</P3>
			</div>
		</div>
	</div>
)