import React from 'react';
import Styles from "./Partner.module.scss";
import ButtonLink from "./ButtonLink";
import H3 from "../../../components/typography/H3";
import ArrowExternal from "../../../icons/ArrowExternal";

interface Base {
	img: string,
	title: string,
	btnText: string,
}

export interface Internal extends Base {
	path: string,
	href?: string,
}

export interface External extends Base {
	href: string,
	path?: string,
}

type PartnerProps = Internal | External

const Partner = ({img, title, path = "", href = "", btnText}: PartnerProps) => (
	<div className={Styles.container}>
		<div className={Styles.img}><img src={img} alt="alt"/></div>
		<div className={Styles.content}>
			<H3>{title}</H3>
			<ButtonLink target="_blank" path={path} href={href} icon={<ArrowExternal/>}>{btnText}</ButtonLink>
		</div>
	</div>
);

export default Partner;
