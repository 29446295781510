import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";
import { UserUnstakeModel } from "../../../models/UserUnstakeModel";

export class RefundAction extends EosActionBase {
	constructor(private unstake: UserUnstakeModel)
	{
		super("refund");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("refund", eos.userAuth, {
			id: this.unstake.id,
			owner: eos.user
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		if (this.unstake.quantity.amount > 0)
			await world.mustUpdateBalance(this.unstake.contract, this.unstake.quantity.symbol);
		if (this.unstake.asset_ids.length > 0)
			await world.updateAtomicAssets(true);
		await world.updatePool(this.unstake.pool_id);
		model.userUnstakes.removeItem(this.unstake.id);
	}
}