import React from 'react';
import Styles from "./InfoBlock.module.scss"
import classNames from "classnames";
import { Animated } from "react-animated-css";
import useWindowSize from "../../../hooks/useWindowSize";
import H3 from "../../../components/typography/H3";
import P1 from "../../../components/typography/P1";

interface InfoBlockProps {
	title: string,
	text: string,
	img: string,
	variant?: 'default' | 'reverse',
	children?: React.ReactNode
}

function useIsInViewport(ref: React.RefObject<HTMLDivElement>)
{
	const [isIntersecting, setIsIntersecting] = React.useState(false);

	const observer = React.useMemo(
		() =>
			new IntersectionObserver(([entry]) => {
					setIsIntersecting(prevState => prevState ? true : entry.isIntersecting)
				},
			),
		[],
	);

	React.useEffect(() => {
		if (!ref.current)
			return;

		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref, observer]);

	return isIntersecting;
}

const InfoBlock = ({title, text, img, variant, children}: InfoBlockProps) => {
	const ref = React.useRef(null);
	const {windowWidth} = useWindowSize();
	const isVisible = useIsInViewport(ref)
	return (
		<div className={classNames(Styles.container, variant === 'reverse' && Styles.reverse)}>
			<div className={Styles.block} ref={ref}>
				<Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={windowWidth < 1025 ? isVisible : true}>
					<div className={Styles.img}>
						<img src={img} alt="alt"/>
					</div>
				</Animated>
			</div>
			<div className={Styles.block}>
				<div className={Styles.inner}>
          <span className={Styles.heading}>
            <H3>{title}</H3>
            <P1>{text}</P1>
          </span>
					{children && (
						<div className={Styles.content}>
							{children}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InfoBlock;
