// LogLevel
export enum LogLevel {
	Log = 0,
	Info = 1,
	Warn = 2,
	Error = 3,
}

export class Logger {
	private static instance: Logger;

	static get(): Logger
	{
		if (!Logger.instance) {
			Logger.instance = new Logger(LogLevel.Log);
		}
		return Logger.instance;
	}

	static log(...args: any[])
	{
		Logger.get().log(...args);
	}

	static info(...args: any[])
	{
		Logger.get().info(...args);
	}

	static warn(...args: any[])
	{
		Logger.get().warn(...args);
	}

	static error(...args: any[])
	{
		Logger.get().error(...args);
	}

	private level: LogLevel;

	constructor(level: LogLevel)
	{
		this.level = level;
	}

	log(...args: any[])
	{
		if (LogLevel.Log >= this.level) {
			console.log(...args);
		}
	}

	info(...args: any[])
	{
		if (LogLevel.Info >= this.level) {
			console.info(...args);
		}
	}

	warn(...args: any[])
	{
		if (LogLevel.Warn >= this.level) {
			console.warn(...args);
		}
	}

	error(...args: any[])
	{
		if (LogLevel.Error >= this.level) {
			console.error(...args);
		}
	}

	setLevel(level: LogLevel)
	{
		this.level = level;
	}
}