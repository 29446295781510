import { action, makeObservable, observable } from "mobx";
import { TUint64 } from "@pro/common/contracts/common_types";
import { UserPoolModel } from "./UserPoolModel";

export class UserPoolsTable {
	items = new Map<string, Map<TUint64, UserPoolModel>>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItem: action,
			clearItems: action,
		});
	}

	updateItems(user: string, records: UserPoolModel[])
	{
		if (!this.items.get(user))
			this.items.set(user, new Map<TUint64, UserPoolModel>());

		for (let r of records)
			this.items.get(user)!.set(r.pool_id, r);
	}

	removeItem(user: string, pool_id: number)
	{
		if (!this.items.get(user))
			return;

		this.items.get(user)!.delete(pool_id);
	}

	clearItems()
	{
		this.items.clear();
	}

	get(user: string, pool_id: number): UserPoolModel | undefined
	{
		if (!this.items.get(user))
			return undefined;
		return this.items.get(user)!.get(pool_id);
	}
}