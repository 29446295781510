import { TEosKeyType } from "./eos_types";

const json = JSON.stringify;

export class EosApiError extends Error
{
	static invalidArg(name: string, value: unknown)
	{
		return new EosApiError("invalid argument:"
		                       + ` ${name}=${json(value)}`);
	}

	static recordNotFound(scope: string, table: string, id: string | number)
	{
		return new EosApiError("record not found:"
		                       + ` scope=${json(scope)}`
		                       + ` table=${json(table)}`
		                       + ` id=${json(id)}`);
	}

	static invalidKey(name: string, value: unknown, type: TEosKeyType)
	{
		return new EosApiError("invalid table key :"
		                       + ` ${name}=${json(value)}`
		                       + ` type=${type}`);
	}

	constructor(message: string)
	{
		super(message);
		this.name = "EosApiError";
		// required for (e instanceof EosApiError) be true
		Object.setPrototypeOf(this, EosApiError.prototype);
	}
}
