import { sleep } from "@pro/common/utils";
import { notificationService } from "../App";

type THandler = () => void;
type TReadyStateAction = (value: (((prevState: boolean) => boolean) | boolean)) => void;

const DEFAULT_HANDLER: THandler = () => {
};

export abstract class EosActionBase {
	protected readonly tag = `[${this.constructor.name}]`;
	private _readyStateAction?: TReadyStateAction;

	private _successHandler = DEFAULT_HANDLER;
	private _errorHandler = DEFAULT_HANDLER;

	abstract onExecute(): Promise<void>

	abstract onSelfUpdate(): Promise<void>

	protected constructor(public readonly actionName: string)
	{
		this.actionName = actionName;
		this.tag = "[" + actionName + "]";
	}

	async execute()
	{
		console.info(this.tag, "execute");

		this.suspendControls();

		let success = false;

		try {
			await this.onExecute();
			success = true;
		} catch (e) {
			console.error(e);
			notificationService.showError(e.message ?? e);
		}

		if (success) {
			await sleep(1000);
			await this.onSelfUpdate();
			this._successHandler();
		} else {
			this._errorHandler();
		}

		this.resumeControls();
	}

	executeAsync()
	{
		this.execute()
			.catch(e => console.error(e));
	}

	setReadyStateAction(readyStateAction: TReadyStateAction): this
	{
		this._readyStateAction = readyStateAction;
		return this;
	}

	onSuccess(handler: THandler): this
	{
		this._successHandler = handler;
		return this;
	}

	onError(handler: THandler): this
	{
		this._errorHandler = handler;
		return this;
	}

	private suspendControls()
	{
		if (this._readyStateAction)
			this._readyStateAction(false);
	}

	private resumeControls()
	{
		if (this._readyStateAction)
			this._readyStateAction(true);
	}
}
