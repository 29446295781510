import { makeObservable } from "mobx";
import { ILottery, ILotteryPrize, IPaintingConf } from "@pro/common/contracts/expo";
import { app, model } from "../App";
import { get64Binary } from "../helpers/formatters";
import { EosAsset } from "@pro/common/eos";

export class LotteryModel {
	constructor(
		public readonly record: ILottery,
	)
	{
		makeObservable(this, {});
	}

	isPosOpened(pos: number): boolean
	{
		return get64Binary(this.record.user_field)[63 - pos] === "1";
	}

	isPosConfirmed(pos: number): boolean
	{
		return get64Binary(this.record.confirm_field)[63 - pos] === "1";
	}

	isPosLucky(pos: number): boolean
	{
		return this.getWinnings(pos) !== undefined
		// return this.isPosOpened(pos) && this.isPosConfirmed(pos) &&
		//     get64Binary(this.record.results_field)[63 - pos] === "1";
	}

	getWinnings(pos: number): ILotteryPrize | undefined
	{
		let p = this.record.prizes.find(it => it.key === pos)
		return p ? p.value : undefined;
	}

	get haveWinnings(): boolean
	{
		return this.record.prizes.length > 0
		// return this.record.total_prizes > 0;
	}

	get canClaim(): boolean
	{
		return this.record.available_attempts === 0 && this.record.user_field === this.record.confirm_field;
	}

	get awaitingResult(): boolean
	{
		return this.record.user_field !== this.record.confirm_field;
	}

	get canBuyAttempts(): boolean
	{
		return this.boughtTries != this.totalTriesCanBeBought;
	}

	get triesCanBeUsed(): number
	{
		return this.record.used_attempts + this.record.available_attempts;
	}

	get boughtTries(): number
	{
		let conf = this.conf;
		if (!conf)
			return 0;
		return this.record.used_attempts + this.record.available_attempts - conf.value.free_attempts;
	}

	get totalTriesCanBeBought(): number
	{
		let conf = this.conf;
		if (!conf)
			return 0;
		return conf.value.max_attempts - conf.value.free_attempts;
	}

	get canTry(): boolean
	{
		return this.record.available_attempts > 0;
	}

	get conf(): IPaintingConf | undefined
	{
		if (!model.config)
			return undefined;

		return model.config.paintings_config.find(it => it.key === this.record.painting_id);
	}

	get maxPrize(): EosAsset
	{
		const maxPrize = app.chainConf.LOTTERY_CONF[app.chainConf.LOTTERY_CONF.length - 1].prize;
		return this.getExpoPrize(maxPrize);
	}

	getExpoPrize(basePrize: number): EosAsset
	{
		const expoPrize = basePrize * (1.2 ** (this.conf!.value.expo - 1));
		return new EosAsset(expoPrize * 10 ** app.chainConf.TKN_SYMBOL.precision, app.chainConf.TKN_SYMBOL)
	}
}