import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import React, { ReactNode } from "react";
import Styles from "./TabsView.module.scss"
import classNames from "classnames";
import P1 from "../typography/P1";

const TabsView = (
    {
        tabs,
        className,
        tabPanelClassName
    }: {
        tabs: { title: string, content: ReactNode}[],
        className?: string,
        tabPanelClassName?: string
    }
) => (
    <Tabs
        className={classNames(Styles.tabs, className)}
        selectedTabClassName={Styles.selectedTab}
        selectedTabPanelClassName={Styles.selectedTabPanel}
    >
        <TabList className={Styles.tabList}>
            {tabs.map(tab => (
                <Tab className={Styles.tab} key={`Tab_${tab.title}`}>
                    <P1 className={Styles.title}>
                        {tab.title}
                    </P1>
                </Tab>
            ))}
        </TabList>
        {tabs.map(tab => (
            <TabPanel className={classNames(Styles.tabPanel, tabPanelClassName)} key={`TabPanel_${tab.title}`}>
                {tab.content}
            </TabPanel>
        ))}
    </Tabs>
);

export default TabsView