import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { CanvasPuzzleCropper } from "../../core/CanvasPuzzleCropper";
import Button from "../../components/shared/Button";
import Styles from "./CropperPage.module.scss";
import { PuzzleType } from "../../core/types";
import { IConfJson } from "@pro/common/conf/PaintingsConf";

const CropperPage = observer(() => {
	const [p, setP] = useState<CanvasPuzzleCropper>();
	const [sizes, setSizes] = useState<{ [key: number]: { w: number, h: number, set_w: number } }>({});
	const [selectedPainting, setSelectedPainting] = useState(0);
	const [conf, setConf] = useState<IConfJson[]>([]);
	const canvasRef = useRef(null);
	const sizeInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const puzzleCropper = new CanvasPuzzleCropper(canvasRef.current!);
		setP(puzzleCropper);

		fetch(`/to_crop/p_config.json`).then(async (response) => {
			setConf((await response.json()) as IConfJson[]);
		})

	}, [])

	return (
		<>
			<div className={Styles.images}>
				<select onChange={(e) => {
					setSelectedPainting(parseInt(e.target.value));
					sizeInputRef.current!.value = sizes[parseInt(e.target.value)] ? sizes[selectedPainting].w.toString() : "";
				}}>
					{[...Array(30).keys()].map((it) => <option value={it}>{it}</option>)}
				</select>

				<select onChange={(e)=>{
					let c = conf[parseInt(e.target.value)];

					let img = new Image();
					img.onload = function () {
						p!.cols = c.cols;
						p!.rows = c.rows;

						let prop = img.naturalWidth / img.naturalHeight;

						let neededWidth = c.cols * 170;
						let neededHeight = neededWidth / prop;

						img.width = neededWidth;
						img.height = neededHeight;

						p!.image = img;
						p!.prepare();

						let t = [PuzzleType.RECT, PuzzleType.TRIANGLE, PuzzleType.ARC].sort(()=>Math.random() > 0.5 ? 1 : -1)[0];
						// let t = PuzzleType.ARC;
						p!.type = t;
						// if (t === PuzzleType.ARC)
						// {
						// 	let minWidth = 0.1;
						// 	let minHeight = 0.1;
						//
						// 	let maxWidth = 0.5
						// 	// let maxHeight = 0.5
						// 	let width = minWidth + Math.random() * (maxWidth-minWidth);
						// 	let height = minHeight + Math.random() * (maxWidth-minWidth);
						//
						// 	let randomWidth = 0;//Math.random() * (maxWidth - width);
						// 	let randomHeight = 0;//Math.random() * (maxHeight - height);
						//
						// 	let minPositionX = height+randomHeight;
						// 	let maxPositionX = 1 - minPositionX*2;
						// 	// let minPositionY = width+randomWidth;
						//
						// 	// let centerX = 0.5 - (width+randomWidth)/2;
						// 	let centerY = 0.5 - (height+randomHeight)/2;
						// 		// let maxPosition = (1 - minPosition*2);// - Math.max(width+randomWidth, height+randomHeight);
						// 	// let position = minPosition + Math.random() * maxPosition;
						// 	// let randPosition = Math.random() * (maxPosition - position)
						//
						// 	p!.minWidth = width;
						// 	p!.maxWidth = randomWidth;
						// 	p!.minHeight = height;
						// 	p!.maxHeight = randomHeight;
						// 	p!.minPaddingX = minPositionX;
						// 	p!.maxPaddingX = maxPositionX;
						// 	p!.minPaddingY = centerY;
						// 	p!.maxPaddingY = 0;
						// }

						let minWidth = 0.1 + Math.random() * 0.4;
						let maxWidth = Math.random() * (0.5 - minWidth);

						let minHeight = 0.1 + Math.random() * 0.4;
						let maxHeight = Math.random() * (0.5 - minHeight);

						let minPaddingX = 0.1 + Math.random() * 0.2;
						let maxPaddingX = Math.random() * (0.5 - minPaddingX);

						let minPaddingY = 0.1 + Math.random() * 0.2;
						let maxPaddingY = Math.random() * (0.5 - minPaddingY);

						let minDepth = 0.1;
						let maxDepth = Math.random() * (0.3 - minDepth);

						p!.minWidth = minWidth;
						p!.maxWidth = maxWidth;
						p!.minHeight = minHeight;
						p!.maxHeight = maxHeight;
						p!.minPaddingX = minPaddingX;
						p!.maxPaddingX = maxPaddingX;
						p!.minPaddingY = minPaddingY;
						p!.maxPaddingY = maxPaddingY;
						p!.minDepth = minDepth;
						p!.maxDepth = maxDepth;
						setSelectedPainting(c.id);
					};
					img.src = `/to_crop/${c.id}.jpg`;

					// sizeInputRef.current!.value = sizes[parseInt(e.target.value)] ? sizes[selectedPainting].w.toString() : "";
				}}>
					{conf.map((it, index) => {
						return <option value={index}>{it.id} - {it.cols}/{it.rows}</option>
					})}
				</select>

				<div>
					<img src={`/to_crop/${selectedPainting}.jpg`} onLoad={(e) => {
						let s = {...sizes}
						if (!sizes[selectedPainting]) {
							s[selectedPainting] = {
								w: e.currentTarget.naturalWidth,
								h: e.currentTarget.naturalHeight,
								set_w: e.currentTarget.naturalWidth
							};
							setSizes(s);
						}
						sizeInputRef.current!.value = s[selectedPainting].w.toString();

					}} onClick={(e) => {
						let img = new Image();
						img.onload = function () {
							let prop = img.width / img.height;
							img.width = Math.min(sizes[selectedPainting].set_w, img.width);
							img.height = sizes[selectedPainting].set_w / prop;
							p!.image = img;
							p!.prepare();
						};
						img.src = e.currentTarget.src;
						// p!.image = e.currentTarget;
					}}/>
					<div className={Styles.size}>
						<input ref={sizeInputRef} type="number" onChange={(e) => {
							let s = {...sizes}
							s[selectedPainting].set_w = parseInt(e.target.value);
							setSizes(s);
						}}/>/{sizes[selectedPainting] ? (Math.round(sizes[selectedPainting].set_w / (sizes[selectedPainting].w / sizes[selectedPainting].h))) : 0}
					</div>
				</div>

			</div>
			<div className={Styles.tools}>
				<input type={"number"} defaultValue={p?.cols.toString()} placeholder={"cols"}
					   onChange={(e) => p!.cols = parseInt(e.target.value)}/>
				<input type={"number"} defaultValue={p?.rows.toString()} placeholder={"rows"}
					   onChange={(e) => p!.rows = parseInt(e.target.value)}/>
				<select defaultValue={p?.type} onChange={(e) => {
					p!.type = parseInt(e.target.value) as PuzzleType;
				}}>
					<option value={PuzzleType.RECT}>RECT</option>
					<option value={PuzzleType.ARC}>ARC</option>
					<option value={PuzzleType.TRIANGLE}>TRIANGLE</option>
				</select>
				<input defaultValue={p?.minWidth.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.minWidth = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.maxWidth.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.maxWidth = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.minHeight.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.minHeight = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.maxHeight.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.maxHeight = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.minPaddingX.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.minPaddingX = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.maxPaddingX.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.maxPaddingX = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.minPaddingX.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.minPaddingY = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.maxPaddingX.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.maxPaddingY = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.minDepth.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.minDepth = parseFloat(e.target.value);
				}}/>
				<input defaultValue={p?.maxDepth.toString()} type={"number"} step={0.001} min={0} onChange={(e) => {
					p!.maxDepth = parseFloat(e.target.value);
				}}/>
				<Button text={"random"} onClick={() => p!.prepare()}/>
				<Button text={"draw"} onClick={() => p!.draw()}/>
				<Button text={"clip"} onClick={() => p!.crop()}/>
				<Button text={"get map"} onClick={() => p!.getMap()}/>
				<Button text={"get image"} onClick={() => p!.getPaintingImages(selectedPainting)}/>
				<Button text={"get image"} onClick={() => p!.getSamples()}/>
			</div>
			<div className={Styles.canvas_container}>
				<canvas ref={canvasRef}/>
			</div>
		</>
	)

});

export default CropperPage;