import { ATemplate } from "@pro/common/contracts/atomicassets";
import { TUint64 } from "@pro/common/contracts/common_types";
import { makeLinkToAssetMedia } from "../helpers/image_helper";

export class AtomicTemplateModel {
	constructor(
		public readonly template: ATemplate,
		public readonly immutableData: { [id: string]: TUint64 },
	)
	{
	}

	getImmutableProp(propName: string)
	{
		return this.immutableData[propName];
	}

	get template_id(): number
	{
		return this.template.template_id;
	}

	get schemaName()
	{
		return this.template.schema_name;
	}

	get name()
	{
		return this.getImmutableProp("name") as string;
	}

	get img()
	{
		return makeLinkToAssetMedia((this.imageSrc || this.getImmutableProp("backimg") || "") as string);
	}

	get video(): string | undefined
	{
		if (!this.getImmutableProp("video"))
			return undefined;
		return makeLinkToAssetMedia((this.getImmutableProp("video") || "") as string);
	}

	get imageSrc(): string
	{
		return this.getImmutableProp("img") as string;
	}

	get puzzle_id(): number
	{
		return this.getImmutableProp("puzzle_id") as number;
	}

	get immutable_serialized_data()
	{
		return this.template.immutable_serialized_data;
	}
}
