import { RpcError } from "eosjs";
import { binaryToDecimal, decimalToBinary } from "eosjs/dist/eosjs-numeric";
import { SerialBuffer } from "eosjs/dist/eosjs-serialize";
import { EosApiError } from "./eos_errors";
import { IEosAuth } from "./eos_types";

export class EosUtils
{
	static getContractConsole(e: RpcError | any): string[]
	{
		let details = e?.json?.error?.details;
		if (!Array.isArray(details))
			return [];

		for (let it of details) {
			let match = String(it.message).match(/pending console output: (.+)/s);
			if (match)
				return match[1].trim().split("\n");
		}
		return [];
	}

	static castAuth(auth: IEosAuth | readonly IEosAuth[]): IEosAuth[]
	{
		return ([] as IEosAuth[]).concat(auth);
	}

	static fixEmptyName(value: string): string
	{
		if (value === "") // https://github.com/EOSIO/eosjs/issues/801
			return ".";
		else
			return value;
	}

	static addNameSpace(value: string): string
	{
		if (value.match(/^\d+$/))
			return value + " ";
		else
			return value;
	}

	static nameToDecimal(name: string): string
	{
		let buffer = new SerialBuffer();
		buffer.pushName(this.fixEmptyName(name));
		return binaryToDecimal(buffer.getUint8Array(8));
	}

	static decimalToName(decimal: string | number): string
	{
		let buffer = new SerialBuffer();
		buffer.pushArray(decimalToBinary(8, '' + decimal));
		return buffer.getName();
	}

	static nextDecimal(value: number | string): string
	{
		if (!/^[0-9]+$/.test(String(value))) {
			throw EosApiError.invalidArg("value", value);
		}

		let chars = String(value).split("").reverse();
		let i = 0;
		while (true) {
			let digit = Number(chars[i]);
			if (!Number.isInteger(digit)) {
				throw EosApiError.invalidArg("value", value);
			}
			if (digit < 9) {
				chars[i] = String(digit + 1);
				break;
			}
			chars[i] = "0";
			i += 1;
			if (i === chars.length) {
				chars.push("1");
				break;
			}
		}
		return chars.reverse().join("");
	}

	static nextName(value: string): string
	{
		let decimal = this.nameToDecimal(value);
		let nextDecimal = this.nextDecimal(decimal);
		return this.decimalToName(nextDecimal);
	}

	static symbolCodeToNumber(symbolCode: string): number
	{
		let result = 0;

		for (let i = symbolCode.length - 1; i >= 0; i--)
		{
			result *= 2 ** 8;
			result += symbolCode.charCodeAt(i);
			console.log(i, symbolCode.charAt(i), result.toString(16));
		}
		return result;
	}


	static symbolCodeToHex(symbolCode: string): string
	{
		let result = "";
		for (let i = 0; i < symbolCode.length; i++)
		{
			result = symbolCode.charCodeAt(i).toString(16) + result;
		}
		return result.padStart(16, "0");
	}

	static hexToSymbolCode(hex: string): string
	{
		let result = "";
		let hexes = hex.match(/.{1,2}/g) || [];
		for (let j = hexes.length - 1; j >= 0; j--) {
			let charCode = parseInt(hexes[j], 16);
			if (charCode !== 0)
				result += String.fromCharCode(charCode);
		}
		return result;
	}
}


