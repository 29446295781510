import Styles from "./Card.module.scss";
import React from "react";
import { AtomicAssetModel } from "../../../models/AtomicAssetModel";
import P2 from "../../typography/P2";
import P1 from "../../typography/P1";
import classNames from "classnames";
import { app } from "../../../App";
import Button from "../../../pages/landing/components/Button";

const CardNFT = (
	{
		asset
	}: {
		asset: AtomicAssetModel
	}
) => (
	<div className={classNames(Styles.card, Styles.nftCard)}>
		<div className={Styles.img}><img src={asset.img} alt="img"/></div>
		<P2>{asset.author}</P2>
		<P1 className={Styles.name}>{asset.name}</P1>
		<Button href={app.paintingPools.pepperStakeURL(Number(asset.expo))}>
			Stake
		</Button>
	</div>
);

export default CardNFT