import { observer } from "mobx-react";
import { localData } from "../../App";
import Button, { EButtonType } from "../../pages/landing/components/Button";

const ExportButton = observer(({painting_id}: { painting_id: number }) => {
	const state = localData.getPaintingState(painting_id);
	let encoded = window.btoa(JSON.stringify(state || "{}"));

	const onClick = () => {
		let a = document.createElement('a');
		a.download = `painting_${painting_id}_state_${(new Date()).toUTCString()}.txt`;
		a.href = 'data:text/plain;charset=utf-8,' + encoded
		a.click();
	}

	return <Button type={EButtonType.SECONDARY} onClick={onClick}>Export</Button>
});
export default ExportButton;