import { observer } from "mobx-react";
import Styles from "./Slider.module.scss";
import { useEffect, useRef, useState } from "react";
import P2 from "../typography/P2";


const Slider = observer(({
							 min,
							 max,
							 value,
							 step,
							 onChange,
							 pointersConf
						 }: {
	min: number,
	value?: number,
	max: number,
	step?: number,
	pointersConf?: { val: number, text: string }[],
	onChange: (val: number) => void,
}) => {

	const parent = useRef<HTMLDivElement>(null);
	const pointer = useRef<HTMLDivElement>(null);
	const [startMousePos, setStartMousePos] = useState(-1);
	const [startPointerPos, setStartPointerPos] = useState(-1);
	const [pointerPos, setPointerPos] = useState(0);

	let getPValue = (): number => {
		return (max - min) / maxX();
	}

	let maxX = (): number => {
		if (!pointer.current)
			return 0;

		return pointer.current.parentElement?.offsetWidth || 0;
	}

	let onMouseMove = (e: MouseEvent | TouchEvent) => {
		if (startMousePos !== -1) {
			let xPos = (e as TouchEvent).touches ? (e as TouchEvent).touches[0].pageX : (e as MouseEvent).pageX;
			let position = Math.min(Math.max((startPointerPos + (xPos - startMousePos)), 0), maxX());
			roundAndSetPos(position);
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	}

	let roundAndSetPos = (newPos: number) => {
		let v = newPos * getPValue();

		if (step) {
			let round = step.toString().includes(".") ? step.toString().split(".")[1].length : 0;
			v = Math.min(parseFloat((step === 1 ? Math.round(v) : Math.ceil(v / step) * step).toFixed(round)), max)
			newPos = v / getPValue();
		}

		setPointerPos(newPos)
		onChange(min + v);
	}

	let onMouseUp = () => {
		setStartMousePos(-1);
	}

	let onMouseDown = (xPos: number) => {
		setStartPointerPos(parseInt(pointer.current!.style.left || "0"))
		setStartMousePos(xPos);
	}
	useEffect(() => {
		if (pointer.current && value)
			roundAndSetPos(getPValue() === 0 ? 0 : (value - min) / getPValue())
	}, [pointer.current, max])

	useEffect(() => {
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("touchmove", onMouseMove, {passive: false});
		window.addEventListener("mouseup", onMouseUp);
		window.addEventListener("touchend", onMouseUp);
		return () => {
			window.removeEventListener("mousemove", onMouseMove);
			window.removeEventListener("touchmove", onMouseMove);
			window.removeEventListener("mouseup", onMouseUp);
			window.removeEventListener("touchend", onMouseUp);
		}
	}, [startMousePos])

	let markStep = Math.ceil(max / 5);
	let keys = [...Array(6).keys()]
	let marks = keys.map(i => {
		let value = i === 0 ? min : (i === keys.length - 1 ? max : i * markStep);
		if (value === 0 || value > max || (i !== 0 && value === min) || (i !== keys.length - 1 && value === max))
			return;

		return <div className={Styles.mark} style={{left: `${(value - min) / getPValue()}px`}} key={i}>
			{value}
		</div>
	})
	let labels = (pointersConf || []).map(i => {
		if (i.val < min || i.val > max)
			return;

		let left = (i.val === 0 ? 0 : (i.val - min)) / getPValue();
		return <div key={i.val}>
			<div className={Styles.label} style={{left: `${left}px`}}><P2>{i.text}</P2></div>
			<div className={Styles.line} style={{left: `${left}px`}}/>
		</div>
	})

	return <div className={Styles.slider} ref={parent}>
		{pointer.current && labels}
		<div style={{left: `${pointerPos}px`}} ref={pointer} className={Styles.pointer} onMouseDown={(e) => {
			onMouseDown(e.pageX);
		}} onTouchStart={(e) => onMouseDown(e.touches[0].pageX)}/>
		{pointer.current && marks}
		<div className={Styles.back} onMouseDown={(e) => {
			if (pointer.current && pointer.current.parentElement)
				roundAndSetPos(e.pageX - pointer.current.parentElement.getBoundingClientRect().left)
		}}/>
		<div style={{width: pointerPos + "px"}} className={`${Styles.back} ${Styles.affected}`} onMouseDown={(e) => {
			if (pointer.current && pointer.current.parentElement)
				roundAndSetPos(e.pageX - pointer.current.parentElement.getBoundingClientRect().left)
		}}/>
	</div>
});
export default Slider;