import { observer } from "mobx-react";
import React from "react";
import Styles from "./PaginatorView.module.scss";
import { Paginator } from "../../helpers/Paginator";
import Button, { EButtonType } from "./Button";
import { useSearchParams } from "react-router-dom";


const PaginatorView = observer(({paginator}: { paginator: Paginator }) => {

	const [searchParams, setSearchParams] = useSearchParams();
	let pages: number[] = [0];
	const per_page_variants = [10, 50, 100, 250, 0];

	const moveToPage = (p: number) => {
		searchParams.set("p", p.toString());
		setSearchParams(searchParams);
	}

	if (paginator.getMaxPageNum > 0) {
		if (paginator.getMaxPageNum <= 10) {
			pages = [...Array(paginator.getMaxPageNum).keys(), paginator.getMaxPageNum];
		} else {
			if (paginator.currentPageNum > 2)
				pages.push(-1);

			for (let i = paginator.currentPageNum - 1; i <= paginator.currentPageNum + 1; i++) {
				if (i > 0 && i <= paginator.getMaxPageNum)
					pages.push(i);
			}
			if (paginator.currentPageNum < paginator.getMaxPageNum - 1) {
				if (paginator.getMaxPageNum - paginator.currentPageNum - 1 > 1)
					pages.push(-1);
				pages.push(paginator.getMaxPageNum);
			}
		}
	}

	return <div className={Styles.paginator_container}>
		<Button onClick={() => moveToPage(paginator.prevPageNum)} type={EButtonType.MINIMAL} text={"PREV"}
				disabled={paginator.currentPageNum === 0}/>
		{
			pages.map((it) => {
				return <div
					className={it === paginator.currentPageNum ? Styles.page_current : (it !== -1 ? Styles.page_nav : Styles.page)}
					onClick={() => {
						if (it !== -1)
							moveToPage(it)
					}
					}>
					{it === -1 ? "..." : it}
				</div>
			})
		}
		<Button onClick={() => moveToPage(paginator.nextPageNum)} text={"NEXT"} type={EButtonType.MINIMAL}
				disabled={paginator.currentPageNum === paginator.getMaxPageNum}/>
		<input type={"number"} step={1} min={0} max={paginator.getMaxPageNum} value={paginator.currentPageNum}
			   onChange={(e) => {
				   let page = parseInt(e.currentTarget.value);
				   if (isNaN(page))
					   page = 0;
				   moveToPage(page);
			   }}/>
		<div className={Styles.paginator_container}>
			<select placeholder={`per page. default ${paginator.showPerPage}`}
					defaultValue={parseInt(searchParams.get("per_page") || "10")}
					onChange={(it) => {
						searchParams.set("per_page", it.currentTarget.value);
						searchParams.set("p", "0");
						setSearchParams(searchParams);
					}}>
				{per_page_variants.map(it => {
					return <option value={it}>{it === 0 ? "All" : `${it} per page`}</option>
				})}
			</select>
		</div>

	</div>;
});

export default PaginatorView;

