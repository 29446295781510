import React, { useEffect } from 'react';
import Styles from "./ExpoCard.module.scss";
import Button from "../landing/components/Button";
import { useNavigate } from "react-router-dom";
import H3 from "../../components/typography/H3";
import P2 from "../../components/typography/P2";
import { makeExpoPath } from "../../helpers/PageHelper";
import { formatDaysLeft } from "../../helpers/formatters";
import classNames from "classnames";

interface ExpoCardProps {
	id: number,
	img: string,
	title: string,
	start_at: number,
}

//TODO cap: remove this constant when project will be ready to start
const YEAR = 31536000;

const ExpoCard = (props: ExpoCardProps) => {
	const [time, setTime] = React.useState(props.start_at - Date.now() / 1000);

	const navigate = useNavigate();

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(props.start_at - Date.now() / 1000)
		}, 1000)
		return () => {
			clearInterval(interval);
		}
	}, []);

	return (
		<div className={classNames(Styles.expo, time > 0 && Styles.disabled)}>
			<img src={props.img} alt="alt"/>
			<div className={Styles.expo_card}>
				<div className={Styles.heading}>
					<H3>{props.title}</H3>
					{time > 0 && <P2>{time > YEAR ? "postponed" : formatDaysLeft(time)}</P2>}
				</div>
				<Button disabled={time > 0} onClick={() => navigate(makeExpoPath(props.id))}>Open</Button>
			</div>
		</div>
	);
};

export default ExpoCard;
