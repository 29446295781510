import React from 'react';

const PackIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
		<path
			d="M3.75044 27.4501L15.7504 31.7001C15.7754 31.7001 15.8004 31.7251 15.8254 31.7251C15.8504 31.7251 15.8504 31.7251 15.8754 31.7251C15.9004 31.7251 15.9254 31.7251 15.9754 31.7251H16.0004C16.0504 31.7251 16.0754 31.7251 16.1254 31.7251H16.1504C16.1754 31.7251 16.2004 31.7001 16.2504 31.7001L28.2504 27.4501C28.5504 27.3501 28.7504 27.0501 28.7504 26.7501V14.4501L31.6504 9.62507C31.6754 9.57507 31.7004 9.52507 31.7004 9.50007V9.47507C31.7254 9.42507 31.7254 9.37507 31.7254 9.32507C31.7254 9.22507 31.7254 9.12507 31.7004 9.02507V9.00007C31.6754 8.92507 31.6254 8.85007 31.5754 8.77507C31.5004 8.67507 31.3754 8.60007 31.2504 8.55007C31.2504 8.55007 31.2504 8.55007 31.2254 8.55007L19.2254 4.30007C18.9004 4.17507 18.5254 4.32507 18.3254 4.62507L16.0004 8.55007L13.6504 4.62507C13.4754 4.32507 13.1004 4.17507 12.7504 4.30007L0.750444 8.55007C0.550444 8.62507 0.375444 8.80007 0.300444 9.00007C0.225444 9.20007 0.250444 9.45007 0.350444 9.62507L3.25044 14.4501V26.7501C3.25044 27.0751 3.45044 27.3501 3.75044 27.4501ZM6.25044 14.2501L16.0004 10.8001L20.2004 12.2751L18.7504 12.7751C18.5754 12.8251 18.4504 12.9501 18.3504 13.1001L15.6754 17.6001L6.25044 14.2501ZM4.75044 15.3001L15.2504 19.0251V29.9251L4.75044 26.2001V15.3001ZM19.3254 5.90007L28.7504 9.25007L26.6504 10.0001L22.4504 11.5001L17.1254 9.62507L19.3254 5.90007ZM2.12544 9.65007L12.6754 5.90007L14.9004 9.60007L4.35044 13.3501L2.12544 9.65007Z"
			fill={color}/>
	</svg>
);

export default PackIcon;
