import { observer } from "mobx-react";
import { model } from "../../App";
import { BurnPuzzlesAction } from "../../eos/actions/BurnPuzzlesAction";
import Styles from "./PopUpPuzzlesView.module.scss"
import Slider from "./Slider";
import { useState } from "react";
import Button from "../../pages/landing/components/Button";
import { L } from "../../l10n/L10n";
import P1 from "../typography/P1";
import { PopUpBase } from "../shared/PopUpBase";

const AddPuzzlesView = observer(({painting_id, onClose}: { painting_id: number, onClose: () => void }) => {
	let painting = model.paintings.get(painting_id);
	let neededPuzzles = model.atomicAssets.getPaintingNeededFromAvailable(painting?.depositedPuzzlesIds || [], painting_id);
	let [amount, setAmount] = useState(0)

	return <PopUpBase title={L.popup.deposit.title} onShowPopUp={onClose} className={Styles.width}>
			<P1>{L.popup.deposit.body}</P1>
			<Slider min={1}
					value={Math.min(neededPuzzles.length, 25)}
					max={neededPuzzles.length}
					step={1}
					pointersConf={[{val: 25, text: "recommended"}]}
					onChange={(val: number) => {
						setAmount(val)
					}}
			/>
			<Button onClick={() => {
				new BurnPuzzlesAction(painting_id, neededPuzzles.slice(0, amount)).onSuccess(() => {
					if (amount === neededPuzzles.length)
						onClose();
				}).executeAsync()
			}}>{L.button.add_puzzles.replace('$amount$', amount.toString())}</Button>
	</PopUpBase>
});
export default AddPuzzlesView;