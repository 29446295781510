import {EosApi, EosUtils, IEosAction, IEosAuth, IEosTransactResult, ITransactOptions} from "@pro/common/eos";
import {
    IConfig,
    IHpainting, ILottery,
    IPainting, IRandJob,
    TActionName,
    TActionParam
} from "@pro/common/contracts/expo/expo_types";

export class ExpoContract
{
    private readonly _api: EosApi;
    private readonly _account: string;

    constructor(api: EosApi, account: string) {
        this._api = api;
        this._account = account;
    }

    action<N extends TActionName>(
        name: N,
        auth: IEosAuth | IEosAuth[],
        data: TActionParam<N>,
        options?: Partial<ITransactOptions>,
    ): Promise<IEosTransactResult | null> {
        return this._api.transact([this.makeAction(name, auth, data)], options);
    }

    actions<N extends TActionName>(actions: { name: N, data: TActionParam<N> }[],
                                   auth: IEosAuth | IEosAuth[]): Promise<IEosTransactResult | null> {
        return this._api.transact(
            actions.map((d) => this.makeAction(d.name, auth, d.data))
        );
    }

    makeAction<N extends TActionName>(name: N,
                                      auth: IEosAuth | IEosAuth[],
                                      data: TActionParam<N>): IEosAction {
        return {
            name: name,
            account: this._account,
            authorization: EosUtils.castAuth(auth),
            data: data,
        };
    }

    async getAllRandJobs() {
        return this._api.getFullTable<IRandJob>({
            code: this._account,
            scope: this._account,
            table: "randjobs",
            key_type: "i64",
        });
    }

    async getAllPaintings(owner: string) {
        return this._api.getFullTable<IPainting>({
            code: this._account,
            scope: owner,
            table: "paintings",
            key_type: "i64",
            scope_type: "name"
        });
    }

    async getPainting(owner: string, identifier: number) {
        return this._api.getRecord<IPainting>(identifier, {
            code: this._account,
            scope: owner,
            table: "paintings",
            key_type: "i64",
            scope_type: "name"
        });
    }

    async findLotteryByOwner(owner: string):Promise<ILottery | undefined> {
        return this._api.findRecord<ILottery>(owner, {
            code: this._account,
            scope: this._account,
            table: "lotteries",
            key_type: "name",
        });
    }

    async findPainting(owner: string, identifier: number) {
        return this._api.findRecord<IPainting>(identifier, {
            code: this._account,
            scope: owner,
            table: "paintings",
            key_type: "i64",
            scope_type: "name"
        });
    }

    // async getAllPuzzles() {
    //     return this._api.getFullTable<IExpoPuzzle>({
    //         code: this._account,
    //         scope: this._account,
    //         table: "puzzles",
    //         key_type: "i64",
    //         scope_type: "name"
    //     });
    // }

    async getPaintingChecksum(painting_id: number):Promise<IHpainting | undefined> {
        let res = await this._api.findRecord<IHpainting>("", {
            code: this._account,
            scope: painting_id.toString(),
            table: "hpaintings",
            scope_type: "i64",
            key_type: "name",
            index_position: "2",
        });
        return res && res.inventor === "" ? res : undefined;
    }

    get active(): IEosAuth {
        return {actor: this._account, permission: "active"};
    }

    get account() {
        return this._account;
    }


    async getConfig() {
        let result = await this._api.getTable<IConfig>({
            code: this._account,
            scope: this._account,
            table: "config",
            key_type: "i64",
            limit: 1,
        });

        return result.rows[0];
    }

}