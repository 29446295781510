import React from 'react';

const UndoIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M15.9989 5.99998C14.4228 5.99792 12.8618 6.30743 11.4057 6.91072C9.94953 7.514 8.62702 8.39915 7.51415 9.51529L3.99951 5.99998V15H12.9995L9.6359 11.6363C10.4881 10.7825 11.5033 10.1089 12.6211 9.65528C13.7388 9.20168 14.9363 8.97741 16.1425 8.99579C17.3486 9.01417 18.5388 9.27483 19.6422 9.76229C20.7456 10.2497 21.7397 10.954 22.5656 11.8333C23.3914 12.7126 24.032 13.7489 24.4494 14.8807C24.8668 16.0125 25.0524 17.2166 24.9952 18.4215C24.938 19.6265 24.6392 20.8076 24.1165 21.8947C23.5938 22.9819 22.8578 23.9529 21.9524 24.75L23.9369 27C25.765 25.3878 27.0589 23.257 27.6462 20.8914C28.2335 18.5257 28.0865 16.0373 27.2247 13.7573C26.3629 11.4772 24.8272 9.51367 22.822 8.12792C20.8167 6.74217 18.437 5.99994 15.9996 5.99998H15.9989Z"
			fill={color}/>
	</svg>
);

export default UndoIcon;
