import { EosActionBase } from "../EosActionBase";
import { app, eos, model, world } from "../../App";
import { TUint64 } from "@pro/common/contracts/common_types";

export class UnboxNeftyAction extends EosActionBase {
	constructor(private assetId: TUint64)
	{
		super("unbox_nefty");
	}

	async onExecute(): Promise<void>
	{
		await eos.aaContract.action("transfer", eos.userAuth, {
			asset_ids: [this.assetId],
			from: model.userName,
			to: app.chainConf.NP_ACCOUNT,
			memo: "unbox",
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdateNeftyClaimAssets(this.assetId);
		model.atomicAssets.removeItems([this.assetId]);
	}
}