import React from 'react';
import Styles from "./PageContainer.module.scss"
import classNames from "classnames";

const PageContainer = (props: React.HTMLAttributes<HTMLElement>) => (
	<div className={classNames(Styles.container, props.className)} {...props}>
		{props.children}
	</div>
);

export default PageContainer;
