import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";

export class WithdrawAction extends EosActionBase {
	constructor(private pool: StakingPoolModel)
	{
		super("pool_withdraw");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("withdraw", eos.userAuth, {
			pool_id: this.pool.poolId,
			owner: eos.user
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdateBalance(this.pool.rewardContract, this.pool.rewardSymbol);
		await world.updateUserPool(model.userName, this.pool.poolId);
	}
}