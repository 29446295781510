import { TUint64 } from "@pro/common/contracts/common_types";
import { AtomicAssetModel } from "./AtomicAssetModel";
import { action, makeObservable, observable } from "mobx";
import { AssetConf } from "@pro/common/conf";
import { app } from "../App";

export class AtomicAssetTable {
	items = new Map<TUint64, AtomicAssetModel>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItems: action,
			clearItems: action,
		});
	}

	updateItems(models: AtomicAssetModel[])
	{
		for (const model of models)
			this.items.set(model.assetId, model);
	}

	removeItems(assetIds: TUint64[])
	{
		for (const assetId of assetIds) {
			this.items.delete(assetId);
		}
	}

	clearItems()
	{
		this.items.clear();
	}

	query(predicate: (it: AtomicAssetModel) => boolean): AtomicAssetModel[]
	{
		let result = [];
		for (let item of this.items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll()
	{
		return this.query(() => true);
	}

	get collectors()
	{
		return this.query((it) => it.schemaName === AssetConf.COLLECTORS_SCHEMA_NAME);
	}


	get paintings()
	{
		return this.query((it) => it.schemaName === AssetConf.PAINTINGS_SCHEMA_NAME);
	}

	get packs()
	{
		return this.query((it) => it.schemaName === AssetConf.PACKS_SCHEMA_NAME);
	}

	get count(): number
	{
		return this.items.size;
	}

	getUniquePuzzlesByPaintingId(painting_id: number): AtomicAssetModel[]
	{
		const puzzles = this.query(
			(p) => p.asset.collection_name === app.chainConf.COLLECTION_NAME &&
				p.schemaName === AssetConf.PUZZLES_SCHEMA_NAME && (p.getImmutableProp("painting_id") as number === painting_id))

		return puzzles.filter((value, index, self) => {
			let checksum = value.getImmutableProp("puzzle_id");
			let existing = self.find(it => it.getImmutableProp("puzzle_id") === checksum);
			return !existing || self.indexOf(existing) === index;
		});
	}

	getPaintingNeededFromAvailable(painting_templates: number[], painting_id: number): AtomicAssetModel[]
	{
		let uniqueCompatiblePuzzles = this.getUniquePuzzlesByPaintingId(painting_id);
		if (painting_templates.length === 0)
			return uniqueCompatiblePuzzles;

		return uniqueCompatiblePuzzles.filter(it => painting_templates.indexOf(it.getImmutableProp("puzzle_id") as number) === -1);
	}
}