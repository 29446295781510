import React from 'react';

const PlusIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M0.666656 11.9785C0.666656 11.6222 0.956605 11.3333 1.31428 11.3333H22.6857C23.0434 11.3333 23.3333 11.6222 23.3333 11.9785C23.3333 12.3348 23.0434 12.6237 22.6857 12.6237H1.31428C0.956605 12.6237 0.666656 12.3348 0.666656 11.9785Z"
			fill={color}/>
		<path
			d="M12.0215 0.666672C12.3778 0.666672 12.6667 0.956621 12.6667 1.31429L12.6667 22.6857C12.6667 23.0434 12.3778 23.3333 12.0215 23.3333C11.6652 23.3333 11.3763 23.0434 11.3763 22.6857L11.3763 1.31429C11.3763 0.956621 11.6652 0.666672 12.0215 0.666672Z"
			fill={color}/>
	</svg>
);

export default PlusIcon;
