import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { IPoolAsset, STAction } from "@pro/common/contracts/staking";
import { model, world } from "../../../../App";
import { EosAsset, EosSymbol } from "@pro/common/eos";
import Styles from "./Summary.module.scss";
import Button from "../../../shared/Button";
import { RegPoolAction } from "../../../../eos/actions/staking/RegPoolAction";
import { PagePath } from "../../../../helpers/PageHelper";
import AdminPoolInfoView from "../../shared/AdminPoolInfoView";
import { StakingPoolModel } from "../../../../models/StakingPoolModel";
import { PoolAssetModel } from "../../../../models/PoolAssetModel";

const Summary = observer(() => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [params, setParams] = useState<STAction["regpool"]>({} as STAction["regpool"]);

	const load = async (account: string) => {
		await world.updateTokenStat(account);
		await world.updateAccount(account);
	}

	const getSymbol = (contract: string, code: string): EosSymbol => {
		return (model.data.tokens.get(contract) || [])
			.map(it => EosAsset.parse(it.max_supply).symbol)
			.find(symbol => symbol.code === code) || new EosSymbol("POW", 0);
	}

	const getAsset = (quantity: number, contract: string, code: string): EosAsset => {
		let sym = getSymbol(contract, code);
		return new EosAsset(quantity * 10 ** sym.precision, sym);
	}

	const finalizeParamsForContract = (res: IPoolAsset[]) => {
		let p = [];
		for (let c of res) {
			let futureKey = c.formats.find(it => it.format_value === "")!;
			let formats = futureKey.power > 0 ?
				c.formats.filter(it => it.format_value === "" || it.power !== futureKey.power) :
				c.formats.filter(it => it.format_value !== "" && it.power !== futureKey.power);

			p.push({...c, formats: formats});
		}
		return p;
	}

	useEffect(() => {
		let p = {} as STAction["regpool"];

		const nft_p: IPoolAsset[] = JSON.parse(searchParams.get("accept_asset_conf") || "[]");
		p.owner = model.userName;
		p.accept_contract = searchParams.get("accept_contract") || "";
		p.accept_symbol = getSymbol(p.accept_contract, searchParams.get("accept_symbol") || "").toString();
		p.reward_contract = searchParams.get("reward_contract") || "";
		p.reward_amount = getAsset(parseFloat(searchParams.get("reward_amount") || ""), searchParams.get("reward_contract") || "", searchParams.get("reward_symbol") || "").toString();
		p.periods_count = parseInt(searchParams.get("periods_count") || "0")
		p.time_per_period_sec = parseInt(searchParams.get("time_per_period_sec") || "0") * 60;
		p.start_at = parseInt(searchParams.get("start_at") || "0");
		p.unstake_time_sec = parseInt(searchParams.get("unstake_time_sec") || "0") * 60;
		p.min_stake = getAsset(parseInt(searchParams.get("min_stake") || "0"), p.accept_contract, EosSymbol.parse(p.accept_symbol).code).toString();
		p.max_stake = getAsset(parseInt(searchParams.get("max_stake") || "0"), p.accept_contract, EosSymbol.parse(p.accept_symbol).code).toString();
		p.accept_asset_conf = nft_p

		if (p.accept_contract !== "")
			load(p.accept_contract).catch();
		if (p.reward_contract !== "")
			load(p.reward_contract).catch();

		for (let k of nft_p) {
			world.loadCollection(k.collection).catch();
		}

		setParams(p);

	}, []);

	if (params.reward_contract === "" || (params.accept_contract === "" && params.accept_asset_conf.length === 0))
		return <>
			<h1>Pool not configured yet</h1>
			<hr/>
		</>

	const pool = new StakingPoolModel({
		...params,
		owner: "",
		accept_assets: params.accept_asset_conf,
		period_reward: {quantity: params.reward_amount, contract: params.reward_contract},
	} as any);

	return <div className={Styles.container}>
		<h1>Summary</h1>
		<hr/>
		{Object.keys(params).length > 0 &&
            <AdminPoolInfoView poolAssets={params.accept_asset_conf.map(it => new PoolAssetModel(it))} pool={pool}/>}
		<Button text={"create"} onClick={() => {

			new RegPoolAction({
				...params,
				accept_asset_conf: finalizeParamsForContract(params.accept_asset_conf)
			}).onSuccess(() => {
				navigate(PagePath.ADMIN_POOLS);
			}).executeAsync()
		}}/>
	</div>
});

export default Summary;