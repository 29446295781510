import { useNavigate, useParams } from "react-router-dom";
import { model } from "../../App";
import Layout from "../shared/Layout";
import Styles from "./StakeAssets.module.scss";
import { observer } from "mobx-react";
import { EosAsset } from "@pro/common/eos";
import { getPathById, PagePath } from "../../helpers/PageHelper";
import Button from "../shared/Button";
import { useState } from "react";
import { StakeAssetsAction } from "../../eos/actions/staking/StakeAssetsAction";
import { UnstakeAction } from "../../eos/actions/staking/UnstakeAction";
import { TUint64 } from "@pro/common/contracts/common_types";
import TemplateMedia from "../shared/TemplateMedia";
import Loading from "../shared/Loading";

const StakeAssets = observer(() => {
	const params = useParams();
	const navigate = useNavigate();
	const pool = model.pools.items.get(parseInt(params["id"] || "-1"));
	const [toStake, setToStake] = useState<TUint64[]>([]);
	const [toUnstake, setToUnstake] = useState<TUint64[]>([]);

	if (!pool)
		return <Layout children={<h1>Pool {params["id"] || "-1"} not found</h1>}/>

	const templates = model.getAcceptedTemplates(model.poolAssets.queryAll(pool.poolId));
	const myAssets = templates.length === 0 ? [] : model.atomicAssets.queryAll().filter(it => templates.map(it => it.t.template_id).includes(it.asset.template_id));
	const myStakedAssets = model.stakedAssets.query(model.userName, it => it.poolId === pool.poolId);

	for (let a of pool.acceptAssets)
		if (!model.atomicTemplates.findByCollectionName(a.collection))
			return <Layout children={<Loading/>}/>

	const reverse = (a: TUint64[], id: TUint64): TUint64[] => {
		let index = a.indexOf(id);
		if (index === -1)
			a.push(id)
		else
			a.splice(index, 1);
		return a;
	}


	const content = <div className={Styles.container}>
		<div className={Styles.header}>
			{pool.owner !== "" ? `Pool #${pool.poolId}` : "New Pool Summary"}
		</div>
		{
			templates.length > 0 && <>
                <div className={Styles.accept_nft_box}>
                    <h1>
                        Accepting assets:
                    </h1>
					{
						templates.map(it => {
							return <div className={Styles.asset}>
								<TemplateMedia t={it.t}/>
								<span>
                            Power: {it.power}
									{pool.minStake.symbol.precision > 1 ?
										<div>equal to {new EosAsset(it.power, pool.minStake.symbol).toString()}</div> : ""}
                            </span>
							</div>
						})
					}
                </div>
				{
					!pool.ended && myAssets.length > 0 && <div className={Styles.accept_nft_box}>
                        <h1>
                            My compatible assets:
                            <Button text={"select all"}
                                    onClick={() => setToStake(toStake.slice().concat(myAssets.map(it => it.assetId)))}/>
                            <Button text={"cancel all"} onClick={() => setToStake([])}/>
                        </h1>
						{myAssets.map(asset => {
							const power = templates.find(it => it.t.template_id === asset.asset.template_id)?.power || 0;
							return <div className={toStake.includes(asset.assetId) ? Styles.selected_asset : Styles.asset}
										onClick={() => setToStake(reverse(toStake.slice(), asset.assetId))}>
								<img src={asset.img}/>
								<span>
                            Power: {power}
									{pool.minStake.symbol.precision > 1 ?
										<div>equal to {new EosAsset(power, pool.minStake.symbol).toString()}</div> : ""}
                        </span>
							</div>
						})}
                    </div>
				}
				{
					myStakedAssets.length > 0 && <div className={Styles.accept_nft_box}>
                        <h1>
                            My staked assets:
                            <Button text={"select all"}
                                    onClick={() => setToUnstake(toUnstake.slice().concat(myStakedAssets.map(it => it.assetId)))}/>
                            <Button text={"cancel all"} onClick={() => setToUnstake([])}/>
                        </h1>
						{myStakedAssets.map(asset => {
							const template = templates.find(it => it.t.template_id === asset.templateId);
							const power = template?.power || 0;
							return <div className={toUnstake.includes(asset.assetId) ? Styles.selected_asset : Styles.asset}
										onClick={() => setToUnstake(reverse(toUnstake.slice(), asset.assetId))}>
								<TemplateMedia t={template?.t}/>
								<span>
                            Power: {power}
									{pool.minStake.symbol.precision > 1 ?
										<div>equal to {new EosAsset(power, pool.minStake.symbol).toString()}</div> : ""}
                        </span>
							</div>
						})}
                    </div>
				}
                <Button text={"cancel"} onClick={() => navigate(getPathById(PagePath.POOL, pool.poolId))}/>
				{toStake.length > 0 && <Button text={"stake"} onClick={() => {
					new StakeAssetsAction(pool, toStake).onSuccess(() => setToStake([])).executeAsync();
				}}/>}
				{toUnstake.length > 0 && <Button text={"unstake"} onClick={() => {
					const q = new EosAsset(0, pool.acceptSymbol);
					new UnstakeAction(q, pool, toUnstake).onSuccess(() => setToUnstake([])).executeAsync();
				}}/>}
            </>
		}
	</div>

	return <Layout children={content}/>
});
export default StakeAssets;