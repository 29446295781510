import React from 'react';

const ChevronUpIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M17.8395 14.7634C17.5999 15.0475 17.179 15.0804 16.8995 14.8369L12 10.5696L7.10054 14.8369C6.82099 15.0804 6.40012 15.0475 6.16051 14.7634C5.92089 14.4794 5.95327 14.0517 6.23282 13.8082L11.5661 9.16308C11.8158 8.94564 12.1842 8.94564 12.4339 9.16308L17.7672 13.8082C18.0467 14.0517 18.0791 14.4794 17.8395 14.7634Z"
			  fill={color}/>
	</svg>
);

export default ChevronUpIcon;
