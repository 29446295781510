import React from 'react';
import { PagePath } from "../../helpers/PageHelper";
import { model } from "../../App";
import Styles from "./Footer.module.scss"
import { useMatch } from "react-router-dom";
import ExpoIcon from "../../icons/ExpoIcon";
import PackIcon from "../../icons/PackIcon";
import MarketIcon from "../../icons/MarketIcon";
import AssetsIcon from "../../icons/AssetsIcon";
import Button from "../../pages/landing/components/Button";
import Tooltip from "./Tooltip";
import classNames from "classnames";
import LotteryIcon from "../../icons/LotteryIcon";
import { observer } from "mobx-react";

export interface INavigationLink {
	path: string,
	icon: React.ReactNode,
	title: string,
	hidden: boolean
}

const links: INavigationLink[] = [
	{
		path: PagePath.EXPOS,
		icon: <ExpoIcon/>,
		title: "Expositions",
		hidden: false
	},
	{
		path: PagePath.PACKS,
		icon: <PackIcon/>,
		title: "Packs",
		hidden: false
	},
	{
		path: PagePath.ASSETS,
		icon: <AssetsIcon/>,
		title: "Assets",
		hidden: false
	},
	{
		path: PagePath.SHOP,
		icon: <MarketIcon/>,
		title: "Shop",
		hidden: false
	}
];

const Footer: React.FC<React.HTMLAttributes<HTMLElement>> = observer(() => {
	return (
		<footer className={Styles.footer}>
			{links.map((it, index) => (
				<Tooltip text={it.title} key={`${it.title}_${index}`}
						 className={classNames({[Styles.hidden]: it.hidden})}>
					<Button
						path={it.path}
						icon={it.icon}
						isActive={Boolean(useMatch(`${it.path}/*`))}
					/>
				</Tooltip>
			))}
			{model.lottery.item &&
            <>
                <hr/>
                <Button path={PagePath.LOTTERY} icon={<LotteryIcon/>}/>
            </>
			}
		</footer>
	);
});

export default Footer;
