import React from 'react';

const InfoIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM14.668 16.128L15.052 17.7H16.084L16.204 17.244C16.564 17.14 16.892 16.992 17.188 16.8C17.484 16.608 17.74 16.388 17.956 16.14C18.18 15.884 18.352 15.612 18.472 15.324C18.6 15.036 18.664 14.744 18.664 14.448C18.664 13.984 18.54 13.568 18.292 13.2C18.044 12.824 17.708 12.532 17.284 12.324C16.86 12.108 16.384 12 15.856 12C15.312 12 14.788 12.152 14.284 12.456C13.788 12.76 13.36 13.188 13 13.74L14.308 14.952C14.532 14.536 14.776 14.236 15.04 14.052C15.304 13.86 15.568 13.764 15.832 13.764C16.016 13.764 16.176 13.796 16.312 13.86C16.448 13.924 16.556 14.016 16.636 14.136C16.716 14.248 16.756 14.384 16.756 14.544C16.756 14.704 16.72 14.856 16.648 15C16.576 15.136 16.476 15.264 16.348 15.384C16.228 15.496 16.08 15.596 15.904 15.684C15.728 15.772 15.536 15.856 15.328 15.936C15.12 16.008 14.9 16.072 14.668 16.128ZM14.908 18.864C14.716 19.064 14.62 19.324 14.62 19.644C14.62 19.988 14.708 20.252 14.884 20.436C15.068 20.62 15.324 20.712 15.652 20.712C15.964 20.712 16.216 20.612 16.408 20.412C16.6 20.212 16.696 19.956 16.696 19.644C16.696 19.3 16.604 19.036 16.42 18.852C16.236 18.66 15.98 18.564 15.652 18.564C15.348 18.564 15.1 18.664 14.908 18.864Z"
			  fill={color}/>
	</svg>
);

export default InfoIcon;

