import { IPoolAsset } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";

export class PoolAssetModel {
	constructor(
		public readonly pool: IPoolAsset,
	)
	{
		makeObservable(this, {});
	}

	get formats()
	{
		return this.pool.formats;
	}

	get format_key()
	{
		return this.pool.format_key;
	}

	get schema_name()
	{
		return this.pool.schema_name;
	}

	get collection()
	{
		return this.pool.collection;
	}
}