import React from 'react';
import Styles from "./Header.module.scss";
import AuthView from "./AuthView";
import { Link } from "react-router-dom";
// import PoolInfo from "./PoolInfo";
import logoSm from "../../images/LogoSM.png";
import logoLg from "../../images/LogoLG.png"
import useWindowSize from "../../hooks/useWindowSize";

const Header = () => {
	const {windowWidth} = useWindowSize();

	const logoImg = React.useMemo(() => {
		return windowWidth > 767 ? logoLg : logoSm
	}, [windowWidth])

	return (
		<header className={Styles.header}>
			<div className={Styles.logo}>
				<Link to="/" >
					<img src={logoImg} alt="logo"/>
				</Link>
			</div>
			{/*<div className={Styles.info}>*/}
			{/*	<PoolInfo/>*/}
			{/*</div>*/}
			<div className={Styles.auth}>
				<AuthView/>
			</div>
		</header>
	);
};

export default Header;
