import { INpClaimAsset } from "@pro/common/contracts/neftyblocksp";
import { AtomicAssetModel } from "./AtomicAssetModel";
import { TUint64 } from "@pro/common/contracts/common_types";

export class NeftyClaimAssetsModel {
	public claim_id: TUint64;

	constructor(public record: INpClaimAsset, public assets: AtomicAssetModel[])
	{
		this.claim_id = record.claim_id;
	}
}