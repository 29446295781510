export const L = {
	page: {
		packs: "My Packs",
		assets: "My Gallery",
		shop: "Shop"
	},
	shared: {
		siteName: "Chain Expo Art",
		deposited: "Deposited:",
		available_needed: "Available/Needed:",
		loading: "Loading...",
		sample: "Sample",
		composed: "Composed",
		wax: "WAX",
		amount: "Amount:",
		balance: "Balance: ",
	},
	expos: {
		title: "Expo $id$",
	},
	button: {
		back: "Back",
		buy: "Buy",
		remove_puzzles: "Remove - $amount$ puzzles",
		add_puzzles: "Add - $amount$ puzzles",
		deposit_puzzles: "Deposit Puzzles",
		compose: "Compose",
		claim: "Claim"
	},
	lottery: {
		lottery_not_found: "Lottery not found",
		lottery: "Lottery",
		lottery_prize_pool: "Lottery Jackpot",
		scratch_your_lottery: "Scratch your Lottery ticket to learn what your prize is.",
		tries_used: "Tries used: ",
		bought_tries: "Bought tries: ",
		price: "Price:",
		buy_try: "Buy a try",
		your_reward: "Your reward",
		lucky_num: "lucky number",
		claim_reward: "Claim reward",
		play_later: "play later",
		congrats: "Congrats!",
		what_pity: "What a pity!",
		you_have_no_reward: "You have no reward(",

	},
	unpack: {
		tittle_puzzles: "Unpacked puzzles",
		tittle_painting: "Unpacked painting",
		new_puzzels: "Puzzels: $amount$",
	},
	popOver: {
		buy_puzzle_first: "Buy puzzle first",
	},
	popup: {
		deposit: {
			title: 'Deposit puzzles',
			body: "Choose the number of puzzles to deposit in one transaction. A large number of puzzles in one transaction may not be deposited."
		},
		withdraw: {
			title: 'Withdraw puzzles',
			body: "Choose the number of puzzles to withdraw in one transaction. A large number of puzzles in one transaction may not be withdrawn."
		},
	},
	landing: {
		cards: {
			card1: {
				title: "Reuse your NFTs",
				content: "Stake NFTs on the Chain Expo Art page within the Pepper Stake NFT Staking System. Earn ART token as revenue.",
				link: "Review the NFTs list"
			},
			card2: {
				title: "Have fun playing",
				content: "Use ART tokens to buy packs with pieces of famous artwork Combine a whole picture playing a classical puzzle game, and craft an NFT of the masterpiece. Crafted NFT you can stake in the Gallery to earn even more ART token.",
				link: "How to play?"
			},
			card3: {
				title: "Win a prize",
				content: "Crafting an NFT grants you a lottery ticket with the opportunity to win ART token. Test your luck and find out if fortune favors you!",
				link: "Learn more about the Lottery"
			},
		},
		sliders: {
			slider1: {
				title: "Enjoy an exciting journey through the world of fine arts",
				text: "Our project is limited in time & combines the modern world of online games & the classical world of fine arts, providing the player with an opportunity to win a part of the Revenue Fund during an exciting artistic quest."
			},
			slider2: {
				title: "Buy a Puzzle Pack",
				text: "Visit the PepperStake platform to collect your ART token. Visit ChainExpo.art to buy a Puzzle Pack. Each pack contains a mix of pieces of three paintings belonging to the brush of one of ten famous classical artists participating in ChainExpo.art. Each piece is NFT."
			},
			slider3: {
				title: "Compose a whole picture",
				text: "Collect all the painting pieces, and combine them into a whole picture. After you finish a masterpiece, create its NFT."
			},
			slider4: {
				title: "Get NFT & Lottery ticket",
				text: "As a result of NFT craft, you’ll get a new NFT & Lottery ticket. Stake your Masterpiece NFT to earn more ART token. Scratch your lottery ticket to learn what your prize is."
			},
			slider5: {
				title: "Proceed to the next artist",
				text: "The game is divided into ten stages. Every next step lasts two weeks, enabling Packs with three works of another genius artist. Finish the game with a whole collection of masterpieces & a chance to get a prize in WAX."

			}
		},
		accordions: {
			accordion1: {
				title: "What is chainexpo.art?",
				text: "Chainexpo.art is an art quest (game) on the WAX blockchain that aims to blur the line between contemporary digital art and world classics. Participants (players) will have the opportunity to take part in the educational collect-to-earn game, interesting lotteries with real prizes, and finally compete for the main prize of the project."
			},
			accordion2: {
				title: "Which collections will participate in the game mechanics of chainexpo.art?",
				text: "From the start of the chainexpo.art project, 20 pools will be opened for popular collections on the WAX blockchain. Information about the collections participating in the project will be updated on our website and in official communication channels."
			},
			accordion3: {
				title: "Who can participate in the project?",
				text: "Owners of NFT collections on the WAX blockchain that will be added to our staking pool can participate in the project."
			},
			accordion4: {
				title: "What wallets does the chainexpo.art project support?",
				text: "All users of the wax cloud wallet and anchor will have the opportunity to participate in the project."
			},
			accordion5: {
				title: "What is ART token?",
				text: "ART token is a token based on the EOS IO standard, which is produced in our project. It can be used to purchase in-game assets and also can be traded on exchanges and swapped for WAX."
			},
			accordion6: {
				title: "Is the entrance to the project paid and what will I get from participating in the project?",
				text: "Participation in our project is free, to start you only need a wallet and NFT collections that hav staking pools open in chainexpo.art. For staking your NFT in our pool, you will receive rewards i ART token, which will be available for exchange on the WAX blockchain. Also, participating in the project, you can take part in interesting lotteries and compete for the main prize."
			},
			accordion7: {
				title: "How to get a Collector Prize NFT?",
				text: "To obtain a Collector Prize NFT, you must be the first person to complete the painting."
			}
		},
		sections: {
			about: {
				title: "About",
				subtitle: "Enjoy an exciting journey through the world of fine arts"
			},
			howItWorks: {
				title: "How it works",
				subtitle: "Interesting and profitable"
			},
			profit: {
				title: "Profit",
				subtitle: "Hurry up to get the WAX",
				text: "The main prize in WAX will become available for players on Expo 10. Hurry up to complete Expo 1-9 as fast as you can! The WAX Prize pool will be funded through project revenues, with a guaranteed amount of",
				highlight: "500,000 WAX"
			},
			collectorNft: {
				title: "Collector Prize NFT",
				subtitle: "Finish first,\Earn Rare NFTs",
				text: "Collector Prize is a unique NFT of a masterpiece, an additional reward for a player who manages to be the first to complete the painting. The Prize is one of its kind for every painting. Its mining power surpasses the mining power of any other NFT of this painting. This NFT also can be staked in a any pool of ten game stages."
			},
			ticket: {
				title: "Ticket",
				subtitle: "Scratch and Win ART Token",
				text: "Discover your prize by scratching a Lottery ticket! Gain an additional prospect to be rewarded throughout all 10 expositions, as you'll get a few free attempts. Don't miss a chance to win a ART token!",
			},
			faq: {
				title: "FAQ",
				subtitle: "Still have questions?"
			},
			areYouReady: {
				text: "To begin, take a look at our list of WAX projects and NFTs you can stake to get an ART token. The project is limited in time, so start playing now! Experience the thrill of turning fine art into a valuable token.",
				subtitle: "Are you ready to play?"
			},
			partnership: {
				title: "Our pools",
				subtitle: "Stake NFTs",
				pre: "Stake NFTs using",
				link: "the Pepper Stake NFT",
				post: "Staking System. Earn ART token as revenue to buy puzzle packs."

			},
			header: {
				links: {
					link1: "About",
					link2: "How it works",
					link3: "FAQ",
					partners: "Staking",
				}
			},
			banner: {
				stake: "Stake",
				create: "Create",
				repeat: "Repeat",
				have_fun: "Have fun",
				subtitle: "Have fun playing, create masterpieces of fine arts & get revenue along the way."
			}
		},
		buttons: {
			stake_your_nft: "Stake your NFTs",
			play_chain_expo: "Play Chain Expo",
			play_game: "Play game"
		},
		coming_soon: "Coming soon",
		play: "Play",
		login: "Login",
		copyright: "ChainExpo.Art | All rights reserved",
	},
	admin: {
		pools: {
			manage_steps: {
				headers: {
					"0": "Accept token",
					"1": "Accept NFT",
					"2": "Reward token",
					"3": "Configuration",
					"4": "Summary",
				},
				accept_token: {
					header: "Do your staking will accept any token?"
				},
				accept_nft: {
					header: "Do your staking will accept any NFT?"
				},
				configuration: {
					"periods_count": "Periods count",
					"time_per_period_sec": "Time each period in minutes",
					"reward_contract": "Rewards token contract",
					"reward_amount": "Reward per each period",
					"min_stake": "Minimum account stake power",
					"max_stake": "Maximum account stake power",
					"unstake_time_sec": "Time to wait unstake in minutes",
					"start_at": "Start time in UNIX format",
					"accept_contract": "Accepting token contract",
					"accept_symbol": "Accepting token symbol",
					"accept_asset_conf": "Accepting NFT config"
				}
			}
		}
	},
	errors: {
		page_not_found: "Page not found",
		invalid_quantity: "Invalid quantity",
		insufficient_funds: "Insufficient funds"
	},
	placeholder: {
		assets: {
			title: "You don't have any created NFT yet...",
			subtitle: "To create a masterpiece NFT, you need to buy packs with puzzles by ART tokens.",
		},
		puzzles: {
			title: "You don't have any puzzle yet...",
			subtitle: "To create a masterpiece NFT, you need to buy packs with puzzles by ART tokens."
		},
		packs: {
			title: "You don't have any puzzle packs yet...",
			subtitle: "To create a masterpiece NFT, you need to buy packs with puzzles by ART tokens."
		}
	}
};
