import { EosActionBase } from "../EosActionBase";
import { eos, model, world } from "../../App";

export class CleanLotteryFieldAction extends EosActionBase {
	constructor(private pos: number)
	{
		super("clnlottfield");
	}

	async onExecute(): Promise<void>
	{
		await eos.expoContract.action("clnlottfield", eos.userAuth, {
			owner: model.userName,
			pos: this.pos
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateUserLottery(model.userName);
		await world.updateConfig();
	}
}