import H4 from "../typography/H4";
import React from "react";
import Styles from "./ArtValue.module.scss";

const ArtValue = (
	{
		value,
		img,
		symbol
	}: {
		value: string,
		img: string,
		symbol: string
	}) => {
	return (
		<H4 className={Styles.text}>{value} <img src={img} alt={symbol}/> {symbol}</H4>
	);
};

export default ArtValue