import React from 'react';
import Styles from "./H2.module.scss";
import classNames from "classnames";

export const H2 = ({children, className}: React.HTMLAttributes<HTMLElement>) => (
	<h2 className={classNames(Styles.h2, className)}>
		{children}
	</h2>
);

export default H2;
