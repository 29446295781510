import React from 'react';

const ExpandIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M21.7197 10.712C21.908 10.5237 22.0138 10.2683 22.0138 10.002C22.0138 9.73571 21.908 9.48032 21.7197 9.29201C21.5314 9.10371 21.276 8.99792 21.0097 8.99792C20.7434 8.99792 20.488 9.10371 20.2997 9.29201L16.0097 13.592L11.7197 9.29201C11.6267 9.19829 11.5161 9.12389 11.3943 9.07312C11.2724 9.02235 11.1417 8.99622 11.0097 8.99622C10.8777 8.99622 10.747 9.02235 10.6251 9.07312C10.5033 9.12389 10.3927 9.19829 10.2997 9.29201C10.206 9.38498 10.1316 9.49558 10.0808 9.61744C10.03 9.7393 10.0039 9.87 10.0039 10.002C10.0039 10.134 10.03 10.2647 10.0808 10.3866C10.1316 10.5085 10.206 10.6191 10.2997 10.712L15.2997 15.712C15.3927 15.8057 15.5033 15.8801 15.6251 15.9309C15.747 15.9817 15.8777 16.0078 16.0097 16.0078C16.1417 16.0078 16.2724 15.9817 16.3943 15.9309C16.5161 15.8801 16.6267 15.8057 16.7197 15.712L21.7197 10.712Z"
			fill={color}/>
		<path
			d="M11.718 16.2978L16.008 20.5978L20.298 16.2978C20.391 16.2041 20.5016 16.1297 20.6234 16.0789C20.7453 16.0282 20.876 16.002 21.008 16.002C21.14 16.002 21.2707 16.0282 21.3926 16.0789C21.5144 16.1297 21.625 16.2041 21.718 16.2978C21.8117 16.3908 21.8861 16.5014 21.9369 16.6232C21.9877 16.7451 22.0138 16.8758 22.0138 17.0078C22.0138 17.1398 21.9877 17.2705 21.9369 17.3924C21.8861 17.5142 21.8117 17.6249 21.718 17.7178L16.718 22.7178C16.625 22.8115 16.5144 22.8859 16.3926 22.9367C16.2707 22.9875 16.14 23.0136 16.008 23.0136C15.876 23.0136 15.7453 22.9875 15.6234 22.9367C15.5016 22.8859 15.391 22.8115 15.298 22.7178L10.298 17.7178C10.1097 17.5295 10.0039 17.2741 10.0039 17.0078C10.0039 16.7415 10.1097 16.4861 10.298 16.2978C10.4863 16.1095 10.7417 16.0037 11.008 16.0037C11.2743 16.0037 11.5297 16.1095 11.718 16.2978Z"
			fill={color}/>
	</svg>
);

export default ExpandIcon;
