import React from 'react';
import Styles from "./Container.module.scss";
import classNames from "classnames";

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
	fluid?: boolean,
	hasBackground?: boolean
}

const Container = ({children, className, fluid, hasBackground = false, ...props}: ContainerProps) => {
	return (
		<div className={classNames(hasBackground && Styles.bg)}>
			<section className={classNames(Styles.container, className, fluid && Styles.fluid)} {...props}>
				{children}
			</section>
		</div>
	);
};

export default Container;
