import { action, makeObservable, observable } from "mobx";
import { TUint64 } from "@pro/common/contracts/common_types";
import { PoolPeriodModel } from "./PoolPeriodModel";

export class PoolPeriodsTable {
	items = new Map<TUint64, PoolPeriodModel[]>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItems: action,
			clearItems: action,
		});
	}

	updateItems(pool_id: number, models: PoolPeriodModel[])
	{
		this.items.set(pool_id, models);
	}

	removeItems(ids: TUint64[])
	{
		for (const id of ids) {
			this.items.delete(id);
		}
	}

	clearItems()
	{
		this.items.clear();
	}

	query(pool_id: number, predicate: (it: PoolPeriodModel) => boolean): PoolPeriodModel[]
	{
		let result = [];
		for (let item of this.items.get(pool_id) || []) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(pool_id: number)
	{
		return this.query(pool_id, () => true);
	}

	get count(): number
	{
		return this.items.size;
	}
}