import { Link, To } from "react-router-dom";
import React from "react";

const InternalLink = (
	{
		children,
		href,
		className,
	}: {
		children?: React.ReactNode,
		href: To,
		className?: string,
	}
) => (
	<Link className={className} to={href}>
		{children}
	</Link>
);

export default InternalLink;