import { app, model } from "../../App";
import P2 from "../typography/P2";
import waxImg from "../../images/wax_token.png";
import P1 from "../typography/P1";
import React from "react";
import Styles from "./WaxBalance.module.scss";
import { observer } from "mobx-react";

const WaxBalance = observer(() => {
	const getWAXBalance = () => {
		const [value, token] = model.getBalance(app.chainConf.SYS_SYMBOL).formatNumberWithPrecision();
		return value ? <><P2>{value}</P2><img src={waxImg} alt="WAX"/><P1 isBold>{token}</P1></> : null
	};
	return (
		<div className={Styles.balance}>
			{getWAXBalance()}
		</div>
	);
});

export default WaxBalance