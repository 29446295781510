import React from "react";
import { observer } from "mobx-react";
import Styles from "./PuzzlesPage.module.scss";
import { app, model } from "../../App";
import { useParams } from "react-router-dom";
import { AssetConf, IPuzzleJson } from "@pro/common/conf";
import { AtomicTemplateModel } from "../../models/AtomicTemplateModel";
import { AtomicAssetModel } from "../../models/AtomicAssetModel";
import P1 from "../../components/typography/P1";
import { makeExpoPath } from "../../helpers/PageHelper";
import Placeholder from "../../components/shared/Placeholder";
import TopNavigation from "../../components/shared/TopNavigation";
import PuzzleCard from "../../components/shared/Card/PuzzleCard";
import 'react-tabs/style/react-tabs.css';
import TabsView from "../../components/shared/TabsView";
import { L } from "../../l10n/L10n";
import { makeLinkToAssetMedia } from "../../helpers/image_helper";
import Loading from "../../components/shared/Loading";

const PuzzlesPage = observer(() => {
	if (!model.config)
		return <Loading/>;

	const params = useParams();
	const expoId = parseInt(params["id"] || "-1");
	const painting_id = parseInt(params["paintingId"] || "-1");
	const title = model.config?.paintings_config.find(it => it.key === painting_id)?.value.name || "";

	let painting = model.paintings.get(painting_id);
	let neededPuzzlesCount = painting?.depositedPuzzlesIds || [];

	let puzzles: TPuzzleTemplate[] = app.useTemplateFIle
		? app.assetConf.puzzles.filter(it => it.painting_id === painting_id)
		: model.atomicTemplates.queryByCollectionName(app.chainConf.COLLECTION_NAME, it => {
			return it.schemaName === AssetConf.PUZZLES_SCHEMA_NAME && it.getImmutableProp("painting_id") === painting_id
		});

	let items: { t: TPuzzleTemplate, p: AtomicAssetModel[] }[] = puzzles.map(it => {
		let myPuzzles = model.atomicAssets.query(p => {
			return p.schemaName === AssetConf.PUZZLES_SCHEMA_NAME &&
				p.getImmutableProp("puzzle_id") === it.puzzle_id &&
				p.getImmutableProp("painting_id") as number === painting_id;
		})
		return {t: it, p: myPuzzles}
	})

	const puzzleList = (items: IPuzzleItem[]) => {
		return (
			<div className={Styles.content}>
				<P1>Total: <b>{items.length}</b></P1>
				<div className={Styles.list}>
					{items.sort((a, b) => a.p.length - b.p.length).map((it, index) =>
						<PuzzleCard
							img={it.t.img !== ""
								? makeLinkToAssetMedia(it.t.img)
								: `/paintings/${painting_id}/puzzles/${it.t.puzzle_id}.png`
							}
							url={`https://wax.atomichub.io/market?order=asc&sort=price&state=1&symbol=WAX&template_id=${it.t.template_id}`}
							count={it.p.length}
							key={`${it.t.name}_${index}`}
						/>
					)}
				</div>
			</div>
		)
	}

	return (
		<div className={Styles.container}>
			<TopNavigation title={title} backBtnPath={`${makeExpoPath(expoId)}`}/>
			{items.length > 0 ?
				<TabsView tabs={
					[{
						title: "My puzzles",
						content: puzzleList(items.filter(it => it.p.length > 0))
					}, {
						title: "Needed puzzles",
						content: puzzleList(items.filter(it => !neededPuzzlesCount.includes(it.t.puzzle_id)))
					}
					]
				}/>
				:
				<Placeholder title={L.placeholder.puzzles.title} description={L.placeholder.puzzles.subtitle}/>}
		</div>
	)
});

export default PuzzlesPage;

type TPuzzleTemplate = IPuzzleJson | AtomicTemplateModel;

interface IPuzzleItem {
	t: TPuzzleTemplate,
	p: AtomicAssetModel[],
}
