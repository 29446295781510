import React from 'react';

const DiscordIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
		<path
			d="M24.7241 8.66264C23.1728 7.9395 21.4933 7.41464 19.7437 7.11138C19.7284 7.11089 19.7131 7.11377 19.699 7.11982C19.6849 7.12586 19.6723 7.13493 19.6621 7.14637C19.4521 7.53127 19.2072 8.03281 19.0439 8.41771C17.1882 8.13778 15.3011 8.13778 13.4454 8.41771C13.2821 8.02114 13.0372 7.53127 12.8156 7.14637C12.8039 7.12305 12.7689 7.11138 12.7339 7.11138C10.9844 7.41464 9.31647 7.9395 7.75354 8.66264C7.74188 8.66264 7.73022 8.67431 7.71855 8.68597C4.54605 13.4331 3.67128 18.0519 4.10283 22.624C4.10283 22.6473 4.11449 22.6706 4.13782 22.6823C6.23727 24.2219 8.25508 25.155 10.2496 25.7732C10.2845 25.7848 10.3195 25.7732 10.3312 25.7498C10.7977 25.1083 11.2176 24.4318 11.5792 23.7204C11.6025 23.6737 11.5792 23.6271 11.5326 23.6154C10.8677 23.3588 10.2379 23.0555 9.61972 22.7056C9.57307 22.6823 9.57307 22.6123 9.60806 22.5773C9.73636 22.484 9.86466 22.3791 9.99296 22.2857C10.0163 22.2624 10.0513 22.2624 10.0746 22.2741C14.0869 24.1053 18.4141 24.1053 22.3797 22.2741C22.403 22.2624 22.438 22.2624 22.4614 22.2857C22.5897 22.3907 22.718 22.484 22.8463 22.589C22.8929 22.624 22.8929 22.694 22.8346 22.7173C22.2281 23.0789 21.5866 23.3705 20.9218 23.6271C20.8751 23.6387 20.8634 23.697 20.8751 23.732C21.2483 24.4435 21.6682 25.12 22.1231 25.7615C22.1581 25.7732 22.1931 25.7848 22.2281 25.7732C24.2342 25.155 26.252 24.2219 28.3515 22.6823C28.3748 22.6706 28.3865 22.6473 28.3865 22.624C28.8997 17.3404 27.535 12.7566 24.7708 8.68597C24.7591 8.67431 24.7474 8.66264 24.7241 8.66264ZM12.1857 19.8364C10.9844 19.8364 9.98129 18.7283 9.98129 17.3637C9.98129 15.9991 10.961 14.891 12.1857 14.891C13.4221 14.891 14.4018 16.0107 14.3901 17.3637C14.3901 18.7283 13.4104 19.8364 12.1857 19.8364ZM20.3153 19.8364C19.1139 19.8364 18.1108 18.7283 18.1108 17.3637C18.1108 15.9991 19.0906 14.891 20.3153 14.891C21.5516 14.891 22.5313 16.0107 22.5197 17.3637C22.5197 18.7283 21.5516 19.8364 20.3153 19.8364Z"
			fill={color}/>
	</svg>
);

export default DiscordIcon;
