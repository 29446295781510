import React from 'react';
import Styles from "./Popover.module.scss";
import H4 from "../../components/typography/H4";
import classNames from "classnames";

export enum ETooltipPosition {
	LEFT,
	RIGHT,
}

interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
	children: React.ReactNode,
	title: string,
	description: React.ReactNode,
	wrong?: boolean,
	tooltipPosition?: ETooltipPosition
}

const PopOver = (
	{
		children,
		title,
		description,
		wrong = false,
		tooltipPosition,
		...htmlProps
	}: TooltipProps
) => {
	function getTooltipPositionClass(tooltipPosition?: ETooltipPosition)
	{
		switch (tooltipPosition) {
			case ETooltipPosition.LEFT:
				return Styles.leftPopOver;
			case ETooltipPosition.RIGHT:
				return Styles.rightPopOver;
		}
	}

	return (
		<div className={classNames(Styles.container, getTooltipPositionClass(tooltipPosition), {[Styles.wrong]: wrong})} {...htmlProps}>
			{children}
			<div className={Styles.tooltip}>
				<div className={Styles.content}>
					<H4><b>{title}</b></H4>
					<div>{description}</div>
				</div>
			</div>
		</div>
	);
};

export default PopOver;
