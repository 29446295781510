import { EosActionBase } from "../EosActionBase";
import { eos, model, world } from "../../App";

export class FinishPaintingAction extends EosActionBase {
	constructor(private checksum: string, private painting_id: number)
	{
		super("finish");
	}

	async onExecute(): Promise<void>
	{
		await eos.expoContract.action("finish", eos.userAuth, {
			owner: model.userName,
			painting_id: this.painting_id,
			checksum: this.checksum
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateAtomicAssets(true);
		await world.updateUserLottery(model.userName);
		model.paintings.removeItem(this.painting_id);
	}
}