import { eos, model, world } from "../../App";
import { EosActionBase } from "../EosActionBase";
import { IMarketConf } from "@pro/common/contracts/expo";
import { EosAsset } from "@pro/common/eos";

export class BuyAction extends EosActionBase {
	private marketConf: IMarketConf;

	constructor(private template_id: number, private quantity:number)
	{
		super("craft");
		this.marketConf = model.config?.market_config!.find(it => it.key === this.template_id)!;
	}

	async onExecute(): Promise<void>
	{
		let transferActions = this.marketConf.value.price.map(it => {
			let a = EosAsset.parse(it.quantity.toString());
			let total = new EosAsset(a.amount*this.quantity, a.symbol);

			return {
				account: it.contract,
				name: "transfer",
				authorization: [eos.userAuth],
				data: {
					from: model.userName,
					to: eos.expoContract.account,
					quantity: total.toString(),
					memo: "fee",
				}
			}
		});

		let buy_action = eos.expoContract.makeAction("buyitem", eos.userAuth, {
			owner: model.userName,
			template_id: this.template_id,
			quantity: this.quantity
		});

		await eos._eosApi.transact([transferActions, buy_action].flat())
	}

	async onSelfUpdate(): Promise<void>
	{
		for (let p of this.marketConf.value.price)
			await world.mustUpdateBalance(p.contract, EosAsset.parse(p.quantity).symbol);
		await world.updateAtomicAssets(true);
		await world.updateConfig();
	}
}