import TagManager from "react-gtm-module";

export class GTagService {
	constructor(public readonly gtmId: string)
	{
	}

	initialize()
	{
		TagManager.initialize({gtmId: this.gtmId});
	}

	ga4event(params: Object)
	{
		const events = {...params, ...{event: "ga4event"}};
		TagManager.initialize({gtmId: this.gtmId, events});
	}
}