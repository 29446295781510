import { EosActionBase } from "../../EosActionBase";
import { app, eos, model, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";
import { UserUnstakeModel } from "../../../models/UserUnstakeModel";

export class RestakeAction extends EosActionBase {
	constructor(private pool: StakingPoolModel, private unstake: UserUnstakeModel)
	{
		super("restake");
	}

	async onExecute(): Promise<void>
	{
		let spotsAction = {
			account: app.chainConf.STAKING_ACCOUNT,
			name: "openspots",
			authorization: [eos.userAuth],
			data: {
				owner: model.userName,
				asset_ids: this.unstake.asset_ids,
			}
		}

		let restakeAction = {
			account: app.chainConf.STAKING_ACCOUNT,
			name: "restake",
			authorization: [eos.userAuth],
			data: {
				id: this.unstake.id,
				owner: model.userName,
			}
		}

		await eos._eosApi.transact([spotsAction, restakeAction].flat())
		// await eos.stContract.action("restake", eos.userAuth, {
		// 	id: this.id,
		// 	owner: eos.user
		// })
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateAllStakedAssets(model.userName);
		await world.updatePool(this.pool.poolId);
		await world.updateUserPool(eos.user, this.pool.poolId);
		model.userUnstakes.removeItem(this.unstake.id);
	}
}