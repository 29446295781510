import { NeftyClaimAssetsModel } from "../../models/NeftyClaimAssetsModel";
import { PopUpBase } from "../../components/shared/PopUpBase";
import Button from "../landing/components/Button";
import React from "react";
import { makeLinkToAssetMedia } from "../../helpers/image_helper";
import { UnpackPaintingCard } from "../../components/shared/Card/UnpackPaintingCard";
import { L } from "../../l10n/L10n";

export const UnpackingPaintingView = (
	{
		neftyClaimAssets,
		onClaimClick,
		btnReady,
	}: {
		neftyClaimAssets: NeftyClaimAssetsModel,
		onClaimClick: () => void,
		btnReady: boolean,
	}
) => {
	return (
		<PopUpBase
			title={L.unpack.tittle_painting}
			onShowPopUp={() => onClaimClick()}
			showCloseButton={false}
		>
			{neftyClaimAssets.assets.map(painting => (
				<UnpackPaintingCard
					key={painting.name}
					img={makeLinkToAssetMedia(painting.img)}
					name={painting.name}
					author={painting.author}
				/>
			))}
			<Button
				onClick={onClaimClick}
				disabled={!btnReady}
			>
				{L.button.claim}
			</Button>
		</PopUpBase>
	)
};