import React from 'react';

const ArrowLeftLg = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M27.5556 16C27.5556 16.4909 27.1576 16.8889 26.6667 16.8889H7.4793L11.6187 21.0283C11.9659 21.3755 11.9659 21.9383 11.6187 22.2854C11.2716 22.6325 10.7088 22.6325 10.3616 22.2854L4.70479 16.6285C4.35766 16.2814 4.35766 15.7186 4.70479 15.3715L10.3616 9.71461C10.7088 9.36748 11.2716 9.36748 11.6187 9.71461C11.9659 10.0617 11.9659 10.6246 11.6187 10.9717L7.4793 15.1111H26.6667C27.1576 15.1111 27.5556 15.5091 27.5556 16Z"
			  fill={color}/>
	</svg>
);

export default ArrowLeftLg;
