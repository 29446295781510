import React from 'react';

const MarketIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M14.1927 3.91944C14.5104 3.27412 14.2708 2.48115 13.6615 2.14756C13.0521 1.81396 12.2917 2.06553 11.974 2.70537L7.125 12.4999H2.66667C1.74479 12.4999 1 13.282 1 14.2499C1 15.2179 1.74479 16 2.66667 16L5.36979 27.3476C5.73958 28.9062 7.07292 30 8.60417 30H23.3958C24.9271 30 26.2604 28.9062 26.6302 27.3476L29.3333 16C30.2552 16 31 15.2179 31 14.2499C31 13.282 30.2552 12.4999 29.3333 12.4999H24.875L20.026 2.70537C19.7083 2.06553 18.9531 1.81396 18.3385 2.14756C17.724 2.48115 17.4896 3.27412 17.8073 3.91944L22.0573 12.4999H9.94271L14.1927 3.91944ZM11 18.625V23.875C11 24.3562 10.625 24.75 10.1667 24.75C9.70833 24.75 9.33333 24.3562 9.33333 23.875V18.625C9.33333 18.1437 9.70833 17.75 10.1667 17.75C10.625 17.75 11 18.1437 11 18.625ZM16 17.75C16.4583 17.75 16.8333 18.1437 16.8333 18.625V23.875C16.8333 24.3562 16.4583 24.75 16 24.75C15.5417 24.75 15.1667 24.3562 15.1667 23.875V18.625C15.1667 18.1437 15.5417 17.75 16 17.75ZM22.6667 18.625V23.875C22.6667 24.3562 22.2917 24.75 21.8333 24.75C21.375 24.75 21 24.3562 21 23.875V18.625C21 18.1437 21.375 17.75 21.8333 17.75C22.2917 17.75 22.6667 18.1437 22.6667 18.625Z"
			fill={color}/>
	</svg>
);

export default MarketIcon;
