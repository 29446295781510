import React from 'react';
import Button, { EButtonType } from "../components/Button";
import bannerImg from "../../../images/img_firstBlock.png";
import Styles from './Banner.module.scss'
import Container from "../components/Container";
import Rhombus from "../../../icons/Rhombus";
import Square from "../../../icons/Square";
import Triangle from "../../../icons/Triangle";
import Circle from "../../../icons/Circle";
import { L } from "../../../l10n/L10n";
import useWindowSize from "../../../hooks/useWindowSize";
import H1 from "../../../components/typography/H1";
import H4 from "../../../components/typography/H4";
import { PagePath } from "../../../helpers/PageHelper";

const Banner = () => {
	const {windowWidth} = useWindowSize();
	const scrollTo = React.useCallback((elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			window.scrollTo(0, element.offsetTop - 84);
		}

	}, [windowWidth]);

	return (
		<div className={Styles.banner}>
			<Container className={Styles.inner}>
				<div className={Styles.info}>
					<div className={Styles.heading}>
						<H1>
							<span>{L.landing.sections.banner.stake}<Rhombus/></span>
							<span>{L.landing.sections.banner.create} <Square/></span>
							<span>{L.landing.sections.banner.have_fun}<Triangle/></span>
							<span>{L.landing.sections.banner.repeat}<Circle/></span>
						</H1>
						<H4 className={Styles.subtitle}>{L.landing.sections.banner.subtitle}</H4>
					</div>
					<div className={Styles.buttons}>
						<Button
							type={EButtonType.PRIMARY}
							path={PagePath.EXPOS}
						>
							{L.landing.buttons.play_game}
						</Button>
						<Button
							type={EButtonType.SECONDARY}
							onClick={() => scrollTo("pools")}
						>
							{L.landing.buttons.stake_your_nft}
						</Button>
					</div>
				</div>
				<div className={Styles.img}>
					<img src={bannerImg} alt="banner"/>
				</div>
			</Container>
		</div>
	)
};

export default Banner;
