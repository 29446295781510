import { action, makeObservable, observable } from "mobx";
import { LotteryModel } from "./LotteryModel";

export class LotteriesTable {
	item?: LotteryModel;

	constructor()
	{
		makeObservable(this, {
			item: observable,
			updateItem: action,
			clear: action,
		});
	}

	updateItem(item: LotteryModel | undefined)
	{
		this.item = item;
	}

	clear()
	{
		this.item = undefined;
	}

}