import React from 'react';

const ArrowLeftSm = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M20.6663 12.0001C20.6663 12.3683 20.3679 12.6668 19.9997 12.6668H5.60915L8.71372 15.7714C8.97407 16.0317 8.97407 16.4538 8.71372 16.7142C8.45337 16.9745 8.03126 16.9745 7.77091 16.7142L3.52827 12.4715C3.26792 12.2112 3.26792 11.7891 3.52827 11.5287L7.77091 7.28608C8.03126 7.02573 8.45337 7.02573 8.71372 7.28608C8.97407 7.54643 8.97407 7.96854 8.71372 8.22889L5.60915 11.3335H19.9997C20.3679 11.3335 20.6663 11.6319 20.6663 12.0001Z"
			  fill={color}/>
	</svg>
);

export default ArrowLeftSm;
