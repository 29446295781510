import { IPoolPeriod } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";

export class PoolPeriodModel {
	constructor(
		public readonly pool: IPoolPeriod,
	)
	{
		makeObservable(this, {});
	}

	get num()
	{
		return this.pool.num;
	}

	get total_staked_power()
	{
		return this.pool.total_staked_power;
	}

	get merged()
	{
		return this.pool.merged;
	}
}