import React from "react";
import { observer } from "mobx-react";
import { localData, model } from "../../App";
import Button from "../../components/shared/Button";
import { useNavigate } from "react-router-dom";
import { getPathById, makePuzzlesPath, PagePath } from "../../helpers/PageHelper";
import Styles from "./ExpositionPage.module.scss";
import { BurnPuzzlesAction } from "../../eos/actions/BurnPuzzlesAction";
import { StartPaintingAction } from "../../eos/actions/StartPaintingAction";

const ExpositionPage = observer(() => {
	const navigate = useNavigate();

	const paintings_templates = model.config?.paintings_config || [];

	return (
		<>
			{paintings_templates.map(it => {
				let id = it.key
				let painting = model.paintings.get(id);
				let availablePuzzles = model.atomicAssets.getPaintingNeededFromAvailable(painting?.depositedPuzzlesIds || [], id);
				let cols = it.value.cols
				let rows = it.value.rows
				let neededPuzzles = rows * cols - (painting?.depositedPuzzlesIds || []).length;
				// let availablePaintingsToStake = model.atomicAssets.query((p => p.asset.template_id === it.template_id));

				return <div className={Styles.painting}>
					<div>
						<img src={`/paintings/${id}/sample.jpg`}/>
					</div>
					<div>
						<div>{it.value.name} {`${cols}x${rows}`}</div>
						<div>Deposited puzzles: {(painting?.depositedPuzzlesIds || []).length}</div>
						<div>Available / Needed puzzles: {availablePuzzles.length} / {neededPuzzles}</div>
						<div>
							{
								model.logined && !painting && <Button text={"begin"} onClick={() => {
									new StartPaintingAction(id)
										.executeAsync()
								}}/>
							}
							{
								neededPuzzles > 0 && availablePuzzles.length > 0 &&
								<Button text={"add puzzles"} onClick={() => {
									new BurnPuzzlesAction(id, availablePuzzles).executeAsync()
								}}/>
							}
							<div>
								<Button text={"compose"}
										onClick={() => navigate(getPathById(PagePath.GALLERY, id))}/>
							</div>
							<div>
								<Button text={"puzzles"}
										onClick={() => navigate(makePuzzlesPath(it.value.expo, id))}/>
							</div>
							{
								localData.getPaintingState(id) && <div>
                                    <Button text={"clean state"} onClick={() => {
										localData.setPaintingState(id, undefined);
										localData.flush();
									}}/>
                                </div>
							}

						</div>
					</div>
				</div>
			})}
		</>
	)
});

export default ExpositionPage;