import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";

export class RemoveUserPoolAction extends EosActionBase {
	constructor(private pool_id: number)
	{
		super("closepool");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("deluserpool", eos.userAuth, {
			pool_id: this.pool_id,
			owner: eos.user
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updatePool(this.pool_id);
		model.userPools.removeItem(model.userName, this.pool_id);
	}
}