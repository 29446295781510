import React from 'react';

const ExitFullScreenIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M6.7585 8.78829H4.4211C3.90388 8.78829 3.42566 9.06361 3.16708 9.51044C2.90825 9.95727 2.90825 10.5079 3.16708 10.9547C3.42569 11.4016 3.90388 11.6769 4.4211 11.6769H10.2548C10.5408 11.6767 10.8205 11.5918 11.0582 11.4329C11.2961 11.2743 11.4815 11.0489 11.5909 10.7853C11.6646 10.6104 11.7028 10.4223 11.703 10.2326V4.41521C11.703 3.89918 11.4269 3.42247 10.9789 3.16437C10.5306 2.90646 9.9787 2.90646 9.53067 3.16437C9.08241 3.4225 8.80656 3.89917 8.80656 4.41521V6.74627L4.47331 2.42308C4.10717 2.05814 3.57354 1.91564 3.07359 2.04932C2.57361 2.18322 2.18308 2.57266 2.0493 3.0715C1.91546 3.57012 2.05856 4.10225 2.42471 4.46721L6.7585 8.78829Z"
			fill={color}/>
		<path
			d="M25.2417 23.1845H27.5791C28.0963 23.1845 28.5745 22.9091 28.8331 22.4623C29.0919 22.0155 29.0919 21.4648 28.8331 21.018C28.5745 20.5712 28.0963 20.2959 27.5791 20.2959H21.7453C21.3617 20.2974 20.9943 20.45 20.7232 20.7206C20.4519 20.9912 20.2989 21.3576 20.2971 21.7402V27.5536C20.2971 28.0697 20.5732 28.5464 21.0213 28.8045C21.4695 29.0624 22.0214 29.0624 22.4695 28.8045C22.9177 28.5464 23.1936 28.0697 23.1936 27.5536V25.2264L27.5266 29.5488C27.8925 29.9137 28.4262 30.0562 28.9261 29.9226C29.4261 29.7891 29.8166 29.3996 29.9504 28.901C30.0844 28.4024 29.9413 27.8705 29.5754 27.5056L25.2417 23.1845Z"
			fill={color}/>
		<path
			d="M8.83998 25.2516V27.5827C8.83998 28.0987 9.11605 28.5756 9.56409 28.8335C10.0121 29.0916 10.5643 29.0916 11.0123 28.8335C11.4604 28.5756 11.7364 28.0987 11.7364 27.5827V21.7653C11.7364 21.3823 11.5838 21.015 11.3123 20.744C11.0408 20.4732 10.6724 20.321 10.2882 20.321H4.45618C3.93874 20.321 3.46052 20.5964 3.20193 21.0432C2.94332 21.49 2.94332 22.0407 3.20193 22.4875C3.46055 22.9343 3.93873 23.2096 4.45618 23.2096H6.79275L2.45951 27.5309C2.09314 27.8965 1.95026 28.4291 2.08452 28.9283C2.21878 29.4274 2.60971 29.8172 3.11036 29.9507C3.61077 30.0844 4.1448 29.9417 4.51119 29.5761L8.83998 25.2516Z"
			fill={color}/>
		<path
			d="M27.5268 2.47105L23.1936 6.79342V4.46236C23.1936 3.94632 22.9177 3.4694 22.4695 3.21152C22.0214 2.95361 21.4695 2.95361 21.0212 3.21152C20.5732 3.46943 20.2971 3.94632 20.2971 4.46236V10.2797C20.2971 10.6627 20.4497 11.03 20.7215 11.301C20.993 11.5718 21.3613 11.724 21.7454 11.724H27.5791C28.0963 11.724 28.5745 11.4486 28.8331 11.0018C29.0919 10.555 29.0919 10.0043 28.8331 9.55752C28.5745 9.11069 28.0963 8.83538 27.5791 8.83538H25.2417L29.5758 4.5141C29.9417 4.14895 30.0846 3.61699 29.9506 3.11839C29.8167 2.61998 29.4262 2.23052 28.9263 2.09688C28.4263 1.96341 27.8928 2.1061 27.5268 2.47105Z"
			fill={color}/>
	</svg>
);

export default ExitFullScreenIcon;
