import React from 'react';

const ExpoIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<g clipPath="url(#clip0_2024_2714)">
			<path
				d="M27.1213 8.4002C27.0222 8.36302 26.9189 8.33411 26.8115 8.31344L26.2951 8.20605V21.2513C26.2951 23.263 24.6593 24.8989 22.6476 24.8989H5.76476C5.2195 24.8989 4.69899 24.7791 4.23633 24.5643C4.63702 25.1137 5.23185 25.5227 5.95063 25.6713L6.20675 25.725L22.4741 29.1165C23.9984 29.4345 25.4938 28.4555 25.8119 26.9313L26.5347 23.4572L27.1213 20.6523L28.026 16.3191L28.5712 13.7001L28.9967 11.6553C29.2941 10.2343 28.468 8.84223 27.1213 8.4002Z"
				fill={color}/>
			<path
				d="M22.6476 2.82324H5.76473C4.20739 2.82324 2.94336 4.08728 2.94336 5.64461V21.251C2.94336 22.8084 4.20739 24.0724 5.76473 24.0724H22.6476C24.2049 24.0724 25.4689 22.8084 25.4689 21.251V5.64461C25.4689 4.08728 24.2049 2.82324 22.6476 2.82324ZM23.4035 18.2479C23.4035 18.3677 23.3911 18.4792 23.3705 18.5907L20.7144 14.5384C20.2765 13.8733 19.3016 13.8733 18.8637 14.5384L15.9473 18.9873L13.6299 15.4472L12.2213 13.3032L11.0068 11.4485C10.5689 10.7834 9.59406 10.7834 9.15619 11.4485L5.00877 17.7729V6.5906C5.00877 5.64877 5.76885 4.8887 6.71068 4.8887H21.7016C22.6434 4.8887 23.4035 5.64877 23.4035 6.5906L23.4035 18.2479Z"
				fill={color}/>
			<path
				d="M15.9596 11.6136C16.7501 11.6136 17.3909 10.9728 17.3909 10.1823C17.3909 9.39179 16.7501 8.75098 15.9596 8.75098C15.1691 8.75098 14.5283 9.39179 14.5283 10.1823C14.5283 10.9728 15.1691 11.6136 15.9596 11.6136Z"
				fill={color}/>
		</g>
		<defs>
			<clipPath id="clip0_2024_2714">
				<rect width="28" height="28" fill="white" transform="translate(2 2)"/>
			</clipPath>
		</defs>
	</svg>
);

export default ExpoIcon;
