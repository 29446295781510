import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { model } from "../../../App";
import AdminLayout from "../../shared/AdminLayout";
import { observer } from "mobx-react";
import AdminPoolInfoView from "../shared/AdminPoolInfoView";
import Button from "../../shared/Button";
import { OpenPoolPeriodsAction } from "../../../eos/actions/staking/OpenPoolPeriodsAction";
import { ActivatePoolAction } from "../../../eos/actions/staking/ActivatePoolAction";
import { RemPoolAction } from "../../../eos/actions/staking/RemPoolAction";
import { PagePath } from "../../../helpers/PageHelper";

const AdminPoolPage = observer(() => {
	const params = useParams();
	const navigate = useNavigate();
	const pool = model.pools.items.get(parseInt((params["id"] || "0")));

	if (!pool)
		return <AdminLayout content={<h1>Pool {params["id"]} not found</h1>}/>

	return <AdminLayout content={
		<>
			<AdminPoolInfoView poolAssets={model.poolAssets.queryAll(pool.poolId)} pool={pool}/>
			{
				!pool.activated && pool.poolPeriods.length < pool.periodsCount &&
                <Button text={"open space"} onClick={() => {
					new OpenPoolPeriodsAction(pool.poolId).executeAsync();
				}}/>
			}
			{
				!pool.activated && pool.poolPeriods.length === pool.periodsCount &&
                <Button text={"activate"} onClick={() => {
					new ActivatePoolAction(pool).executeAsync();
				}}/>
			}
			{
				pool.canBeDeleted && <Button text={"delete"} onClick={() => {
					new RemPoolAction(pool.poolId)
						.onSuccess(() => navigate(PagePath.ADMIN_POOLS))
						.executeAsync();
				}}/>
			}
		</>

	}/>
});

export default AdminPoolPage;