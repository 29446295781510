import { useEffect, useState } from "react";

interface Size {
	windowWidth: number;
	windowHeight: number;
}

const useWindowSize = (): Size => {
	const [windowSize, setWindowSize] = useState<Size>({
		windowWidth: 0,
		windowHeight: 0,
	});

	const handleResize = () => {
		setWindowSize({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
		});
	}
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
}

export default useWindowSize;