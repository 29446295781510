import { action, makeObservable, observable } from "mobx";
import { StakingPoolModel } from "./StakingPoolModel";

export class StakingPoolsTable {
	items = new Map<number, StakingPoolModel>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItems: action,
			clearItems: action,
		});
	}

	updateItems(models: StakingPoolModel[])
	{
		for (const record of models)
			this.items.set(record.poolId, record);
	}

	removeItems(ids: number[])
	{
		for (const id of ids) {
			this.items.delete(id);
		}
	}

	clearItems()
	{
		this.items.clear();
	}

	query(predicate: (it: StakingPoolModel) => boolean): StakingPoolModel[]
	{
		let result = [];
		for (let item of this.items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll()
	{
		return this.query(() => true);
	}

	get count(): number
	{
		return this.items.size;
	}
}