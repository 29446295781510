export function formatLongDoubleValue(v: number): number
{
	return parseFloat(parseFloat(v.toString()).toFixed(4));
}

export function formatDateTime(date: Date)
{
	return date.toLocaleDateString()
		+ " " + date.getHours().toString().padStart(2, '0')
		+ ":" + date.getMinutes().toString().padStart(2, '0');
}

export function formatDaysLeft(secondsLeft: number)
{
	const days = Math.floor(secondsLeft / 86400);
	if (days === 0)
		return formatTimeLeft(secondsLeft);

	const hours = Math.floor((secondsLeft - days * 86400) / 3600);

	const day = days === 1 ? "day" : "days";
	const hour = hours === 1 ? "hour" : "hours"

	return `${days.toString()} ${day} ${hours.toString().padStart(2, "0")} ${hour}`;
}

export function formatTimeLeft(secondsLeft: number)
{
	const hours = Math.floor(secondsLeft / 3600);
	const minutes = Math.floor((secondsLeft - hours * 3600) / 60);
	const seconds = Math.floor(secondsLeft % 60);
	const formattedTime = hours.toString().padStart(2, "0") + ":" +
		minutes.toString().padStart(2, "0") + ":" +
		seconds.toString().padStart(2, "0");

	return formattedTime;
}

export function formatNumbers(value: number | string)
{
	if (typeof value === "number")
		value = value.toString();

	const parts = value.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts.join(".");
}

export function formatThousandsOfNumbers(value: number): string
{
	if (value < 1_000)
		return formatNumbers(value);

	value = Math.floor(value / 1000);
	return formatNumbers(value) + " K";
}

export function get64Binary(val: number): string
{
	let binary = BigInt(val).toString(2);
	while (binary.length < 64)
		binary = "0" + binary;

	return binary;
}
