import { EosActionBase } from "../../EosActionBase";
import { app, eos, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";

export class ActivatePoolAction extends EosActionBase {
	constructor(private pool: StakingPoolModel)
	{
		super("stake");
	}

	async onExecute(): Promise<void>
	{
		await eos.transfer(this.pool.rewardContract, app.chainConf.STAKING_ACCOUNT, this.pool.totalReward, `activate:${this.pool.poolId}`);
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdateBalance(this.pool.rewardContract, this.pool.rewardSymbol);
		await world.updatePool(this.pool.poolId);
	}
}