import Styles from "./PopUp.module.scss";
import H3 from "../../components/typography/H3";
import { app } from "../../App";
import React from "react";
import Button from "../landing/components/Button";
import CloseIcon from "../../icons/CloseIcon";
import { LotteryModel } from "../../models/LotteryModel";

const PopUp = (
	{
		lottery,
		onShowPopUp
	}: {
		lottery: LotteryModel
		onShowPopUp: () => void
	}
) => {
	return (
		<div className={Styles.container}>
			<div className={Styles.content}>
				<div className={Styles.title}>
					<H3>Lottery prize pool</H3>
					<Button onClick={onShowPopUp} icon={<CloseIcon/>} className={Styles.close}/>
				</div>
				<div className={Styles.prizeList}>
					{app.chainConf.LOTTERY_CONF.map((it, index) => {
						let numAmounts = it.num - (index === 0 ? -1 : app.chainConf.LOTTERY_CONF[index-1].num);
						let chance = numAmounts * 100 / (app.chainConf.MAX_RAND_NUM+1);
						return <div key={`lottery_conf_${index}`}>
							Chance: {chance.toFixed(4)}%
							Prize: {lottery.getExpoPrize(it.prize).toString()}
						</div>
					})}
				</div>
			</div>
		</div>
	);
};

export default PopUp