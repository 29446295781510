import React from "react";
import "react-notifications-component/dist/theme.css";
import { observer } from "mobx-react";
import Styles from "./NavigationView.module.scss";
import InternalLink from "./InternalLink";
import { useLocation } from "react-router-dom";


export interface INavigationLink {
	name: string,
	path: string
}

const AuthView = observer(({links}: { links: INavigationLink[] }) => {

	const location = useLocation();
	const linksContent = links.map((it) => {
		return <InternalLink href={it.path} children={it.name}
							 className={it.path === location.pathname ? Styles.activeLink : Styles.link}/>;
	})
	return <div className={Styles.container}>
		{linksContent}
	</div>
});

export default AuthView;