import { action, makeObservable, observable } from "mobx";
import { StakingAssetModel } from "./StakingAssetModel";
import { TUint64 } from "@pro/common/contracts/common_types";

export class StakingAssetsTable {
	items = new Map<string, Map<TUint64, StakingAssetModel>>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItems: action,
			clearItems: action,
		});
	}

	updateItems(user: string, models: StakingAssetModel[])
	{
		if (!this.items.get(user))
			this.items.set(user, new Map<TUint64, StakingAssetModel>());

		for (const record of models)
			this.items.get(user)!.set(record.assetId, record);
	}

	removeItems(user: string, ids: TUint64[])
	{
		if (!this.items.get(user))
			return;

		for (const id of ids) {
			this.items.get(user)!.delete(id);
		}
	}

	clearItems()
	{
		this.items.clear();
	}

	query(user: string, predicate: (it: StakingAssetModel) => boolean): StakingAssetModel[]
	{
		let result = [];
		if (this.items.get(user)) {
			for (let item of this.items.get(user)!.values()) {
				if (predicate(item))
					result.push(item);
			}
		}

		return result;
	}

	queryAll(user: string)
	{
		return this.query(user, () => true);
	}

	get count(): number
	{
		return this.items.size;
	}
}