import Styles from "./Button.module.scss"
import React, { ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export enum EButtonSize {
	SMALL = 0,
	MEDIUM = 1,
}

export enum EButtonType {
	PRIMARY,
	SECONDARY,
}

export enum EIconSide {
	LEFT,
	RIGHT,
	CENTER
}

interface ButtonsProps extends React.HTMLAttributes<HTMLButtonElement> {
	size?: EButtonSize
	type?: EButtonType,
	icon?: ReactNode,
	iconSide?: EIconSide,
	label?: string,
	isActive?: boolean,
	disabled?: boolean,
	href?: string,
	path?: string,
	target?: string
}

const Button = (
	{
		children,
		size = EButtonSize.MEDIUM,
		type = EButtonType.PRIMARY,
		disabled,
		icon,
		className,
		onClick,
		onBlur,
		iconSide = EIconSide.LEFT,
		label,
		isActive,
		href,
		path,
		target
	}: ButtonsProps
) => {
	let buttonClassName = classNames(
		getSizeClass(size),
		getTypeClass(type),
		icon && getIconSide(iconSide),
		{[Styles.with_icon]: icon},
		{[Styles.min_width]: children},
		Styles.button,
		className
	);

	function getSizeClass(size: EButtonSize)
	{
		switch (size) {
			case EButtonSize.SMALL:
				return Styles.small;
			case EButtonSize.MEDIUM:
				return Styles.medium;
		}
	}

	function getTypeClass(type: EButtonType)
	{
		switch (type) {
			case EButtonType.PRIMARY:
				return Styles.primary;
			case EButtonType.SECONDARY:
				return Styles.secondary;
		}
	}

	function getIconSide(side: EIconSide)
	{
		switch (side) {
			case EIconSide.LEFT:
				return Styles.icon_left
			case EIconSide.CENTER:
				return Styles.icon_center
			case EIconSide.RIGHT:
				return Styles.icon_right
		}
	}

	function getContent(side: EIconSide)
	{
		switch (side) {
			case EIconSide.LEFT:
				return <>{icon}{children}</>
			case EIconSide.RIGHT:
				return <>{children}{icon}</>
			case EIconSide.CENTER:
				return <>{icon}</>
		}
	}

	const Container = React.useCallback(({children, ...props}) => {
		if (href) {
			return <a href={href} target={target} {...props}>{children}</a>
		}
		if (path) {
			return <Link to={path} {...props}>{children}</Link>
		}
		return <button {...props}>{children}</button>
	}, [href])

	return (
		<Container
			className={classNames(buttonClassName, isActive && Styles.active)}
			onClick={onClick}
			onBlur={onBlur}
			disabled={disabled}
		>
			{label && <span className={Styles.label}>{label}</span>}
			{icon ? getContent(iconSide) : children}
		</Container>
	)
};

export default Button