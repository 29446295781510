import React from 'react';
import Styles from "./Counter.module.scss"
import PlusIcon from "../../icons/PlusIcon";
import MinusIcon from "../../icons/MinusIcon";
import classNames from "classnames";
import Tooltip from "./Tooltip";

interface CounterProps {
	onChange?: (value: number | "") => void,
	error?: string,
	max?: number,
	disabled?: boolean
}

const Counter = ({onChange, error, max, disabled}: CounterProps) => {
	const [count, setCount] = React.useState<number | "">(1);

	React.useEffect(() => onChange && onChange(count), [count])

	const onChangeInput = React.useCallback(({currentTarget: {value}}: React.FormEvent<HTMLInputElement>) => {
		const newValue = !!value.replace(/^0+/, '') ? Number(value) : "";
		setCount(prevState => max && newValue > max ? prevState : newValue)
	}, []);

	const onAdd = React.useCallback(
		() => {
			setCount(prevState => {
				if (!prevState) {
					return 1
				}
				if (max && max < prevState + 1) {
					return prevState
				}
				return prevState + 1
			})
		},
		[]
	)

	const onMinus = React.useCallback(() => setCount(prevState => prevState && prevState - 1 > 1 ? prevState - 1 : 1), [])

	const onKeyPress = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!/[0-9]/.test(e.key)) {
			e.preventDefault();
		}
	}, []);

	return (
		<Tooltip text={error ? error : ""} className={Styles.error}>
			<div
				className={classNames(Styles.counter, error && Styles.invalid, disabled && Styles.disabled)}>
				<button onClick={onMinus} disabled={count <= 1 || disabled}><MinusIcon/></button>
				<div className={Styles.divider}></div>
				<input
					onKeyPress={onKeyPress}
					type="number"
					value={count}
					onChange={onChangeInput}
					disabled={disabled}
					min={0}
				/>
				<span className={Styles.divider}></span>
				<button onClick={onAdd} disabled={count === max || disabled}><PlusIcon/></button>
			</div>
		</Tooltip>
	);
};

export default Counter;
