import { observer } from "mobx-react";
import Styles from "./HelpView.module.scss"
import React, { useEffect, useRef, useState } from "react";
import { IPoint } from "../../core/types";
import Loading from "../shared/Loading";

const HelpView = observer(({painting_id, onClose}: { painting_id: number, onClose: () => void }) => {

	const container = useRef<HTMLDivElement>(null);
	const [state] = useState<{
		naturalWidth: number,
		position: IPoint,
		oldPos?: IPoint,
		touch_start_distance?: number,
		touch_start_scale?: number,
		scale: number,
		start?: IPoint
	}>({
		position: {
			x: 0,
			y: 0
		},
		scale: 1,
		naturalWidth: 0
	});

	const [position, setPosition] = useState({x: 0, y: 0});
	const [scale, setScale] = useState(1);

	const getTouchesDistance = (t0: React.Touch, t1: React.Touch) => {
		let a = t0.clientX - t1.clientX;
		let b = t0.clientY - t1.clientY;
		return Math.sqrt(a * a + b * b);
	}

	const setNewScale = (newScale: number) => {
		state.scale = newScale;
		setScale(newScale)
	}

	const setNewPosition = (newPosition: IPoint) => {
		state.position = newPosition;
		setPosition(newPosition)
	}

	const getMousePosition = (e: MouseEvent | TouchEvent): { x: number, y: number } => {
		return {
			x: (e as TouchEvent).touches ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX,
			y: (e as TouchEvent).touches ? (e as TouchEvent).touches[0].clientY : (e as MouseEvent).clientY
		}
	}

	const onScroll = (e: WheelEvent) => {
		let xs = (e.clientX - state.position.x) / state.scale,
			ys = (e.clientY - state.position.y) / state.scale,
			newScale = state.scale * (e.deltaY > 0 ? 1.05 : 0.95);

		setNewPosition({x: e.clientX - xs * newScale, y: e.clientY - ys * newScale});
		setNewScale(newScale);
	}

	const onMouseDown = (e: MouseEvent | TouchEvent) => {
		state.start = getMousePosition(e);
		state.oldPos = {...state.position};
	}

	const onMouseMove = (e: MouseEvent | TouchEvent) => {
		if (state.start) {
			if ((e as TouchEvent).touches && (e as TouchEvent).touches.length === 2 && state.touch_start_scale && state.touch_start_distance) {
				let d = getTouchesDistance((e as TouchEvent).touches[0], (e as TouchEvent).touches[1]);
				let mousePox = getMousePosition(e);

				let centerX = Math.abs((e as TouchEvent).touches[0].clientX - (e as TouchEvent).touches[1].clientX);
				let centerY = Math.abs((e as TouchEvent).touches[0].clientY - (e as TouchEvent).touches[1].clientY);

				let clientX = (e as TouchEvent).touches[0].clientX + centerX / 2;
				let clientY = (e as TouchEvent).touches[0].clientY + centerY / 2;

				let xs = (clientX - state.position.x - (mousePox.x - state.start.x)) / state.scale,
					ys = (clientY - state.position.y - (mousePox.y - state.start.y)) / state.scale,
					newScale = state.touch_start_scale * (d / state.touch_start_distance);

				setNewPosition({x: clientX - xs * newScale, y: clientY - ys * newScale});
				setNewScale(newScale);
				state.start = mousePox;
			} else {
				let mousePox = getMousePosition(e);
				setNewPosition({
					x: state.oldPos!.x + (mousePox.x - state.start.x),
					y: state.oldPos!.y + (mousePox.y - state.start.y),
				})
			}
		}
		e.stopPropagation();
		e.preventDefault();
	}

	useEffect(() => {
		if (container.current) {
			container.current.addEventListener("mousemove", onMouseMove);
			container.current.addEventListener("touchmove", onMouseMove);
		}
		return () => {
			if (container.current) {
				container.current.removeEventListener("mousemove", onMouseMove);
				container.current.removeEventListener("touchmove", onMouseMove);
			}
		}
	}, []);

	return <div ref={container} className={Styles.help_view} onClick={(e) => {
		if (!(e.target instanceof HTMLImageElement))
			onClose()
	}} onMouseUp={() => state.start = undefined} onTouchEnd={() => state.start = undefined}>
		{state.naturalWidth === 0 && <Loading/>}
		<div style={{
			left: `${position.x}px`,
			top: `${position.y}px`,
		}}>
			<img style={{
				width: `${state.naturalWidth * scale}px`,
				height: "auto"
			}}
				 src={`/paintings/${painting_id}/painting.jpg`}
				 onMouseDown={(e) => onMouseDown(e as unknown as MouseEvent)}
				 onTouchStart={(e) => {
					 if (e.touches.length > 1) {
						 state.touch_start_scale = state.scale;
						 state.touch_start_distance = getTouchesDistance(e.touches[0], e.touches[1]);
					 }
					 onMouseDown(e as unknown as MouseEvent)

				 }}
				 onLoad={(e) => {
					 state.naturalWidth = e.currentTarget.naturalWidth;
					 let neededScaleX = ((container.current!.offsetWidth * 0.8) / e.currentTarget.naturalWidth);
					 let neededScaleY = ((container.current!.offsetHeight * 0.8) / e.currentTarget.naturalHeight);
					 setNewScale(Math.min(neededScaleX, neededScaleY));

					 const newWidth = (e.currentTarget.width * state.scale);
					 const newHeight = (e.currentTarget.width * state.scale);

					 setNewPosition({
						 x: newWidth / 2 + (container.current!.offsetWidth - newWidth) / 2,
						 y: newHeight / 2 + (container.current!.offsetHeight - newHeight) / 2
					 })
				 }}
				 onWheel={(e)=>{onScroll(e as unknown as WheelEvent)}}
			/>
		</div>
	</div>
});
export default HelpView;