import Styles from "./LotterySlot.module.scss";
import classNames from "classnames";
import { CleanLotteryFieldAction } from "../../eos/actions/CleanLotteryFieldAction";
import { LotteryModel } from "../../models/LotteryModel";
import LoadingAnimation from "../../components/shared/LoadingAnimation";
import CloseIcon from "../../icons/CloseIcon";
import artToken from "../../images/art_token.png";
import Popover, { ETooltipPosition } from "./PopOver";
import P1 from "../../components/typography/P1";
import { app } from "../../App";
import { EosAsset } from "@pro/common/eos";
import { L } from "../../l10n/L10n";
import LockIcon from "../../icons/LockIcon";

const LotterySlot = (
	{
		lottery,
		pos
	}: {
		lottery: LotteryModel | undefined,
		pos: number
	}
) => {
	let win = lottery!.getWinnings(pos);
	const rewardAmount = new EosAsset((win?.amount || 0) * 10 ** app.chainConf.TKN_SYMBOL.precision, app.chainConf.TKN_SYMBOL)
	const disable = !!win || lottery!.isPosConfirmed(pos) || lottery!.awaitingResult || !lottery?.canTry

	function getTooltipPosition()
	{
		if (pos % 8 === 0 || pos % 8 === 1) {
			return ETooltipPosition.LEFT
		}
		if (pos % 8 === 7 || pos % 8 === 6) {
			return ETooltipPosition.RIGHT
		}
	}

	function getContent()
	{
		if (win)
			return (
				<Popover title={L.lottery.congrats}
						 description={<P1>{L.lottery.your_reward} <b>{rewardAmount.formatNumberWithPrecision(0).join(' ')}</b></P1>}
						 tooltipPosition={getTooltipPosition()}>
					<button className={classNames(Styles.col, Styles.win)}
							disabled={disable}>
						<img src={artToken} alt={L.shared.wax}/>
					</button>
				</Popover>)

		if (!lottery || !lottery!.isPosOpened(pos)) {
			if (!lottery?.canTry)
				return (
					<button className={Styles.col} disabled>
						<LockIcon color={"#FBFAF4"}/>
					</button>)
			else
				return <button className={Styles.col} onClick={() => new CleanLotteryFieldAction(pos).executeAsync()}/>
		}

		if (!lottery!.isPosConfirmed(pos)) {
			return (
				<button className={Styles.col} disabled>
					<LoadingAnimation/>
				</button>)
		}

		return (
			<Popover title={L.lottery.what_pity}
					 wrong={true}
					 description={<P1>{L.lottery.you_have_no_reward}</P1>}
					 tooltipPosition={getTooltipPosition()}>
				<button className={Styles.col} disabled>
					<CloseIcon color={"#F35034"}/>
				</button>
			</Popover>)
	}


	return (
		<>{getContent()}</>
	)
};

export default LotterySlot