import { action, makeObservable, observable } from "mobx";
import { IHpainting } from "@pro/common/contracts/expo";
import { TUint64 } from "@pro/common/contracts/common_types";

export class HPaintingsTable {
	updateAt: number
	items = new Map<TUint64, IHpainting>();

	constructor()
	{
		this.updateAt = 0;
		makeObservable(this, {items: observable, updateAt: observable, updateItem: action});
	}

	updateItem(painting_ids: number[], records: IHpainting[])
	{
		for (const i of painting_ids) {
			this.items.set(i, records[i]);
		}
	}

	composed(painting_id: number): IHpainting
	{
		return this.items.get(painting_id) || {} as IHpainting;
	}

	updateAtNow()
	{
		this.updateAt = Date.now();
	}

	needUpdate(): boolean
	{
		return this.updateAt === 0 ? true : Date.now() - this.updateAt > 1000 * 180;
	}
}