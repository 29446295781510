import { observer } from "mobx-react";
import { app, model } from "../../App";
import Styles from "./PopUpPuzzlesView.module.scss"
import Slider from "./Slider";
import { useState } from "react";
import { GetPuzzlesAction } from "../../eos/actions/GetPuzzlesAction";
import Button from "../../pages/landing/components/Button";
import P1 from "../typography/P1";
import { L } from "../../l10n/L10n";
import { PopUpBase } from "../shared/PopUpBase";

const RemovePuzzlesView = observer((
	{
		painting_id,
		onClose
	}: {
		painting_id: number,
		onClose: () => void
	}
) => {
	let painting = model.paintings.get(painting_id);
	let [amount, setAmount] = useState(0)

	if (!painting)
		return <>Painting not found</>;

	return <PopUpBase title={L.popup.withdraw.title} onShowPopUp={onClose} className={Styles.width}>
		<P1>{L.popup.withdraw.body}</P1>
		<Slider min={1} value={Math.min(painting.depositedPuzzlesIds.length, 25)}
				max={painting.depositedPuzzlesIds.length} step={1} pointersConf={[{val: 25, text: "reccomended"}]}
				onChange={(val: number) => {
					setAmount(val)
				}}/>
		<Button onClick={() => {
			let template_ids = painting!.depositedPuzzlesIds.slice(0, amount)
				.map(it => {
					return app.useTemplateFIle
						? app.assetConf.findPuzzleByPaintingAndPuzzleId(painting_id, it)
						: model.atomicTemplates.find(app.chainConf.COLLECTION_NAME, (t) => {
							return t.getImmutableProp("puzzle_id") as number === it
						})
				}).map(it => it!.template_id)
			new GetPuzzlesAction(template_ids, painting_id).onSuccess(() => {
				if (amount === painting!.depositedPuzzlesIds.length)
					onClose();
			}).executeAsync()
		}}>{L.button.remove_puzzles.replace('$amount$', amount.toString())}</Button>
	</PopUpBase>
});
export default RemovePuzzlesView;