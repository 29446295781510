import React, { useEffect } from 'react';
import Styles from "./ExpoSlider.module.scss"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper";
import Container from "../landing/components/Container";
import ArrowLeftLg from "../../icons/ArrowLeftLg";
import ExpoCard from "./ExpoCard";
import ArrowRightLg from "../../icons/ArrowRightLg";
import { model } from "../../App";
import { L } from "../../l10n/L10n";
import { observer } from "mobx-react";
import { IMarketConf } from "@pro/common/contracts/expo";
import "swiper/css/pagination";
import "swiper/css";
import { useSearchParams } from "react-router-dom";

const ExpoSlider = observer(() => {
	const sliderRef = React.useRef<SwiperRef>(null);
	const [searchParams] = useSearchParams();

	const expos = (model.config?.market_config.map((item: IMarketConf, index: number) => {
		let id = index + 1;
		return {
			id: id,
			img: `/expos/expo_bg_${id}.png`,
			title: L.expos.title.replace("$id$", id.toString()),
			start_at: item.value.start_at,
		}
	}) ?? []);

	const pagination = {
		clickable: true,
		renderBullet: (_: number, className: string) => (
			'<button class="' + className + " " + Styles.button + '">' + "</button>"
		)
	};

	const handlePrev = React.useCallback(() => {
		sliderRef?.current?.swiper.slidePrev();
	}, []);

	const handleNext = React.useCallback(() => {
		sliderRef?.current?.swiper.slideNext();
	}, []);

	useEffect(()=>
	{
		if (sliderRef && sliderRef.current && searchParams.get("s"))
		{
			sliderRef.current.swiper.slideTo(parseInt(searchParams.get("s") || "1")+2);
		}
	}, [searchParams.get("s")])

	return (
		<Container className={Styles.container} fluid>
			<button onClick={handlePrev} className={Styles.prevBtn}>
				<ArrowLeftLg/>
			</button>
			<div className={Styles.inner}>
				<Swiper
					ref={sliderRef}
					className={Styles.swiper}
					direction="horizontal"
					modules={[Mousewheel, Pagination]}
					pagination={pagination}
					centeredSlides
					mousewheel={{
						thresholdDelta: 10
					}}
					loop
					noSwipingSelector="button"
					slidesPerView={1.25}
					spaceBetween={20}
					breakpoints={{
						360: {
							spaceBetween: 30,
							slidesPerView: 1.5
						},
						576: {
							spaceBetween: 30,
							slidesPerView: 2.5
						},
						1024: {
							spaceBetween: 30,
							slidesPerView: 3,
						},
					}}
				>
					{
						expos.map((slide, key) => (
							<SwiperSlide key={key} className={Styles.swiperSlide}>
								<ExpoCard {...slide} />
							</SwiperSlide>
						))
					}
				</Swiper>
			</div>
			<button onClick={handleNext} className={Styles.nextBtn}>
				<ArrowRightLg/>
			</button>
		</Container>
	);
});

export default ExpoSlider;
