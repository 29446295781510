import { EosActionBase } from "../EosActionBase";
import { app, eos, model, world } from "../../App";

export class ClaimLotteryAction extends EosActionBase {
	constructor()
	{
		super("claimlottery");
	}

	async onExecute(): Promise<void>
	{
		await eos.expoContract.action("claimlottery", eos.userAuth, {
			owner: model.userName
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdateBalance(app.chainConf.TKN_ACCOUNT, app.chainConf.TKN_SYMBOL);
		await world.updateUserLottery(model.userName);
	}
}