import React from 'react';

const TwitterIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M8.35537 20C15.9823 20 20.1533 13.8441 20.1533 8.50533C20.1533 8.33057 20.1533 8.15663 20.1415 7.98352C20.9532 7.41137 21.6538 6.70291 22.2105 5.89134C21.4539 6.21842 20.6511 6.43304 19.8291 6.52802C20.6944 6.02286 21.342 5.22863 21.6514 4.29308C20.8376 4.76357 19.9473 5.09515 19.0189 5.27353C18.3937 4.62581 17.5668 4.19691 16.6662 4.05318C15.7656 3.90946 14.8415 4.05893 14.0368 4.47847C13.2322 4.89801 12.5919 5.56423 12.215 6.37403C11.8381 7.18383 11.7457 8.09208 11.952 8.95822C10.3035 8.87772 8.69079 8.46038 7.21856 7.73329C5.74633 7.0062 4.44748 5.98561 3.40632 4.73777C2.8762 5.6272 2.71392 6.68004 2.9525 7.68195C3.19109 8.68385 3.8126 9.55951 4.69053 10.1307C4.03056 10.1115 3.38503 9.93784 2.80842 9.62444V9.67613C2.80868 10.6088 3.14004 11.5127 3.7463 12.2346C4.35256 12.9564 5.19641 13.4517 6.13474 13.6365C5.52433 13.7985 4.88395 13.8221 4.26274 13.7054C4.52766 14.5082 5.04346 15.2102 5.73801 15.7133C6.43255 16.2164 7.27111 16.4954 8.13642 16.5114C7.27664 17.1696 6.29213 17.6561 5.2392 17.9433C4.18626 18.2305 3.08555 18.3127 2 18.1851C3.89604 19.371 6.10229 20.0001 8.35537 19.9975"
			fill={color}/>
	</svg>
);

export default TwitterIcon;
