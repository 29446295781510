import Styles from "./LotteryPrize.module.scss";
import P2 from "../../components/typography/P2";
import { L } from "../../l10n/L10n";
import InfoIcon from "../../icons/InfoIcon";
import React, { useState } from "react";
import PopUp from "./PopUp";
import { LotteryModel } from "../../models/LotteryModel";
import waxIcon from "../../images/art_token.png";
import ArtValue from "../../components/shared/ArtValue";

const LotteryPrize = ({lottery}: { lottery: LotteryModel }) => {
	const [showPopUp, setShowPopUp] = useState(false);

	return (
		<div className={Styles.prizePool}>
			{showPopUp && <PopUp lottery={lottery} onShowPopUp={() => setShowPopUp(false)}/>}
			<P2>{L.lottery.lottery_prize_pool}</P2>
			<ArtValue value={lottery.maxPrize.symbol.formatNumber(lottery.maxPrize.amount)} img={waxIcon}
					  symbol={lottery.maxPrize.symbol.code}/>
			<div onClick={() => setShowPopUp(true)} className={Styles.openPopUp}>
				<InfoIcon/>
			</div>
		</div>
	);
};

export default LotteryPrize