import React from "react";
import { AtomicTemplateModel } from "../../models/AtomicTemplateModel";

const TemplateMedia = (
	{
		t,
		onClick
	}: {
		t?: AtomicTemplateModel,
		onClick?: Function
	}
) => {
	const click = () => {
		if (onClick)
			onClick();
	}
	// return <></>;
	return t?.video ? <video src={t.video} onClick={() => click()}/> :
		<img width={80} src={t?.img} onClick={() => click()}/>
}

export default TemplateMedia;