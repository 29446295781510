import React from 'react';

const LockIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
		<path
			d="M21.625 12.7V10.5C21.625 7.42 19.15 5 16 5C12.85 5 10.375 7.42 10.375 10.5V12.7C8.4625 12.7 7 14.13 7 16V23.7C7 25.57 8.4625 27 10.375 27H21.625C23.5375 27 25 25.57 25 23.7V16C25 14.13 23.5375 12.7 21.625 12.7ZM12.625 10.5C12.625 8.63 14.0875 7.2 16 7.2C17.9125 7.2 19.375 8.63 19.375 10.5V12.7H12.625V10.5ZM17.125 21.5C17.125 22.16 16.675 22.6 16 22.6C15.325 22.6 14.875 22.16 14.875 21.5V18.2C14.875 17.54 15.325 17.1 16 17.1C16.675 17.1 17.125 17.54 17.125 18.2V21.5Z"
			fill={color}/>
	</svg>
);

export default LockIcon;

