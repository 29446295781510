import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import Styles from "./Button.module.scss";

export enum EButtonType {
	MINIMAL,
	TELEGRAM,
	TWITTER,
	MEDIUM,
	DEFAULT,
	PANEL_BUTTON,
	EXIT_BUTTON,
	SCRIPT_BUTTON
}

type Props = {
	text: string;
	onClick?: MouseEventHandler;
	disabled?: boolean;
	ready?: boolean;
	type?: EButtonType;
}

const Button = React.forwardRef<HTMLButtonElement, Props>((
	{
		text,
		onClick = () => {
		},
		disabled = false,
		ready = true,
		type = EButtonType.DEFAULT,
	},
	ref,
) => {
	const className = classNames(
		Styles.button,
		getTypeClass(type),
		{[Styles.disabled]: disabled},
		{[Styles.busy]: !ready},
	);

	const handleClick: MouseEventHandler = (e) => {
		if (!disabled && ready)
			onClick(e);
	};

	return (
		<button
			className={className}
			onClick={handleClick}
			disabled={disabled}
			ref={ref}
		>
			{text}
		</button>
	);
});

export function getTypeClass(type: EButtonType)
{
	switch (type) {
		case EButtonType.MINIMAL:
			return Styles.minimal_button;
		case EButtonType.TELEGRAM:
			return Styles.telegram;
		case EButtonType.TWITTER:
			return Styles.twitter;
		case EButtonType.MEDIUM:
			return Styles.medium;

		case EButtonType.DEFAULT:
			return Styles.textButton;
		case EButtonType.PANEL_BUTTON:
			return Styles.panel_button;
		case EButtonType.EXIT_BUTTON:
			return Styles.exit_button;
		case EButtonType.SCRIPT_BUTTON:
			return Styles.script_button;
	}
}

export default Button;
