import { IPlayerPuzzle, IPoint, IPuzzle, IPuzzlePlayerMap, IPuzzlesMap, PuzzleType } from "./types";
import { AssetConf } from "@pro/common/conf";

export class CanvasPuzzleCropper {
	get maxDepth(): number
	{
		return this._maxDepth;
	}

	set maxDepth(value: number)
	{
		this._maxDepth = value;
		this.prepare();
	}

	get minDepth(): number
	{
		return this._minDepth;
	}

	set minDepth(value: number)
	{
		this._minDepth = value;
		this.prepare();
	}

	get rows(): number
	{
		return this._rows;
	}

	set rows(value: number)
	{
		this._rows = value;
		this.prepare();
	}

	get cols(): number
	{
		return this._cols;
	}

	set cols(value: number)
	{
		this._cols = value;
		this.prepare();
	}

	get maxPaddingX(): number
	{
		return this._maxPaddingX;
	}

	set maxPaddingX(value: number)
	{
		this._maxPaddingX = value;
		this.prepare();
	}

	get maxPaddingY(): number
	{
		return this._maxPaddingX;
	}

	set maxPaddingY(value: number)
	{
		this._maxPaddingY = value;
		this.prepare();
	}

	get minPaddingY(): number
	{
		return this._minPaddingY;
	}

	set minPaddingY(value: number)
	{
		this._minPaddingY = value;
		this.prepare();
	}

	get minPaddingX(): number
	{
		return this._minPaddingX;
	}

	set minPaddingX(value: number)
	{
		this._minPaddingX = value;
		this.prepare();
	}

	get maxHeight(): number
	{
		return this._maxHeight;
	}

	set maxHeight(value: number)
	{
		this._maxHeight = value;
		this.prepare();
	}

	get minHeight(): number
	{
		return this._minHeight;
	}

	set minHeight(value: number)
	{
		this._minHeight = value;
		this.prepare();
	}

	get maxWidth(): number
	{
		return this._maxWidth;
	}

	set maxWidth(value: number)
	{
		this._maxWidth = value;
		this.prepare();
	}

	get minWidth(): number
	{
		return this._minWidth;
	}

	set minWidth(value: number)
	{
		this._minWidth = value;
		this.prepare();
	}

	private readonly _context: CanvasRenderingContext2D;
	private _image: HTMLImageElement | undefined;
	private map?: IPuzzlesMap;
	// private shadowX = 0;
	// private shadowY = 0;
	// private shadowBlur = 0;
	private shadowBlur = 4;
	private shadowX = 2;
	private shadowY = 2;
	private _type = PuzzleType.RECT;

	private _rows = 5;
	private _cols = 5;
	private _minWidth = 0.1;
	private _maxWidth = 0.1;
	private _minHeight = 0.1;
	private _maxHeight = 0.1;
	private _minDepth = 0.1;
	private _maxDepth = 0.1;
	private _minPaddingX = 0.25;
	private _maxPaddingX = 0.25;
	private _minPaddingY = 0.25;
	private _maxPaddingY = 0.25;

	constructor(private readonly _canvas: HTMLCanvasElement)
	{
		this._context = this._canvas.getContext('2d')!
	}

	get canvas(): HTMLCanvasElement
	{
		return this._canvas;
	}

	get imgWidth(): number
	{
		return (this._image?.width || 0);// * window.devicePixelRatio
	}

	get imgHeight(): number
	{
		return (this._image?.height || 0);// * window.devicePixelRatio
	}

	private set width(val: number)
	{
		this._canvas.style.width = `${val}px`;
		this._canvas.width = Math.floor(val) + this.shadowX + this.shadowBlur;
	}

	private set height(val: number)
	{
		this._canvas.style.height = `${val}px`;
		this._canvas.height = Math.floor(val) + this.shadowX + this.shadowBlur;
	}

	set type(val: PuzzleType)
	{
		this._type = val;
		this.prepare();
	}

	set image(val: HTMLImageElement)
	{
		this._image = val;
		this.width = this._image.width;
		this.height = this._image.height;
		this.draw();
	}

	private drawArcBorder(startPoint: IPoint, points: IPoint[])
	{
		if (points.length > 1) {
			if (points[points.length - 1].x === points[0].x) {
				this._context.lineTo(startPoint.x, startPoint.y + points[0].y);
				// this._context.arc(startPoint.x, startPoint.y + points[0].y, 5, 0, Math.PI*2)

				let center = {
					x: startPoint.x,
					y: startPoint.y + points[0].y + (points[points.length - 2].y - points[0].y) / 2
				};
				let r = points[1].x * 2;

				if (points[points.length - 1].y > points[0].y) {
					this._context.arc(center.x, center.y, Math.abs(r) / 2, -Math.PI / 2, Math.PI / 2, points[1].x < 0);
				} else {
					this._context.arc(center.x, center.y, Math.abs(r) / 2, Math.PI / 2, -Math.PI / 2, points[1].x > 0);
				}
			} else {
				this._context.lineTo(startPoint.x + points[0].x, startPoint.y);
				let center = {
					x: startPoint.x + points[0].x + (points[points.length - 2].x - points[0].x) / 2,
					y: startPoint.y
				};

				let r = points[1].y * 2;

				if (points[points.length - 1].x > points[0].x) {
					this._context.arc(center.x, center.y, Math.abs(r) / 2, Math.PI, 0, points[1].y > 0);
				} else {
					this._context.arc(center.x, center.y, Math.abs(r) / 2, 0, Math.PI, points[1].y < 0);
				}

			}
		}
		this._context.lineTo(startPoint.x + points[points.length - 1].x, startPoint.y + points[points.length - 1].y);
	}

	private drawBorder(startPoint: IPoint, points: IPoint[])
	{
		for (let p of points)
			this._context.lineTo(startPoint.x + p.x, startPoint.y + p.y);
	}

	private getPuzzleRect(puzzle: IPuzzle): { x: number, y: number, w: number, h: number }
	{
		const w = Math.floor(this.imgWidth / this.map!.cols);
		const h = Math.floor(this.imgHeight / this.map!.rows);
		let startPoint = {x: puzzle.pos.x * w, y: puzzle.pos.y * h};

		let topPoint = puzzle.topBorder.sort((a, b) => a.y - b.y)[0];
		let top = startPoint.y + (topPoint && topPoint.y < 0 ? topPoint.y : 0);

		let letfPoint = puzzle.leftBorder.sort((a, b) => a.x - b.x)[0];
		let left = startPoint.x + (letfPoint && letfPoint.x < 0 ? letfPoint.x : 0);

		let bottomPoint = puzzle.bottomBorder.sort((a, b) => b.y - a.y)[0];
		let bottom = startPoint.y + h + (bottomPoint && bottomPoint.y > 0 ? bottomPoint.y : 0);

		let rightPoint = puzzle.rightBorder.sort((a, b) => b.x - a.x)[0];
		let right = startPoint.x + w + (rightPoint && rightPoint.x > 0 ? rightPoint.x : 0);

		return {x: left, y: top, w: right - left, h: bottom - top};
	}

	private drawPuzzle(puzzle: IPuzzle)
	{
		const w = Math.floor(this.imgWidth / this.map!.cols);
		const h = Math.floor(this.imgHeight / this.map!.rows);
		let startPoint = {x: puzzle.pos.x * w, y: puzzle.pos.y * h};
		this._context.moveTo(startPoint.x, startPoint.y);

		if (this._type === PuzzleType.ARC) {
			this.drawArcBorder(startPoint, [...puzzle.topBorder, {x: w, y: 0}]);
			this.drawArcBorder({x: startPoint.x + w, y: startPoint.y}, [...puzzle.rightBorder, {x: 0, y: h}]);
			this.drawArcBorder({x: startPoint.x + w, y: startPoint.y + h}, [...puzzle.bottomBorder, {x: -w, y: 0}]);
			this.drawArcBorder({x: startPoint.x, y: startPoint.y + h}, [...puzzle.leftBorder, {x: 0, y: -h}]);
		} else {
			this.drawBorder(startPoint, [...puzzle.topBorder, {x: w, y: 0}]);
			this.drawBorder({x: startPoint.x + w, y: startPoint.y}, [...puzzle.rightBorder, {x: 0, y: h}]);
			this.drawBorder({x: startPoint.x + w, y: startPoint.y + h}, [...puzzle.bottomBorder, {x: -w, y: 0}]);
			this.drawBorder({x: startPoint.x, y: startPoint.y + h}, [...puzzle.leftBorder, {x: 0, y: -h}]);
		}
	}


	private drawImage()
	{
		this._context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this._context.drawImage(this._image!, 0, 0, this.imgWidth, this.imgHeight);
	}

	getPaintingImages(painting_id: number)
	{
		let newCanvas = this.resizeImage(Math.floor(this.imgWidth/4), Math.floor(this.imgHeight/4));
		for (let r of AssetConf.RARITIES)
		{
			let a = document.createElement('a');
			a.download = `${painting_id}_${r}.jpg`;
			a.href = newCanvas.toDataURL("image/jpeg");
			a.click();
		}

	}

	getSamples()
	{
		let newCanvas = this.resizeImage(Math.floor(this.imgWidth/2), Math.floor(this.imgHeight/2))

		let a = document.createElement('a');
		a.download = `painting.jpg`;
		a.href = newCanvas.toDataURL("image/jpeg");
		a.click();

		newCanvas = this.resizeImage(Math.floor(this.imgWidth/4), Math.floor(this.imgHeight/4))

		a = document.createElement('a');
		a.download = `sample.jpg`;
		a.href = newCanvas.toDataURL("image/jpeg");
		a.click();
	}

	private resizeImage(ww: number, hh: number):HTMLCanvasElement
	{
		this.drawImage();
		let newCanvas = document.createElement('canvas');

		newCanvas.width = ww;
		newCanvas.height = hh;
		let newContext = newCanvas.getContext('2d');
		newContext!.drawImage(this.canvas, 0, 0, this.imgWidth, this.imgHeight, 0, 0, ww, hh);

		this._context.restore();

		return newCanvas;

	}

	crop()
	{
		if (!this._image || !this.map)
			return;

		let t = 0;

		// for (let row = 0; row < 1; row++) {
		//     for (let col = 0; col < 1; col++) {
		for (let row = 0; row < this.map.rows; row++) {
			for (let col = 0; col < this.map.cols; col++) {
				setTimeout(() => {
					this._context.save();
					this._context.beginPath();
					this._context.clearRect(0, 0, this.canvas.width, this.canvas.height);

					const puzzle = this.map!.puzzles.find(it => it.pos.x === col && it.pos.y === row)!;
					this._context!.shadowColor = "#000000";
					this._context!.shadowOffsetX = this.shadowX;
					this._context!.shadowOffsetY = this.shadowY;
					this._context!.shadowBlur = this.shadowBlur;

					this.drawPuzzle(puzzle);
					this._context!.fill();
					this._context.clip();

					this.drawImage();
					let newCanvas = document.createElement('canvas');

					let r = this.getPuzzleRect(puzzle);

					const ww = r.w + this.shadowX + this.shadowBlur * 2;
					const hh = r.h + this.shadowY + this.shadowBlur * 2;
					newCanvas.width = ww;
					newCanvas.height = hh;
					let newContext = newCanvas.getContext('2d');
					newContext!.drawImage(this.canvas, r.x - this.shadowBlur/2, r.y - this.shadowBlur/2, ww, hh, 0, 0, ww, hh);

					this._context.restore();

					let a = document.createElement('a');
					a.download = `${puzzle.puzzle_id}.png`;
					a.href = newCanvas.toDataURL();
					a.click();

					// let img = new Image();
					// img.onload = ()=>
					// {
					// 	setTimeout(()=>
					// 	{
					// 		let nC = this.generateImage(img, 0.2);
					// 		let a = document.createElement('a');
					// 		a.download = `${puzzle.checksum}_s.png`;
					// 		a.href = nC.toDataURL();
					// 		a.click();
					// 	}, 150)
					// 	setTimeout(()=>
					// 	{
					// 		let nC2 = this.generateImage(img, 0.4);
					// 		let b = document.createElement('a');
					// 		b.download = `${puzzle.checksum}_m.png`;
					// 		b.href = nC2.toDataURL();
					// 		b.click();
					// 	}, 300)
					// }
					// img.src = a.href;

				}, t * 200)
				t++;

			}
		}
	}

	getMap()
	{
		if (!this.map)
			return;
		let map = {
			cols: this.map.cols,
			rows: this.map.rows,
			puzzle_width: this.map.puzzle_width,
			puzzle_height: this.map.puzzle_height
		} as IPuzzlePlayerMap;

		map.puzzles = [] as IPlayerPuzzle[];

		for (let p of this.map.puzzles) {
			let topPoint = p.topBorder.sort((a, b) => a.y - b.y)[0];
			let letfPoint = p.leftBorder.sort((a, b) => a.x - b.x)[0];

			map.puzzles.push({
				x: 0,
				y: 0,
				leftOffset: Math.min(letfPoint?.x || 0, 0),
				topOffset: Math.min(topPoint?.y || 0, 0),
				puzzle_id: p.puzzle_id
			})
		}

		var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(map));
		let b1 = document.createElement('a');
		b1.download = 'p_map.json';
		b1.href = dataStr;
		b1.click();

		map.puzzles.sort((a, b) => a.puzzle_id - b.puzzle_id);
		var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(map));
		let b = document.createElement('a');
		b.download = 'map.json';
		b.href = dataStr;
		b.click();
	}

	draw()
	{
		if (!this._image || !this.map)
			return;

		this.drawImage();
		this._context.beginPath();

		// for (let row = 1; row < 2; row++) {
		for (let row = 0; row < this.map.rows; row++) {
			// for (let col = 1; col < 2; col++) {
			for (let col = 0; col < this.map.cols; col++) {
				const puzzle = this.map!.puzzles.find(it => it.pos.x === col && it.pos.y === row)!;
				this.drawPuzzle(puzzle);
				// let r = this.getPuzzleRect(puzzle);
				// this._context.rect(r.x, r.y, r.w, r.h);
			}
		}

		this._context.stroke();
		this._context.closePath();
	}

	prepare()
	{
		const puzzles: IPuzzle[] = [];

		const rows = this._rows;
		const cols = this._cols;

		const w = Math.floor(this.imgWidth / cols);
		const h = Math.floor(this.imgHeight / rows);

		const maxWidth = w * this._maxWidth;
		const minWidth = w * this._minWidth;

		const maxHeight = h * this._maxHeight;
		const minHeight = h * this._minHeight;

		const maxPaddingX = this._maxPaddingX;
		const minPaddingX = this._minPaddingX;

		const maxPaddingY = this._maxPaddingY;
		const minPaddingY = this._minPaddingY;

		const maxDepth = Math.min(w, h) * this._maxDepth;
		const minDepth = Math.min(w, h) * this._minDepth;

		let ids = Array.from(Array(rows * cols).keys()).sort(() => (Math.random() > .5) ? 1 : -1);

		for (let row = 0; row < rows; row++) {
			for (let col = 0; col < cols; col++) {
				let checksum = ids.shift();
				let puzzle: IPuzzle = {pos: {x: col, y: row}, puzzle_id: checksum} as IPuzzle;

				if (row === 0) {
					puzzle.topBorder = [];
				} else {
					let colisionBorder = puzzles.find(it => it.pos.x === puzzle.pos.x && it.pos.y === puzzle.pos.y - 1)!.bottomBorder;
					puzzle.topBorder = colisionBorder.slice().reverse().map(p => {
						return {x: p.x + w, y: (p.y)}
					});
				}

				if (row === rows - 1) {
					puzzle.bottomBorder = [];
				} else {
					let standard = Math.random() > 0.5;

					const startX = Math.floor(w * minPaddingX + (standard ? (this.minPaddingX + this.maxPaddingX) / 2 : Math.random()) * w * maxPaddingX);
					const lastX = Math.floor(startX + minWidth + (standard ? (this.minWidth + this.maxWidth) / 2 : Math.random()) * maxWidth);

					const secondX = Math.floor(startX * 0.95 + (standard ? 0.5 : Math.random()) * (startX * 0.1));
					const secondY = Math.floor((minDepth + (standard ? (this.minDepth + this.maxDepth) / 2 : Math.random()) * maxDepth) * (Math.random() > 0.5 ? 1 : -1));

					const thirdX = Math.floor(lastX * 0.95 + (standard ? 0.5 : Math.random()) * (lastX * 0.1));
					const thirdY = Math.floor((minDepth + (standard ? (this.minDepth + this.maxDepth) / 2 : Math.random()) * maxDepth) * (secondY > 1 ? 1 : -1));

					if (this._type === PuzzleType.RECT) {
						puzzle.bottomBorder = [
							{x: -startX, y: 0},
							{x: -secondX, y: secondY},
							{x: -thirdX, y: thirdY},
							{x: -lastX, y: 0}
						]
					} else if (this._type === PuzzleType.TRIANGLE) {
						puzzle.bottomBorder = [
							{x: -startX, y: 0},
							{x: Math.floor(-(secondX + thirdX) / 2), y: Math.floor((secondY + thirdY) / 2)},
							{x: -lastX, y: 0}
						]
					} else {
						puzzle.bottomBorder = [
							{x: -startX, y: 0},
							{
								x: Math.floor(-(startX + (lastX - startX) / 2)),
								y: Math.floor((lastX - startX) / 2 * (Math.random() > 0.5 ? 1 : -1))
							},
							{x: -lastX, y: 0}
						];
					}
				}

				if (col === 0) {
					puzzle.leftBorder = [];
				} else {
					let colisionBorder = puzzles.find(it => it.pos.y === puzzle.pos.y && it.pos.x === puzzle.pos.x - 1)!.rightBorder;
					puzzle.leftBorder = colisionBorder.slice().reverse().map(p => {
						return {x: p.x, y: (p.y - h)}
					});
				}

				if (col === cols - 1) {
					puzzle.rightBorder = [];
				} else {
					let standard = Math.random() > 0.5;

					const startY = Math.floor(h * minPaddingY + (standard ? (this.minPaddingY + this.maxPaddingY) / 2 : Math.random()) * h * maxPaddingY);
					const lastY = Math.floor(startY + minHeight + (standard ? (this.minHeight + this.maxHeight) / 2 : Math.random()) * maxHeight);

					const secondY = Math.floor(startY * 0.95 + (standard ? 0.5 : Math.random()) * (startY * 0.1));
					const secondX = Math.floor((minDepth + (standard ? (this.minDepth + this.maxDepth) / 2 : Math.random()) * maxDepth) * (Math.random() > 0.5 ? 1 : -1));

					const thirdY = Math.floor(lastY * 0.95 + (standard ? 0.5 : Math.random()) * (lastY * 0.1));
					const thirdX = Math.floor((minDepth + (standard ? (this.minDepth + this.maxDepth) / 2 : Math.random()) * maxDepth) * (secondX > 1 ? 1 : -1));

					if (this._type === PuzzleType.RECT) {
						puzzle.rightBorder = [
							{x: 0, y: startY},
							{x: secondX, y: secondY},
							{x: thirdX, y: thirdY},
							{x: 0, y: lastY}
						]
					} else if (this._type === PuzzleType.TRIANGLE) {
						puzzle.rightBorder = [
							{x: 0, y: startY},
							{x: Math.floor((secondX + thirdX) / 2), y: Math.floor((secondY + thirdY) / 2)},
							{x: 0, y: lastY}
						];
					} else {
						puzzle.rightBorder = [
							{x: 0, y: startY},
							{
								x: Math.floor((lastY - startY) / 2 * (Math.random() > 0.5 ? 1 : -1)),
								y: Math.floor(startY + (lastY - startY) / 2)
							},
							{x: 0, y: lastY}
						];
					}
				}
				puzzles.push(puzzle);
			}
		}

		this.map = {cols: cols, rows: rows, puzzles: puzzles, puzzle_width: w, puzzle_height: h};
		this.draw();
	}
}