export enum PagePath {
	ROOT = "/",
	EXPOS = "/expos",
	EXPO = "/expos/:id",
	PUZZLES = "/expos/:id/puzzles/:paintingId",
	GALLERY = "/gallery/:id",
	LOTTERY = "/lottery",
	PACKS = "/packs",
	SHOP = "/shop",
	ASSETS = "/assets",

	EXPOSITION = "/exposition",
	CROPPER = "/cropper",
	TEST = "/test",

	POOL = "/pool/:id",
	POOL_STAKE = "/pool/:id/stake",
	ADMIN = "/admin",
	ADMIN_POOLS = "/admin/pools",
	ADMIN_POOL = "/admin/pool/:id",
	ADMIN_POOL_USERS = "/admin/pool/:id/users",
	ADMIN_POOL_USER = "/admin/pool/:id/user/:account",
	NEW_POOL = "/admin/pools/create",
	EDIT_POOL = "/admin/pools/edit/:id",
}

export function getPathById(path: PagePath, id: number, account?: string)
{
	let p = path.replace(":id", id.toString());
	if (account)
		return p.replace(":account", account)
	return p;
}

export function makeExpoPath(id: number)
{
	return getPathById(PagePath.EXPO, id);
}

export function makePuzzlesPath(expoId: number, paintingId: number)
{
	return PagePath.PUZZLES.replace(":id", expoId.toString()).replace(":paintingId", paintingId.toString());
}

// export function makePageTitle(title: string): string
// {
// 	return title + " | " + L.shared.siteName;
// }
//
// export async function navigateToWindow(navigate: NavigateFunction, window: WindowPath)
// {
// 	await navigate(getPathToWindow(window));
// }
//
// export function getPathToWindow(window: WindowPath): string
// {
// 	return PagePath.GAME + "?w=" + window;
// }
//
// export function packBackPath(location: Location): string
// {
// 	return location.pathname + location.search.split("&").join("|");
// }
//
// export function unpackBackPath(path: string): string
// {
// 	return path.split("|").join("&");
// }
