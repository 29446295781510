import React from 'react';

const ExternalIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M16.2622 7.03436C16.5651 7.03142 16.8083 7.27461 16.8054 7.57755L16.7574 12.5142C16.7545 12.8171 16.5065 13.0651 16.2036 13.068C15.9006 13.071 15.6574 12.8278 15.6604 12.5249L15.6955 8.91244L8.93893 15.669C8.72264 15.8853 8.37534 15.8887 8.16321 15.6765C7.95109 15.4644 7.95446 15.1171 8.17075 14.9008L14.9273 8.14426L11.3149 8.17937C11.012 8.18231 10.7688 7.93912 10.7717 7.63618C10.7747 7.33324 11.0226 7.08528 11.3256 7.08233L16.2622 7.03436Z"
			  fill={color}/>
	</svg>
);

export default ExternalIcon;