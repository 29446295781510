import { check } from "@pro/common/utils";
import { AFormat } from "@pro/common/contracts/atomicassets";

const PROMO_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "img", type: "image"},
	{name: "video", type: "string"},
	{name: "variant", type: "string"},
];

const PACK_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "img", type: "image"},
	{name: "description", type: "string"},
	{name: "expo", type: "uint8"},
	{name: "type", type: "string"},
];

const PAINTING_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "img", type: "image"},
	{name: "description", type: "string"},
	{name: "author", type: "string"},
	{name: "expo", type: "uint8"},
	{name: "rarity", type: "string"},
	{name: "puzzles", type: "uint16"},
];

const PUZZLES_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "img", type: "image"},
	{name: "painting_id", type: "uint8"},
	{name: "puzzle_id", type: "uint16"},
];

const COLLECTORS_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "img", type: "image"},
	{name: "author", type: "string"},
	{name: "expo", type: "uint8"},
	{name: "collector", type: "string"},
];

const TOOLS_SCHEMA_FORMAT: readonly Readonly<AFormat>[] = [
	{name: "name", type: "string"},
	{name: "description", type: "string"},
];

export type TSchemaName = "promo" | "packs" | "paintings" | "puzzles" | "collectors" | "tools";
export type TRarity = "common" | "rare" | "epic" | "legendary";

export interface ITemplateJson
{
	/* table fields */
	readonly template_id: number,
	readonly schema_name: TSchemaName,
	readonly transferable: boolean,
	readonly burnable: boolean,
	readonly max_supply: number,

	/* sheet data */
	readonly id: string,
	readonly img_path: string,

	/* serialized attributes */
	readonly name: string,
	readonly img: string,
}

export interface IPromoJson extends ITemplateJson
{
	readonly video?: string,
	readonly variant: string,
}

export interface IPackJson extends ITemplateJson
{
	/* sheet data */
	readonly description: string,
	readonly expo: number | string,
	readonly type: string,

	readonly painting_id?: number,
	readonly price?: string,
	readonly start_time?: number,
	readonly end_time?: number,
	readonly limit?: number,
}

export interface IPaintingJson extends ITemplateJson
{
	readonly description: string,
	readonly rarity: string,
	readonly author: string,
	readonly expo: number,
	readonly painting_id: number,
	readonly puzzles: number,
}

export interface IPuzzleJson extends ITemplateJson
{
	readonly painting_id: number,
	readonly puzzle_id: number,
}

export interface ICollectorJson extends ITemplateJson
{
	readonly author: string,
	readonly expo: number,
	readonly painting_id: number,
	readonly collector?: string,
}

export interface IToolJson extends ITemplateJson
{
	readonly description: number,
}

export class AssetConf
{
	static PACKS_SCHEMA_NAME = "packs" as "packs";
	static PAINTINGS_SCHEMA_NAME = "paintings" as "paintings";
	static PUZZLES_SCHEMA_NAME = "puzzles" as "puzzles";
	static COLLECTORS_SCHEMA_NAME = "collectors" as "collectors";
	static TOOLS_SCHEMA_NAME = "tools" as "tools";

	static readonly COLLECTION_DATA = {
		name: "ChainExpoArt",
		img: "QmS9Hh5hkAziX63JRwLwrRQdeyXAVGaqxV1n3YsrFpJMwg",
		description: "Chainexpo.art is an art quest (game) on the WAX blockchain that aims to blur the line between contemporary digital art and world classics. Participants (players) will have the opportunity to take part in the educational collect-to-earn game, interesting lotteries with real prizes, and finally, compete for the main prize of the project.",
		url: "https://chainexpo.art/",
	};

	static readonly RARITIES: readonly TRarity[] = [
		"common",
		"rare",
		"epic",
		"legendary",
	];

	static readonly SCHEMA_NAMES: readonly TSchemaName[] = [
		"promo",
		AssetConf.PACKS_SCHEMA_NAME,
		AssetConf.PAINTINGS_SCHEMA_NAME,
		AssetConf.PUZZLES_SCHEMA_NAME,
		AssetConf.COLLECTORS_SCHEMA_NAME,
		AssetConf.TOOLS_SCHEMA_NAME,
	];

	static readonly SCHEMAS = {
		promo: PROMO_SCHEMA_FORMAT,
		packs: PACK_SCHEMA_FORMAT,
		paintings: PAINTING_SCHEMA_FORMAT,
		puzzles:PUZZLES_SCHEMA_FORMAT,
		collectors:COLLECTORS_SCHEMA_FORMAT,
		tools:TOOLS_SCHEMA_FORMAT
	};

	readonly _byAttribId: ReadonlyMap<string, ITemplateJson>;
	readonly _byTemplateId: ReadonlyMap<number, ITemplateJson>;
	readonly _bySchema: ReadonlyMap<TSchemaName, ITemplateJson[]>;

	constructor(data: readonly ITemplateJson[])
	{
		let byAttribId = new Map<string, ITemplateJson>();
		let byTemplateId = new Map<number, ITemplateJson>();
		let bySchema = new Map<TSchemaName, ITemplateJson[]>();

		AssetConf.SCHEMA_NAMES.forEach(s => bySchema.set(s, []));

		for (let item of data)
		{
			check(!byAttribId.has(item.id), `"id" is not unique: ${JSON.stringify(item)}`);
			byAttribId.set(item.id, item);
			byTemplateId.set(item.template_id, item);

			let list = bySchema.get(item.schema_name);
			check(list, `invalid schema name: "${item.schema_name}"`);
			list.push(item);

			check(AssetConf.SCHEMA_NAMES.includes(item.schema_name), `invalid schema name: "${item.schema_name}"`);
		}

		this._byAttribId = byAttribId;
		this._byTemplateId = byTemplateId;
		this._bySchema = bySchema;
	}

	findByAttribId<T extends ITemplateJson>(id: string): T | undefined
	{
		return this._byAttribId.get(id) as T;
	}

	getByAttribId<T extends ITemplateJson>(id: string): T
	{
		const template = this.findByAttribId<T>(id);
		if (!template)
			throw Error(`Template with id ${id} note found`)
		return template;
	}

	getByTemplateId<T extends ITemplateJson>(templateId: number): T
	{
		let template = this._byTemplateId.get(templateId) as T;
		if (!template)
			throw Error("Template not found");
		return template;
	}

	getBySchema<T extends ITemplateJson>(schema: TSchemaName): readonly T[]
	{
		let list = this._bySchema.get(schema);
		check(list, `invalid schema name: "${schema}"`);
		return list as T[];
	}

	findPuzzleByPaintingAndPuzzleId(paintingId: number, puzzleId: number): IPuzzleJson | undefined
	{
		return this.puzzles.find(p => p.painting_id === paintingId && p.puzzle_id === puzzleId);
	}

	get paintings(): readonly IPaintingJson[]
	{
		return this.getBySchema(AssetConf.PAINTINGS_SCHEMA_NAME);
	}

	get packs(): readonly IPackJson[]
	{
		return this.getBySchema(AssetConf.PACKS_SCHEMA_NAME);
	}

	get collectors(): readonly ICollectorJson[]
	{
		return this.getBySchema(AssetConf.COLLECTORS_SCHEMA_NAME);
	}

	get puzzles(): readonly IPuzzleJson[]
	{
		return this.getBySchema(AssetConf.PUZZLES_SCHEMA_NAME);
	}
}
