import React from 'react';

const ArrowRightLg = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M27.2952 15.3715C27.6423 15.7186 27.6423 16.2814 27.2952 16.6285L21.6383 22.2854C21.2912 22.6325 20.7284 22.6325 20.3813 22.2854C20.0341 21.9383 20.0341 21.3754 20.3813 21.0283L24.5207 16.8889H5.33333C4.84241 16.8889 4.44444 16.4909 4.44444 16C4.44444 15.5091 4.84241 15.1111 5.33333 15.1111H24.5207L20.3813 10.9717C20.0341 10.6246 20.0341 10.0617 20.3813 9.7146C20.7284 9.36747 21.2912 9.36747 21.6383 9.7146L27.2952 15.3715Z"
			  fill={color}/>
	</svg>
);

export default ArrowRightLg;
