import Styles from "./PopUpBase.module.scss";
import H3 from "../typography/H3";
import React from "react";
import Button from "../../pages/landing/components/Button";
import CloseIcon from "../../icons/CloseIcon";
import classNames from "classnames";

export const PopUpBase = (
	{
		title,
		onShowPopUp,
		children,
		showCloseButton = true,
		className
	}: {
		title: string,
		onShowPopUp: () => void,
		children: React.ReactNode,
		showCloseButton?: boolean,
		className?: string
	}
) => (
	<div className={Styles.container}>
		<div className={classNames(Styles.content, className)}>
			<div className={classNames(Styles.title, {[Styles.showCloseButton]: !showCloseButton})}>
				<H3>{title}</H3>
				{showCloseButton && <Button onClick={onShowPopUp} icon={<CloseIcon/>} className={Styles.close}/>}
			</div>
			{children}
		</div>
	</div>
);
