import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import Styles from "./HowItWorks.module.scss"
import img1 from "../../../images/img_slider1.png"
import img2 from "../../../images/img_slider2.png"
import img3 from "../../../images/img_slider3.png"
import img4 from "../../../images/img_slider4.png"
import img5 from "../../../images/img_slider5.png"
import Button from "../components/Button";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import InfoBlock from "../components/InfoBlock";
import classNames from "classnames";
import ArrowDown from "../../../icons/ArrowDown";
import ArrowUp from "../../../icons/ArrowUp";
import { L } from "../../../l10n/L10n";
import { PagePath } from "../../../helpers/PageHelper";

const HowItWorksSlider = () => {
	const slides = [
		{
			img: img1,
			title: L.landing.sliders.slider1.title,
			text: L.landing.sliders.slider1.text,
		},
		{
			img: img2,
			title: L.landing.sliders.slider2.title,
			text: L.landing.sliders.slider2.text,
		},
		{
			img: img3,
			title: L.landing.sliders.slider3.title,
			text: L.landing.sliders.slider3.text,
		},
		{
			img: img4,
			title: L.landing.sliders.slider4.title,
			text: L.landing.sliders.slider4.text,
		},
		{
			img: img5,
			title: L.landing.sliders.slider5.title,
			text: L.landing.sliders.slider5.text,
			children: <Button path={PagePath.EXPOS}>{L.landing.buttons.play_game}</Button>
		}
	]

	const sliderRef = React.useRef<SwiperRef>(null);
	const [activeIndex, setActiveIndex] = React.useState(0);

	const handlePrev = React.useCallback(() => {
		sliderRef?.current?.swiper.slidePrev();
	}, []);

	const handleNext = React.useCallback(() => {
		sliderRef?.current?.swiper.slideNext();
	}, []);

	const handleItem = React.useCallback((index) => {
		sliderRef?.current?.swiper.slideTo(index - 1)
	}, []);

	const onSlideChange = ({activeIndex}: { activeIndex: number }) => setActiveIndex(activeIndex)

	return (
		<Container className={Styles.container} id="how">
			<Heading title={L.landing.sections.howItWorks.title} subTitle={L.landing.sections.howItWorks.subtitle}/>
			<div className={Styles.inner}>
				<Swiper
					ref={sliderRef}
					className={Styles.swiper}
					direction="vertical"
					slidesPerView={1}
					onSlideChange={onSlideChange}
					modules={[Mousewheel]}
					mousewheel
				>
					{
						slides.map((slide, key) => (
							<SwiperSlide key={key} className={Styles.swiperSlide}>
								<InfoBlock {...slide}/>
							</SwiperSlide>
						))
					}
				</Swiper>
				<div className={Styles.pagination}>
					<button
						onClick={handlePrev}
						disabled={activeIndex === 0}
						className={classNames(Styles.button, Styles.arrow)}
					>
						<ArrowUp/>
					</button>
					{
						slides.map((_, index) => (
							<button
								type="button"
								className={classNames(Styles.button, activeIndex === index && Styles.active)}
								onClick={() => handleItem(index)}
							>
								{
									String(index).length === 1 ? `0${++index}` : ++index
								}
							</button>
						))
					}
					<button
						onClick={handleNext}
						disabled={slides.length - 1 === activeIndex}
						className={classNames(Styles.button, Styles.arrow)}
					>
						<ArrowDown/>
					</button>
				</div>
			</div>
		</Container>
	);
};

export default HowItWorksSlider;
