import React from 'react';

const ArrowDown = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M12 3.33334C12.3682 3.33334 12.6667 3.63181 12.6667 4V18.3905L15.7712 15.286C16.0316 15.0256 16.4537 15.0256 16.7141 15.286C16.9744 15.5463 16.9744 15.9684 16.7141 16.2288L12.4714 20.4714C12.2111 20.7318 11.789 20.7318 11.5286 20.4714L7.28596 16.2288C7.02561 15.9684 7.02561 15.5463 7.28596 15.286C7.54631 15.0256 7.96842 15.0256 8.22877 15.286L11.3333 18.3905V4C11.3333 3.63181 11.6318 3.33334 12 3.33334Z"
			  fill={color}/>
	</svg>
);

export default ArrowDown;
