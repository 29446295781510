import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { StakingPoolModel } from "../../../models/StakingPoolModel";
import Styles from "./AdminPoolInfoView.module.scss";
import { EosAsset } from "@pro/common/eos";
import { formatTimeLeft } from "../../../helpers/formatters";
import TemplateMedia from "../../shared/TemplateMedia";
import { PoolAssetModel } from "../../../models/PoolAssetModel";
import { model } from "../../../App";


const TokenSelectorView = observer(({pool, poolAssets}: { pool: StakingPoolModel, poolAssets: PoolAssetModel[] }) => {
	const [timeToStart, setTimeToStart] = useState(pool.timeToStart)
	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeToStart(pool.timeToStart);
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	return <div className={Styles.container}>
		<div className={Styles.header}>
			{pool.owner !== "" ? `Pool #${pool.poolId}` : "New Pool Summary"}
		</div>
		<div className={Styles.info}>
			<h3>Reward per period {pool.rewardAsset.toString()}</h3>
			<h3>Periods {pool.periodsCount}</h3>
			<h3>Total reward {pool.totalReward.toString()}</h3>
			<h3>Period time {formatTimeLeft(pool.timePerPeriodSecs)}</h3>
			<h3>Total pool time {formatTimeLeft(pool.totalTimeSeconds)}</h3>
			<h3>Time to start {formatTimeLeft(timeToStart)}</h3>
			<h3>Activated {pool.activated ? "YES" : "NO"}</h3>
			{!pool.activated && pool.owner !== "" && <h3>
                Opened periods {pool.poolPeriods.length} / {pool.periodsCount}
            </h3>}

		</div>
		<div className={Styles.accept_token_box}>
			<h1>Reward token:</h1>
			<h4>Contract: {pool.rewardContract}, Symbol: {pool.rewardSymbol.code}</h4>
		</div>
		{
			pool.acceptContract !== "" && <div className={Styles.accept_token_box}>
                <h1>Accepting token:</h1>
                <h4>Contract: {pool.acceptContract}, Symbol: {pool.acceptSymbol.code}</h4>
            </div>
		}
		{
			poolAssets.length > 0 && <div className={Styles.accept_nft_box}>
                <h1>Accepting assets:</h1>
				{model.getAcceptedTemplates(poolAssets).map(it => {
					return <div className={Styles.asset}>
						<TemplateMedia t={it.t}/>
						<span>
                            Power: {it.power}
							{pool.minStake.symbol.precision > 1 ?
								<div>equal to {new EosAsset(it.power, pool.minStake.symbol).toString()}</div> : ""}
                        </span>
					</div>
				})}
            </div>
		}
	</div>
});

export default TokenSelectorView;