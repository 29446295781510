import React from 'react';

const TGChatIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M21.9909 3.25026C22.1451 1.39036 20.2949 2.1562 20.2949 2.1562C18.9288 2.75649 17.52 3.36655 16.0959 3.98319C11.6803 5.8952 7.11848 7.87054 3.23245 9.8146C1.12534 10.6352 2.35877 11.4557 2.35877 11.4557L5.69934 12.5498C7.2411 13.0421 8.06339 12.4951 8.06339 12.4951L15.2584 7.2983C17.8281 5.4384 17.2113 6.97008 16.5946 7.62653L11.1983 13.0968C10.3761 13.8626 10.7872 14.5191 11.147 14.8473C11.6012 15.2728 12.3511 15.8556 13.1397 16.4386C13.6696 14.4655 15.6446 13 18 13C18.9268 13 19.7948 13.2269 20.5389 13.6224L21.1172 9.59582C21.3022 8.10482 21.5144 6.6717 21.6839 5.52708C21.844 4.44598 21.9659 3.62226 21.9909 3.25026Z"
			fill={color}/>
		<path
			d="M22 17.9994C22 15.7905 20.2092 14 18 14C15.7908 14 14 15.7905 14 17.9994C14 18.6565 14.1588 19.2912 14.458 19.8591L14.0116 21.5748C13.9968 21.6321 13.9971 21.6923 14.0127 21.7494C14.0282 21.8065 14.0585 21.8586 14.1003 21.9004C14.1422 21.9423 14.1943 21.9725 14.2514 21.988C14.3085 22.0036 14.3687 22.004 14.426 21.9891L16.142 21.5424C16.7151 21.8432 17.3528 21.9998 18 21.9987C20.2092 21.9987 22 20.2082 22 17.9994Z"
			fill={color}/>
	</svg>
);

export default TGChatIcon;
