import React from 'react';
import H3 from "../typography/H3";
import P1 from "../typography/P1";
import Button from "../../pages/landing/components/Button";
import Styles from './Placeholder.module.scss'
import { PagePath } from "../../helpers/PageHelper";

const Placeholder = ({title, description}: { title?: string, description?: string }) => {
	return (
		<div className={Styles.container}>
			<div className={Styles.heading}>
				{
					title && <H3>{title}</H3>
				}
				{
					description &&
                    <P1>{description}</P1>
				}
			</div>
			<div className={Styles.buttons}>
				{/*<Button*/}
				{/*	href="https://pools/author/chainexpoart"*/}
				{/*	target="_blank"*/}
				{/*	type={EButtonType.SECONDARY}*/}
				{/*>*/}
				{/*	Earn ART token*/}
				{/*</Button>*/}
				<Button disabled path={PagePath.SHOP}>Buy pack</Button>
			</div>
		</div>
	);
};

export default Placeholder;
