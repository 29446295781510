import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import gpkImg from "../../../images/gpk_logo.png"
import dcFankoImg from "../../../images/dc_fanko.png"
import cartombsR2Img from "../../../images/cartombs_r2.png"
import ultrahorrorsImg from "../../../images/ultrahorrors.png"
import tribalbooksImg from "../../../images/tribalbooks.png"
import streetfighterImg from "../../../images/streetfighter.png"
import cryptomonKeysImg from "../../../images/cryptomonKeys.png"
import graffitiKingsImg from "../../../images/GraffitiKings.png"
import exitlimboImg from "../../../images/exitlimbo.png"
import rplanetImg from "../../../images/rplanet.png"
import Partner from "../components/Partner";
import Styles from "./Partnership.module.scss"
import { L } from "../../../l10n/L10n";

const Partnership = () => {
	return (
		<Container className={Styles.container} fluid id="pools">
			<Heading
				title={L.landing.sections.partnership.title}
				subTitle={L.landing.sections.partnership.subtitle}
				text={
					<>
						{L.landing.sections.partnership.pre}
						<a target="_blank"
						   href='https://pepperstake.online/how_it_works'>{L.landing.sections.partnership.link}</a>
						{L.landing.sections.partnership.post}
					</>
				}
			/>
			<div className={Styles.inner}>
				<Partner
					title="Garbage Pail Kids"
					href="https://pepperstake.online/pool/7"
					btnText="Stake"
					img={gpkImg}
				/>
				<Partner
					title="DC x Funko"
					href="https://pepperstake.online/pool/8"
					btnText="Stake"
					img={dcFankoImg}
				/>
				<Partner
					title="Cartombs!"
					href="https://pepperstake.online/pool/9"
					btnText="Stake"
					img={cartombsR2Img}
				/>
				<Partner
					title="The Horrors"
					href="https://pepperstake.online/pool/10"
					btnText="Stake"
					img={ultrahorrorsImg}
				/>
				<Partner
					title="Street Fighter"
					href="https://pepperstake.online/pool/12"
					btnText="Stake"
					img={streetfighterImg}
				/>
				<Partner
					title="Tribal Books"
					href="https://pepperstake.online/pool/13"
					btnText="Stake"
					img={tribalbooksImg}
				/>
				<Partner
					title="cryptomonKeys"
					href="https://pepperstake.online/pool/15"
					btnText="Stake"
					img={cryptomonKeysImg}
				/>
				<Partner
					title="Graffiti Kings"
					href="https://pepperstake.online/pool/16"
					btnText="Stake"
					img={graffitiKingsImg}
				/>
				<Partner
					title="Exit Limbo"
					href="https://pepperstake.online/pool/29"
					btnText="Stake"
					img={exitlimboImg}
				/>
				<Partner
					title="RPlanet"
					href="https://pepperstake.online/pool/30"
					btnText="Stake"
					img={rplanetImg}
				/>
			</div>
		</Container>
	)
}

export default Partnership;
