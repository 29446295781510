import React from "react";

const PropertyView = (
	{
		name,
		value,
		className = ""
	}: {
		name: string,
		value: string | number,
		className?: string
	}
) => (
	<div className={className}>
		<div>{name}</div>
		<div>{value}</div>
	</div>
);

export default PropertyView;