import { IUserUnstake } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";
import { EosAsset } from "@pro/common/eos";

export class UserUnstakeModel {
	constructor(
		public readonly record: IUserUnstake,
	)
	{
		makeObservable(this, {});
	}

	get quantity(): EosAsset
	{
		return EosAsset.parse(this.record.quantity.quantity);
	}

	get contract(): string
	{
		return this.record.quantity.contract;
	}

	get asset_ids(): number[]
	{
		return this.record.asset_ids;
	}

	get ready_at(): number
	{
		return this.record.ready_at;
	}

	get pool_id(): number
	{
		return this.record.pool_id;
	}

	get id(): number
	{
		return this.record.id;
	}
}