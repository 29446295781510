import React from 'react';

const ArrowUp = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M11.5286 3.5286C11.789 3.26825 12.2111 3.26825 12.4714 3.5286L16.7141 7.77124C16.9744 8.03159 16.9744 8.4537 16.7141 8.71405C16.4537 8.9744 16.0316 8.9744 15.7712 8.71405L12.6667 5.60948V20C12.6667 20.3682 12.3682 20.6667 12 20.6667C11.6318 20.6667 11.3333 20.3682 11.3333 20V5.60948L8.22877 8.71405C7.96842 8.9744 7.54631 8.9744 7.28596 8.71405C7.02561 8.4537 7.02561 8.03159 7.28596 7.77124L11.5286 3.5286Z"
			  fill={color}/>
	</svg>
);

export default ArrowUp;
