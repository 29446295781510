import { useEffect } from "react";
import { withUAL } from "ual-reactjs-renderer";
import { User } from "universal-authenticator-library";
import { L } from "../../l10n/L10n";
import { eos, model, notificationService, ualService } from "../../App";
import { IUal } from "../../services/UalService";

const UalView = withUAL((
	{
		ual,
	}: {
		ual: IUal,
	}
) => {
	useEffect(() => ualService.init(ual), []);

	useEffect(() => {
		if (ualService.isDevAuth)
			return;

		if (ual.activeUser) {
			loginWithUal(ual.activeUser).catch(e => notificationService.showError(e?.message ?? e));
		} else {
			if (model.logined) {
				model.logout();
				eos.logout()
			}
		}
	}, [ual.activeUser]);

	const loginWithUal = async (activeUser: User) => {
		const userName = await activeUser.getAccountName();
		if (userName !== model.userName) {
			await eos.loginWithUal(activeUser);
		}
	};

	return null;
});

UalView.displayName = L.shared.siteName;

export default UalView;