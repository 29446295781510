import { TUint64 } from "@pro/common/contracts/common_types";
import { EosApi, EosUtils, IEosAuth, IEosTransactResult } from "@pro/common/eos";
import {
	INpClaimAsset,
	INpPack,
	INpRamBalance,
	TNpActionName,
	TNpActionParam
} from "@pro/common/contracts/neftyblocksp/np_types";
import { BN } from "bn.js";

export class NPContract {
	private readonly _api: EosApi;
	private readonly _account: string;

	constructor(api: EosApi, account: string)
	{
		this._account = account;
		this._api = api;
	}

	action<N extends TNpActionName>(name: N,
									auth: IEosAuth | IEosAuth[],
									data: TNpActionParam<N>): Promise<IEosTransactResult | null>
	{
		return this._api.transact([{
			name: name,
			account: this._account,
			authorization: EosUtils.castAuth(auth),
			data: data,
		}]);
	}

	get api()
	{
		return this._api;
	}

	getPack(pack_id: TUint64): Promise<INpPack>
	{
		return this._api.getRecord(pack_id, {
			code: this._account,
			scope: this._account,
			table: "packs",
			key_type: "i64",
		});
	}

	getPacksByCollection(collectionName: string)
	{
		const decimalName = EosUtils.nameToDecimal(collectionName);
		const hexName = new BN(decimalName).toString(16).padStart(32, "0");
		return this._api.getFullTable<INpPack>({
			code: this._account,
			scope: this._account,
			table: "packs",
			key_type: "sha256",
			index_position: "3",
			lower_bound: hexName.padEnd(64, "0"),
			upper_bound: hexName.padEnd(64, "f"),
		});
	}

	async getClaimAsset(claimId: TUint64)
	{
		return this._api.findRecord<INpClaimAsset>(claimId, {
			code: this._account,
			scope: this._account,
			table: "claimassets",
			key_type: "i64",
		});
	}

	async getClaimAssetsByUser(name: string)
	{
		let result = await this._api.getTable<INpClaimAsset>({
			code: this._account,
			scope: this._account,
			table: "claimassets",
			key_type: "name",
			index_position: "2",
			lower_bound: name,
			upper_bound: name,
		});
		return result.rows;
	}

	async getRamBalance(collectionName: string)
	{
		return this._api.findRecord<INpRamBalance>(collectionName, {
			code: this._account,
			scope: this._account,
			table: "rambalances",
			key_type: "name",
		});
	}
}