import React from 'react';
import Styles from "./Card.module.scss"
import Button from "../../../pages/landing/components/Button";
import P2 from "../../typography/P2";
import H4 from "../../typography/H4";
import Counter from "../Counter";
import classNames from "classnames";
import tokenImg from "../../../images/art_token.png";
import { formatDaysLeft } from "../../../helpers/formatters";

interface CardProps extends React.HTMLAttributes<HTMLElement> {
	img: string,
	onClickBtn?: () => void,
	error?: string,
	price: string,
	token: string,
	available: number,
	total: number,
	onChangeValue: (value: number | "") => void,
	id: string,
	disabled?: boolean,
	startAt: number,
}

const ShopCard = ({
					  img,
					  onClickBtn,
					  error,
					  price,
					  token,
					  available,
					  total,
					  onChangeValue,
					  disabled,
					  startAt,
				  }: CardProps) => {
	const [time, setTime] = React.useState(startAt - Date.now() / 1000);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setTime(startAt - Date.now() / 1000)
		}, 1000)
		return () => {
			clearInterval(interval);
		}
	}, []);

	const buttonText = React.useMemo(() => {
		if (available < 1) {
			return "SOLD OUT"
		} else if (time > 0) {
			return formatDaysLeft(time)
		}
		return "Buy"

	}, [time, available]);

	const isDisabledCard = disabled || time > 0 || available < 1;

	return (
		<div className={classNames(Styles.card, Styles.shopCard)}>
			<P2 className={Styles.topper}>{`Available: ${available}/${total}`}</P2>
			<span className={Styles.divider}></span>
			<div className={classNames(Styles.img, isDisabledCard && Styles.disabled)}><img src={img} alt="img"/>
			</div>
			<Counter error={error} disabled={isDisabledCard} onChange={onChangeValue} max={Number(available)}/>
			<span className={Styles.divider}></span>
			<div className={Styles.bottom}>
				<div className={Styles.price}>
					<H4>{price}</H4>
					<span>
						<img src={tokenImg} alt="ART"/>
						<H4>{token}</H4>
					</span>
				</div>
				<div
					className={Styles.buttons}>
					<Button
						disabled={isDisabledCard || !!error}
						onClick={onClickBtn}
					>
						{buttonText}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ShopCard;