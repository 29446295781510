import React, { useEffect } from 'react';
import Styles from "./Menu.module.scss"
import Button, { EButtonType } from "../../landing/components/Button";
import NavigationIcon from "../../../icons/NavigationIcon";
import FullscreenIcon from "../../../icons/FullscreenIcon";
import P3 from "../../../components/typography/P3";
import P1 from "../../../components/typography/P1";
import { getPathById, PagePath } from "../../../helpers/PageHelper";
import ExportButton from "../../../components/puzzles/ExportButton";
import { IPuzzlePlayerState } from "../../../core/types";
import { model, notificationService } from "../../../App";
import ImportButton from "../../../components/puzzles/ImportButton";
import { CanvasPuzzlePlayer } from "../../../core/CanvasPuzzlePlayer";
import { IPaintingConf } from "@pro/common/contracts/expo";
import Toggle from "../../../components/shared/Toggle";
import { AtomicAssetModel } from "../../../models/AtomicAssetModel";
import { PaintingModel } from "../../../models/PaintingModel";
import CloseIcon from "../../../icons/CloseIcon";
import ExitFullScreenIcon from "../../../icons/ExitFullScreenIcon";

const Menu = (
	{
		player,
		onShowMissedPuzzles,
		paintingConf,
		neededPuzzles,
		painting,
		onAddPuzzlesMode,
		onRemovePuzzlesMode,
		showMissedPuzzles
	}: {
		player: CanvasPuzzlePlayer,
		paintingConf: IPaintingConf,
		onShowMissedPuzzles: (e: React.ChangeEvent<HTMLInputElement>) => void,
		neededPuzzles: AtomicAssetModel[],
		painting: PaintingModel | undefined,
		onAddPuzzlesMode: () => void,
		onRemovePuzzlesMode: () => void,
		showMissedPuzzles: boolean
	},
) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isFullScreen, setIsFullScreen] = React.useState(false);

	const menuHandler = React.useCallback(() => setIsOpen(prevState => !prevState), []);

	const onFullScreen = React.useCallback(() => {
		document.fullscreenElement ? document.exitFullscreen() : document.body.requestFullscreen()
		setIsFullScreen(!document.fullscreenElement)
	}, [])
	let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

	const onDoneImport = (s: IPuzzlePlayerState) => {
		const v = player!.isStateValid(s);
		return v ? player!.previewNewState(s) : notificationService.showError("State invalid")
	}

	const neededPuzzlesCount = paintingConf.value.rows * paintingConf.value.cols - (painting?.depositedPuzzlesIds || []).length;
	const availablePuzzles =
		model.atomicAssets.getPaintingNeededFromAvailable(painting?.depositedPuzzlesIds || [], paintingConf.key);

	const handleWindowClick = (e: MouseEvent | TouchEvent) => {
		if (e.target instanceof HTMLCanvasElement)
			setIsOpen(false);
	}
	useEffect(() => {
		window.addEventListener("mousedown", handleWindowClick);
		window.addEventListener("touchstart", handleWindowClick);
		return () => {
			window.removeEventListener("mousedown", handleWindowClick);
			window.removeEventListener("touchstart", handleWindowClick);
		}
	}, []);

	return (
		<div className={Styles.container}>
			<div className={Styles.controllers}>
				{!isIOS &&
                    <Button onClick={onFullScreen} icon={
						isFullScreen ? <ExitFullScreenIcon/> : <FullscreenIcon/>
					}/>
				}
				<Button onClick={menuHandler} icon={isOpen ? <CloseIcon/> : <NavigationIcon/>}/>
			</div>
			{
				isOpen && (
					<div className={Styles.menu}>
						<div className={Styles.inner}>
							<div className={Styles.block}>
								<P3 className={Styles.headline}>Pic Info:</P3>
								<div className={Styles.info}>
									<div className={Styles.item}><P1>Composed:</P1><P1 isBold>0</P1></div>
									<div className={Styles.item}><P1>Sample:</P1><P1 isBold>
										{`${paintingConf.value.cols}x${paintingConf.value.rows}`}
									</P1></div>
								</div>
							</div>
							<span className={Styles.divider}/>
							<div className={Styles.block}>
								<P3 className={Styles.headline}>Puzzles:</P3>
								<div className={Styles.info}>
									<div className={Styles.item}>
										<P1>Deposited:</P1>
										<P1 isBold>
											{(painting?.depositedPuzzlesIds || []).length}
										</P1>
									</div>
									<div className={Styles.item}>
										<P1>Available/Needed:</P1>
										<P1 isBold>
											{`${availablePuzzles.length}/${neededPuzzlesCount}`}
										</P1>
									</div>
								</div>
								<div className={Styles.actions}>
									<div className={Styles.buttons}>
										<Button type={EButtonType.SECONDARY}
												disabled={neededPuzzles.length == 0}
												onClick={onAddPuzzlesMode}>
											Deposit
										</Button>
										<Button type={EButtonType.SECONDARY}
												disabled={!painting || painting && painting.depositedPuzzlesIds.length == 0}
												onClick={onRemovePuzzlesMode}>
											Withdraw
										</Button>
									</div>
									<Toggle
										label="Missed puzzles"
										name="missed_puzzles"
										onChange={onShowMissedPuzzles}
										checked={showMissedPuzzles}
									/>
								</div>
							</div>

							<span className={Styles.divider}/>
							<div className={Styles.block}>
								<P3 className={Styles.headline}>Painting state:</P3>
								<div className={Styles.buttons}>
									<ExportButton painting_id={paintingConf.key}/>
									<ImportButton c={paintingConf} onDone={onDoneImport}/>
								</div>
							</div>
						</div>
						<span className={Styles.divider}/>
						<Button path={getPathById(PagePath.EXPO, paintingConf.value.expo)}>Exit</Button>
					</div>
				)
			}
		</div>
	);
};

export default Menu;
