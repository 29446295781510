import { EosActionBase } from "../EosActionBase";
import { app, eos, model, world } from "../../App";
import { AtomicAssetModel } from "../../models/AtomicAssetModel";

export class BurnPuzzlesAction extends EosActionBase {
	constructor(private painting_id: number, private puzzles: AtomicAssetModel[])
	{
		super("draw");
	}

	async onExecute(): Promise<void>
	{

		let actions:any[] = this.puzzles.map((it) => {
			return {
				account: app.chainConf.AA_ACCOUNT,
				name: "burnasset",
				authorization: [eos.userAuth],
				data: {
					asset_id: it.assetId,
					asset_owner: eos.user
				}
			}
		})

		if (!model.paintings.get(this.painting_id))
		{
			actions.unshift({
				account: app.chainConf.EXPO_ACCOUNT,
				name: "start",
				authorization: [eos.userAuth],
				data: {
					owner: model.userName,
					identifier: this.painting_id
				}
			})
		}

		await eos._eosApi.transact(actions)
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateUserPainting(model.userName, this.puzzles[0].getImmutableProp("painting_id") as number);
		model.atomicAssets.removeItems(this.puzzles.map(it => it.assetId));
	}
}