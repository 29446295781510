import {EosApi, EosUtils, IEosAction, IEosAuth, IEosTransactResult, ITransactOptions} from "@pro/common/eos";
import {
    IPool, IPoolAsset, IPoolPeriod, IPoolUser, IStakedAsset, IUserPool, IUserUnstake,
    TActionName,
    TActionParam
} from "@pro/common/contracts/staking/st_types";

export class STContract
{
    private readonly _api: EosApi;
    private readonly _account: string;

    constructor(api: EosApi, account: string) {
        this._api = api;
        this._account = account;
    }

    action<N extends TActionName>(
        name: N,
        auth: IEosAuth | IEosAuth[],
        data: TActionParam<N>,
        options?: Partial<ITransactOptions>,
    ): Promise<IEosTransactResult | null> {
        return this._api.transact([this.makeAction(name, auth, data)], options);
    }

    actions<N extends TActionName>(actions: { name: N, data: TActionParam<N> }[],
                                   auth: IEosAuth | IEosAuth[]): Promise<IEosTransactResult | null> {
        return this._api.transact(
            actions.map((d) => this.makeAction(d.name, auth, d.data))
        );
    }

    makeAction<N extends TActionName>(name: N,
                                      auth: IEosAuth | IEosAuth[],
                                      data: TActionParam<N>): IEosAction {
        return {
            name: name,
            account: this._account,
            authorization: EosUtils.castAuth(auth),
            data: data,
        };
    }

    async getAllPools() {
        return this._api.getFullTable<IPool>({
            code: this._account,
            scope: this._account,
            table: "pools",
            key_type: "i64",
            scope_type: "name"
        });
    }

    async getPoolsByOwner(owner: string):Promise<IPool[]> {
        let res = await this._api.getTable<IPool>({
            code: this._account,
            scope: this._account,
            lower_bound: owner,
            upper_bound: owner,
            table: "pools",
            key_type: "name",
            index_position: "2",
            limit: 100,
            reverse: true
        });
        return res.rows;
    }

    async findPool(pool_id: number) {
        let r = this._api.findRecord<IPool>(pool_id, {
            code: this._account,
            scope: this._account,
            table: "pools",
            key_type: "i64",
        });

        return r || null;
    }

    async getPool(pool_id: number) {
        return this._api.getRecord<IPool>(pool_id, {
            code: this._account,
            scope: this._account,
            table: "pools",
            key_type: "i64",
        });
    }

    async getAllPoolPeriods(pool_id: number) {
        return this._api.getFullTable<IPoolPeriod>({
            code: this._account,
            scope: pool_id.toString(),
            table: "poolperiods",
            key_type: "i64",
            scope_type: "i64"
        });
    }

    async getAllPoolAssets(pool_id: number) {
        return this._api.getFullTable<IPoolAsset>({
            code: this._account,
            scope: pool_id.toString(),
            table: "poolassets",
            key_type: "i64",
            scope_type: "i64"
        });
    }

    async getAllUserUnstake(owner: string) {
        return this._api.getFullTable<IUserUnstake>({
            code: this._account,
            scope: owner.toString(),
            table: "userunstake",
            key_type: "i64",
        });
    }

    async getSelectedPoolPeriods(pool_id: number, lower: number, upper: number | undefined) {
        return this._api.getFullTable<IPoolPeriod>({
            code: this._account,
            scope: pool_id.toString(),
            lower_bound: lower,
            upper_bound: upper,
            table: "poolperiods",
            key_type: "i64",
            scope_type: "i64"
        });
    }

    async getPoolPeriod(pool_id: number, period: number) {
        return this._api.getRecord<IPoolPeriod>(period, {
            code: this._account,
            scope: pool_id.toString(),
            table: "poolperiods",
            key_type: "i64",
            scope_type: "i64"
        });
    }

    async getAllPoolUsers(pool_id: number) {
        return this._api.getFullTable<IPoolUser>({
            code: this._account,
            scope: pool_id.toString(),
            table: "poolusers",
            key_type: "i64",
            scope_type: "i64"
        });
    }

    async findUserPool(owner:string, pool_id: number) {
        let r = this._api.findRecord<IUserPool>(pool_id, {
            code: this._account,
            scope: owner,
            table: "userpools",
            key_type: "i64",
        });

        return r || null;
    }

    async getUserPool(owner:string, pool_id: number) {
        return this._api.getRecord<IUserPool>(pool_id, {
            code: this._account,
            scope: owner,
            table: "userpools",
            key_type: "i64",
        });
    }

    async getAllUserPools(owner:string) {
        return this._api.getFullTable<IUserPool>({
            code: this._account,
            scope: owner.toString(),
            table: "userpools",
            key_type: "i64",
        });
    }

    async getAllStakedAssets(owner:string) {
        return this._api.getFullTable<IStakedAsset>({
            code: this._account,
            scope: owner.toString(),
            table: "stakedassets",
            key_type: "i64",
        });
    }

    get account() {
        return this._account;
    }

    get active(): IEosAuth {
        return {actor: this._account, permission: "active"};
    }
}