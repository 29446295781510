import React, { useState } from "react";
import Button from "../../../shared/Button";
import { L } from "../../../../l10n/L10n";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import TokenSelectorView from "../../shared/TokenSelectorView";

const AcceptToken = observer(() => {
	const contract_key = "accept_contract";
	const symbol_key = "accept_symbol";

	const [searchParams, setSearchParams] = useSearchParams();
	const [contract, setContract] = useState(searchParams.get(contract_key) || "");
	const [symbol, setSymbol] = useState(searchParams.get(symbol_key) || "");
	const [tokenAccepted, setTokenAccepted] = useState(!!(contract || symbol));

	const nextStep = () => {
		searchParams.set("step", (parseInt(searchParams.get("step") || "0") + 1).toString());
		setSearchParams(searchParams);
	}

	if (!tokenAccepted)
		return <div>
			<h1>{L.admin.pools.manage_steps.accept_token.header}</h1>
			<Button text={"Yes"} onClick={() => setTokenAccepted(true)}/>
			<Button text={"No"} onClick={() => nextStep()}/>
		</div>

	return <div>
		<h1>Select token that will be accepted for staking</h1>
		<hr/>
		<TokenSelectorView acc={contract} sym={symbol}
						   selected={(contract, token_symbol) => {
							   setContract(contract);
							   setSymbol(token_symbol);
						   }}/>

		{contract && symbol &&
            <Button text={"continue"} onClick={() => {
				searchParams.set(contract_key, contract);
				searchParams.set(symbol_key, symbol);
				nextStep();
			}}/>}
	</div>
});

export default AcceptToken;