import React from 'react';

const CloseIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M2.23126 2.23122C2.53955 1.92293 3.0394 1.92293 3.34769 2.23122L21.7688 20.6523C22.0771 20.9606 22.0771 21.4605 21.7688 21.7688C21.4605 22.0771 20.9606 22.0771 20.6524 21.7688L2.23126 3.34765C1.92297 3.03936 1.92297 2.53951 2.23126 2.23122Z"
			fill={color}/>
		<path
			d="M21.7687 2.23122C21.4604 1.92293 20.9606 1.92293 20.6523 2.23122L2.23122 20.6523C1.92293 20.9606 1.92293 21.4605 2.23122 21.7688C2.53951 22.0771 3.03936 22.0771 3.34765 21.7688L21.7687 3.34765C22.077 3.03936 22.077 2.53951 21.7687 2.23122Z"
			fill={color}/>
	</svg>
);

export default CloseIcon;

