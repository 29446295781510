import { TUint64 } from "@pro/common/contracts/common_types";

export function makeIpfsLink(cid: string)
{
	return "https://ipfs.wecan.dev/ipfs/" + cid;
}

export function makeLinkToAssetMedia(assetImg: string)
{
	if (assetImg.replace(" ", "").startsWith("https://"))
		return assetImg;

	return makeIpfsLink(assetImg.replace(" ", ""));
}

export function makePaintingSrc(paintingId: TUint64)
{
	return `/paintings/${paintingId}/sample.jpg`
}

export async function generateImage(img: HTMLImageElement, scale: number): Promise<HTMLImageElement>
{
	let newCanvas = document.createElement('canvas');

	const ww = Math.floor(img.width * scale);
	const hh = Math.floor(img.height * scale);
	newCanvas.width = ww;
	newCanvas.height = hh;

	let newContext = newCanvas.getContext('2d');
	newContext!.drawImage(img, 0, 0, ww, hh);
	return await loadImage(newCanvas.toDataURL())
}

export async function loadImage(src: any): Promise<HTMLImageElement>
{
	return new Promise((resolve, reject) => {
		let img = new Image()
		img.crossOrigin = 'Anonymous';
		img.onload = () => resolve(img)
		img.onerror = reject
		img.src = src
	})
}