export class Paginator {
	constructor(private _rows: any[], private _showPerPage: number, readonly currentPageNum: number)
	{
	}


	get rows(): any[]
	{
		if (this._showPerPage === 0)
			return this._rows;

		let start = this.currentPageNum * this._showPerPage;
		return this._rows.slice(start, start + this._showPerPage);
	}

	get nextPageNum(): number
	{
		return Math.min(this.currentPageNum + 1, this.getMaxPageNum);
	}

	get prevPageNum(): number
	{
		return Math.max(this.currentPageNum - 1, 0);
	}

	get getMaxPageNum(): number
	{
		if (this.showPerPage === 0)
			return 0;
		return Math.ceil(this._rows.length / this._showPerPage) - 1;
	}

	get showPerPage(): number
	{
		return this._showPerPage;
	}

	setRows(rows: any[])
	{
		this._rows = rows;
	}

	setPerPage(_val: number)
	{
		this._showPerPage = _val;
	}
}