import React from 'react';

const TelegramIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
		<path
			d="M26.9464 4.65033C26.9464 4.65033 29.5366 3.64033 29.3207 6.09319C29.2488 7.1032 28.6012 10.6382 28.0976 14.4618L26.3708 25.7883C26.3708 25.7883 26.2269 27.4475 24.9318 27.7361C23.6366 28.0247 21.694 26.7261 21.3342 26.4375C21.0464 26.2211 15.938 22.9747 14.1392 21.3875C13.6356 20.9547 13.06 20.0889 14.2112 19.0789L21.766 11.8647C22.6294 10.9989 23.4928 8.97894 19.8952 11.4318L9.82223 18.2854C9.82223 18.2854 8.67103 19.0068 6.51256 18.3575L1.83578 16.9147C1.83578 16.9147 0.10897 15.8325 3.05893 14.7503C10.254 11.3596 19.1038 7.89674 26.9464 4.65033Z"
			fill={color}/>
	</svg>
);

export default TelegramIcon;
