import { EosActionBase } from "../../EosActionBase";
import { STAction } from "@pro/common/contracts/staking";
import { eos, world } from "../../../App";

export class RegPoolAction extends EosActionBase {
	constructor(private params: STAction["regpool"])
	{
		super("regpool");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("regpool", eos.userAuth, this.params);
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateAllPools();
	}
}