import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import imgProfit from "../../../images/img_profit.png";
import Styles from "./Profit.module.scss"
import { L } from "../../../l10n/L10n";

const Profit = () => (
	<Container className={Styles.container} id="#benefits" hasBackground>
		<div>
			<Heading
				title={L.landing.sections.profit.title}
				subTitle={L.landing.sections.profit.subtitle}
				align="left"
				text={<>{L.landing.sections.profit.text} <mark className={Styles.highlight}>{L.landing.sections.profit.highlight}</mark></>}
			/>
		</div>
		<div>
			<img src={imgProfit} alt="profit"/>
		</div>
	</Container>
);

export default Profit;
