import { EosActionBase } from "../EosActionBase";
import { app, eos, model, world } from "../../App";

export class BuyAttemptAction extends EosActionBase {
	constructor(private painting_id: number)
	{
		super("claimlottery");
	}

	async onExecute(): Promise<void>
	{
		let conf = (model.config?.paintings_config || []).find(it=>it.key === this.painting_id);
		if (!conf)
		    return;

		await world.updateConfig();
		let transferAction = {
			account: app.chainConf.SYS_ACCOUNT,
			name: "transfer",
			authorization: [eos.userAuth],
			data: {
				from: model.userName,
				to: eos.expoContract.account,
				quantity: model.lotteryAttemptPrice.toString(),
				memo: "fee",
			}
		};

		let buy_action = eos.expoContract.makeAction("buyattempts", eos.userAuth, {
		    owner: model.userName,
		    amount: 1,
		});

		await eos._eosApi.transact([transferAction, buy_action].flat())
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateUserLottery(model.userName);
		await world.mustUpdateBalance(app.chainConf.SYS_ACCOUNT, app.chainConf.SYS_SYMBOL);
		await world.updateConfig();
	}
}