import React from "react";
import ExpandIcon from "../../../icons/ExpandIcon";
import Styles from "./Toolbar.module.scss"
import classNames from "classnames";

const Toolbar = ({children, className, ...htmlProps}: React.HTMLAttributes<HTMLElement>) => {

	const [isOpen, setIsOpen] = React.useState(true);

	const toolbarHandler = React.useCallback(() => setIsOpen(prevState => !prevState), []);

	return (
		<nav className={classNames(Styles.container, className && className)} {...htmlProps}>
			{isOpen && children}
			<button className={classNames(Styles.button, Styles.handler, isOpen && Styles.opened)}
					onClick={toolbarHandler}>
				<ExpandIcon/></button>
		</nav>
	)
}

export default Toolbar;