import React from 'react';
import Styles from "./MenuDropdown.module.scss"
import useWindowSize from "../../hooks/useWindowSize";
import ChevronUpIcon from "../../icons/ChevronUp";
import P2 from "../typography/P2";
import Button from "../../pages/landing/components/Button";
import UserIcon from "../../icons/UserIcon";
import ChevronDownIcon from "../../icons/ChevronDown";

interface MenuDropdownProps {
	title: string,
	children: React.ReactNode,
}

const MenuDropdown = ({title, children}: MenuDropdownProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const {windowWidth} = useWindowSize();
	const dropdownHandler = (value: boolean) => () => setIsOpen(value)

	return (
		<div className={Styles.menu} onMouseOver={dropdownHandler(true)}
			 onMouseLeave={dropdownHandler(false)}>
			{
				windowWidth > 767 ? (
					<button
						className={Styles.header}
					>
						<P2 isBold>{title}</P2>
							{
								isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>
							}
					</button>
				) : (
					<Button
						icon={<UserIcon/>}
						onClick={() => setIsOpen(prevState => !prevState)}
					/>
				)
			}
			<div className={Styles.content}>
				{isOpen && <div className={Styles.inner}>{children}</div>}
			</div>
		</div>
	)
};

export default MenuDropdown;

