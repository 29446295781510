import React from 'react';

const NavigationIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M0.888672 3.52721C0.888672 3.05212 1.27527 2.66699 1.75216 2.66699H30.2474C30.7243 2.66699 31.1109 3.05212 31.1109 3.52721C31.1109 4.00229 30.7243 4.38742 30.2474 4.38742H1.75216C1.27527 4.38742 0.888672 4.00229 0.888672 3.52721Z"
			fill={color}/>
		<path
			d="M0.888672 15.5702C0.888672 15.0951 1.27527 14.71 1.75216 14.71H30.2474C30.7243 14.71 31.1109 15.0951 31.1109 15.5702C31.1109 16.0453 30.7243 16.4304 30.2474 16.4304H1.75216C1.27527 16.4304 0.888672 16.0453 0.888672 15.5702Z"
			fill={color}/>
		<path
			d="M0.888672 28.4734C0.888672 27.9984 1.27527 27.6132 1.75216 27.6132H30.2474C30.7243 27.6132 31.1109 27.9984 31.1109 28.4734C31.1109 28.9485 30.7243 29.3337 30.2474 29.3337H1.75216C1.27527 29.3337 0.888672 28.9485 0.888672 28.4734Z"
			fill={color}/>
	</svg>
);

export default NavigationIcon;
