import React from 'react';

const Triangle = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
		<path
			d="M28.6567 20.6667L16.8491 1.16671C15.9745 -0.277736 13.7879 -0.277738 12.9133 1.16671L1.10568 20.6667C0.231047 22.1112 1.32434 23.9167 3.07361 23.9167H26.6888C28.438 23.9167 29.5313 22.1112 28.6567 20.6667Z"
			fill={color}/>
	</svg>
);

export default Triangle;
