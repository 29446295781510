import { ASchema } from "@pro/common/contracts/atomicassets";

export class AtomicSchemaTable {
	_items = new Map<string, Map<string, ASchema>>();

	getItem(collection: string, schemaName: string)
	{
		return this._items.get(collection)?.get(schemaName);
	}

	updateItem(collection: string, record: ASchema)
	{
		const item = this._items.get(collection)?.get(record.schema_name);
		if (!item) {
			let items = this._items.get(collection);
			if (!items)
				this._items.set(collection, new Map<string, ASchema>([[record.schema_name, record]]));
			else
				items.set(record.schema_name, record);
		}
	}


	query(collection_name: string, predicate: (it: ASchema) => boolean): ASchema[]
	{
		let result = [];
		for (let item of this._items.get(collection_name)!.values() || []) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(collection_name: string)
	{
		return this.query(collection_name, () => true);
	}

}