import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { app, eos, model, world } from "../../App";
import Styles from "./PoolPage.module.scss";
import { observer } from "mobx-react";
import { formatDaysLeft, formatTimeLeft } from "../../helpers/formatters";
import { EosAsset } from "@pro/common/eos";
import PropertyView from "../shared/PropertyView";
import Button from "../shared/Button";
import { RefundAction } from "../../eos/actions/staking/RefundAction";
import { RestakeAction } from "../../eos/actions/staking/RestakeAction";
import { RemoveUserPoolAction } from "../../eos/actions/staking/RemoveUserPoolAction";
import { OpenUserPoolAction } from "../../eos/actions/staking/OpenUserPoolAction";
import { CollectAction } from "../../eos/actions/staking/CollectAction";
import { WithdrawAction } from "../../eos/actions/staking/WithdrawAction";
import { UnstakeAction } from "../../eos/actions/staking/UnstakeAction";
import { StakeAction } from "../../eos/actions/staking/StakeAction";
import { getPathById, PagePath } from "../../helpers/PageHelper";
import { PoolPeriodModel } from "../../models/PoolPeriodModel";

const PoolPage = observer(() => {
	const params = useParams();
	const navigate = useNavigate();
	const pool = model.pools.items.get(parseInt(params["id"] || "-1"));
	const userPool = model.userPools.get(model.userName, parseInt(params["id"] || "-1"));
	const userUnstake = (model.logined && pool) ? model.userUnstakes.query(it => it.pool_id === pool.poolId) : [];

	const [periodTimeLeft, setPeriodTimeLeft] = useState(pool?.currentPeriodLeftSec || 0)
	const [poolPeriods, setPoolPeriods] = useState<PoolPeriodModel[]>([]);
	const [stakeAmount, setStakeAmount] = useState(0);
	const [unstakeAmount, setUnstakeAmount] = useState(0);
	const [currentPeriod, setCurrentPeriod] = useState(pool?.currentPeriod || 0);

	let updatePeriods = async () => {
		if (!userPool || !pool || userPool.totalStaked === 0)
			return;

		let merged = poolPeriods.filter(it => it.merged);
		const lastNum = merged.length ?
			merged.sort((a, b) => b.num - a.num)[0].num : 0;

		let lower = Math.max(lastNum, userPool.next_claim_num);
		await world.updatePoolPeriods(userPool.pool_id, lower, pool.currentPeriod);
		setPoolPeriods(model.poolPeriods.queryAll(userPool.pool_id));
	}

	const load = async () => {
		if (pool) {
			if (pool.acceptContract !== "")
				world.updateBalance(pool.acceptContract, pool.acceptSymbol).catch();
			world.updateBalance(pool.rewardContract, pool.rewardSymbol).catch();
		}
	}

	useEffect(() => {
		if (pool) {
			load().catch()
		}
	}, [pool])

	React.useEffect(() => {
		updatePeriods().catch();
	}, [currentPeriod]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentPeriod(pool?.currentPeriod || 0);
			setPeriodTimeLeft(pool?.currentPeriodLeftSec || 0);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [pool])

	if (!pool)
		return <>Pool is not found</>

	const acceptedTemplates = model.getAcceptedTemplates(model.poolAssets.queryAll(pool.poolId));
	const myAssets = acceptedTemplates.length === 0 ? [] : model.atomicAssets.queryAll().filter(it => acceptedTemplates.map(it => it.t.template_id).includes(it.asset.template_id));

	if (eos.user && userPool === null)
		return <div>Loading...</div>

	const collectedAsset = new EosAsset(pool && userPool ? userPool.collected : 0, pool.rewardSymbol);
	const pendingAsset = new EosAsset(pool && userPool ? pool.getUserCollected(userPool, poolPeriods) : 0, pool.rewardSymbol);
	const paidAsset = new EosAsset(pool && userPool ? userPool.total_claimed : 0, pool.rewardSymbol);
	const acceptBalanceAsset = new EosAsset(Math.max(model.getBalance(pool.acceptSymbol).amount, 0), pool.acceptSymbol);
	const rewardBalanceAsset = new EosAsset(Math.max(model.getBalance(pool.rewardSymbol).amount, 0), pool.rewardSymbol);
	const stakedBalanceAsset = new EosAsset(pool && userPool ? userPool.staked_tokens : 0, pool.acceptSymbol);
	const stakedNFTBalanceAsset = new EosAsset(pool && userPool ? userPool.staked_assets_power : 0, pool.acceptSymbol);

	const amountForEstimate = new EosAsset(1000 * 10 ** pool.acceptSymbol.precision, pool.acceptSymbol);

	return <div className={Styles.staking_container}>
			<div className={Styles.side}>
				<div className={Styles.properties_block}>
					<fieldset>
						<legend>Pool properties</legend>
						{!pool.started && <PropertyView name={"Start at"}
                                                        value={pool.timePerPeriodSecs > 86400 ? formatDaysLeft(pool.timeToStart) : formatTimeLeft(pool.timeToStart)}/>}
						{pool.acceptContract !== "" && <PropertyView name={`Total staked ${pool.acceptSymbol.code}`}
                                                                     value={pool.tokensStaked.toString()}/>}
						{pool.acceptAssets.length > 0 &&
                            <PropertyView name={"Total staked NFT power"} value={pool.assetsPowerStaked.toString()}/>}
						<PropertyView name={"Total users"} value={pool.totalUsers.toString()}/>
						<PropertyView name={"Total staked assets"} value={pool.totalAssets.toString()}/>
						{pool.started &&
                            <PropertyView name={"Period"} value={`${currentPeriod + 1} / ${pool.periodsCount}`}/>}
						<PropertyView name={"Reward per period"} value={pool.rewardAsset.toString()}/>
						<PropertyView name={"Total paid"} value={pool.totalPaid.toString()}/>
						<PropertyView name={"Time to unstake"} value={formatTimeLeft(pool.unstakeSec)}/>
						{pool.started && !pool.ended && <PropertyView name={"Next payout at"}
                                                                      value={periodTimeLeft > 86400 ? formatDaysLeft(periodTimeLeft) : formatTimeLeft(periodTimeLeft)}/>}
						{!pool.ended && <PropertyView
                            name={`Estimate payout per ${amountForEstimate.toString()}`}
                            value={new EosAsset(pool.calcRewardPerPeriod(amountForEstimate.amount, pool.tokensStaked.amount + amountForEstimate.amount), pool.rewardSymbol).toString()}/>}
						<PropertyView name={"Min total stake"}
									  value={pool.minStake.amount === 0 ? "Unlimited" : pool.minStake.toString()}/>
						<PropertyView name={"Max total stake"}
									  value={pool.maxStake.amount === 0 ? "Unlimited" : pool.maxStake.toString()}/>
					</fieldset>
				</div>
				<div className={Styles.properties_block}>
					<fieldset>
						<legend>My properties</legend>
						{pool.acceptContract !== "" && <PropertyView name={`${pool.acceptSymbol.code} balance`}
                                                                     value={acceptBalanceAsset.toString()}/>}
						<PropertyView name={`${pool.rewardSymbol.code} balance`}
									  value={rewardBalanceAsset.toString()}/>
						{pool.acceptContract !== "" && <PropertyView name={`Staked ${pool.acceptSymbol.code}`}
                                                                     value={stakedBalanceAsset.toString()}/>}
						{pool.acceptAssets.length > 0 &&
                            <PropertyView name={"Staked NFT power"} value={stakedNFTBalanceAsset.toString()}/>}
						<PropertyView
							name={"Pending estimate payout"}
							value={new EosAsset(pool.calcRewardPerPeriod(userPool?.totalStaked || 0), pool.rewardSymbol).toString()}/>

						<PropertyView name={"Total claimed"} value={paidAsset.toString()}/>
						<PropertyView name={"Collected"} value={collectedAsset.toString()}/>
						<PropertyView name={"Ready to collect"} value={pendingAsset.toString()}/>

					</fieldset>
				</div>
			</div>
			<div className={Styles.side}>
				{model.userName ? <div className={Styles.properties_block}>
					{
						(userPool && userPool.staked_tokens > 0) && <fieldset>
                            <legend>Unstake</legend>
                            <h4 onClick={() => setUnstakeAmount(parseFloat(stakedBalanceAsset.toString().split(" ")[0]))}>Max
                                amount {stakedBalanceAsset.toString()}</h4>
                            <div className={Styles.input_block}>
                                <input type={"number"} step={1 / 10 ** pool.acceptSymbol.precision}
                                       value={unstakeAmount}
                                       max={userPool.staked_tokens / 10 ** pool.acceptSymbol.precision}
                                       onChange={(e) => setUnstakeAmount(parseFloat(e.currentTarget.value))}/>

                                <Button text={"unstake"} onClick={() => {
									new UnstakeAction(new EosAsset(Math.floor(unstakeAmount * 10 ** pool.acceptSymbol.precision), pool.acceptSymbol), pool)
										.executeAsync()
								}}/>
                            </div>
                        </fieldset>
					}
					{
						userPool && pool.acceptAssets.length > 0 && (!pool.ended || model.stakedAssets.query(model.userName, it => it.poolId === pool.poolId).length > 0) &&
                        <fieldset>
                            <legend>Manage NFT</legend>
							{pool.acceptCollections.map(collection => {
								return <PropertyView name={`Accept templates from collection "${collection}"`}
													 value={pool.acceptAssets.filter(it => it.collection === collection).length}/>
							})}
                            <hr/>
                            <PropertyView name={"Compatible assets"} value={myAssets.length}/>
                            <PropertyView name={"Staked assets"}
                                          value={model.stakedAssets.query(model.userName, it => it.poolId === pool.poolId).length}/>
                            <Button text={"manage"}
                                    onClick={() => navigate(getPathById(PagePath.POOL_STAKE, pool.poolId))}/>
                        </fieldset>
					}
					{
						(userPool && !pool.ended && pool.acceptContract !== "") && <fieldset>
                            <legend>Stake</legend>
							{
								acceptBalanceAsset.amount > 0 ? <>
									<h4 onClick={() => setStakeAmount(parseFloat(acceptBalanceAsset.toString().split(" ")[0]))}>Max
										amount {acceptBalanceAsset.toString()}</h4>
									<div className={Styles.input_block}>
										<input type={"number"} step={1 / 10 ** pool.acceptSymbol.precision}
											   value={stakeAmount}
											   max={acceptBalanceAsset.amount / 10 ** pool.acceptSymbol.precision}
											   onChange={(e) => setStakeAmount(parseFloat(e.currentTarget.value))}/>
										<Button text={"stake"} onClick={() => {
											new StakeAction(pool.acceptContract, new EosAsset(Math.floor(stakeAmount * 10 ** pool.acceptSymbol.precision), pool.acceptSymbol), pool).onSuccess(async () => {
												// setUserPool(await world.loadUserStakingPool(eos.user, pool.poolId));
												await world.updateUserPool(eos.user, pool.poolId);

												setPoolPeriods([]);
											}).executeAsync()
										}}/>
									</div>
								</> : <>
									<h3>Insufficient balance</h3>
									<a href={"https://alcor.exchange/trade/art-chainexpoatok_wax-eosio.token"}
									   target={'_blank'}>
										Buy ART on Alcor exchange</a>
								</>
							}
                        </fieldset>
					}
					{
						(userUnstake.length > 0) && <fieldset>
                            <legend>Refunds</legend>
							{userUnstake.map((it) => {
								const timeLeft = Math.max(it.ready_at - app.now_second, 0);
								return <div className={Styles.restake_block}>

									<div>
										{it.quantity.amount > 0 && <h3>{it.quantity.toString()}</h3>}
										{it.asset_ids.length > 0 && <h3>{JSON.stringify(it.asset_ids)}</h3>}
										<h4>Wait {formatTimeLeft(timeLeft)}</h4>
									</div>
									{(userPool && !pool.ended) && <Button text={"restake"} onClick={() => {
										new RestakeAction(pool, it).executeAsync()
									}}/>}
									<Button text={"refund"} disabled={timeLeft > 0} onClick={() => {
										new RefundAction(it).executeAsync()
									}}/>
								</div>
							})}

                        </fieldset>
					}
					{
						(userPool && collectedAsset.amount === 0 && pendingAsset.amount === 0 && stakedBalanceAsset.amount === 0 && stakedNFTBalanceAsset.amount === 0) &&
                        <fieldset>
                            <legend>Release ram</legend>
                            <h3>To release RAM close your staking account</h3>
                            <hr/>
                            <Button text={"close"} onClick={() => {
								new RemoveUserPoolAction(pool.poolId).executeAsync()
							}}/>
                        </fieldset>
					}
					{
						((!userPool && !pool.ended) || collectedAsset.amount > 0 || pendingAsset.amount > 0 || (userPool && pool.ended)) &&
                        <fieldset className={Styles.actions_block}>
                            <legend>Actions</legend>
							{
								!userPool && !pool.ended && <p>
                                    <h5>You have to open account in this pool before start use it</h5>
                                    <hr/>
                                    <Button text={"open"} onClick={() => {
										new OpenUserPoolAction(pool.poolId).executeAsync()
									}}/></p>
							}
							{
								pendingAsset.amount > 0 && <Button text={"collect"} onClick={() => {
									new CollectAction(pool).onSuccess(async () => {
										await updatePeriods();
									}).executeAsync()
								}}/>
							}
							{
								collectedAsset.amount > 0 && <Button text={"withdraw"} onClick={() => {
									new WithdrawAction(pool).onSuccess(async () => {
										await updatePeriods();
									}).executeAsync()
								}}/>
							}

                        </fieldset>
					}
					{pool.ended && <fieldset>
                        <legend>Pool status</legend>
                        <h2>Staking is ended</h2></fieldset>}
				</div> : <fieldset>
					<legend>Unauthorized</legend>
					<h3>{"Press start->login to authorize"}</h3>
				</fieldset>
				}
			</div>
		</div>
});

export default PoolPage;