import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { app, model, notificationService, world } from "../../App";
import Styles from "./ExpositionPage.module.scss";
// import { StartPaintingAction } from "../../eos/actions/StartPaintingAction";
import { getPathById, makePuzzlesPath, PagePath } from "../../helpers/PageHelper";
import { observer } from "mobx-react";
import TopNavigation from "../../components/shared/TopNavigation";
import P2 from "../../components/typography/P2";
import Button, { EButtonType } from "../landing/components/Button";
import P1 from "../../components/typography/P1";
import P3 from "../../components/typography/P3";
import PuzzlesIcon from "../../icons/PuzzlesIcon";
import Tooltip from "../../components/shared/Tooltip";
import PageContainer from "../../components/shared/PageContainer";
import { L } from "../../l10n/L10n";
import AddPuzzlesView from "../../components/puzzles/AddPuzzlesView";
import { makePaintingSrc } from "../../helpers/image_helper";
import Loading from "../../components/shared/Loading";

const ExpoPage = observer(() => {
	const navigate = useNavigate();
	const {id} = useParams()
	const [addPuzzlesMode, setAddPuzzlesMode] = useState<number | undefined>(undefined)
	const paintings = model.config?.paintings_config.filter(it => it.value.expo === parseInt(id || "-1")) || [];

	useEffect(() => {
		updateData().catch()
	}, [paintings])

	async function updateData()
	{
		if (model.hPaintings.needUpdate() && paintings.length > 0) {
			model.hPaintings.updateAtNow()
			const paintings_ids = paintings.map(it => it.key)
			await world.updateHPaintings(paintings_ids).catch(e => {
				notificationService.showError(e?.message ?? e)
			})
		}
	}

	if (!model.config)
		return <Loading/>

	const paintings_pack = app.assetConf.packs.filter(it => it.type === "paintings")

	return (
		<PageContainer>
			<TopNavigation title={L.expos.title.replace('$id$', id ?? "")} backBtnPath={PagePath.EXPOS + `?s=${id}`}/>
			{addPuzzlesMode != undefined &&
            <AddPuzzlesView painting_id={addPuzzlesMode} onClose={() => setAddPuzzlesMode(undefined)}/>
			}
			<div className={Styles.cards}>
				{paintings.map((it, index) => {
					let painting_id = it.key
					let painting = model.paintings.get(painting_id);
					let availablePuzzles = model.atomicAssets.getPaintingNeededFromAvailable(painting?.depositedPuzzlesIds || [], painting_id);
					let neededPuzzlesCount = it.value.rows * it.value.cols - (painting?.depositedPuzzlesIds || []).length;
					let composed = model.hPaintings.composed(painting_id).id
					let max_supply = paintings_pack.find(it => it.painting_id === painting_id)?.max_supply || 0

					return (
						<div className={Styles.painting} key={`${index}_${it.key}`}>
							<div className={Styles.img}>
								<img src={makePaintingSrc(painting_id)} alt={it.value.name}/>
							</div>
							<div className={Styles.description}>
								<div className={Styles.info}>
									<div className={Styles.heading}>
										<P1 isBold>{it.value.name}</P1>
										<P3 isBold={false}>{it.value.author}</P3>
									</div>
									<div className={Styles.details}>
										<div>
											<P2>{L.shared.deposited}</P2>
											<P2 isBold>{(painting?.depositedPuzzlesIds || []).length}</P2>
										</div>
										<div>
											<P2>{L.shared.available_needed}</P2>
											<P2 isBold>{availablePuzzles.length}/{neededPuzzlesCount}</P2>
										</div>
									</div>
									{/*{*/}
									{/*	model.logined && !painting && <Button onClick={() => {*/}
									{/*		new StartPaintingAction(painting_id)*/}
									{/*			.executeAsync()*/}
									{/*	}}>begin</Button>*/}
									{/*}*/}
									<div className={Styles.buttons}>
										{/*{*/}
										{/*	painting && <>*/}
										{/*		{*/}
										{/*			neededPuzzlesCount > 0 && availablePuzzles.length > 0 &&*/}
										{/*<Button type={EButtonType.SECONDARY} onClick={() => {*/}
										{/*	new BurnPuzzlesAction(painting_id, availablePuzzles).executeAsync()*/}
										{/*}}>Deposit Puzzles</Button>*/}
										{/*}*/}
										{/*</>*/}
										{/*}*/}
										{neededPuzzlesCount > 0 && availablePuzzles.length > 0 ?
											<Button className={Styles.deposit} type={EButtonType.SECONDARY}
													onClick={() => setAddPuzzlesMode(painting_id)}>
												{L.button.deposit_puzzles}
											</Button>
											:
											<Tooltip text={L.popOver.buy_puzzle_first}>
												<Button className={Styles.deposit} type={EButtonType.SECONDARY}
														disabled>
													{!model.appDataLoaded ?
														L.shared.loading
														:
														L.button.deposit_puzzles
													}
												</Button>
											</Tooltip>
										}
										<Button
											icon={<PuzzlesIcon/>}
											type={EButtonType.SECONDARY}
											onClick={() => navigate(makePuzzlesPath(it.value.expo, painting_id))}
										/>
									</div>
									<span className={Styles.divider}/>
									<Button onClick={() => navigate(getPathById(PagePath.GALLERY, painting_id))}>
										{L.button.compose}
									</Button>
								</div>
								<div className={Styles.bottom}>
									<div>
										<P3>{L.shared.sample}:</P3>
										<P3 isBold>{`${it.value.cols}x${it.value.rows}`}</P3>
									</div>
									<div>
										<P3>{L.shared.composed}:</P3>
										<P3 isBold>{composed}/{max_supply}</P3>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</PageContainer>
	)
});

export default ExpoPage;
