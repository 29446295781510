import React from 'react';
import { Helmet } from "react-helmet-async";
import { L } from "../../l10n/L10n";

const HeadBase = (
	{
		title = L.shared.siteName,
	}: {
		title?: string,
	}
) => (
	<Helmet prioritizeSeoTags={true}>
		<title>{title}</title>
	</Helmet>
);

export default HeadBase;
