import { observer } from "mobx-react";
import { IPaintingConf } from "@pro/common/contracts/expo";
import { IPuzzlePlayerState } from "../../core/types";
import { useRef } from "react";
import Button, { EButtonType } from "../../pages/landing/components/Button";

const ImportButton = observer(({onDone}: { c: IPaintingConf, onDone: (s: IPuzzlePlayerState) => void }) => {

	const input = useRef<HTMLInputElement>(null);

	const onClick = () => input.current!.click();

	return (
		<>
			<Button type={EButtonType.SECONDARY} onClick={onClick}>Import</Button>
			<input style={{display: "none"}} ref={input} type="file" name="state" accept="text/plain" onChange={(e) => {
				let file = (e.target.files || [])[0];
				if (file) {
					let reader = new FileReader();
					reader.readAsText(file, "UTF-8");
					reader.onload = function (evt) {
						let val = (evt.target?.result || "").toString().replace(/\r?\n|\r/, "");
						onDone(JSON.parse(window.atob(val)))
					}
				}
			}}/>
		</>
	)
});
export default ImportButton;