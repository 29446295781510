import { makeObservable } from "mobx";
import { IPainting } from "@pro/common/contracts/expo";
import { get64Binary } from "../helpers/formatters";

export class PaintingModel {
	constructor(
		public readonly record: IPainting,
	)
	{
		makeObservable(this, {});
	}

	get id(): number
	{
		return this.record.id;
	}

	get depositedPuzzlesIds(): number[]
	{
		let res: number[] = [];
		for (let r = 0; r < this.record.puzzles.length; r++) {
			let b = get64Binary(this.record.puzzles[r]);
			for (let i = 0; i < 64; i++) {
				if (b[63 - i] === "1")
					res.push(r * 64 + i);
			}
		}
		return res;
	}

}