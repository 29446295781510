import React from 'react';

const MagnetIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M21 21.8889C21 21.3366 20.5523 20.8889 20 20.8889H14.2143C11.4706 20.8889 9.2381 18.6956 9.2381 16C9.2381 13.3044 11.4706 11.1111 14.2143 11.1111H20C20.5523 11.1111 21 10.6634 21 10.1111V5C21 4.44771 20.5523 4 20 4H14.2143C7.47924 4 2 9.38311 2 16C2 22.6169 7.47924 28 14.2143 28H20C20.5523 28 21 27.5523 21 27V21.8889Z"
			fill={color}/>
		<path
			d="M28 21H23C22.4477 21 22 21.4477 22 22V27C22 27.5523 22.4477 28 23 28H28C28.5523 28 29 27.5523 29 27V22C29 21.4477 28.5523 21 28 21Z"
			fill={color}/>
		<path
			d="M28 4H23C22.4477 4 22 4.44772 22 5V10C22 10.5523 22.4477 11 23 11H28C28.5523 11 29 10.5523 29 10V5C29 4.44772 28.5523 4 28 4Z"
			fill={color}/>
	</svg>
);

export default MagnetIcon;
