import React from 'react';
import Styles from "./Toggle.module.scss"
import P1 from "../typography/P1";

interface ToggleProps extends React.HTMLAttributes<HTMLInputElement> {
	name: string,
	label?: string,
	checked?: boolean
}

const Toggle = ({
					name,
					checked,
					label,
					...htmlProps
				}: ToggleProps) => {

	return (
		<div className={Styles.container}>
			<input type="checkbox" id={name} checked={checked} {...htmlProps}/>
			<span className={Styles.inner}>
				<span className={Styles.circle}></span>
			</span>
			{
				label && <label htmlFor={name}><P1>{label}</P1></label>
			}
		</div>
	);
};

export default Toggle;
