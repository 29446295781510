import { CanvasPuzzlePlayer } from "./CanvasPuzzlePlayer";

export class PuzzlePlayerController {
	constructor(private _canvas: HTMLCanvasElement, private player: CanvasPuzzlePlayer)
	{
		_canvas.addEventListener("touchstart", this.handleTouchStart, {passive: false});
		_canvas.addEventListener("mousedown", this.handleMouseDown);
		_canvas.addEventListener("touchend", this.handleTouchEnd, {passive: false});
		_canvas.addEventListener("mouseup", this.handleMouseUp);
		_canvas.addEventListener("touchmove", this.handleTouchMove, {passive: false});
		_canvas.addEventListener("mousemove", this.handleMouseMove);
		window.addEventListener("keydown", this.handleKeyDown);
		window.addEventListener("keyup", this.handleKeyUp);
	}

	unsubscribe()
	{
		this._canvas.removeEventListener("touchstart", this.handleTouchStart);
		this._canvas.removeEventListener("mousedown", this.handleMouseDown);
		this._canvas.removeEventListener("touchend", this.handleTouchEnd);
		this._canvas.removeEventListener("mouseup", this.handleMouseUp);
		this._canvas.removeEventListener("touchmove", this.handleTouchMove);
		this._canvas.removeEventListener("mousemove", this.handleMouseMove);
		window.removeEventListener("keydown", this.handleKeyDown);
		window.removeEventListener("keyup", this.handleKeyUp);
	}

	private stopPropagation = (e: MouseEvent | TouchEvent | KeyboardEvent) => {
		e.preventDefault();
		return false;
	}

	private handleTouchStart = (e: TouchEvent) => {
		this.player.touchStart(e as unknown as TouchEvent)
		return this.stopPropagation(e);
	};

	private handleMouseDown = (e: MouseEvent) => {
		this.player.mouseDown(e as unknown as MouseEvent)
		return this.stopPropagation(e);
	};

	private handleMouseUp = (e: MouseEvent) => {
		this.player.mouseUp();
		return this.stopPropagation(e);
	};
	private handleTouchEnd = (e: TouchEvent) => {
		this.player.touchEnd(e as unknown as TouchEvent);
		return this.stopPropagation(e);
	};

	private handleMouseMove = (e: MouseEvent) => {
		this.player.mouseMove(e as unknown as MouseEvent)
		return this.stopPropagation(e);
	};
	private handleTouchMove = (e: TouchEvent) => {
		this.player.touchMove(e as unknown as TouchEvent)
		return this.stopPropagation(e);
	};
	private handleKeyDown = (e: KeyboardEvent) => {
		switch (e.code)
		{
			case "Space":
			{
				this.player.moving = true;
				break;
			}
			case "KeyZ":
			{
				if (e.ctrlKey)
					this.player.moveState(e.shiftKey ? 1 : -1);
				break;
			}
		}
		return this.stopPropagation(e);
	};

	private handleKeyUp = (e: KeyboardEvent) => {
		switch (e.code)
		{
			case "Space":
			{
				this.player.moving = false;
				break;
			}
		}

		return this.stopPropagation(e);
	};

}