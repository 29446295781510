import React from 'react';

const MinusIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M0.666656 11.9785C0.666656 11.6222 0.956605 11.3333 1.31428 11.3333H22.6857C23.0434 11.3333 23.3333 11.6222 23.3333 11.9785C23.3333 12.3348 23.0434 12.6237 22.6857 12.6237H1.31428C0.956605 12.6237 0.666656 12.3348 0.666656 11.9785Z"
			fill={color}/>
	</svg>
);

export default MinusIcon;
