import { JsonRpc } from "eosjs/dist";
import { TFetch } from "./fetch_types";

class EosNode
{
	readonly rpc: JsonRpc;
	readonly url: string;

	rate: number = 0;

	constructor(url: string, fetch: TFetch)
	{
		this.url = url;
		this.rpc = new JsonRpc(url, {fetch});
		this.reset();
	}

	reset()
	{
		this.rate = 0;
	}
}

export class EosNodeBalancer
{
	private _nodes: EosNode[] = [];
	private readonly _fetch: TFetch;
	private _selectedNode?: EosNode;

	constructor(urls: string[], fetch: TFetch)
	{
		this._fetch = fetch;
		this.setUrls(urls);
	}

	setUrls(urls: string[])
	{
		this._nodes = urls.map(it => new EosNode(it, this._fetch));
	}

	getRpc(): JsonRpc
	{
		if (this._nodes.length === 0)
			throw new Error("Empty nodes list");


		return (this._selectedNode || this._nodes[0]).rpc;
	}

	selectNode(url: string)
	{
		for (let n of this.nodes)
			if (n.url === url)
			{
				this._selectedNode = n;
				break;
			}
	}

	get nodes(): readonly EosNode[]
	{
		return this._nodes;
	}

	reset()
	{
		this._nodes.forEach(it => it.reset());
	}
}
