export class EosSymbol {
	static readonly empty = new EosSymbol("", 0);

	static parse(value: string): EosSymbol
	{
		let parts = value.split(",");
		if (parts.length !== 2)
			throw new Error("Invalid EosSymbol string: comma");

		let precision = Number(parts[0]);

		if (isNaN(precision))
			throw new Error("Invalid EosSymbol string: precision");

		let code = parts[1];

		return new EosSymbol(code, precision);
	}

	constructor(readonly code: string,
				readonly precision: number)
	{
	}

	equals(other: EosSymbol)
	{
		return this.code === other.code &&
			this.precision === other.precision;
	}

	formatNumber(amount: number): string
	{
		let divider = 10 ** this.precision;
		return (amount / divider).toFixed(this.precision);
	}

	formatInt(integerPart: number | string): string
	{
		let fraction = this.precision > 0
			? "." + "0".repeat(this.precision)
			: "";

		return `${integerPart}${fraction} ${this.code}`;
	}

	toString()
	{
		return `${this.precision},${this.code}`;
	}
}

export class EosAsset {
	static readonly empty = new EosAsset(0, EosSymbol.empty);

	static parse(value: string): EosAsset
	{
		let space = value.indexOf(" ");
		if (space === -1)
			throw new Error("Invalid EosAsset string: space");

		let strAmount = value.substr(0, space);

		let dot = value.indexOf(".");
		let precision = (dot >= 0)
			? strAmount.length - dot - 1
			: 0;

		let name = value.substr(space + 1);
		let amount = Number(strAmount.replace(".", ""));

		return new EosAsset(amount, new EosSymbol(name, precision));
	}

	constructor(readonly amount: number,
				readonly symbol: EosSymbol)
	{
	}

	equals(other: EosAsset): boolean
	{
		return this.amount === other.amount &&
			this.symbol.equals(other.symbol);
	}

	formatNumberWithPrecision(precision: number = 2): [string, string]
	{
		const value = Number(this.symbol.formatNumber(this.amount > 0 ? this.amount : 0)).toFixed(precision);
		const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		return [formattedValue, this.symbol.code];
	}

	toString()
	{
		return `${this.symbol.formatNumber(this.amount)} ${this.symbol.code}`;
	}
}
