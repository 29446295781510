/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger, sleep } from "@pro/common/utils";
import { Api, JsonRpc } from "eosjs/dist";
import { AuthorityProvider, SignatureProvider, TransactConfig } from "eosjs/dist/eosjs-api-interfaces";
import { GetBlockResult, GetInfoResult, PushTransactionArgs } from "eosjs/dist/eosjs-rpc-interfaces";
import { EosApiError } from "./eos_errors";
import {
	IEosAccount,
	IEosAction,
	IEosAuth,
	IEosAuthDef,
	IEosGetTableParam,
	IEosTable,
	IEosTransact,
	IEosTransactResult,
	IGetSopeResult,
	ITableScope,
	TEosIndexPos,
	TEosKeyType,
	TEosScopeType
} from "./eos_types";
import { EosUtils } from "./eos_utils";
import { EosAsset, EosSymbol } from "./EosAsset";
import { EosNodeBalancer } from "./EosNodeBalancer";
import { TFetch } from "./fetch_types";
import {ITokenStat} from "@pro/common/contracts/staking";

export interface ITransactOptions
{
	sign: boolean,
	broadcast: boolean,
	blocksBehind: number,
	expireSeconds: number,
	randomize: boolean,
	dataFormatter: (data: any) => string;
}

interface IEosApiOptions
{
	defaultCode?: string;
	defaultScope?: string;
	defaultLimit?: number;
	signatureProvider?: SignatureProvider;
	authorityProvider?: AuthorityProvider;
	textEncoder?: any;
	textDecoder?: any;
	tag?: string;
	logRequest?: boolean;
	logResponse?: boolean;
	transactOptions?: Partial<ITransactOptions>;
	api?: Api;
}

let randomizationCounter = 0;
const json = JSON.stringify;

export class EosApi
{
	static defaultTransactOptions: ITransactOptions = {
		sign: true,
		broadcast: true,
		blocksBehind: 3,
		expireSeconds: 60,
		randomize: false,
		dataFormatter,
	} as const;

	private readonly _nodes: EosNodeBalancer;
	private readonly _defaultCode?: string;
	private readonly _defaultScope?: string;
	private readonly _defaultLimit?: number;
	private readonly _api: Api;
	private readonly _tag: string;
	private readonly _logRequest: boolean;
	private readonly _logResponse: boolean;
	private readonly _userBalancerForApi: boolean;
	private _transactOptions: ITransactOptions;

	private _chainId?: string;
	private _batch: IEosAction[] | null = null;
	private _signTransactFn?: (transaction: any, config?: TransactConfig) => Promise<any>;

	constructor(private urls: string | string[], fetch: TFetch, p: IEosApiOptions)
	{
		this._nodes = new EosNodeBalancer(this.urlArray.slice(), fetch);
		this._defaultCode = p.defaultCode;
		this._defaultScope = p.defaultScope;
		this._defaultLimit = p.defaultLimit ?? 1000;
		this._logRequest = Boolean(p.logRequest);
		this._logResponse = Boolean(p.logResponse);
		this._tag = p.tag ?? "[EosApi]";
		this._transactOptions = {
			...EosApi.defaultTransactOptions,
			...p.transactOptions,
		};

		if (p.api) {
			this._userBalancerForApi = false;
			this._api = p.api;
		}
		else {
			this._userBalancerForApi = true;
			this._api = new Api({
				rpc: urls.length > 0
					? this._nodes.getRpc()
					: new JsonRpc("url_is_not_set"),
				authorityProvider: p.authorityProvider,
				signatureProvider: p.signatureProvider as any, // it works without provider works for readonly access
				textEncoder: p.textEncoder,
				textDecoder: p.textDecoder
			});
		}
	}

	get urlArray(): readonly string[]
	{
		return ([] as string[]).concat(this.urls);
	}

	selectNode(url: string)
	{
		this._nodes.selectNode(url);
	}

	setSignTransactFn(signtTransactionFn?: (transaction: any, config?: TransactConfig) => Promise<any>)
	{
		this._signTransactFn = signtTransactionFn;
	}

	getTransactOptions(): Readonly<ITransactOptions>
	{
		return this._transactOptions;
	}

	setTransactOptions(options: Partial<ITransactOptions>)
	{
		this._transactOptions = Object.freeze({
			...this._transactOptions,
			...options,
		});
	}

	async getInfo(): Promise<GetInfoResult>
	{
		if (this._logRequest)
			Logger.log(this._tag, `<- getInfo`);

		let result = await this.getRpc().get_info();

		if (this._logResponse)
			Logger.log(this._tag, `-> getInfo`);

		return result;
	}

	async getChainId(): Promise<string>
	{
		if (!this._chainId) {
			let info = await this.getInfo();
			this._chainId = info.chain_id;
		}
		return this._chainId;
	}

	async getBlock(num: number): Promise<GetBlockResult>
	{
		if (this._logRequest)
			Logger.log(this._tag, `<- getBlock`);

		let result = await this.getRpc().get_block(num);

		if (this._logResponse)
			Logger.log(this._tag, `-> getBlock`);

		return result;
	}

	async getIrreversibleTime(): Promise<number>
	{
		let info = await this.getInfo();
		let block = await this.getBlock(info.last_irreversible_block_num);
		let time_ms = new Date(block.timestamp + "Z").valueOf();
		return Math.floor(time_ms / 1000);
	}

	async getAccount(name: string): Promise<IEosAccount>
	{
		if (this._logRequest)
			Logger.log(this._tag, `<- getAccount: ${name}`);

		const result = await this.getRpc().get_account(name);

		if (this._logResponse)
			Logger.log(this._tag, `-> getAccount: ${name}`);

		return result;
	}

	async getBalances(contract: string, account: string): Promise<EosAsset[]>
	{
		if (this._logRequest)
			Logger.log(this._tag, `<- getBalances: ${contract}:${account}`);

		const balances: string[] = await this.getRpc()
			.get_currency_balance(contract, account);

		if (this._logResponse)
			Logger.log(this._tag, `-> getBalances: ${contract}:${account}`);

		return balances.map(it => EosAsset.parse(it));
	}

	async getCurrencyStat(contract: string, symbol: EosSymbol): Promise<{ [s: string]: ITokenStat }>
	{
		return await this.getRpc().get_currency_stats(contract, symbol.code);
	}

	async getBalance(contract: string, account: string, symbol: EosSymbol): Promise<EosAsset>
	{
		const balances = await this.getBalances(contract, account);
		return balances.find(it => it.symbol.equals(symbol)) ?? new EosAsset(0, symbol);
	}

	pushSignedTransaction(tr: PushTransactionArgs): Promise<IEosTransactResult>
	{
		return this._api.pushSignedTransaction(tr);
	}

	deserializeTransaction(tr: Uint8Array)
	{
		return this._api.deserializeTransaction(tr);
	}

	serializeTransaction(tr: IEosTransact)
	{
		return this._api.serializeTransaction(tr);
	}

	beginBatch()
	{
		if (this._batch === null)
			this._batch = [];
	}

	async endBatch(): Promise<IEosTransactResult | null>
	{
		if (!this._batch?.length) {
			this._batch = null;
			return Promise.resolve(null);
		}

		let actions = this._batch;
		this._batch = null;
		try {
			return await this.transact(actions);
		}
		catch (e) {
			this._batch = actions;
			throw e;
		}
	}

	async transact(actions: IEosAction[], options?: Partial<ITransactOptions>): Promise<IEosTransactResult>
	{
		if (this._batch) {
			this._batch = this._batch.concat(actions);
			return {} as IEosTransactResult;
		}

		if (!this._api.signatureProvider && !this._signTransactFn)
			throw new Error("SignatureProvider is not set!");

		let o = {
			...this._transactOptions,
			...options,
		};

		if (this._logRequest) {
			let msg = actions.map(it => {
				return `[action] ${it.name} ${o.dataFormatter(it.data)}`;
			});
			Logger.log(this._tag, `<- transact[${actions.length}]:\n${msg.join("\n")}`);
		}

		if (o.randomize)
			o.expireSeconds += (randomizationCounter++) % 1000;

		if (this._userBalancerForApi)
			this._api.rpc = this.getRpc();

		const tranactOptions = {
			sign: o.sign,
			broadcast: o.broadcast,
			blocksBehind: o.blocksBehind,
			expireSeconds: o.expireSeconds,
		};

		const result = this._signTransactFn
			? await this._signTransactFn({actions}, tranactOptions)
			: await this._api.transact({actions}, tranactOptions);

		if (this._logResponse) {
			let msg = actions.map(it => it.name).join(",");
			Logger.log(this._tag, `<- transact[${msg}]`);
		}

		return result;
	}

	async transfer(contract: string, p: {
		from: string; to: string; quantity: string; memo: string;
	}): Promise<IEosTransactResult | null>
	{
		return await this.transact([{
			account: contract,
			name: "transfer",
			authorization: [{actor: p.from, permission: "active"}],
			data: {
				from: p.from,
				to: p.to,
				quantity: p.quantity,
				memo: p.memo ?? ""
			}
		}]);
	}

	updateAuth(account: string,
	           permission: string,
	           parent: string,
	           data: IEosAuthDef,
	           auth?: IEosAuth): Promise<IEosTransactResult | null>
	{
		if (!auth)
			auth = {actor: account, permission: "active"};

		let action: IEosAction = {
			account: "eosio",
			name: "updateauth",
			authorization: [auth],
			data: {
				account,
				permission,
				parent,
				auth: data
			}
		};
		return this.transact([action]);
	}

	createAccount(accountName: string, {
		creator,
		owner_key,
		active_key = owner_key,
		ram_bytes = 8192,
		stake_net_quantity,
		stake_cpu_quantity,
		permissions
	}: {
		creator: string,
		owner_key: string,
		active_key?: string,
		ram_bytes?: number,
		stake_net_quantity: string,
		stake_cpu_quantity: string,
		permissions?: {
			keys?: Array<{key: string, weight: number}>,
			accounts?: Array<{
				permission: IEosAuth,
				weight: number,
			}>
		}
	}): Promise<IEosTransactResult | null>
	{
		const authorization: IEosAuth[] = [{actor: creator, permission: "active"}];

		let newAccount: IEosAction = {
			account: "eosio",
			name: "newaccount",
			authorization,
			data: {
				creator,
				name: accountName,
				owner: {
					threshold: 1,
					keys: [{key: owner_key, weight: 1}],
					accounts: [],
					waits: []
				},
				active: {
					threshold: 1,
					keys: [{key: active_key, weight: 1}],
					accounts: [],
					waits: [],
					...permissions
				}
			}
		};

		let buyRamBytes: IEosAction = {
			account: "eosio",
			name: "buyrambytes",
			authorization,
			data: {
				payer: creator,
				receiver: accountName,
				bytes: ram_bytes
			}
		};

		let delegateBw: IEosAction = {
			account: "eosio",
			name: "delegatebw",
			authorization,
			data: {
				from: creator,
				receiver: accountName,
				stake_net_quantity,
				stake_cpu_quantity,
				transfer: false
			}
		};
		return this.transact([newAccount, buyRamBytes, delegateBw]);
	}

	async getRecord<T = {}>(id: string | number, options: IFindRecordParams): Promise<T>
	{
		let result = await this.findRecord<T>(id, options);
		if (!result) {
			let scope = options.scope ?? this._defaultScope;
			throw EosApiError.recordNotFound(scope!, options.table, id);
		}
		return result;
	}

	async findRecord<T>(key: string | number, p: IFindRecordParams): Promise<T | undefined>
	{
		let code = p.code ?? this._defaultCode;
		let scope = p.scope ?? this._defaultScope;
		let scope_type = p.scope_type ?? "name";

		checkCodeArg("code", code);
		checkScopeArg("scope", scope, scope_type);
		checkIndexKey("key", key, p.key_type);

		let query: IEosGetTableParam = {
			code,
			scope: scope_type === "name" ? EosUtils.addNameSpace(scope) : scope,
			table: p.table,
			lower_bound: key,
			upper_bound: key,
			index_position: p.index_position,
			key_type: p.key_type,
			limit: 1,
			reverse: p.reverse,
			show_payer: p.show_payer
		};

		/**
		 * empty-string is a default value for the upper_bound,
		 * workaround: if upper bound is "" then result is
		 * either empty or s single record with key=""
		 */
		const zeroNameWorkaround = p.key_type === "name" && key === "";
		if (zeroNameWorkaround) {
			query.key_type = "i64";
			query.lower_bound = 0;
			query.upper_bound = 0;
			query.limit = 1;
		}

		if (this._logRequest) {
			let msg = `<- findRecord: ${query.table} key=${json(key)}`;
			if (query.code !== this._defaultCode)
				msg += ` code=${query.code}`;
			if (query.scope !== this._defaultScope)
				msg += ` scope=${json(query.scope)}`;
			if (p.index_position !== undefined)
				msg += ` idx=${p.index_position}`;

			Logger.log(this._tag, msg);
		}

		let result = await this.getRpc().get_table_rows(query);

		if (this._logResponse)
			Logger.log(`-> getRecord ${query.table}`);

		return result.rows[0] || undefined;
	}

	async getTable<T>(p: IFindTableParams): Promise<IEosTable<T>>
	{
		let code = p.code ?? this._defaultCode;
		let scope = p.scope ?? this._defaultScope;
		let scope_type = p.scope_type ?? "name";

		checkCodeArg("code", code);
		checkScopeArg("scope", scope, p.scope_type ?? "name");

		if (p.lower_bound !== undefined)
			checkIndexKey("lower_bound", p.lower_bound, p.key_type);

		if (p.upper_bound !== undefined)
			checkIndexKey("upper_bound", p.upper_bound, p.key_type);

		let query: IEosGetTableParam = {
			code,
			scope: scope_type === "name" ? EosUtils.addNameSpace(scope) : scope,
			table: p.table,
			lower_bound: p.lower_bound,
			upper_bound: p.upper_bound,
			index_position: p.index_position,
			key_type: p.key_type,
			limit: p.limit ?? this._defaultLimit,
			reverse: p.reverse,
			show_payer: p.show_payer
		};

		/**
		 * empty-string is a default value for the upper_bound,
		 * workaround: if upper bound is "" then result is
		 * either empty or s single record with key=""
		 */
		const zeroNameWorkaround = p.key_type === "name" && p.upper_bound === "";
		if (zeroNameWorkaround) {
			query.key_type = "i64";
			query.lower_bound = 0;
			query.upper_bound = 0;
			query.limit = 1;
		}

		if (this._logRequest) {
			let msg = `<- getTable: ${query.table}`;
			if (query.code !== this._defaultCode)
				msg += ` code=${query.code}`;
			if (query.scope !== this._defaultScope)
				msg += ` scope=${json(query.scope)}`;
			if (p?.index_position !== undefined)
				msg += ` idx=${p.index_position}`;
			if (p?.lower_bound !== undefined)
				msg += ` lower=${json(p.lower_bound)}`;
			if (p?.upper_bound !== undefined)
				msg += ` upper=${json(p.upper_bound)}`;

			Logger.log(this._tag, msg);
		}

		const result: IEosTable<T> = await this.getRpc().get_table_rows(query);

		if (this._logResponse) {
			let msg = `-> getTable: ${query.table}`
			          + ` rows=${result.rows.length}`
			          + ` more=${result.more}`
			          + ` next_key=${json(result.next_key)}`;
			Logger.log(this._tag, msg);
		}

		return result;
	}

	async getFullTable<T>(p: IFindTableParams): Promise<T[]>
	{
		const PAUSE = 100;

		let lower_bound = p.lower_bound;
		let upper_bound = p.upper_bound;
		let key_type = p.key_type;

		if (p.key_type === "name") {
			/**
			 * As response returns the next_key in a decimal format
			 * the simplest way to handle 'lower_bound' and 'upper_bound'
			 * if to convert them also to a decimal format
			 */
			if (lower_bound !== undefined) {
				checkIndexKey("lower_bound", lower_bound, "name");
				lower_bound = EosUtils.nameToDecimal(lower_bound as string);
			}
			if (upper_bound !== undefined) {
				checkIndexKey("upper_bound", upper_bound, "name");
				upper_bound = EosUtils.nameToDecimal(upper_bound as string);
			}
			key_type = "i64";
		}

		let nextKey = lower_bound;
		let result: T[] = [];
		let response: IEosTable<T>;
		let tries = 0;
		let pause = PAUSE;

		do {
			try {
				response = await this.getTable<T>({
					...p,
					lower_bound: nextKey,
					upper_bound,
					key_type
				});

				result = result.concat(response.rows);
				nextKey = response.next_key;
				tries = 0;
				pause = PAUSE;
			}
			catch (e) {
				response = {more: true} as any as IEosTable<T>;

				if (e?.json?.error?.name === "timeout_exception") {
					Logger.log(this._tag, "-> getTable: timeout_exception");
					await sleep(pause);
					pause *= 2;
				}
				else {
					Logger.log(this._tag, "-> getTable:", e.message);
					tries++;
					if (tries > 10)
						throw e;

					await sleep(1000);
				}
			}
		}
		while (response.more);

		return result;
	}

	async getScopes(table: string, {
		code = this._defaultCode,
		lower_bound = "",
		upper_bound = "",
		limit = this._defaultLimit
	} = {}): Promise<IGetSopeResult>
	{
		if (this._logRequest) {
			let msg = `<- getScopes: ${table}`;
			if (lower_bound)
				msg += ` lower=${json(lower_bound)}`;
			if (upper_bound)
				msg += ` upper=${json(upper_bound)}`;

			Logger.log(this._tag, msg);
		}

		const result: IGetSopeResult = await this.getRpc().get_table_by_scope({
			code,
			table,
			lower_bound: EosUtils.addNameSpace(lower_bound),
			upper_bound: EosUtils.addNameSpace(upper_bound),
			limit
		});

		if (this._logResponse) {
			let msg = `-> getScopes: ${table}`;
			msg += ` rows=${result.rows.length}`;
			msg += ` more=${json(result.more)}`;
			Logger.log(this._tag, msg);
		}

		return result;
	}

	/**
	 * Use this method VERY carefully!
	 *
	 * - It may return wrong result if scope = ""
	 * - EosUtils.nextName is used as a workaround of infinite loops, so 'reverse' is not supported
	 */
	async getAllScopes(table: string, {
		code = this._defaultCode,
		lower_bound = "",
		upper_bound = "",
		limit = 1000
	} = {}): Promise<ITableScope[]>
	{
		let result: ITableScope[] = [];
		let response: IGetSopeResult;

		while (true) {
			response = await this.getScopes(table, {
				code,
				lower_bound,
				upper_bound,
				limit
			});

			result = result.concat(response.rows);
			if (response.more === "") {
				break;
			}

			let lastReceived = response.rows.length > 0
				? response.rows[response.rows.length - 1].scope
				: undefined;

			if (response.more === lastReceived)
				lower_bound = EosUtils.nextName(response.more);
			else
				lower_bound = response.more;
		}

		return result;
	}

	getRpc(): JsonRpc
	{
		return this._nodes.getRpc();
	}

	get api(): Api
	{
		return this._api;
	}
}

export interface IFindRecordParams
{
	readonly code?: string,
	readonly scope?: string,
	readonly scope_type?: TEosScopeType,
	readonly table: string,
	readonly index_position?: TEosIndexPos,
	readonly key_type: TEosKeyType,
	readonly reverse?: boolean,
	readonly show_payer?: boolean,
}

export interface IFindTableParams
{
	readonly code?: string,
	readonly scope?: string,
	readonly scope_type?: TEosScopeType,
	readonly table: string,
	readonly lower_bound?: string | number,
	readonly upper_bound?: string | number,
	readonly index_position?: TEosIndexPos,
	readonly key_type: TEosKeyType,
	readonly limit?: number,
	readonly reverse?: boolean,
	readonly show_payer?: boolean,
}

function checkCodeArg(name: string, value: unknown)
{
	if (!value)
		throw EosApiError.invalidArg(name, value);
}

function checkScopeArg(name: string, value: unknown, scope_type: TEosScopeType): asserts value is string
{
	let ok = scope_type === "name"
		? typeof (value) === "string"
		  && /^[.1-5a-z]*[^.]$|^[A-Z]{1,7}$/.test(value)
		: (typeof (value) === "string" || typeof (value) === "number")
		  && /^[0-9]*$/.test(value.toString());

	if (!ok)
		throw EosApiError.invalidArg(name, value);
}

function checkIndexKey(name: string, value: string | number, type: TEosKeyType)
{
	let ok: boolean;

	if (type === "name") {
		ok = typeof (value) === "string"
		     && !value.endsWith(".")
		     && /^[.1-5a-z]*$/.test(value);
	}
	else if (type === "symbol_code") {
		ok = typeof (value) === "string"
		     && /^[A-Z]{1,7}$/.test(value);
	}
	else {
		ok = /^[0-9a-f]+$/.test(String(value));
	}
	if (!ok)
		throw EosApiError.invalidKey(name, value, type);
}

function dataFormatter(data: any): string
{
	return JSON.stringify(data);
}
