import { User } from "universal-authenticator-library";
import { model } from "../App";

export class UalService {
	private _ual?: IUal;
	private _isDevAuth = false;

	init(ual: IUal)
	{
		this._ual = ual;
	}

	logout()
	{
		this._ual?.logout();
		model.logout();
	}

	async login()
	{
		try {
			return this._ual?.showModal();
		} catch (e) {
			console.error("login error:", e);
		}
	}

	async devAuth()
	{
		const url = new URL(window.location.href);
		const devAuth = url.searchParams.get("dev_auth");
		if (devAuth) {
			this._isDevAuth = true;
			await model.login(devAuth);
			return;
		}
	}

	get isDevAuth(): boolean
	{
		return this._isDevAuth;
	}
}

export interface IUal {
	activeUser?: User;

	showModal(): void;

	logout(): void;
}