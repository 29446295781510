import { Logger } from "@pro/common/utils/Logger";
import { action, makeObservable, observable } from "mobx";
import { IPuzzlePlayerState } from "../core/types";

const tag = "[LocalData]";

export class LocalData {
	_node_url: string = "";
	_paintingState: any = {};

	constructor()
	{
		makeObservable(this, {
			_node_url: observable,
			_paintingState: observable,
			init: action,
		});
	}

	init()
	{
		console.info(tag, "init");
		let storage = window.localStorage;
		this._node_url = storage.getItem("node_url") || "";

		let state = storage.getItem("paiting_state");
		this._paintingState = state ? JSON.parse(state) : {};
	}

	flush()
	{
		Logger.log(tag, "flush");
		let storage = window.localStorage;
		storage.setItem("node_url", String(this._node_url));
		storage.setItem("paiting_state", String(JSON.stringify(this._paintingState)));
	}

	logout()
	{
		Logger.log(tag, "logout");
		this.flush();
	}

	//
	// get resolution() { return this._resolution }
	// setResolution(value: string)
	// {
	// 	this._resolution = value;
	// }

	get nodeUrl()
	{
		return this._node_url
	}

	set nodeUrl(value: string)
	{
		this._node_url = value;
	}

	setPaintingState(painting_template_id: number, state: any)
	{
		if (state === undefined)
			delete this._paintingState[painting_template_id.toString()];
		else
			this._paintingState[painting_template_id.toString()] = state;
	}

	getPaintingState(painting_template_id: number): IPuzzlePlayerState | undefined
	{
		return this._paintingState[painting_template_id.toString()];
	}
}
