import React, { useState } from 'react';
import Styles from "./Card.module.scss"
import Button from "../../../pages/landing/components/Button";
import { AtomicAssetModel } from "../../../models/AtomicAssetModel";
import { UnboxNeftyAction } from "../../../eos/actions/UnboxNeftyAction";

const PackCard = (
	{
		asset,
	}: {
		asset: AtomicAssetModel,
	}
) => {
	const [btnReady, setBtnReady] = useState(true);

	async function onBtnClick()
	{
		setBtnReady(false);
		new UnboxNeftyAction(asset.assetId)
			.onError(() => setBtnReady(true))
			.executeAsync()

		// new UnpackAction(it)
		// 	.onError(() => setBtnReady(true))
		// 	.executeAsync()
	}

	return (
		<div className={Styles.card}>
			<div className={Styles.img}>
				<img src={asset.img} alt="img"/>
			</div>
			<div className={Styles.buttons}>
				<Button
					onClick={onBtnClick}
					disabled={!btnReady}
				>
					Unpack
				</Button>
			</div>
		</div>
	);
};

export default PackCard;