import React from 'react';
import Styles from "./CongratsPopup.module.scss"
import H3 from "../../../components/typography/H3";
import P1 from "../../../components/typography/P1";
import Button from "../../landing/components/Button";
import P3 from "../../../components/typography/P3";
import { PagePath } from "../../../helpers/PageHelper";
import { app } from "../../../App";
import { ICollectorJson, IPackJson } from "@pro/common/conf";
import { makeLinkToAssetMedia } from "../../../helpers/image_helper";

interface CongratsPopupProps {
	author: string,
	pack_template_id: number,
	collector_template_id?: number,
}

const CongratsPopup = (props: CongratsPopupProps) => {

	let pack: IPackJson | undefined = app.assetConf.getByTemplateId(props.pack_template_id) as IPackJson;
	const collector: ICollectorJson | undefined = props.collector_template_id ?
		app.assetConf.getByTemplateId(props.collector_template_id) as ICollectorJson : undefined;

	return <div className={Styles.wrapper}>
		<div className={Styles.container}>
			<div className={Styles.heading}>
				<H3>Congratulations!</H3>
				{collector ? <P1>You came first in this race! Now get your prize worth of the winner:</P1>
					: <P1>Nice job! Your prize is:</P1>
				}
			</div>
			<div className={Styles.prizes}>
				{
					pack && <div className={Styles.prize}>
                        <div className={Styles.img}>
                            <img src={makeLinkToAssetMedia(pack.img)} alt=""/>
                        </div>
                        <div className={Styles.desc}>
                            <span><P1 isBold>{pack.name}</P1></span>
                            <span><P1 isBold>{pack.description}</P1></span>
                        </div>
                    </div>
				}
				{
					collector && <div className={Styles.prize}>
                        <div className={Styles.img}>
                            <img src={makeLinkToAssetMedia(collector.img)} alt=""/>
                        </div>
                        <div className={Styles.desc}>
                            <div className={Styles.naming}>
                                <span><P1 isBold>{collector.name}</P1></span>
                            </div>
                            <span><P3 isBold={false}>{props.author}</P3></span>
                        </div>
                    </div>
				}
			</div>
			<Button path={PagePath.LOTTERY}>Claim reward</Button>
		</div>
	</div>
};

export default CongratsPopup;
