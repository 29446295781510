import React from 'react';
import Styles from "./Card.module.scss"
import classNames from "classnames";
import H3 from "../../../components/typography/H3";

interface CardProps extends React.HTMLAttributes<HTMLElement> {
	img: string,
}

const Card: React.FC<CardProps> = (props: CardProps) => (
	<div className={Styles.card}>
		{props.img && <div className={Styles.img}><img src={props.img} alt="card"/></div>}
		<div className={Styles.inner}>{props.children}</div>
	</div>
)

export default Card;

export const CardHeader: React.FC<React.HTMLAttributes<HTMLElement>> = props => (
	<H3 className={classNames(Styles.title, props.className)}>{props.children}</H3>
)

export const CardContent: React.FC<React.HTMLAttributes<HTMLElement>> = props => (
	<div className={classNames(Styles.content, props.className)}>{props.children}</div>
)

export const CardFooter: React.FC<React.HTMLAttributes<HTMLElement>> = props => (
	<div className={classNames(Styles.footer, props.className)}>{props.children}</div>
)
