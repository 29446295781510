import { model } from "../../App";
import TopNavigation from "../../components/shared/TopNavigation";
import CardNFT from "../../components/shared/Card/CardNFT";
import React from "react";
import { observer } from "mobx-react";
import Placeholder from "../../components/shared/Placeholder";
import CardStack from "../../components/shared/Card/CardStack";
import { L } from "../../l10n/L10n";
import PageContainer from "../../components/shared/PageContainer";
import Loading from "../../components/shared/Loading";

const AssetsPage = observer(() => {
	const rows: JSX.Element[] = model.atomicAssets.paintings.concat(model.atomicAssets.collectors).map(it =>
		<CardNFT
			key={it.asset.asset_id}
			asset={it}
		/>
	);

	if (!model.appDataLoaded)
		return <Loading/>;

	return (
		<PageContainer>
			<TopNavigation title={L.page.assets}/>
			{rows.length > 0
				? <CardStack>{rows}</CardStack>
				: <Placeholder title={L.placeholder.assets.title} description={L.placeholder.assets.subtitle}/>
			}
		</PageContainer>

	);
});

export default AssetsPage