import React, { useEffect, useState } from 'react';
import Styles from "../GalleryPage.module.scss";
import H4 from "../../../components/typography/H4";
import { formatTimeLeft } from "../../../helpers/formatters";
import { CanvasPuzzlePlayer } from "../../../core/CanvasPuzzlePlayer";

interface TimerProps {
	initialValue: number;
	player: CanvasPuzzlePlayer
}

const Timer = ({player, initialValue}: TimerProps) => {
	const [spentTime, setSpentTime] = useState(initialValue);
	useEffect(() => {
		let interval: any;
		if (player) {
			interval = setInterval(() => {
				setSpentTime(player.timeSpent)
			}, 1000)
		}
		return () => {
			if (player) {
				clearInterval(interval);
			}
		}
	}, [player]);

	return (
		<div className={Styles.timer}>
			<H4>{formatTimeLeft(spentTime / 1000)}</H4>
		</div>
	);
};

export default Timer;
