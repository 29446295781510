import { IStakedAsset } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";

export class StakingAssetModel {
	constructor(
		public readonly asset: IStakedAsset,
	)
	{
		makeObservable(this, {});
	}

	get assetId(): number
	{
		return parseInt(this.asset.asset_id);
	}

	get templateId(): number
	{
		return this.asset.template_id;
	}

	get poolId(): number
	{
		return this.asset.pool_id;
	}

}