import { Wax } from '@eosdacio/ual-wax';
import React, { useEffect } from "react";
import { Anchor } from 'ual-anchor';
import { UALProvider } from 'ual-reactjs-renderer';
import { Scatter } from 'ual-scatter';
import { AppContext } from "./AppContext";
import UalView from "./components/shared/UalView";
import { EosService } from "./eos/EosService";
import { L } from "./l10n/L10n";
import { AppModel } from "./models/AppModel";
import { NotificationService } from "./services/NotificationService";
import { UalService } from "./services/UalService";
import { WorldService } from "./services/WorldService";
import { LocalData } from "./models/LocalData";
import { GTagService } from "./services/GTagService";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PagePath } from "./helpers/PageHelper";
import "./styles/global.scss";
import PoolPage from "./components/pool/PoolPage";
import AdminPage from "./components/admin/AdminPage";
import AdminPoolsPage from "./components/admin/pools/AdminPoolsPage";
import CreatePoolPage from "./components/admin/new_pool/CreatePoolPage";
import AdminPoolPage from "./components/admin/pool/AdminPoolPage";
import StakeAssets from "./components/pool/StakeAssets";
import AdminPoolUsers from "./components/admin/pool/AdminPoolUsers";
import AdminPoolUser from "./components/admin/pool/AdminPoolUser";
import GalleryPage from "./pages/gallery/GalleryPage";
import CropperPage from "./pages/cropper/CropperPage";
import LotteryPage from "./pages/lottery/LotteryPage";
import PacksPage from "./pages/packs/PacksPage";
import ShopPage from "./pages/shop/ShopPage";
import PuzzlesPage from "./pages/puzzles/PuzzlesPage";
import TestPage from "./pages/test/TestPage";
import ExpositionPage from "./pages/exposition/ExpositionPage";
import { Logger, LogLevel } from '@pro/common/utils';
import LandingPage from "./pages/landing/LandingPage";
import ExposPage from "./pages/exposition/ExposPage";
import ExpoPage from "./pages/exposition/ExpoPage";
import Layout from './components/shared/Layout';
import AssetsPage from "./pages/assets/AssetsPage";
import NotificationView from "./components/shared/NotificationView";

setLogLevel();

export const localData = new LocalData();
export const app = new AppContext();
export const notificationService = new NotificationService();
export const model = new AppModel();
export const eos = new EosService(app.nodeos_urls, app.chainConf);
export const world = new WorldService();
export const ualService = new UalService();
export const gTagService = new GTagService("GTM-5LMRF9V");

async function launchAppAsync()
{
	localData.init();
	await eos.mustConnect();
	await ualService.devAuth();
	await loadAppData();
}

function setLogLevel()
{
	const isLogALl: boolean = (new URL(window.location.href)).searchParams.get("log") === "all";
	const logLevel = !isLogALl && process.env.REACT_APP_PROJ_ENV === "prod" ? LogLevel.Warn : LogLevel.Log;
	Logger.get().setLevel(logLevel);
}

async function loadAppData()
{
	await world.updateConfig();
	await world.updateAllSchemas(app.chainConf.COLLECTION_NAME)
	if (!app.useTemplateFIle)
		await world.updateAllTemplates(app.chainConf.COLLECTION_NAME);
	if (model.logined)
		await world.updateAtomicAssets();
	model.setAppDataLoaded(true)
}


launchAppAsync().catch();

const authenticators = app.projEnv === "prod"
	? [
		new Wax(app.chains, {
			apiSigner: {
				getAvailableKeys: () => Promise.resolve(["EOS8NpCnBbL1qoQsrPPeSDkWRjrnvXWT4H432yV9UmEXS8qFxneyC"]),
				sign: () => Promise.resolve({
					signatures: [],
					serializedTransaction: new Uint8Array(),
				}),
			}
		}),
		new Anchor(app.chains, {appName: L.shared.siteName}),
	]
	: [
		new Anchor(app.chains, {appName: L.shared.siteName}),
		new Scatter(app.chains, {appName: L.shared.siteName}),
	];

function App()
{
	useEffect(() => {
		if (app.projEnv === "prod")
			gTagService.initialize();
	}, []);

	return (
		<HelmetProvider>
			<NotificationView/>
			<UALProvider
				chains={app.chains}
				authenticators={authenticators}
				appName={L.shared.siteName}
			>
				<BrowserRouter>
					<Routes>
						<Route path={PagePath.ROOT} element={<LandingPage/>}/>
						<Route path={`${PagePath.ROOT}*`} element={<Game/>}/>
						<Route path={PagePath.LOTTERY} element={<LotteryPage/>}/>
						<Route path={PagePath.GALLERY} element={<GalleryPage/>}/>
					</Routes>
				</BrowserRouter>
				<UalView/>
			</UALProvider>
		</HelmetProvider>
	);
}

export default App;

const Game = () => (
	<Layout>
		<Routes>
			<Route path={PagePath.POOL} element={<PoolPage/>}/>
			<Route path={PagePath.EXPOS} element={<ExposPage/>}/>
			<Route path={PagePath.EXPO} element={<ExpoPage/>}/>
			<Route path={PagePath.PUZZLES} element={<PuzzlesPage/>}/>
			<Route path={PagePath.PACKS} element={<PacksPage/>}/>
			<Route path={PagePath.SHOP} element={<ShopPage/>}/>
			<Route path={PagePath.ASSETS} element={<AssetsPage/>}/>
			{["dev", "stage"].includes(app.projEnv) && <>
                <Route path={PagePath.EXPOSITION} element={<ExpositionPage/>}/>
                <Route path={PagePath.TEST} element={<TestPage/>}/>
                <Route path={PagePath.CROPPER} element={<CropperPage/>}/>
                <Route path={PagePath.POOL_STAKE} element={<StakeAssets/>}/>
                <Route path={PagePath.ADMIN} element={<AdminPage/>}/>
                <Route path={PagePath.ADMIN_POOLS} element={<AdminPoolsPage/>}/>
                <Route path={PagePath.ADMIN_POOL} element={<AdminPoolPage/>}/>
                <Route path={PagePath.ADMIN_POOL_USERS} element={<AdminPoolUsers/>}/>
                <Route path={PagePath.ADMIN_POOL_USER} element={<AdminPoolUser/>}/>
                <Route path={PagePath.NEW_POOL} element={<CreatePoolPage/>}/>
            </>}
		</Routes>
	</Layout>
)
