import { EosActionBase } from "../EosActionBase";
import { eos, model, world } from "../../App";
import { NeftyClaimAssetsModel } from "../../models/NeftyClaimAssetsModel";

export class ClaimNeftyAction extends EosActionBase
{
	constructor(private claimAsset: NeftyClaimAssetsModel)
	{
		super("claim_nefty");
	}

	async onExecute(): Promise<void>
	{
		const roll_indexes = this.claimAsset.assets.map((_, index) => index);

		await eos.npContract.action("claim", eos.userAuth, {
			claim_id: this.claimAsset.claim_id,
			roll_indexes: roll_indexes,
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		// TODO cap: maybe we should update only the claimed assets
		await world.updateAtomicAssets(true)
		model.neftyClaimAssets.removeItems([this.claimAsset.claim_id]);
	}
}