import React from "react";
import { model } from "../../../App";
import AdminLayout from "../../shared/AdminLayout";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/Button";
import { getPathById, PagePath } from "../../../helpers/PageHelper";
import { RemPoolAction } from "../../../eos/actions/staking/RemPoolAction";
import { OpenPoolPeriodsAction } from "../../../eos/actions/staking/OpenPoolPeriodsAction";
import Styles from "./AdminPoolsPage.module.scss";
import { ActivatePoolAction } from "../../../eos/actions/staking/ActivatePoolAction";
import { CleanupPoolAction } from "../../../eos/actions/staking/CleanupPoolAction";

const AdminPoolsPage = observer(() => {
	const navigate = useNavigate();
	if (!model.pools.queryAll().length)
		return <AdminLayout content={<>
			<h2>You haven't any pool yet</h2>
		</>}/>

	const my_pools = model.pools.query(it => it.owner === model.userName).map(it => {
		return <tr>
			<td>{it.poolId}</td>
			<td>{it.totalUsers}</td>
			<td>{it.totalStaked.toString()}</td>
			<td>{it.totalPaid.toString()}</td>
			<td>{it.currentPeriod + 1}/{it.periodsCount}</td>
			<td>{it.activated ? it.periodsCount : it.poolPeriods.length}/{it.periodsCount}</td>
			<td>{it.activated ? (it.started ? (it.ended ? "ended" : "active") : "upcoming") : "awaiting activation"}</td>
			<td>
				<Button text={"details"} onClick={() => navigate(getPathById(PagePath.ADMIN_POOL, it.pool.id))}/>
				{
					it.totalUsers > 0 && <Button text={"users"}
                                                 onClick={() => navigate(getPathById(PagePath.ADMIN_POOL_USERS, it.pool.id))}/>
				}
				{
					it.canBeDeleted && <Button text={"delete"} onClick={() => {
						new RemPoolAction(it.poolId).executeAsync();
					}}/>
				}
				{
					it.needCleanup === true && <Button text={"cleanup"} onClick={() => {
						new CleanupPoolAction(it).executeAsync();
					}}/>
				}
				{
					!it.activated && it.poolPeriods.length < it.periodsCount &&
                    <Button text={"open space"} onClick={() => {
						new OpenPoolPeriodsAction(it.poolId).executeAsync();
					}}/>
				}
				{
					!it.activated && it.poolPeriods.length === it.periodsCount &&
                    <Button text={"activate"} onClick={() => {
						new ActivatePoolAction(it).executeAsync();
					}}/>
				}
			</td>
		</tr>
	});

	if (my_pools.length === 0)
		return <AdminLayout content={<h2>You have no pools</h2>}/>

	return <AdminLayout content={
		<table className={Styles.table}>
			<thead>
			<tr>
				<th>Pool ID</th>
				<th>Pool total users</th>
				<th>Pool total staked</th>
				<th>Pool total paid</th>
				<th>Current/total periods</th>
				<th>Created periods/Total periods</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			{my_pools}
			</tbody>
		</table>
	}/>
});

export default AdminPoolsPage;