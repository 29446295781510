import React from 'react';

const ArrowExternal = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M23.2747 8.13382C23.7644 8.12906 24.1575 8.52217 24.1527 9.01185L24.0752 16.9916C24.0704 17.4813 23.6696 17.8821 23.1799 17.8869C22.6903 17.8917 22.2971 17.4986 22.3019 17.0089L22.3586 11.1696L11.4371 22.0912C11.0875 22.4408 10.5261 22.4463 10.1832 22.1034C9.84028 21.7605 9.84573 21.1991 10.1954 20.8495L21.1169 9.92791L15.2777 9.98465C14.788 9.98941 14.3949 9.5963 14.3996 9.10662C14.4044 8.61694 14.8052 8.21612 15.2949 8.21136L23.2747 8.13382Z"
			  fill={color}/>
	</svg>
);

export default ArrowExternal;
