import React from 'react';
import Styles from "./P1.module.scss";
import classNames from "classnames";

interface P1Props extends React.HTMLAttributes<HTMLElement> {
	isBold?: boolean
}

export const P1 = ({children, className, isBold}: P1Props) => (
	<p className={classNames(Styles.p1, className, isBold && Styles.bold)}>
		{children}
	</p>
)

export default P1;
