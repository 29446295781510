import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";

export class CollectAction extends EosActionBase {
	constructor(private pool: StakingPoolModel)
	{
		super("pool_collect");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("collect", eos.userAuth, {
			pool_id: this.pool.poolId,
			owner: eos.user,
			period: this.pool.currentPeriod + (this.pool.ended ? 1 : 0)
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updatePool(this.pool.poolId);
		await world.updateUserPool(model.userName, this.pool.poolId);
	}
}