import { app, localData } from "../App";
import { generateImage, loadImage, makeLinkToAssetMedia } from "../helpers/image_helper";
import { ImageSize, IPlayerPuzzle, IPoint, IPuzzlePlayerMap, IPuzzlePlayerState } from "./types";
import { PuzzleCluster } from "./CanvasPuzzleCluster";

export class CanvasPuzzlePlayer {
	private readonly _context: CanvasRenderingContext2D;
	private _clusters: PuzzleCluster[] = [];
	private _activeClusters: PuzzleCluster[] = [];

	private _states: IPuzzlePlayerState[] = [];
	private _startPlayTime: number = 0;
	private _spentTime: number = 0;

	private collisedPuzzle?: IPlayerPuzzle;
	private activeSelectionStart?: IPoint;
	private movingStart?: IPoint;
	private touchOffset?: IPoint;
	private _scale = 1;
	private _moving: boolean = false;
	private _autoMagnet: boolean = false;
	private mousePos: IPoint = {x: 0, y: 0};
	private _depositedPuzzles?: number[];

	private _touchesDistance: number = 0;
	private _touchesScale: number = 0;

	private _canvas_position: IPoint = {x: 0, y: 0};
	private _old_canvas_position: IPoint = {x: 0, y: 0};

	private _current_state: number = 0;

	public onReady = () => {
	};
	public stateChanged = () => {
	};

	private mouseDownTime: number = 0;

	private _puzzleImages: Map<number, Map<ImageSize, HTMLImageElement>> = new Map<number, Map<ImageSize, HTMLImageElement>>();

	constructor(private readonly _canvas: HTMLCanvasElement, readonly map: IPuzzlePlayerMap)
	{
		this._context = this._canvas.getContext('2d')!;
		this.resize();
	}

	private get state(): IPuzzlePlayerState | undefined
	{
		return this._states[this.currentState];
	}

	private get currentState(): number
	{
		return this._current_state;
	}

	get images(): Map<number, Map<ImageSize, HTMLImageElement>>
	{
		return this._puzzleImages;
	}

	get autoMagnet(): boolean
	{
		return this._autoMagnet;
	}

	set autoMagnet(value: boolean)
	{
		this._autoMagnet = value;
	}

	get canvas_position(): IPoint
	{
		return this._canvas_position;
	}

	get activeClusters(): PuzzleCluster[]
	{
		return this._activeClusters;
	}

	private get activeCluster(): PuzzleCluster | undefined
	{
		return this._activeClusters.length === 1 ? this._activeClusters[0] : undefined;
	}

	get painting(): number[] | undefined
	{
		return this._depositedPuzzles;
	}

	set painting(painting: number[] | undefined)
	{
		this._depositedPuzzles = painting;
		this.draw();
	}

	get canvas(): HTMLCanvasElement
	{
		return this._canvas;
	}

	get context(): CanvasRenderingContext2D
	{
		return this._context;
	}

	private set width(val: number)
	{
		this._canvas.style.width = `${val}px`;
		this._canvas.width = Math.floor(val * window.devicePixelRatio);
	}

	private set height(val: number)
	{
		this._canvas.style.height = `${val}px`;
		this._canvas.height = Math.floor(val * window.devicePixelRatio);
	}

	resize()
	{
		this.width = this.canvas.parentElement!.clientWidth;
		this.height = this.canvas.parentElement!.clientHeight;
		this.setBaseTransfom();
	}

	draw()
	{
		if (!this.loaded)
			return;

		this._context.beginPath();
		this._context.clearRect(-this.canvas.width / 2, -this.canvas.height / 2, this.canvas.width, this.canvas.height);

		for (let p of this._clusters) {
			p.draw();
		}

		if (this.activeSelectionStart) {
			this._context.rect(
				this.activeSelectionStart.x * this.scale,
				this.activeSelectionStart.y * this.scale,
				(this.mousePos.x - this.activeSelectionStart.x) * this.scale,
				(this.mousePos.y - this.activeSelectionStart.y) * this.scale
			);
			this._context.stroke();
		}

		this._context.closePath();
	}

	initState()
	{
		let state = localData.getPaintingState(this.map.painting_id);
		if (state) {
			this._states = [state];
			this._spentTime = state.spentTime || 0;
			this._current_state = 0;
			this.restoreState();
			this.recenter();
		} else
			this.createState();
	}

	async init()
	{
		this.initState();
		for (let puzzle of this.map.puzzles) {
			let m = this._puzzleImages.get(puzzle.puzzle_id);
			const ipfsHash = app.assetConf.puzzles
				.find(it => it.painting_id === this.map.painting_id && it.puzzle_id === puzzle.puzzle_id)?.img;
			const imgSrc = ipfsHash
				? makeLinkToAssetMedia(ipfsHash)
				: `/paintings/${this.map.painting_id}/images/${puzzle.puzzle_id}.png`;
			let img = await loadImage(imgSrc);
			if (!m)
				m = new Map<ImageSize, HTMLImageElement>()

			m.set(ImageSize.large, img);

			if (this.map.puzzles.length >= 100 || img.width >= 300) {
				let smallImage = await generateImage(img, 0.2);
				m.set(ImageSize.small, smallImage);

				let mediumImg = await generateImage(img, 0.4);
				m.set(ImageSize.medium, mediumImg);
			}

			this._puzzleImages.set(puzzle.puzzle_id, m);
			this.stateChanged();

			if (this.loaded) {
				this._startPlayTime = new Date().getTime();
				this.draw();
				this.onReady();
			}
		}
	}

	private setScaleBasedOnContent(totalWidth: number, totalHeight: number)
	{
		let scaleXNeeded = 1 / (totalWidth / this.canvas.width * 1.1);
		let scaleYNeeded = 1 / (totalHeight / this.canvas.height * 1.1);
		this.scale = Math.min(scaleXNeeded, scaleYNeeded);
	}

	private rearrangeClusters(clusters: PuzzleCluster[])
	{
		let totalWidth = this.map.cols * this.map.puzzle_width * 1.5 - this.map.puzzle_width * .5;
		let totalHeight = this.map.rows * this.map.puzzle_height * 1.5 - this.map.puzzle_width * .5;
		let startX = -this._canvas.width / 2 + (this.canvas.width - totalWidth) / 2;
		let startY = -this._canvas.height / 2 + (this.canvas.height - totalHeight) / 2;
		for (let i = 0; i < clusters.length; i++) {
			let row = Math.floor(i / this.map.cols)
			let col = i - row * this.map.cols;
			clusters[i].pos = {
				x: Math.floor(startX + col * (this.map.puzzle_width * 1.5)),
				y: Math.floor(startY + row * (this.map.puzzle_height * 1.5))
			}
		}
	}

	previewNewState(s: IPuzzlePlayerState)
	{
		this.restoreState(s.clusters || s);
		this.saveState();
		this.recenter();
	}

	canMoveStep(step: number): boolean
	{
		let newStep = this.currentState + step;
		return newStep >= 0 && newStep <= this._states.length - 1;
	}

	moveState(step: number)
	{
		if (this.canMoveStep(step)) {
			this.touchOffset = undefined;
			this._activeClusters = [];
			this._current_state += step;
			this.restoreState();
			this.draw();
			this.stateChanged();
		}
	}

	private restoreState(clusters: { pos: IPoint, puzzles: IPlayerPuzzle[] }[] = this.state!.clusters || this.state)
	{

		if ((clusters[0].puzzles[0] as any).checksum !== undefined)
			for (let p of clusters)
				for (let pp of p.puzzles) {
					pp.puzzle_id = (pp as any).checksum;
					delete (pp as any).checksum;
				}

		this._clusters = [];
		for (let p of clusters) {
			let cluster = new PuzzleCluster(this, JSON.parse(JSON.stringify(p.puzzles)), {...p.pos});
			this._clusters.push(cluster);
		}
	}

	private createState()
	{
		this._clusters = this.map.puzzles.map(it => new PuzzleCluster(this, [{...it}], {x: 0, y: 0}));
		this.rearrangeClusters(this._clusters);
		this._startPlayTime = new Date().getTime();
		this.saveState();
		this.recenter();
	}

	rearrange()
	{
		this.rearrangeClusters(this._clusters.filter(it => it.puzzles.length === 1));
		this.recenter();
	}

	touchEnd(e: TouchEvent)
	{
		if (e.touches.length > 0) {
			this._moving = false;
			this.stateChanged();
		}
		this.mouseUp();
	}

	private getTouchesDistance(t0: Touch, t1: Touch)
	{
		let a = t0.clientX - t1.clientX;
		let b = t0.clientY - t1.clientY;
		return Math.sqrt(a * a + b * b);
	}

	touchStart(e: TouchEvent)
	{
		if (e.touches.length === 2) {
			this._activeClusters = [];
			this.collisedPuzzle = undefined;
			this.activeSelectionStart = undefined;
			this.touchOffset = undefined;
			this._touchesDistance = this.getTouchesDistance(e.touches[0], e.touches[1]);
			this._touchesScale = this._scale;
			this._moving = true;
			this.stateChanged();
		}

		this.mouseDown(e);
	}

	async make(id: number, percent: number = 1)
	{
		this._clusters = [];
		this.createState();

		let map = {} as IPuzzlePlayerMap;

		try {
			map = await (await fetch(`/paintings/${id}/p_map.json`)).json()
		} catch (e) {
			map = {...this.map}
		}

		let puzzles = [];
		let toRemove = [];

		for (let i = 0; i < Math.floor(this._clusters.length * percent); i++) {
			let cl = this._clusters[i];
			for (let p of cl.puzzles) {
				let originalIndex = map.puzzles.indexOf(map.puzzles.find(it => it.puzzle_id === p.puzzle_id)!);
				let row = Math.floor(originalIndex / map.cols);
				let col = originalIndex - row * map.cols;
				p.x = col;
				p.y = row;
				puzzles.push(p);
			}
			toRemove.push(cl)
		}
		for (let cl of toRemove) {
			this._clusters.splice(this._clusters.indexOf(cl), 1);
		}

		let c = new PuzzleCluster(this, puzzles, {x: 0, y: 0});
		c.pos = {x: Math.floor(-c.width / 2), y: Math.floor(-c.height / 2)}
		this._clusters.push(c);
		this.draw();
	}

	mouseDown(e: MouseEvent | TouchEvent)
	{
		if (!this.loaded)
			return;

		let c = this.getCursorPoint(e);

		if (this._moving) {
			this._old_canvas_position = this.canvas_position;
			this.movingStart = c;
			return;
		} else if (this._activeClusters.length === 0) {
			for (let i = this._clusters.length - 1; i >= 0; i--) {
				let p = this._clusters[i];

				let colised = p.getCollized(c);
				if (colised) {
					let sp = this._clusters.splice(i, 1);
					this._clusters.push(sp[0]);
					this._activeClusters = [this._clusters[this._clusters.length - 1]];
					this.collisedPuzzle = colised;
					this.saveClustersPositions();

					this.touchOffset = c;
					this.mouseDownTime = (new Date()).getTime();
					break;
				}
			}
		}

		if (this.activeCluster)
			this.draw();
		else if (this._activeClusters.length > 1) {
			this.touchOffset = c;
		} else {
			this.activeSelectionStart = c;
		}
	}

	mouseUp()
	{
		if (!this.loaded)
			return;

		if (!this._activeClusters.length && !this.activeSelectionStart && !this.movingStart)
			return;

		let state_changed: boolean | undefined = false;

		if (this.activeCluster && this.collisedPuzzle) {
			if ((new Date()).getTime() - this.mouseDownTime < 100) {
				if (this.activeCluster.puzzles.length > 1) {
					let c = this.activeCluster.removePuzzle(this.collisedPuzzle)!;
					this._clusters.push(c);

					if (this.activeCluster.puzzles.length < 200) {
						this._clusters.splice(this._clusters.indexOf(this.activeCluster), 1);
						let toMerge = this.activeCluster.split();
						this._clusters = this._clusters.concat(toMerge)
						this.mergeGroup(toMerge);
					}
				}
			} else if (this.autoMagnet) {
				let pos = this.activeCluster.pos;
				let r = {
					x: pos.x - this.map.puzzle_width * 0.2 + this.canvas_position.x,
					y: pos.y - this.map.puzzle_width * 0.2 + this.canvas_position.y,
					w: this.activeCluster.width + this.map.puzzle_width * 0.2 * 2,
					h: this.activeCluster.height + this.map.puzzle_width * 0.2 * 2
				}
				this.mergeByRect(r);

			}
			state_changed = true;
		} else if (!this._moving && this.activeSelectionStart) {
			let r = {
				x: Math.min(this.activeSelectionStart.x, this.mousePos.x),
				y: Math.min(this.activeSelectionStart.y, this.mousePos.y),
				w: Math.abs(this.activeSelectionStart.x - this.mousePos.x),
				h: Math.abs(this.activeSelectionStart.y - this.mousePos.y)
			}
			state_changed = this.mergeByRect(r)
		}

		if (state_changed || this.touchOffset)
			this.saveState();

		this.collisedPuzzle = undefined;
		if (this.activeCluster || this.touchOffset)
			this._activeClusters = [];

		this.touchOffset = undefined;
		this.activeSelectionStart = undefined;
		this.movingStart = undefined;

		this.draw();
	}

	private mergeByRect(r: { x: number, y: number, w: number, h: number }): boolean | undefined
	{
		let clustersToMerge: PuzzleCluster[] = [];

		for (let c of this._clusters) {
			if (c.getCollized({x: r.x, y: r.y}, r.w, r.h))
				clustersToMerge.push(c);
		}

		if (clustersToMerge.length > 1) {
			let toMergeOld = clustersToMerge.length
			this.mergeGroup(clustersToMerge);
			if (toMergeOld === clustersToMerge.length) {
				for (let p of clustersToMerge) {
					this._clusters.splice(this._clusters.indexOf(p), 1)
					this._clusters.push(p);
				}
				this._activeClusters = clustersToMerge;
				this.saveClustersPositions();
			} else
				return true;
		}

	}

	private saveClustersPositions()
	{
		for (let p of this._activeClusters)
			p.savePos();
	}

	recenter()
	{
		this.scale = 1;
		this._canvas_position = {x: 0, y: 0};


		let minX: number | undefined;
		let maxX: number | undefined;
		let minY: number | undefined;
		let maxY: number | undefined;

		for (let p of this._clusters) {
			if (!minX || p.pos.x < minX)
				minX = p.pos.x;
			if (!minY || p.pos.y < minY)
				minY = p.pos.y;

			let right = p.pos.x + p.width
			if (!maxX || right > maxX)
				maxX = right;

			let bottom = p.pos.y + p.height
			if (!maxY || bottom > maxY)
				maxY = bottom;
		}
		if (minX && maxX && minY && maxY) {
			let tw = maxX - minX;
			let th = maxY - minY;
			this.setScaleBasedOnContent(tw, th);
			this._canvas_position = {
				x: Math.floor(-this.canvas.width / 2 / this.scale - minX + (this.canvas.width - tw * this.scale) / this.scale / 2),
				y: Math.floor(-this.canvas.height / 2 / this.scale - minY + (this.canvas.height - th * this.scale) / this.scale / 2)
			}
			this.draw();
		}
	}

	get timeSpent(): number
	{
		return this._spentTime + (new Date().getTime() - this._startPlayTime)
	}

	saveState()
	{
		let state: IPuzzlePlayerState = {
			spentTime: this.timeSpent,
			clusters: this._clusters.map(it => {
				return {
					pos: {...it.pos},
					puzzles: JSON.parse(JSON.stringify(it.puzzles))
				}
			})
		};

		if (this.isStateValid(state)) {
			if (this.currentState !== this._states.length - 1) {
				this._states = this._states.slice(0, this.currentState + 1);
			}

			this._states.push(state);
			if (this._states.length > 20)
				this._states.shift();
			else
				this._current_state++;

			this._spentTime = this.timeSpent;
			this._startPlayTime = new Date().getTime();
			localData.setPaintingState(this.map.painting_id, state);
			localData.flush();
			this.stateChanged();
		} else {
			throw new Error("Invalid state!");
		}
	}

	isStateValid(state: IPuzzlePlayerState): boolean
	{
		let checksums: number[] = [];
		let clusters = state.clusters || state; //todo: temporary

		for (let c of clusters)
			for (let p of c.puzzles) {
				if (checksums.includes(p.puzzle_id))
					return false;

				checksums.push(p.puzzle_id)
			}
		return checksums.length === this.map.rows * this.map.cols;
	}

	private mergeGroup(clustersToMerge: PuzzleCluster[])
	{
		for (let c of clustersToMerge) {
			for (let j = 0; j < clustersToMerge.length; j++) {
				let c2 = clustersToMerge[j];
				if (c === c2)
					continue;

				let mergeWith = c.canMergeWith(c2);
				if (mergeWith) {
					clustersToMerge.splice(j, 1);
					this._clusters.splice(this._clusters.indexOf(c2), 1);
					c.merge(c2, mergeWith.mp, mergeWith.cp, mergeWith.xOffset, mergeWith.yOffset);
					j = 0;
				}
			}
		}
	}

	touchMove(e: TouchEvent)
	{
		if (!this.loaded)
			return;

		if (e.touches.length === 2) {
			let d = this.getTouchesDistance(e.touches[0], e.touches[1]);
			this.scale = this._touchesScale * (d / this._touchesDistance);
		}
		this.mouseMove(e);
	}

	mouseMove(e: MouseEvent | TouchEvent)
	{
		if (!this.loaded)
			return;

		this.mousePos = this.getCursorPoint(e);
		if (!this._activeClusters.length && !this.activeSelectionStart && !this.movingStart)
			return;

		if (this.touchOffset)
			for (let c of this._activeClusters) {
				c.pos = {
					x: Math.floor(this.mousePos.x + c.oldPos.x - this.touchOffset.x),
					y: Math.floor(this.mousePos.y + c.oldPos.y - this.touchOffset.y)
				};
			}
		if (this.movingStart && this._moving) {
			this._canvas_position = {
				x: Math.floor(this._old_canvas_position.x + Math.ceil(this.mousePos.x - this.movingStart.x)),
				y: Math.floor(this._old_canvas_position.y + Math.ceil(this.mousePos.y - this.movingStart.y))
			}
		}
		this.draw();
	}

	private getCursorPoint(e: MouseEvent | TouchEvent): IPoint
	{
		let touches = (e as TouchEvent).touches;
		let mouseX = (touches ? touches[touches.length - 1] : e as MouseEvent).clientX;
		let mouseY = (touches ? touches[touches.length - 1] : e as MouseEvent).clientY;

		return {
			x: ((mouseX - this.canvas.offsetLeft + this.canvas.parentElement!.scrollLeft) * window.devicePixelRatio - this.canvas.width / 2) / this.scale,
			y: ((mouseY - this.canvas.offsetTop + this.canvas.parentElement!.scrollTop) * window.devicePixelRatio - this.canvas.height / 2) / this.scale
		}
	}

	get scale(): number
	{
		return this._scale;
	}

	setScale(val: number, e: MouseEvent | TouchEvent)
	{
		if (!this.loaded)
			return;

		this.moving = true;
		this.mouseDown(e);
		this.scale = val;
		this.mouseMove(e);
		this.moving = false;
	}

	set scale(val: number)
	{
		this._scale = val;
		this.draw();
	}

	get moving(): boolean
	{
		return this._moving;
	}

	set moving(val: boolean)
	{
		this._moving = val;
		this.stateChanged();
	}

	private setBaseTransfom()
	{
		this._context.setTransform(1, 0, 0, 1, this._canvas.width / 2, this.canvas.height / 2);
	}

	get checksum(): string
	{
		if (this._clusters.length > 1)
			return "";

		return this._clusters[0].puzzles
			.sort((a, b) => (a.y * this.map.cols + a.x) - (b.y * this.map.cols + b.x))
			.map(it => it.puzzle_id).join("");
	}

	get loaded(): boolean
	{
		return this._puzzleImages.size === this.map.puzzles.length;
	}

	get loadingPercent(): number
	{
		return this._puzzleImages.size * 100 / this.map.puzzles.length;
	}
}
