import Styles from "./Loading.module.scss";
import H4 from "../typography/H4";
import { L } from "../../l10n/L10n";
import React from "react";

const Loading = (
	{
		loading,
		onlyText = false,
	}: {
		loading?: number,
		onlyText?: boolean,
	}) => {
	return (
		onlyText ?
			<div>Loading...</div>
			:
			<div className={Styles.loading}>
				<div className={Styles.loading_test}>
					<div className={Styles.box}/>
					<div className={Styles.box}/>
					<div className={Styles.box}/>
					<div className={Styles.box}/>
				</div>
				{loading !== undefined && loading >= 0 &&
                <H4>{`${L.shared.loading}${loading.toFixed(2)}%`}</H4>
				}
			</div>
	);
};

export default Loading