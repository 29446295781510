import React from 'react';
import Rhombus from "../../../icons/Rhombus";
import Styles from "./Heading.module.scss"
import classNames from "classnames";
import H4 from "../../../components/typography/H4";
import H2 from "../../../components/typography/H2";
import P1 from "../../../components/typography/P1";

export interface HeadingProps {
	title?: React.ReactNode,
	subTitle?: React.ReactNode,
	text?: React.ReactNode,
	align?: 'left' | 'center',
	className?: string
}

const Heading = ({title, subTitle, text, align = 'center', className}: HeadingProps) => {
	return (
		<div className={classNames(Styles.heading, className, align === 'left' && Styles.left)}>
			{title && <H4 className={Styles.title}><Rhombus/> {title}</H4>}
			{subTitle && <H2>{subTitle}</H2>}
			{text && <P1 className={Styles.text}>{text}</P1>}
		</div>
	);
};

export default Heading;
