import React from 'react';
import Container from "../components/Container";
import Styles from "./LandingFooter.module.scss";
import { L } from "../../../l10n/L10n";
import Socials from "../components/Socials";
import P1 from "../../../components/typography/P1";
// import ButtonLink from "../components/ButtonLink";

const LandingFooter = () => (
	<Container className={Styles.container} fluid hasBackground>
		<footer className={Styles.footer}>
			<div className={Styles.info}>
				<Socials/>
				{/*<ButtonLink path="#" hasIcon>{L.landing.whitepaper}</ButtonLink>*/}
			</div>
			<P1>{`©${new Date().getFullYear()}-${L.landing.copyright}`}</P1>
		</footer>
	</Container>
);

export default LandingFooter;
