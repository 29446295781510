import React, { useState } from "react";
import Button from "../../../shared/Button";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import Styles from "./Configuration.module.scss";
import { L } from "../../../../l10n/L10n";

const enum Formats {
	Number,
	Minutes,
	Datetime
}

const Configuration = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [params, setParams] = useState({
		periods_count: {value: parseInt(searchParams.get("periods_count") || "1"), format: Formats.Number, min: 1},
		time_per_period_sec: {
			value: parseInt(searchParams.get("time_per_period_sec") || "1"),
			format: Formats.Number,
			min: 1
		},
		reward_amount: {value: parseFloat(searchParams.get("reward_amount") || "1"), format: Formats.Number, min: 1},
		min_stake: {value: parseInt(searchParams.get("min_stake") || "0"), format: Formats.Number, min: 0},
		max_stake: {value: parseInt(searchParams.get("max_stake") || "0"), format: Formats.Number, min: 0},
		unstake_time_sec: {
			value: parseInt(searchParams.get("unstake_time_sec") || "0"),
			format: Formats.Number,
			min: 0
		},
		start_at: {
			value: parseInt(searchParams.get("start_at") || ((new Date()).getTime() / 1000 + 120).toString()),
			format: Formats.Number,
			min: 1
		}
	});

	const setParam = (key: string, val: number) => {
		let p = {...params};
		if (p[key as keyof typeof params].format === Formats.Minutes)
			val *= 60;

		p[key as keyof typeof params].value = val;
		setParams(p);
	}

	const saveParams = () => {
		for (let k of Object.keys(params))
			if (params[k as keyof typeof params].value < params[k as keyof typeof params].min)
				return alert("Params not valid")

		for (let k of Object.keys(params))
			searchParams.set(k, params[k as keyof typeof params].value.toString());

		setSearchParams(searchParams);
		nextStep();
	}

	const nextStep = () => {
		searchParams.set("step", (parseInt(searchParams.get("step") || "0") + 1).toString());
		setSearchParams(searchParams);
	}

	return <div className={Styles.container}>
		<h1>Pool global parameters</h1>
		<hr/>
		{
			Object.keys(params).map(it => {
				return <label>{L.admin.pools.manage_steps.configuration[it.toString() as "periods_count"]}
					<input onChange={(e) => setParam(it, parseFloat(e.target.value))}
						   defaultValue={params[it as keyof typeof params].value}/>
				</label>
			})
		}
		<Button text={"continue"} onClick={() => saveParams()}/>
	</div>
});

export default Configuration;