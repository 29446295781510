import React, { useEffect, useState } from "react";
import { model, world } from "../../../App";
import Button from "../../shared/Button";
import Styles from "./TokenSelectorView.module.scss";
import { EosAsset } from "@pro/common/eos";
import { observer } from "mobx-react";

const TokenSelectorView = observer(({
										acc, sym, selected
									}: { acc: string, sym: string, selected: (acc: string, sym: string) => void }) => {

	const [account, setAccount] = useState(acc);
	const [symbol, setSymbol] = useState(sym);

	const [accInput, setAccInput] = useState<string>(acc);
	let needLoad = account !== accInput && accInput !== "";

	const load = async () => {
		if (account !== "") {
			await world.updateTokenStat(account);
			await world.updateAccount(account);
		}
	}

	useEffect(() => {
		load().catch()
	});

	useEffect(() => {
		selected(account, symbol);
	}, [])

	const clean = () => {
		setAccount("");
		setSymbol("");
	}

	const loadAccount = async () => {
		clean();
		setAccount(accInput);
		await load();
	}

	const selectAccountToken = async (code: string) => {
		setAccount(accInput);
		setSymbol(code);
		selected(accInput, code);
	}

	return <div>
		<label>
			Select contract
			<input placeholder={"contract account"} onChange={e => {
				selected("", "");
				setAccInput(e.target.value)
			}}
				   defaultValue={account}/>
		</label>

		{needLoad && <Button text={"Load"} onClick={() => loadAccount()}/>}

		{!needLoad && model.data.accounts.get(accInput) && (model.data.accounts.get(accInput) && !(model.data.accounts.get(accInput) instanceof Error)) &&
            <>
				{
					model.data.tokens.get(accInput) && model.data.tokens.get(accInput)!.length ?
						<>
							<h2>Select token</h2>
							<div className={Styles.available_symbols}>
								{model.data.tokens.get(accInput)!.map((it) => {
									const asset_sym = EosAsset.parse(it.max_supply).symbol.code.toString();
									return <div className={asset_sym === symbol ? Styles.active : ""}
												onClick={() => selectAccountToken(asset_sym)}>
										{asset_sym}
									</div>
								})}
							</div>
						</> : <div>There are no valid tokens created on selected accounts</div>
				}
            </>
		}

		{!needLoad && model.data.accounts.get(accInput) && model.data.accounts.get(accInput) instanceof Error &&
            <div>Error {(model.data.accounts.get(accInput) as Error).message}</div>}
		{account !== "" && !model.data.accounts.get(account) && <div>Loading...</div>}

	</div>
});

export default TokenSelectorView;