import React from 'react';

const UserIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M16.3 14.2C17.7173 14.2001 19.0799 14.7473 20.1036 15.7275C21.1272 16.7078 21.733 18.0453 21.7945 19.4613L21.8 19.7V20.8C21.8002 21.355 21.5906 21.8896 21.2132 22.2966C20.8358 22.7036 20.3185 22.9529 19.765 22.9945L19.6 23H4.2C3.64497 23.0002 3.11038 22.7906 2.7034 22.4132C2.29642 22.0358 2.04713 21.5185 2.0055 20.965L2 20.8V19.7C2.00008 18.2827 2.5473 16.9201 3.52753 15.8964C4.50776 14.8728 5.84533 14.267 7.2613 14.2055L7.5 14.2H16.3ZM11.9 1C13.3587 1 14.7576 1.57946 15.7891 2.61091C16.8205 3.64236 17.4 5.04131 17.4 6.5C17.4 7.95869 16.8205 9.35764 15.7891 10.3891C14.7576 11.4205 13.3587 12 11.9 12C10.4413 12 9.04236 11.4205 8.01091 10.3891C6.97946 9.35764 6.4 7.95869 6.4 6.5C6.4 5.04131 6.97946 3.64236 8.01091 2.61091C9.04236 1.57946 10.4413 1 11.9 1Z"
			fill={color}/>
	</svg>
);

export default UserIcon;
