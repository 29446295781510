import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import Styles from "./Ticket.module.scss";
import ticketImg from "../../../images/ticket_Img.png";
import { L } from "../../../l10n/L10n";

const Ticket = () => (
	<Container className={Styles.container} id="ticket" hasBackground>
		<Heading
			title={L.landing.sections.ticket.title}
			subTitle={L.landing.sections.ticket.subtitle}
			text={L.landing.sections.ticket.text}
			align="left"
		/>
		<div>
			<img src={ticketImg} alt="ticket"/>
		</div>
	</Container>
)

export default Ticket;
