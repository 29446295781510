import React from 'react';

const ArrangeIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<g clipPath="url(#clip0_2166_14949)">
			<path
				d="M18.5 4C17.9477 4 17.5 4.44772 17.5 5V9C17.5 9.55228 17.9477 10 18.5 10H24C24.5523 10 25 9.55228 25 9V5C25 4.44772 24.5523 4 24 4H18.5ZM5 5.5C4.44772 5.5 4 5.94772 4 6.5V10.5C4 11.0523 4.44772 11.5 5 11.5H10.5C11.0523 11.5 11.5 11.0523 11.5 10.5V6.5C11.5 5.94772 11.0523 5.5 10.5 5.5H5ZM21.5 11.5C20.9477 11.5 20.5 11.9477 20.5 12.5V16.5C20.5 17.0523 20.9477 17.5 21.5 17.5H27C27.5523 17.5 28 17.0523 28 16.5V12.5C28 11.9477 27.5523 11.5 27 11.5H21.5ZM12.5 14.5C11.9477 14.5 11.5 14.9477 11.5 15.5V19.5C11.5 20.0523 11.9477 20.5 12.5 20.5H18C18.5523 20.5 19 20.0523 19 19.5V15.5C19 14.9477 18.5523 14.5 18 14.5H12.5ZM5 22C4.44772 22 4 22.4477 4 23V27C4 27.5523 4.44772 28 5 28H10.5C11.0523 28 11.5 27.5523 11.5 27V23C11.5 22.4477 11.0523 22 10.5 22H5ZM14 22C13.4477 22 13 22.4477 13 23V27C13 27.5523 13.4477 28 14 28H19.5C20.0523 28 20.5 27.5523 20.5 27V23C20.5 22.4477 20.0523 22 19.5 22H14Z"
				fill={color}/>
		</g>
		<defs>
			<clipPath id="clip0_2166_14949">
				<rect width="24" height="24" fill="white" transform="translate(4 4)"/>
			</clipPath>
		</defs>
	</svg>
);

export default ArrangeIcon;
