import React from 'react';

const MenuIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M0.666992 2.64516C0.666992 2.28885 0.956941 2 1.31461 2H22.686C23.0437 2 23.3337 2.28885 23.3337 2.64516C23.3337 3.00147 23.0437 3.29032 22.686 3.29032H1.31461C0.956941 3.29032 0.666992 3.00147 0.666992 2.64516Z"
			fill={color}/>
		<path
			d="M0.666992 11.6774C0.666992 11.3211 0.956941 11.0323 1.31461 11.0323H22.686C23.0437 11.0323 23.3337 11.3211 23.3337 11.6774C23.3337 12.0337 23.0437 12.3226 22.686 12.3226H1.31461C0.956941 12.3226 0.666992 12.0337 0.666992 11.6774Z"
			fill={color}/>
		<path
			d="M0.666992 21.3548C0.666992 20.9985 0.956941 20.7097 1.31461 20.7097H22.686C23.0437 20.7097 23.3337 20.9985 23.3337 21.3548C23.3337 21.7112 23.0437 22 22.686 22H1.31461C0.956941 22 0.666992 21.7112 0.666992 21.3548Z"
			fill={color}/>
	</svg>
);

export default MenuIcon;

