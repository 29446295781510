export interface IPoint {
	x: number,
	y: number
}

export interface IPuzzle {
	puzzle_id: number,
	pos: IPoint
	topBorder: IPoint[],
	rightBorder: IPoint[],
	bottomBorder: IPoint[],
	leftBorder: IPoint[],
}

export interface IPuzzlesMap {
	rows: number,
	cols: number,
	puzzles: IPuzzle[],
	puzzle_width: number,
	puzzle_height: number
}

export interface IPlayerPuzzle {
	x: number,
	y: number,
	puzzle_id: number,
	leftOffset: number,
	topOffset: number,
}

export interface IPuzzlePlayerMap {
	rows: number,
	cols: number,
	puzzles: IPlayerPuzzle[],
	puzzle_width: number,
	puzzle_height: number,
	painting_id: number
}

export enum PuzzleType {
	RECT,
	TRIANGLE,
	ARC
}

export interface IPuzzlePlayerState {
	spentTime: number,
	clusters:
		{
			pos: IPoint,
			puzzles: IPlayerPuzzle[]
		}[]
}
export enum ImageSize {
	large,
	medium,
	small
}
