import React, { ReactNode } from 'react';
import ButtonLink from "../../pages/landing/components/ButtonLink";
import Styles from "./TopNavigation.module.scss"
import ArrowLeftSm from "../../icons/ArrowLeftSm";
import H4 from "../typography/H4";
import { L } from "../../l10n/L10n";

interface TopNavigationProps extends React.HTMLAttributes<HTMLElement> {
	title: string,
	withBalance?: ReactNode,
	backBtnPath?: string
}

const TopNavigation = ({title, withBalance, backBtnPath, ...htmlProps}: TopNavigationProps) => {
	return (
		<div className={Styles.nav} {...htmlProps}>
			{
				backBtnPath && (
					<div className={Styles.button}>
						<ButtonLink path={backBtnPath} icon={<ArrowLeftSm/>} isReverse>{L.button.back}</ButtonLink>
					</div>
				)
			}
			<H4>{title}</H4>
			{withBalance &&
            <div className={Styles.balance}>
				{withBalance}
            </div>
			}
		</div>
	);
};

export default TopNavigation;
