import React from 'react';
import Container from "../landing/components/Container";
import ExpoSlider from "./ExpoSlider";
import { model } from "../../App";
import { observer } from "mobx-react";
import Loading from "../../components/shared/Loading";

const ExposPage = observer(() => {
	if (!model.appDataLoaded)
		return <Loading/>

	return (
		<Container>
			<ExpoSlider/>
		</Container>
	)
})

export default ExposPage;
