import React from 'react';
import Styles from "./H4.module.scss";
import classNames from "classnames";

export const H4 = ({children, className}: React.HTMLAttributes<HTMLElement>) => (
	<h4 className={classNames(Styles.h4, className)}>
		{children}
	</h4>
);

export default H4;
