import { PopUpBase } from "../../components/shared/PopUpBase";
import { model } from "../../App";
import { NeftyClaimAssetsModel } from "../../models/NeftyClaimAssetsModel";
import { makePaintingSrc } from "../../helpers/image_helper";
import { IPaintingConf } from "@pro/common/contracts/expo";
import { UnpackPuzzleCard } from "../../components/shared/Card/UnpackPuzzleCard";
import React from "react";
import Button from "../landing/components/Button";
import Styles from "./UnpackingPaintingView.module.scss";
import { L } from "../../l10n/L10n";

export const UnpackingPuzzleView = (
	{
		neftyClaimAssets,
		onClaimClick,
		btnReady,
	}: {
		neftyClaimAssets: NeftyClaimAssetsModel,
		onClaimClick: () => void,
		btnReady: boolean,
	}
) => {
	const paintingIds = new Set(neftyClaimAssets.assets.map(it => it.paintingId));
	const paintings: IPaintingConf[] = [];
	for (let paintingId of paintingIds) {
		const painting = model.config?.paintings_config.find(pc => pc.key === paintingId);
		if (painting)
			paintings.push(painting);
	}

	return (
		<PopUpBase
			title={L.unpack.tittle_puzzles}
			onShowPopUp={() => onClaimClick()}
			showCloseButton={false}
		>
			<div className={Styles.list}>
				{paintings.map(painting => (
					<UnpackPuzzleCard
						key={painting.value.name}
						img={makePaintingSrc(painting.key)}
						name={painting.value.name}
						author={painting.value.author}
						amount={neftyClaimAssets.assets.reduce((acc, it) => {
							return acc + (it.paintingId === painting.key ? 1 : 0)
						}, 0)}
					/>
				))}
			</div>
			<Button
				onClick={onClaimClick}
				disabled={!btnReady}
			>
				{L.button.claim}
			</Button>
		</PopUpBase>
	)
};