import React, { useEffect, useState } from "react";
import Button from "../../../shared/Button";
import { model, world } from "../../../../App";
import { observer } from "mobx-react";
import { L } from "../../../../l10n/L10n";
import { useSearchParams } from "react-router-dom";
import { IPoolAsset, ITokenStat } from "@pro/common/contracts/staking";
import SchemasSelectorView from "../../shared/SchemasSelectorView";
import Styles from "../../shared/SchemasFilterView.module.scss";
import { EosAsset, EosSymbol } from "@pro/common/eos";

const AcceptNft = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedCollection, setSelectedCollection] = useState("");
	const [collectionInput, setCollectionInput] = useState("");
	const [loading, setLoading] = useState(false);
	const [acceptedCollections, setAcceptedCollections] = useState<IPoolAsset[]>(JSON.parse(searchParams.get("accept_asset_conf") || "[]"));
	const [nftAccepted, setNftAccepted] = useState(searchParams.get("accept_contract") === null || Object.keys(acceptedCollections).length);

	const accept_token_stat: ITokenStat | undefined = (model.data.tokens.get(searchParams.get("accept_contract") || "") || [])
		.find(it => EosAsset.parse(it.max_supply).symbol.code === searchParams.get("accept_symbol"));

	const symbol: EosSymbol | undefined = accept_token_stat ? EosAsset.parse(accept_token_stat.max_supply).symbol : undefined;

	let selectedTemplates = model.atomicTemplates.getAllByCollectionName(selectedCollection)
		.filter(it => !acceptedCollections.filter(it => it.collection === selectedCollection).map(it => it.schema_name).includes(it.schemaName));

	useEffect(() => {
		for (let k of acceptedCollections.map(it => it.collection)) {
			world.loadCollection(k).catch();
		}
	})

	const loadCollection = async () => {
		setLoading(true);
		await world.loadCollection(collectionInput);
		setSelectedCollection(collectionInput);
		setLoading(false);
	}

	const nextStep = () => {
		searchParams.set("step", (parseInt(searchParams.get("step") || "0") + 1).toString());
		setSearchParams(searchParams);
	}

	const newNftSelected = (res: IPoolAsset[]) => {
		let params = acceptedCollections.slice().concat(res);
		searchParams.set("accept_asset_conf", JSON.stringify(params));
		setAcceptedCollections(params);
		setSearchParams(searchParams);

		setSelectedCollection("");
		setCollectionInput("");
	}

	if (!nftAccepted)
		return <div>
			<h1>{L.admin.pools.manage_steps.accept_nft.header}</h1>
			<Button text={"Yes"} onClick={() => setNftAccepted(true)}/>
			<Button text={"No"} onClick={() => nextStep()}/>
		</div>

	return <div>
		{
			(!selectedCollection || !model.atomicTemplates.collectionHasAssets(selectedCollection)) && <>
                <h1>Adding collection to staking</h1>
                <hr/>
                <label>
                    Type collection name
                    <input placeholder={"Accept contract"} onChange={(e) => {
						setCollectionInput(e.target.value)
					}} value={collectionInput || ""}/>
                </label>
                <br/>
                <Button disabled={collectionInput === ""} text={"add"} onClick={() => loadCollection()}/>
            </>
		}
		{loading && <h3>Loading collection...</h3>}
		{!loading && selectedCollection && !model.atomicTemplates.collectionHasAssets(selectedCollection) &&
            <h3>Collection is invalid</h3>}
		{
			(!selectedCollection || selectedCollection && !model.atomicTemplates.collectionHasAssets(selectedCollection)) && acceptedCollections.length > 0 &&
            <div>
                <hr/>
                <h2>Added collections</h2>
				{acceptedCollections.map((c, i) => {
					return <fieldset className={Styles.schema_box}>
						<legend>{c.collection}{"=>"}{c.schema_name}</legend>
						<div>
							{c.formats.map(it => {
								return <div className={Styles.format_value_power}>
									<div>
										{it.format_value === "" ?
											<span>All future <b>{c.format_key}</b></span> : it.format_value} = {it.power} {symbol ? `(equal to ${new EosAsset(it.power, symbol).toString()})` : ""}
									</div>
								</div>
							})}
						</div>
						<Button text={"delete"} onClick={() => {
							let c = acceptedCollections.slice();
							c.splice(i, 1);
							setAcceptedCollections(c);
						}}/>
					</fieldset>
				})}
            </div>
		}
		{
			!loading && selectedCollection !== "" && model.atomicTemplates.collectionHasAssets(selectedCollection) && <>
                <h1>Adding schemas from collection {selectedCollection}</h1>
                <hr/>
                <SchemasSelectorView symbol={symbol} collection={selectedCollection}
                                     templates={selectedTemplates}
                                     onSelected={(result) => newNftSelected(result)}/>
            </>
		}
		<br/>
		{(selectedCollection === "" || !model.atomicTemplates.collectionHasAssets(selectedCollection)) &&
			Object.keys(acceptedCollections).length > 0 &&
            <Button text={"continue"} onClick={() => {
				nextStep();
			}}/>
		}

	</div>
});

export default AcceptNft;