import { EosActionBase } from "../../EosActionBase";
import { eos, world } from "../../../App";

export class OpenPoolPeriodsAction extends EosActionBase {
	constructor(private pool_id: number)
	{
		super("openperiods");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("openperiods", eos.userAuth, {pool_id: this.pool_id, limit: 1000});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updatePoolPeriods(this.pool_id);
	}
}