import React, { useEffect, useState } from "react";
import Styles from "./ManagePoolHeader.module.scss";
import { useSearchParams } from "react-router-dom";
import { L } from "../../../l10n/L10n";

const ManagePoolHeader = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const step = parseInt(searchParams.get("step") || "0");
	const [maxStep, setMaxStep] = useState(parseInt(searchParams.get("max_step") || step.toString()))

	useEffect(() => {
		if (!searchParams.get("step") || step > maxStep)
			setMaxStep(step);
	}, [step])
	return <div className={Styles.container}>
		{[0, 1, 2, 3, 4].map((it) => {
			return <div key={`header_${it}`}
						className={step === it ? Styles.active : it > maxStep ? Styles.inactive : ""} onClick={() => {
				if (it <= maxStep) {
					searchParams.set("step", it.toString());
					setSearchParams(searchParams);
				}
			}}>
				{it} - {L.admin.pools.manage_steps.headers[it.toString() as "0"]}
			</div>
		})}
	</div>
};

export default ManagePoolHeader;