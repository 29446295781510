import { TUint64 } from "@pro/common/contracts/common_types";
import { action, makeObservable, observable } from "mobx";
import { NeftyClaimAssetsModel } from "./NeftyClaimAssetsModel";

export class NeftyClaimAssetsTable {
	items = new Map<TUint64, NeftyClaimAssetsModel>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItems: action,
		})
	}

	updateItems(items: NeftyClaimAssetsModel[])
	{
		for (const item of items)
			this.items.set(item.claim_id, item);
	}

	removeItems(claimIds: TUint64[])
	{
		for (const claimId of claimIds)
			this.items.delete(claimId);
	}

	getFirstItem(): NeftyClaimAssetsModel | undefined
	{
		return this.items.values().next().value;
	}

	query(predicate: (it: NeftyClaimAssetsModel) => boolean): NeftyClaimAssetsModel[]
	{
		let result = [];
		for (let item of this.items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll()
	{
		return this.query(() => true);
	}
}