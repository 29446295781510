import React from 'react';
import Styles from "./CollectorPrize.module.scss";
import Heading from "../components/Heading";
import { L } from "../../../l10n/L10n";
import imgCollectorNFT from "../../../images/img_collectorNFT.png";
import Container from "../components/Container";

const CollectorPrize = () => {
	return (
		<Container className={Styles.container} id="#collectorNft" hasBackground>
			<div>
				<Heading
					title={L.landing.sections.collectorNft.title}
					subTitle={L.landing.sections.collectorNft.subtitle}
					text={L.landing.sections.collectorNft.text}
					align="left"
				/>
			</div>
			<div>
				<img src={imgCollectorNFT} alt="collector_nft"/>
			</div>
		</Container>
	);
};

export default CollectorPrize;
