import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";

export class RemPoolAction extends EosActionBase {
	constructor(private pool_id: number)
	{
		super("rempool");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("rempool", eos.userAuth, {pool_id: this.pool_id, limit: 2000});
	}

	async onSelfUpdate(): Promise<void>
	{
		let pool = await eos.stContract.findPool(this.pool_id);
		if (pool) {
			await world.updatePool(this.pool_id);
			await world.updatePoolPeriods(this.pool_id)
		} else
			await model.pools.removeItems([this.pool_id]);
	}
}