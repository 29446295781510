import React from 'react';
import { Link } from "react-router-dom";
import Styles from "./ButtonLink.module.scss"
import classNames from "classnames";


export interface Base extends React.HTMLAttributes<HTMLElement> {
	icon?: React.ReactNode,
	size?: 'sm' | 'md' | 'lg',
	isReverse?: boolean,
	target?: string
}

export interface Internal extends Base {
	path: string,
	href?: string,
}

export interface External extends Base {
	href: string,
	path?: string,
}

type ButtonLinkProps = Internal | External

const ButtonLink = ({path, href, children, icon, size = 'md', target, isReverse, className}: ButtonLinkProps) => {

	const sizeClass = React.useMemo(() => {
		switch (size) {
			case 'sm':
				return Styles.small;
			case 'md':
				return Styles.medium;
		}
	}, [size])

	if (path) {
		return (
			<Link to={path}
				  className={classNames(Styles.buttonlink, className, sizeClass, isReverse && Styles.reverse)}>
				{children} {icon}
			</Link>
		)
	}

	return (
		<a href={href} target={target}
		   className={classNames(Styles.buttonlink, className, sizeClass, isReverse && Styles.reverse)}>
			{children} {icon}
		</a>
	);
};

export default ButtonLink;
