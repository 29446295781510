import React from 'react';

const AssetsIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M23.495 3H8.50504C7.12231 3 6 3.95178 6 5.12441V16.8756C6 18.0482 7.12231 19 8.50504 19H23.495C24.8777 19 26 18.0482 26 16.8756V5.12441C26 3.95178 24.8777 3 23.495 3ZM24.1662 14.6143C24.1662 14.7045 24.1552 14.7885 24.1368 14.8725L21.7785 11.8211C21.3897 11.3204 20.5242 11.3204 20.1354 11.8211L17.546 15.1711L15.4884 12.5054L14.2377 10.8911L13.1594 9.49455C12.7706 8.99376 11.905 8.99376 11.5162 9.49455L7.83384 14.2566V5.83671C7.83384 5.12754 8.50869 4.55523 9.34492 4.55523H22.655C23.4913 4.55523 24.1661 5.12754 24.1661 5.83671L24.1662 14.6143Z"
			fill={color}/>
		<path
			d="M17.5 9C18.3284 9 19 8.32843 19 7.5C19 6.67157 18.3284 6 17.5 6C16.6716 6 16 6.67157 16 7.5C16 8.32843 16.6716 9 17.5 9Z"
			fill={color}/>
		<path
			d="M23.3999 29H25.5017C25.582 28.9996 25.661 28.9797 25.732 28.9419C25.8031 28.9042 25.8642 28.8497 25.9101 28.7831C25.956 28.7164 25.9854 28.6396 25.9958 28.5591C26.0062 28.4786 25.9973 28.3967 25.9699 28.3204L23.5577 21.7712H24.4686C24.7841 21.7712 25.0386 21.5137 25.0386 21.1945V20H6.96227V21.1945C6.96227 21.5137 7.21673 21.7712 7.53225 21.7712H8.4381L6.03098 28.3204C6.00307 28.3967 5.99383 28.4787 6.00403 28.5594C6.01423 28.6401 6.04358 28.7172 6.08957 28.7839C6.13556 28.8507 6.19683 28.9052 6.26816 28.9428C6.33948 28.9805 6.41874 29.0001 6.49917 29H8.60095C8.8096 29 8.99281 28.8661 9.06914 28.6705L11.6035 21.7712H14.4279V28.4954C14.4279 28.7735 14.6518 29 14.9266 29H17.0742C17.349 29 17.573 28.7735 17.573 28.4954V21.7712H20.3923L22.9317 28.6705C23.003 28.8661 23.1913 29 23.3999 29Z"
			fill={color}/>
	</svg>
);

export default AssetsIcon;
