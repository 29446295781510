import { EosActionBase } from "../../EosActionBase";
import { app, eos, model, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";
import { TUint64 } from "@pro/common/contracts/common_types";

export class StakeAssetsAction extends EosActionBase {
	constructor(private pool: StakingPoolModel, private asset_ids: TUint64[])
	{
		super("stake");
	}

	async onExecute(): Promise<void>
	{

		let transferAction = {
			account: app.chainConf.STAKING_ACCOUNT,
			name: "openspots",
			authorization: [eos.userAuth],
			data: {
				owner: model.userName,
				asset_ids: this.asset_ids,
			}
		}

		let stake_action = eos.aaContract.makeAction("transfer", eos.userAuth, {
			from: model.userName,
			to: eos.stContract.account,
			memo: `stake:${this.pool.poolId}`,
			asset_ids: this.asset_ids
		})

		await eos._eosApi.transact([transferAction, stake_action].flat())
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateAllStakedAssets(model.userName);
		model.atomicAssets.removeItems(this.asset_ids);
		await world.updatePool(this.pool.poolId);
		await world.updateUserPool(model.userName, this.pool.poolId);
	}
}