import React from 'react';
import Styles from "./Card.module.scss"
import classNames from "classnames";

const CardStack: React.FC<React.HTMLAttributes<HTMLElement>> = props => (
	<div className={classNames(Styles.stack, props.className)}>
		{props.children}
	</div>
)

export default CardStack;
