import {EosActionBase} from "../EosActionBase";
import {eos, model, world} from "../../App";

export class StartPaintingAction extends EosActionBase {
    constructor(private identifier: number) {
        super("start");
    }

    async onExecute(): Promise<void> {
        await eos.expoContract.action("start", eos.userAuth, {
            owner: model.userName,
            identifier: this.identifier,
        });
    }

    async onSelfUpdate(): Promise<void> {
        await world.updateUserPaintings(model.userName);
    }
}