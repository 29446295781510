import { EosActionBase } from "../../EosActionBase";
import { eos, world } from "../../../App";

export class OpenUserPoolAction extends EosActionBase {
	constructor(private pool_id: number)
	{
		super("openpool");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("openuserpool", eos.userAuth, {
			pool_id: this.pool_id,
			owner: eos.user
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updatePool(this.pool_id);
		await world.updateUserPool(eos.user, this.pool_id);
	}
}