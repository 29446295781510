import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { model, notificationService, world } from "../../App";
import TopNavigation from "../../components/shared/TopNavigation";
import PackCard from "../../components/shared/Card/PackCard";
import CardStack from "../../components/shared/Card/CardStack";
import Placeholder from "../../components/shared/Placeholder";
import { L } from "../../l10n/L10n";
import PageContainer from "../../components/shared/PageContainer";
import { UnpackingView } from "./UnpackingView";
import Loading from "../../components/shared/Loading";
import P3 from "../../components/typography/P3";
import P2 from "../../components/typography/P2";

const PacksPage = observer(() => {
	const rows: JSX.Element[] = model.atomicAssets.packs.map(it =>
		<PackCard
			key={it.assetId}
			asset={it}
		/>
	);

	const neftyClaimAssets = model.neftyClaimAssets.getFirstItem();

	useEffect(() => {
		if (model.logined)
			loadUserData().catch(e => notificationService.showError(e?.message ?? e))
	}, [model.logined]);

	async function loadUserData()
	{
		await world.updateNeftyClaimAssets();
	}

	if (!model.appDataLoaded)
		return <Loading/>

	return (
		<PageContainer>
			<TopNavigation title={L.page.packs}
						   withBalance={<><P3>{L.shared.amount}</P3><P2>{rows.length.toString()}</P2></>}/>
			{neftyClaimAssets !== undefined &&
            <UnpackingView neftyClaimAssets={neftyClaimAssets}/>
			}
			{rows.length > 0 ? (
				<CardStack>{rows}</CardStack>
			) : (
				<Placeholder
					title={L.placeholder.packs.title}
					description={L.placeholder.packs.subtitle}
				/>
			)}
		</PageContainer>

	)
});

export default PacksPage;