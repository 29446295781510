import React from 'react';
import Styles from "./Tooltip.module.scss";
import P2 from "../typography/P2";
import classNames from "classnames";

interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
	text: string
}

const Tooltip = ({children, text, className, ...htmlProps}: TooltipProps) => {
	return (
		<span className={classNames(Styles.container, className)}  {...htmlProps}>
			<span>{children}</span>
			{
				text && (
					<div className={Styles.tooltip}>
						<div className={Styles.content}><P2>{text}</P2></div>
					</div>
				)
			}
		</span>
	);
};

export default Tooltip;
