import React from "react";
import InternalLink from "../shared/InternalLink";
import { PagePath } from "../../helpers/PageHelper";
import AdminLayout from "../shared/AdminLayout";

const AdminPage = () => {
	const menu = <div>
		<InternalLink href={PagePath.NEW_POOL}/>
		<InternalLink href={PagePath.ADMIN_POOLS}/>
	</div>

	return <AdminLayout content={menu}/>
}

export default AdminPage;