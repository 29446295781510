import React from 'react';
import Container from "../components/Container";
import Styles from "./AreYouReady.module.scss";
import Heading from "../components/Heading";
import footerImg from "../../../images/img_lastBlock.png";
import Button, { EButtonType } from "../components/Button";
import Triangle from "../../../icons/Triangle";
import { L } from "../../../l10n/L10n";
import { PagePath } from "../../../helpers/PageHelper";

const AreYouReady = () => {
	return (
		<Container className={Styles.container} hasBackground>
			<div className={Styles.heading}>
				<Heading
					text={L.landing.sections.areYouReady.text}
					subTitle={<>{L.landing.sections.areYouReady.subtitle} <Triangle/></>}
					align="left"
				/>
				<Button
					type={EButtonType.PRIMARY}
					path={PagePath.EXPOS}
				>
					{L.landing.buttons.play_game}
				</Button>
			</div>
			<div className={Styles.img}>
				<img src={footerImg} alt="footer"/>
			</div>
		</Container>
	)
}

export default AreYouReady;
