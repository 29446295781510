import React from 'react';
import Styles from "./H3.module.scss";
import classNames from "classnames";

export const H3 = ({children, className}: React.HTMLAttributes<HTMLElement>) => (
	<h3 className={classNames(Styles.h3, className)}>
		{children}
	</h3>
);

export default H3;
