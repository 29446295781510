import React from 'react';

const Rhombus = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
		<path
			d="M13.6858 0.544339C14.4116 -0.181447 15.5884 -0.181446 16.3141 0.544339L29.4557 13.6858C30.1814 14.4116 30.1814 15.5884 29.4557 16.3141L16.3141 29.4557C15.5884 30.1814 14.4116 30.1814 13.6858 29.4557L0.544339 16.3141C-0.181447 15.5884 -0.181446 14.4116 0.544339 13.6858L13.6858 0.544339Z"
			fill={color}/>
	</svg>
);

export default Rhombus;
