import { EosAsset, EosSymbol, IEosAccount } from "@pro/common/eos";
import { action, makeObservable, observable } from "mobx";
import { AtomicSchemaTable } from "./AtomicSchemaTable";
import { AtomicTemplateTable } from "./AtomicTemplateTable";
import { AtomicAssetTable } from "./AtomicAssetTable";
import { StakingPoolsTable } from "./StakingPoolsTable";
import { TUint64 } from "@pro/common/contracts/common_types";
import { ITokenStat } from "@pro/common/contracts/staking";
import { StakingAssetsTable } from "./StakingAssetsTable";
import { PoolAssetsTable } from "./PoolAssetsTable";
import { PoolPeriodsTable } from "./PoolPeriodsTable";
import { AtomicTemplateModel } from "./AtomicTemplateModel";
import { app, model } from "../App";
import { PoolAssetModel } from "./PoolAssetModel";
import { UserPoolsTable } from "./UserPoolsTable";
import { UserUnstakesTable } from "./UserUnstakesTable";
import { PoolUsersTable } from "./PoolUsersTable";
import { PaintingsTable } from "./PaintingsTable";
import { LotteriesTable } from "./LotteriesTable";
import { IConfig } from "@pro/common/contracts/expo";
import { Logger } from "@pro/common/utils/Logger";
import { NeftyClaimAssetsTable } from "./NeftyClaimAssetsTable";
import { HPaintingsTable } from "./HPaintingsTable";

const tag = "[app_model]";

export class AppModel {
	logined = false;
	userName = "";
	balances = new Map<string, EosAsset>();
	config?: IConfig;
	appDataLoaded = false;

	readonly atomicAssets = new AtomicAssetTable();
	readonly atomicTemplates = new AtomicTemplateTable();
	readonly atomicSchemas = new AtomicSchemaTable();

	readonly neftyClaimAssets = new NeftyClaimAssetsTable();

	readonly pools = new StakingPoolsTable();
	readonly poolUsers = new PoolUsersTable();
	readonly poolAssets = new PoolAssetsTable();
	readonly poolPeriods = new PoolPeriodsTable();

	readonly userPools = new UserPoolsTable();
	readonly stakedAssets = new StakingAssetsTable();
	readonly userUnstakes = new UserUnstakesTable();

	readonly paintings = new PaintingsTable();
	readonly lottery = new LotteriesTable();

	readonly hPaintings = new HPaintingsTable();


	readonly data = {
		accounts: new Map<string, IEosAccount | Error>(),
		templates: new Map<number, { [p: string]: TUint64 }>(),
		tokens: new Map<string, ITokenStat[]>()
	};

	constructor()
	{
		makeObservable(this, {
			logined: observable,
			userName: observable,
			balances: observable,
			config: observable,
			appDataLoaded: observable,
			data: observable,
			login: action,
			logout: action,
			setBalance: action,
			setConfig: action,
			setAppDataLoaded: action,
			updateAccount: action,
			updateTemplate: action,
		});
	}

	updateAccount(name: string, data: IEosAccount | Error)
	{
		this.data.accounts.set(name, data);
	}

	updateTemplate(id: number, data: { [p: string]: TUint64 })
	{
		this.data.templates.set(id, data);
	}

	updateTokenStat(contract: string, data: ITokenStat[])
	{
		this.data.tokens.set(contract, data);
	}

	login(userName: string)
	{
		Logger.log(tag, "login", userName);
		this.userName = userName;
		this.logined = true;
	}

	logout()
	{
		Logger.log(tag, "logout", this.userName);
		this.userName = "";
		this.logined = false;
		this.balances.clear();
		this.atomicAssets.clearItems();
		this.stakedAssets.clearItems();
		this.userPools.clearItems();
		this.userUnstakes.clearItems();
		this.paintings.clearItems();
		this.lottery.clear();
		this.clearBalances();
	}

	setBalance(balance: EosAsset)
	{
		this.balances.set(balance.symbol.code, balance);
	}

	setConfig(config: IConfig)
	{
		this.config = config;
	}
	setAppDataLoaded(value: boolean) {
		this.appDataLoaded = value;
	}

	getBalance(symbol: EosSymbol)
	{
		const balance = this.balances.get(symbol.code);
		return balance ?? new EosAsset(-1, symbol);
	}

	clearBalances()
	{
		this.balances.clear();
	}

	getAcceptedTemplates(poolAssets: PoolAssetModel[]): { t: AtomicTemplateModel, power: number }[]
	{
		return poolAssets
			.map(it => {
				const templates = model.atomicTemplates.queryByCollectionName(it.collection, t => t.schemaName === it.schema_name);
				let result = [];
				for (let t of templates)
					for (let f of it.formats) {
						if (f.format_value === "" || f.format_value === t.getImmutableProp(it.format_key)) {
							if (f.power > 0)
								result.push({t: t, power: f.power});
							break;
						}
					}

				return result;
			}).flat();
	}

	get lotteryAttemptPrice():EosAsset
	{
		return new EosAsset(app.chainConf.ATTEMPT_PRICE, app.chainConf.SYS_SYMBOL);
	}
}