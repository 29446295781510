import React from 'react';
import Styles from "./Accordion.module.scss"
import classNames from "classnames";
import MinusIcon from "./../../../icons/MinusIcon";
import useWindowSize from "./../../../hooks/useWindowSize";
import H4 from "../../../components/typography/H4";

interface AccordionProps {
	title: string,
	children: React.ReactNode,
	action?: React.ReactNode,
	initialIsOpen?: boolean,
}


const Accordion = ({title, children, action, initialIsOpen}: AccordionProps) => {
	const [isOpen, setIsOpen] = React.useState(initialIsOpen);
	const accordionRef = React.useRef(null as null | HTMLDivElement);
	const headerRef = React.useRef(null as null | HTMLDivElement);
	const [height, setHeight] = React.useState(0);
	const {windowWidth} = useWindowSize();

	const updateAccordion = () => {
		if (accordionRef && accordionRef.current && headerRef && headerRef.current) {
			setHeight(isOpen ? accordionRef.current.scrollHeight : headerRef.current.clientHeight)
		}
	}

	React.useEffect(
		() => (document as any).fonts.ready.then(updateAccordion),
		[isOpen, accordionRef.current, windowWidth]
	);

	React.useEffect(() => setIsOpen(false), [windowWidth])

	return (
		<div
			ref={accordionRef}
			className={classNames(Styles.accordion, isOpen && Styles.opened)}
			style={{
				height: `${height}px`
			}}
		>
			<div
				ref={headerRef}
				className={Styles.header}
				onClick={() => setIsOpen(prevState => !prevState)}
			>
				<H4>{title}</H4>
				<span className={classNames(Styles.icon, isOpen && Styles.active)}><MinusIcon/><MinusIcon/></span>
			</div>
			<div className={Styles.content}>{children}</div>
			{action && <div>{action}</div>}
		</div>
	);
};

export default Accordion;
