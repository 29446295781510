import { IPoolUser } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";

export class PoolUserModel {
	constructor(
		public readonly record: IPoolUser,
	)
	{
		makeObservable(this, {});
	}

	get account()
	{
		return this.record.account;
	}

	get staked_assets_power(): number
	{
		return parseInt(this.record.staked_assets_power);
	}

	get staked_tokens(): number
	{
		return parseInt(this.record.staked_tokens);
	}
}