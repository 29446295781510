import React, { useEffect } from 'react';
import Button, { EButtonSize, EButtonType } from "../landing/components/Button";
import Styles from './TestPage.module.scss'
import CloseIcon from "../../icons/CloseIcon";
import H1 from "../../components/typography/H1";
import H2 from "../../components/typography/H2";
import H3 from "../../components/typography/H3";
import H4 from "../../components/typography/H4";
import P1 from "../../components/typography/P1";
import P2 from "../../components/typography/P2";
import P3 from "../../components/typography/P3";
import { notificationService } from "../../App";

const TestPage = () => {
	useEffect(() => {
		notificationService.showError("Error example\nIt is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.");
		notificationService.showInfo("Success/Info example");
	}, []);

	return (
		<div className={Styles.container}>
			<div className={Styles.section}>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.MEDIUM}>
					Stake your NFT
				</Button>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.MEDIUM} label="Comming soon">
					Stake your NFT
				</Button>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.SMALL}>
					Stake your NFT
				</Button>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.SMALL} disabled>
					Stake your NFT
				</Button>
			</div>
			<div className={Styles.section}>
				<Button type={EButtonType.SECONDARY} size={EButtonSize.MEDIUM}>
					Stake your NFT
				</Button>
				<Button type={EButtonType.SECONDARY} size={EButtonSize.MEDIUM} label="Comming soon">
					Stake your NFT
				</Button>
				<Button type={EButtonType.SECONDARY} size={EButtonSize.SMALL}>
					Stake your NFT
				</Button>
				<Button type={EButtonType.SECONDARY} size={EButtonSize.SMALL} disabled>
					Stake your NFT
				</Button>
			</div>
			<div className={Styles.section}>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.MEDIUM} icon={<CloseIcon/>}/>
				<Button type={EButtonType.PRIMARY} size={EButtonSize.SMALL} icon={<CloseIcon/>}/>
			</div>
			<div className={Styles.section}><H1>Heading H1</H1></div>
			<div className={Styles.section}><H2>Heading H2</H2></div>
			<div className={Styles.section}><H3>Heading H3</H3></div>
			<div className={Styles.section}><H4>Heading H4</H4></div>
			<div className={Styles.section}><P1>Paragraph P1</P1></div>
			<div className={Styles.section}><P1 isBold>Paragraph P1 Bold</P1></div>
			<div className={Styles.section}><P2>Paragraph P2</P2></div>
			<div className={Styles.section}><P2 isBold>Paragraph P2 Bold</P2></div>
			<div className={Styles.section}><P3>Paragraph P3 Bold</P3></div>
		</div>
	);
};

export default TestPage;
