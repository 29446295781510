import { action, makeObservable, observable } from "mobx";
import { PoolUserModel } from "./PoolUserModel";

export class PoolUsersTable {
	items = new Map<number, PoolUserModel[]>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItem: action,
			clearItems: action,
		});
	}

	updateItems(poolId: number, records: PoolUserModel[])
	{
		this.items.set(poolId, records);
	}

	removeItem(pool_id: number)
	{
		this.items.delete(pool_id);
	}

	clearItems()
	{
		this.items.clear();
	}

	get(pool_id: number): PoolUserModel[]
	{
		return this.items.get(pool_id) || [];
	}
}