import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import Styles from "./HowItWorks.module.scss"
import InfoBlock from "../components/InfoBlock";
import img1 from "../../../images/img_slider1.png"
import img2 from "../../../images/img_slider2.png"
import img3 from "../../../images/img_slider3.png"
import img4 from "../../../images/img_slider4.png"
import img5 from "../../../images/img_slider5.png"
import Button from "../components/Button";
import { L } from "../../../l10n/L10n";
import { PagePath } from "../../../helpers/PageHelper";

const HowItWorks = () => {
	return (
		<Container className={Styles.container} id="how">
			<Heading title={L.landing.sections.howItWorks.title} subTitle={L.landing.sections.howItWorks.subtitle}/>
			<div className={Styles.inner}>
				<InfoBlock
					img={img1}
					title={L.landing.sliders.slider1.title}
					text={L.landing.sliders.slider1.text}
				/>
				<InfoBlock
					img={img2}
					title={L.landing.sliders.slider2.title}
					text={L.landing.sliders.slider2.text}
					variant="reverse"
				/>
				<InfoBlock
					img={img3}
					title={L.landing.sliders.slider3.title}
					text={L.landing.sliders.slider3.text}
				/>
				<InfoBlock
					img={img4}
					title={L.landing.sliders.slider4.title}
					text={L.landing.sliders.slider4.text}
					variant="reverse"
				/>
				<InfoBlock
					img={img5}
					title={L.landing.sliders.slider5.title}
					text={L.landing.sliders.slider5.text}
				>
					<Button path={PagePath.EXPOS}>{L.landing.buttons.play_game}</Button>
				</InfoBlock>
			</div>
		</Container>
	);
};

export default HowItWorks;
