import React, { useState } from "react";
import { observer } from "mobx-react";
import { AtomicTemplateModel } from "../../../models/AtomicTemplateModel";
import SchemasFilterView from "./SchemasFilterView";
import Button from "../../shared/Button";
import { IPoolAsset } from "@pro/common/contracts/staking";
import Styles from "./SchemasSelectorView.module.scss";
import { EosAsset, EosSymbol } from "@pro/common/eos";

export interface ISchemaSelectorResult {
	schema_name: string,
	formatKey: string,
	formats: { format_value: string, power: number }[],
	templates: AtomicTemplateModel[]
}

const SchemasSelectorView = observer(({
										  collection,
										  templates,
										  onSelected,
										  symbol
									  }: { symbol?: EosSymbol, collection: string, templates: AtomicTemplateModel[], onSelected: (result: IPoolAsset[]) => void }) => {
	const [selected, setSelected] = useState<IPoolAsset[]>([]);
	const [filteredTemplates, setFilteredTemplates] = useState<ISchemaSelectorResult | undefined>();
	const [avaiableTemplates, setAvaiableTemplates] = useState(templates);
	const [filterActive, setFilterActive] = useState(false);
	const [activeFormats, setActiveFormats] = useState<{ [key: string]: boolean }>({});


	const setEditableValue = (format_value: any, val: number) => {
		let m: ISchemaSelectorResult = {...filteredTemplates} as ISchemaSelectorResult;
		let index = m.formats.indexOf(m.formats.find(f => f.format_value === format_value)!);
		m.formats[index].power = val;
		setFilteredTemplates(m);
	}

	return <>
		{
			!filterActive && !filteredTemplates && selected.map((c, i) => {
				return <fieldset className={Styles.schema_box}>
					<legend>{c.collection}{"=>"}{c.schema_name}</legend>
					<div>
						{c.formats.map(it => {
							return <div className={Styles.format_value_power}>
								<div>
									{it.format_value === "" ? `All future assets wit not yet created ${c.format_key}` : it.format_value} = {it.power} {symbol ? `(equal to ${new EosAsset(it.power, symbol).toString()})` : ""}
								</div>
							</div>
						})}
						<Button text={"edit"} onClick={() => {
							setFilteredTemplates(
								{
									formats: c.formats,
									templates: [],
									formatKey: c.format_key,
									schema_name: c.schema_name
								}
							)
						}}/>
						<Button text={"remove"} onClick={() => {
							let v = selected.slice();
							v.splice(i, 1);
							setSelected(v);
							setAvaiableTemplates(templates.filter(it => !v.map((it) => it.schema_name).includes(it.schemaName)));
						}}/>
					</div>
				</fieldset>
			})
		}
		{
			filterActive && <SchemasFilterView collection={collection} templates={avaiableTemplates}
                                               onFiltered={(res) => {
												   setFilterActive(false);
												   let active: { [key: string]: boolean } = {};
												   if (res) {
													   res.formats.push({format_value: "", power: 0});
													   for (let r of res.formats) {
														   if (r.power > 0)
															   active[r.format_value] = true;
														   r.power = 0;
													   }
												   }
												   setActiveFormats(active);
												   setFilteredTemplates(res);
											   }}/>
		}
		{
			filteredTemplates && <>
                <fieldset className={Styles.schema_box}>
                    <legend>{collection}{"=>"}{filteredTemplates.schema_name}</legend>
                    <h2>Select power to each {filteredTemplates.formatKey}</h2>
                    <div>
                        <input placeholder={"set to all"} onChange={(e) => {
							let m: ISchemaSelectorResult = {...filteredTemplates} as ISchemaSelectorResult;
							for (let i = 0; i < m.formats.length; i++) {
								if (activeFormats[m.formats[i].format_value])
									m.formats[i].power = parseInt(e.target.value || "0");
							}
							setFilteredTemplates(m);
						}}/>
                        <hr/>
						{filteredTemplates.formats.map(it => {
							return <div className={Styles.format_value_power}>
								<div>
									{
										it.format_value === "" ?
											<span>Future <b>{filteredTemplates.formatKey}</b> not created/minted yet (0 not accept any future templates assets).</span> :
											<span>{filteredTemplates.formatKey} {it.format_value}</span>
									}
								</div>
								<div className={Styles.input}>
									{<input type={"checkbox"} checked={activeFormats[it.format_value]} onChange={(e) => {
										let a = {...activeFormats};
										a[it.format_value] = e.target.checked;
										setActiveFormats(a);
										// setEditableValue(it.format_value, 0);
									}}/>}
									<div className={Styles.value}>
										<input placeholder={"power"} onChange={(e) => {
											setEditableValue(it.format_value, parseInt(e.target.value || "0"));
										}} value={it.power}/>
										{(symbol ? `(equal to ${new EosAsset(it.power, symbol).toString()})` : "")}
									</div>
								</div>
							</div>
						})}
                    </div>
                </fieldset>

                <Button text={"Save"}
                        disabled={filteredTemplates.formats.find(it => activeFormats[it.format_value] && it.power === 0) !== undefined}
                        onClick={() => {
							const r: IPoolAsset = {
								id: 0,
								author: "",
								format_key: filteredTemplates.formatKey,
								collection: collection,
								schema_name: filteredTemplates.schema_name,
								formats: filteredTemplates.formats.map(it => {
									return {
										format_value: it.format_value,
										power: activeFormats[it.format_value] ? it.power : 0
									}
								})
							}
							let newSelected = selected.slice();
							let old = newSelected.find(cur => cur.collection === r.collection && cur.schema_name === r.schema_name && cur.format_key === r.format_key);
							if (old)
								newSelected.splice(newSelected.indexOf(old), 1);
							newSelected.push(r);
							setSelected(newSelected);
							setAvaiableTemplates(templates.filter(it => !newSelected.map((it) => it.schema_name).includes(it.schemaName)));
							setFilteredTemplates(undefined);
						}}/>
                <Button text={"Cancel"} onClick={() => setFilteredTemplates(undefined)}/>
            </>
		}
		<br/>
		{
			!filteredTemplates && !filterActive && <Button text={"cancel"} onClick={() => {
				onSelected([]);
			}}/>
		}
		{
			!filteredTemplates && !filterActive && selected.length > 0 && <Button text={"save"} onClick={() => {
				onSelected(selected);
			}}/>
		}
		{!filteredTemplates && !filterActive && <Button text={"add schema"} onClick={() => setFilterActive(true)}/>}
	</>;
});

export default SchemasSelectorView;