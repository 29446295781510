import { Logger } from "./Logger";

export function sleep(ms: number)
{
	return new Promise(r => setTimeout(r, ms));
}

export async function retryPromiseUntilResolve<T>(
	fn: () => Promise<T>,
	retriesLeft: number,
	interval: number = 1000,
): Promise<T>
{
	try {
		return await fn();
	} catch (error) {
		if (retriesLeft) {
			console.error(error?.message ?? error);
			Logger.log(`retriesLeft: ${retriesLeft}`);
			await sleep(interval);
			return retryPromiseUntilResolve(fn, retriesLeft - 1, interval)
		} else {
			throw new Error(error);
		}
	}
}