import { IPool } from "@pro/common/contracts/staking";
import { app, model } from "../App";
import { EosAsset, EosSymbol } from "@pro/common/eos";
import { makeObservable } from "mobx";
import { PoolAssetModel } from "./PoolAssetModel";
import { PoolPeriodModel } from "./PoolPeriodModel";
import { UserPoolModel } from "./UserPoolModel";

export class StakingPoolModel {

	constructor(
		public readonly pool: IPool,
	)
	{
		makeObservable(this, {});
	}

	get owner(): string
	{
		return this.pool.owner;
	}

	get poolId(): number
	{
		return this.pool.id as number;
	}

	get startAt(): number
	{
		return this.pool.start_at;
	}

	get currentPeriod(): number
	{
		return Math.max(Math.min(Math.floor((app.now_second - this.pool.start_at) / this.pool.time_per_period_sec), this.pool.periods_count - 1), 0);
	}

	get started(): boolean
	{
		return this.pool.start_at <= app.now_second;
	}

	get ended(): boolean
	{
		return this.pool.start_at + this.pool.periods_count * this.pool.time_per_period_sec < app.now_second;
	}

	get currentPeriodLeftSec(): number
	{
		return this.pool.start_at + (this.currentPeriod + 1) * this.pool.time_per_period_sec - app.now_second;
	}

	get acceptSymbol(): EosSymbol
	{
		return EosSymbol.parse(this.pool.accept_symbol);
	}

	get acceptContract(): string
	{
		return this.pool.accept_contract;
	}

	get poolPeriods(): PoolPeriodModel[]
	{
		return model.poolPeriods.queryAll(this.poolId)
	}

	// get acceptedTemplates(): { t: AtomicTemplateModel, power: number }[] {
	//     return model.poolAssets.queryAll(this.poolId)
	//         .map(it => {
	//             const templates = model.atomicTemplates.queryByCollectionName(it.collection).filter(t => t.schema_name === it.schema_name);
	//             let result = [];
	//             for (let t of templates)
	//                 for (let f of it.formats) {
	//                     if (f.format_value === "" || f.format_value === t.getImmutableProp(it.format_key)) {
	//                         result.push({t: t, power: f.power});
	//                         break;
	//                     }
	//                 }
	//
	//             return result;
	//         }).flat();
	// }

	get acceptCollections(): string[]
	{
		if (this.acceptAssets.length === 0)
			return [];

		let c = this.acceptAssets.map(it => it.collection);
		return c.filter((n, i) => c.indexOf(n) === i);
	}

	get acceptAssets(): PoolAssetModel[]
	{
		return model.poolAssets.queryAll(this.poolId);
	}

	get rewardAsset(): EosAsset
	{
		return EosAsset.parse(this.pool.period_reward.quantity)
	}

	get rewardSymbol(): EosSymbol
	{
		return this.rewardAsset.symbol
	}

	get rewardContract(): string
	{
		return this.pool.period_reward.contract;
	}

	get timePerPeriodSecs(): number
	{
		return this.pool.time_per_period_sec;
	}

	get timeToStart(): number
	{
		return Math.max(this.pool.start_at - app.now_second, 0);
	}

	get periodsCount(): number
	{
		return this.pool.periods_count
	}

	get activated(): boolean
	{
		return this.pool.activated;
	}

	get totalStaked(): EosAsset
	{
		return new EosAsset(parseInt(this.pool.assets_staked_power) + parseInt(this.pool.tokens_staked_power), this.acceptSymbol)
	}

	get tokensStaked(): EosAsset
	{
		return new EosAsset(parseInt(this.pool.tokens_staked_power), this.acceptSymbol);
	}

	get assetsPowerStaked(): EosAsset
	{
		return new EosAsset(parseInt(this.pool.assets_staked_power), this.acceptSymbol);
	}

	get totalUsers(): number
	{
		return this.pool.total_user_pools;
	}

	get totalAssets(): number
	{
		return this.pool.staked_assets;
	}

	get totalPaid(): EosAsset
	{
		return new EosAsset(parseInt(this.pool.total_paid), this.rewardSymbol)
	}

	get totalReward(): EosAsset
	{
		return new EosAsset(EosAsset.parse(this.pool.period_reward.quantity).amount * this.periodsCount, this.rewardSymbol)
	}

	get totalTimeSeconds(): number
	{
		return this.timePerPeriodSecs * this.periodsCount;
	}

	get minStake(): EosAsset
	{
		return EosAsset.parse(this.pool.min_stake);
	}

	get maxStake(): EosAsset
	{
		return EosAsset.parse(this.pool.max_stake);
	}

	get unstakeSec(): number
	{
		return this.pool.unstake_time_sec;
	}

	get canBeDeleted(): boolean
	{
		return !this.activated || (this.totalStaked.amount === 0 && this.totalUsers === 0 && this.ended);
	}

	get needCleanup(): boolean
	{
		return this.activated && this.ended && (this.totalStaked.amount > 0 || this.totalUsers > 0);
	}

	getUserCollected(userPool: UserPoolModel, poolPeriods: PoolPeriodModel[])
	{
		let collected = 0;
		if (!poolPeriods.length)
			return collected;

		let lastPeriod = this.currentPeriod + (this.ended ? 1 : 0);
		let userTotalStaked = userPool.totalStaked;
		if (userTotalStaked > 0 && userPool.next_claim_num < lastPeriod) {
			for (let i = userPool.next_claim_num; i < lastPeriod; i++) {
				const total = poolPeriods[i] && poolPeriods[i].num !== this.currentPeriod && poolPeriods[i].merged ?
					parseInt(poolPeriods[i].total_staked_power) : this.totalStaked.amount;
				collected += this.calcRewardPerPeriod(userTotalStaked, total);
			}
		}
		return collected
	}

	calcRewardPerPeriod(staked: number, total: number = this.totalStaked.amount): number
	{
		return staked == 0 ? 0 : Math.floor(EosAsset.parse(this.pool.period_reward.quantity).amount * (staked / total));
	}

	// getEstimateReward(staking_amount: EosAsset)
	// {
	//
	// }
}