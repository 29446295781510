import { TUint64 } from "@pro/common/contracts/common_types";
import { action, makeObservable, observable } from "mobx";
import { AtomicTemplateModel } from "./AtomicTemplateModel";

export class AtomicTemplateTable {
	_collections = new Map<string, AtomicTemplateModel[]>();
	_items = new Map<string, Map<TUint64, AtomicTemplateModel>>();

	constructor()
	{
		makeObservable(this, {
			_items: observable,
			updateItem: action,
			updateItems: action,
		});
	}

	getItem(collectionName: string, template_id: TUint64): AtomicTemplateModel | undefined
	{
		return this._items.get(collectionName)?.get(template_id);
	}

	updateItem(collectionName: string, record: AtomicTemplateModel)
	{
		const item = this._items.get(collectionName)?.get(record.template_id);
		if (!item) {
			let items = this._items.get(collectionName);

			if (!items)
				this._items.set(collectionName, new Map<TUint64, AtomicTemplateModel>([[record.template_id, record]]));
			else
				items.set(record.template_id, record);
		}
	}

	updateCollection(collectionName: string, records: AtomicTemplateModel[])
	{
		this._collections.set(collectionName, records);
		this.updateItems(collectionName, records);
	}

	updateItems(collectionName: string, records: AtomicTemplateModel[])
	{
		for (const record of records) {
			this.updateItem(collectionName, record);
		}
	}


	find(collectionName: string, predicate: (it: AtomicTemplateModel) => boolean): AtomicTemplateModel | undefined
	{
		let items = this._items.get(collectionName);
		if (!items)
			return undefined;

		for (let item of items.values()) {
			if (predicate(item))
				return item;
		}
	}

	queryByCollectionName(collectionName: string, predicate: (it: AtomicTemplateModel) => boolean): AtomicTemplateModel[]
	{
		let result = [];
		for (let item of this._items.get(collectionName)?.values() || []) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	getAllByCollectionName(collectionName: string): AtomicTemplateModel[]
	{
		if (!this._items.get(collectionName))
			return [];

		return Array.from(this._items.get(collectionName)!.values());
	}

	findByCollectionName(collectionName: string): AtomicTemplateModel | undefined
	{
		return this._items.get(collectionName)?.values().next().value;
	}


	collectionHasAssets(collection: string): boolean
	{
		return (this._collections.get(collection) || []).length > 0
	}
}