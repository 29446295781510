import { IChainConf } from "@pro/common/conf";
import { AAContract } from "@pro/common/contracts/atomicassets";
import { EosApi, EosAsset, EosSymbol, IEosAuth, IEosTransactResult } from "@pro/common/eos";
import { TFetch } from "@pro/common/eos/fetch_types";
import { Logger, sleep } from "@pro/common/utils";
import { TransactConfig } from "eosjs/dist/eosjs-api-interfaces";
import { ScatterUser } from "ual-scatter";
import { User } from "universal-authenticator-library";
import { app, localData, model, world } from "../App";
import { ITokenStat, STContract } from "@pro/common/contracts/staking";
import { ExpoContract } from "@pro/common/contracts/expo";
import { NPContract } from "@pro/common/contracts/neftyblocksp";

const tag = "[eos]";

export class EosService {
	readonly _eosApi = new EosApi(this.urls, global.fetch as unknown as TFetch, {
		logRequest: true,
		logResponse: true,
	});

	public readonly aaContract = new AAContract(this._eosApi, this.chainConf.AA_ACCOUNT);
	public readonly stContract = new STContract(this._eosApi, this.chainConf.STAKING_ACCOUNT);
	public readonly npContract = new NPContract(this._eosApi, this.chainConf.NP_ACCOUNT)
	public readonly expoContract = new ExpoContract(this._eosApi, this.chainConf.EXPO_ACCOUNT);

	private _userName = "";
	private _userAuth: IEosAuth = {actor: "", permission: "active"};

	private _chainId = "a6a617c6e2af63439d4a660c5ec608acd29c26c84892772e96d2e0ab3714c4b9";
	private _signTransactionFn?: (transaction: any, config?: TransactConfig) => Promise<IEosTransactResult | null>;

	constructor(private urls: string[],
				private chainConf: IChainConf)
	{
	}

	async mustConnect()
	{
		if (localData.nodeUrl)
			this._eosApi.selectNode(localData.nodeUrl);

		let delay = 2_000;

		// eslint-disable-next-line no-constant-condition
		while (true) {
			try {
				Logger.log(tag, `<- connecting to ${this.nodeUrl}`);
				let info = await this._eosApi.getInfo();
				Logger.log(tag, "-> connected");
				this._chainId = info.chain_id;
				Logger.log(tag, "server_version: " + info.server_version);
				Logger.log(tag, "head_block_num: " + info.head_block_num);
				Logger.log(tag, "chain_id: " + this._chainId);
				return;
			} catch (e) {
				Logger.error(tag, e);
				Logger.error("Connection error, retrying...");
				if (delay < 10_000) {
					delay += 2_000;
				}
				await sleep(delay);
			}
		}
	}

	logout()
	{
		this._userName = "";
		this._userAuth = {actor: "", permission: "active"};
	}

	async loginWithUal(ualUser: User)
	{
		this._userName = await ualUser.getAccountName();
		this._userAuth.actor = this._userName;

		this._signTransactionFn = async (transaction: any, config?: TransactConfig): Promise<IEosTransactResult | null> => {
			try {
				if (ualUser instanceof ScatterUser) {
					config = {...{requiredKeys: await (ualUser as ScatterUser).getKeys()}, ...config};
				}

				const result = await ualUser.signTransaction(transaction, config);
				return result.transaction;
			} catch (e) {
				if (e.message === "Cannot read property '0' of undefined")
					throw new Error("Transaction signing error");
				throw e;
			}
		};
		this._eosApi.setSignTransactFn(this._signTransactionFn);
		await this.loadDataAndLogin();
	}

	private async loadDataAndLogin()
	{
		model.login(this._userName);
		if (!model.config)
			await world.updateConfig();

		if (model.appDataLoaded)
			world.updateAtomicAssets().catch();
		world.updateUserPaintings(model.userName).catch();
		world.updateUserLottery(model.userName).catch();
		world.updateALlTokenBalances().catch();
		world.updateBalance(app.chainConf.SYS_ACCOUNT, app.chainConf.SYS_SYMBOL).catch();
		// world.updateAllStakedAssets(model.userName).catch();
		// world.updateAllUserPools().catch();
		// world.updateUserUnstakes(model.userName).catch();
	}

	async transfer(code: string, recipient: string, quantity: EosAsset, memo: string = "")
	{
		const title = `transfer: ${quantity.toString()} -> ${recipient} : ${memo}`;

		Logger.log(tag, `<- ${title}`);

		const result = await this._signTransactionFn!({
			actions: [{
				account: code,
				name: "transfer",
				authorization: [this._userAuth],
				data: {
					from: this._userName,
					to: recipient,
					quantity: quantity.toString(),
					memo: memo,
				},
			}],
		}, {
			blocksBehind: 3,
			expireSeconds: 60,
		});

		Logger.log(tag, `-> ${title}, status: ${result ? result.processed.receipt.status : result}`);
		return result;
	}

	async getBalance(contract: string, symbol: EosSymbol): Promise<EosAsset>
	{
		return this._eosApi.getBalance(contract, model.userName, symbol);
	}

	async getTokenStat(contract: string, code: string): Promise<ITokenStat | undefined>
	{
		const stat = await this._eosApi.getCurrencyStat(contract, new EosSymbol(code, 0));
		return stat[code];
	}

	get nodeUrl(): string
	{
		return this._eosApi.getRpc().endpoint;
	}

	get user(): string
	{
		return this._userName;
	}

	get userAuth(): IEosAuth
	{
		return this._userAuth;
	}
}
