import { AssetConf, ChainConf, TProjEnv } from "@pro/common/conf";
import { Logger } from "@pro/common/utils/Logger";
import { Chain } from 'universal-authenticator-library';
import { PaintingPools } from "./helpers/PaintingPools";
import * as process from "process";
import preval from 'babel-plugin-preval/macro'

export class AppContext {
	readonly projEnv = process.env.REACT_APP_PROJ_ENV as TProjEnv ?? "dev";
	readonly isDevAuth = process.env.REACT_APP_IS_DEV_AUTH === "true";
	readonly chainId = process.env.REACT_APP_CHAIN_ID
		|| "a6a617c6e2af63439d4a660c5ec608acd29c26c84892772e96d2e0ab3714c4b9";
	readonly nodeos_urls = process.env.REACT_APP_NODEOS_URLS?.split(" ") || [];
	readonly chainConf = ChainConf.get(this.projEnv);
	readonly assetConf:AssetConf;
	readonly chains: Chain[];
	readonly paintingPools = new PaintingPools(process.env.REACT_APP_PAINTING_POOL_ID?.split(',') || []);
	readonly useTemplateFIle = process.env.REACT_APP_USE_TEMPLATE_FILE === "true";

	constructor()
	{
		const rpcEndpoints = this.nodeos_urls.map(nodeosUrl => {
			const [protocol, hostPort] = nodeosUrl.split("://");
			let [host, port] = hostPort.split(":");
			if (!port)
				port = "443";
			return {protocol, host, port: Number(port)};
		});
		this.chains = [{chainId: this.chainId, rpcEndpoints}];

		this.assetConf = new AssetConf(this.getTemplates());

		Logger.log("projEnv:", this.projEnv);
		Logger.log("isDevAuth:", this.isDevAuth);
		Logger.log("chainId:", this.chainId);
		Logger.log("nodeos_urls:", this.nodeos_urls.toString());
	}

	get now_second(): number
	{
		return Math.floor((new Date().getTime()) / 1000)
	}

	private getTemplates()
	{
		// noinspection NodeCoreCodingAssistance
		return preval`
			const fs = require('fs');
			const env = process.env["REACT_APP_PROJ_ENV"];
			const templatesStr = fs.readFileSync('../common/data/' + env + '/templates.json', 'utf8');
  			module.exports = JSON.parse(templatesStr);
		`;
	}
}