import React from 'react';

const CenterIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15ZM13 16C13 14.3431 14.3431 13 16 13C17.6569 13 19 14.3431 19 16C19 17.6569 17.6569 19 16 19C14.3431 19 13 17.6569 13 16Z"
			  fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M17 4V10H15V4H17Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M15.293 10.7072L12.293 7.70718L13.7072 6.29297L16.0001 8.58586L18.293 6.29297L19.7072 7.70718L16.7072 10.7072C16.3167 11.0977 15.6835 11.0977 15.293 10.7072Z"
			  fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M15 28V22H17V28H15Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M15.293 21.2929C15.6835 20.9024 16.3167 20.9024 16.7072 21.2929L19.7072 24.2929L18.293 25.7071L16.0001 23.4142L13.7072 25.7071L12.293 24.2929L15.293 21.2929Z"
			  fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M28 17H22V15H28V17Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M21.2929 15.293L24.2929 12.293L25.7071 13.7072L23.4142 16.0001L25.7071 18.293L24.2929 19.7072L21.2929 16.7072C20.9024 16.3167 20.9024 15.6835 21.2929 15.293Z"
			  fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M4 15H10V17H4V15Z" fill={color}/>
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M8.58586 16.0001L6.29297 13.7072L7.70718 12.293L10.7072 15.293C11.0977 15.6835 11.0977 16.3167 10.7072 16.7072L7.70718 19.7072L6.29297 18.293L8.58586 16.0001Z"
			  fill={color}/>
	</svg>
);

export default CenterIcon;
