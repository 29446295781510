import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Styles from "./SchemasFilterView.module.scss";
import { model } from "../../../App";
import Button from "../../shared/Button";
import { AtomicTemplateModel } from "../../../models/AtomicTemplateModel";
import { ISchemaSelectorResult } from "./SchemasSelectorView";
import TemplateMedia from "../../shared/TemplateMedia";
import { Paginator } from "../../../helpers/Paginator";
import { useSearchParams } from "react-router-dom";
import PaginatorView from "../../shared/PaginatorView";
import { ASchema } from "@pro/common/contracts/atomicassets";

const NftFilterView = observer(({
									collection,
									templates,
									onFiltered
								}: { collection: string, templates: AtomicTemplateModel[], onFiltered: (result?: ISchemaSelectorResult) => void }) => {


	const [schemas] = useState(model.atomicSchemas.queryAll(collection));
	const [searchParams, setSearchParams] = useSearchParams();

	const [activeSchema, setActiveSchema] = useState<ASchema>();
	const [formatKey, setFormatKey] = useState<string>("");
	const [allFormats, setAllFormats] = useState<{ [schema_key: string]: { [format_key: string]: any[] } }>({});
	const [bySchemas, setBySchemas] = useState<{ [key: string]: AtomicTemplateModel[] }>({});
	const [selectedFormats, setSelectedFormats] = useState<string[]>([]);
	const [filteredTemplates, setFilteredTemplates] = useState<AtomicTemplateModel[]>([]);

	const reset = () => {
		setActiveSchema(undefined);
		setFormatKey("");
		onNewSelectedFormats([]);
		setFilteredTemplates([]);
		searchParams.delete("p");
	}

	const onNewSelectedFormats = (s: string[]) => {
		setSelectedFormats(s);
		setFilteredTemplates(activeSchema && bySchemas[activeSchema.schema_name]
			.filter(it => s.includes(it.getImmutableProp(formatKey) as any)) || []);
		searchParams.delete("p");
		setSearchParams(searchParams);
	}

	useEffect(() => {

		let byS: { [key: string]: AtomicTemplateModel[] } = {};
		for (let t of templates) {
			if (!byS[t.schemaName])
				byS[t.schemaName] = [];
			byS[t.schemaName].push(t);
		}

		let m: { [schema_key: string]: { [format_key: string]: any[] } } = {};
		for (let schema of schemas) {
			if (!byS[schema.schema_name])
				continue;

			if (!m[schema.schema_name])
				m[schema.schema_name] = {};

			for (let t of byS[schema.schema_name]) {
				for (let format of schema.format) {
					let v = t.getImmutableProp(format.name) as any;
					if (!v)
						continue;

					if (!m[schema.schema_name][format.name])
						m[schema.schema_name][format.name] = [];

					if (!m[schema.schema_name][format.name].includes(v))
						m[schema.schema_name][format.name].push(v);
				}
			}
		}

		setBySchemas(byS);
		setAllFormats(m);

	}, [])

	const filter = () => {
		const tt = templates.filter(t => t.schemaName === activeSchema?.schema_name || "");
		onFiltered({
			schema_name: activeSchema?.schema_name || "",
			formatKey: formatKey,
			formats: allFormats[activeSchema!.schema_name][formatKey].map(it => {
				return {format_value: it, power: selectedFormats.includes(it) ? 1 : 0}
			}),
			templates: tt
		});
		reset();
	}

	const activeValues = activeSchema && allFormats[activeSchema.schema_name] &&
		allFormats[activeSchema.schema_name][formatKey];

	const paginator: Paginator | undefined = filteredTemplates.length ? new Paginator(filteredTemplates, parseInt(searchParams.get("per_page") || "10"), parseInt(searchParams.get("p") || "0")) : undefined;

	const notIncludedSchemas = schemas.filter(it => Object.keys(bySchemas).includes(it.schema_name));
	const cancelButtonBlock = <Button text={"cancel"} onClick={() => {
		reset();
		onFiltered(undefined);
	}}/>;

	if (notIncludedSchemas.length === 0)
		return <>
			<h2>All schemas from this collection already added</h2>
			{cancelButtonBlock}
		</>

	return <>
		<div>
			<h3>Select schema</h3>
			<div className={Styles.container}>
				{notIncludedSchemas.map(it => {
					return <div className={it !== activeSchema ? "" : Styles.selected} onClick={() => {
						setActiveSchema(it);
						setFormatKey("");
						onNewSelectedFormats([]);
						setFilteredTemplates([]);
					}}>{it.schema_name}</div>
				})}
				<Button text={"reset"} onClick={() => reset()}/>
			</div>
		</div>

		{
			activeSchema && <div>
                <h3>Select format key</h3>
                <div className={Styles.container}>
					{activeSchema.format.map(it => it.name).map(it => {
						let ff = allFormats[activeSchema?.schema_name || ""][it];
						if (!ff || ff.length === 0)
							return <></>

						return <div className={it !== formatKey ? "" : Styles.selected} onClick={() => {
							setFormatKey(it);
							onNewSelectedFormats([]);
						}}>{it}</div>
					})}
                </div>
            </div>
		}

		<div>
			{
				activeValues && activeValues.length > 0 && <div>
                    <h3>Variants</h3>
                    <div className={Styles.container}>
						{activeValues.map((v: any) => {
							return <div className={selectedFormats.includes(v) ? Styles.selected : ""} onClick={() => {
								let s = selectedFormats.slice();
								if (!s.includes(v))
									s.push(v);
								else
									s.splice(s.indexOf(v), 1);
								onNewSelectedFormats(s);
							}}>{v}</div>
						})}
                        <Button text={"select"}
                                onClick={() => onNewSelectedFormats(allFormats[activeSchema!.schema_name][formatKey])}/>
                        <Button text={"dselect"} onClick={() => onNewSelectedFormats([])}/>
                    </div>
                </div>
			}
		</div>

		{paginator &&
            <>
                <hr/>
                <div className={Styles.selectedTemplates}>
					{
						paginator.rows.map(it => {
							return <div className={Styles.asset}>
								<div className={Styles.body}>
									<TemplateMedia t={it}/>
									<div>{it.getImmutableProp(formatKey)}</div>
								</div>
							</div>
						})
					}
                </div>
                <PaginatorView paginator={paginator}/>
            </>
		}
		<hr/>
		{selectedFormats.length > 0 && <Button text={"confirm"} onClick={() => filter()}/>}
		{cancelButtonBlock}
	</>;
});

export default NftFilterView;