import React from "react";
import "react-notifications-component/dist/theme.css";
import { model, notificationService, ualService } from "../../App";
import { observer } from "mobx-react";
import Styles from "./AuthView.module.scss";
import MenuDropdown from "./MenuDropdown";
import Button, { EButtonSize } from "src/pages/landing/components/Button";
import P3 from "../typography/P3";
import P2 from "../typography/P2";
import WaxBalance from "./WaxBalance";
import ARTBalance from "./ARTBalance";

const AuthView = observer(() => {
	const onLoginClick = async () => {
		try {
			await ualService.login();
		} catch (e) {
			console.error(e);
			notificationService.showError(e.message ?? e);
		}
	};

	return (
		model.logined ? (
			<MenuDropdown title={model.userName}>
				<div className={Styles.container}>
					<div className={Styles.balance}>
						<P3 isBold>Balances:</P3>
						<ARTBalance/>
						<WaxBalance/>
					</div>
					<hr className={Styles.divider}/>
					<P2 className={Styles.username} isBold>{model.userName}</P2>
					<Button size={EButtonSize.SMALL} onClick={() => ualService.logout()}>Logout</Button>
				</div>
			</MenuDropdown>
		) : <Button size={EButtonSize.SMALL} onClick={onLoginClick}>Login</Button>
	)
});

export default AuthView;