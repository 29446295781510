import { eos, model, world } from "../../App";
import { EosActionBase } from "../EosActionBase";

export class GetPuzzlesAction extends EosActionBase {
	constructor(private template_ids: number[], private painting_id: number)
	{
		super("getpuzzles");
	}

	async onExecute(): Promise<void>
	{
		await eos.expoContract.action("getpuzzles", eos.userAuth, {
			owner: model.userName,
			painting_id: this.painting_id,
			template_ids: this.template_ids
		});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updateAtomicAssets(true);
		await world.updateUserPainting(model.userName, this.painting_id);
		await world.updateConfig();
	}
}