import { IUserPool } from "@pro/common/contracts/staking";
import { makeObservable } from "mobx";

export class UserPoolModel {
	constructor(
		public readonly pool: IUserPool,
	)
	{
		makeObservable(this, {});
	}

	get staked_tokens(): number
	{
		return parseInt(this.pool.staked_tokens);
	}

	get staked_assets_power(): number
	{
		return parseInt(this.pool.staked_assets_power);
	}

	get collected(): number
	{
		return parseInt(this.pool.collected);
	}

	get next_claim_num(): number
	{
		return this.pool.next_claim_num;
	}

	get total_claimed(): number
	{
		return parseInt(this.pool.total_claimed);
	}

	get pool_id(): number
	{
		return this.pool.pool_id;
	}

	get totalStaked(): number
	{
		return this.staked_assets_power + this.staked_tokens;
	}
}