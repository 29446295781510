import React from 'react';

const LotteryIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M12.1792 33.9166C12.6417 34.3791 13.2583 34.6875 14.0292 34.6875C14.8 34.6875 15.5708 34.3791 16.3417 33.7625L33.7625 16.1875C34.9958 14.9541 34.9958 13.1041 33.9167 12.025L32.9917 10.9458C32.0667 10.0208 30.525 10.0208 29.2917 10.7916C28.8292 11.1 28.3667 11.2541 27.9042 11.2541C27.4417 11.2541 26.6708 11.2541 26.2083 10.7916C25.4375 10.175 25.4375 8.78747 26.2083 7.7083C26.9792 6.47497 26.825 5.08747 25.9 4.16247L24.8208 3.0833C23.7417 2.00414 21.8917 2.00414 20.6583 3.23747L3.2375 20.8125C2.00417 22.0458 2.00417 23.8958 3.08334 24.975L4.1625 25.9C5.0875 26.825 6.62917 26.825 7.8625 26.0541C8.325 25.7458 8.7875 25.5916 9.25 25.5916C9.55834 25.5916 10.3292 25.5916 10.7917 26.2083C11.2542 26.825 11.2542 27.4416 11.2542 27.9041C11.2542 28.3666 11.1 28.8291 10.7917 29.2916C10.0208 30.525 10.175 31.9125 11.1 32.8375L12.1792 33.9166ZM18.0375 15.4167L16.3417 14.6459C15.725 14.3375 15.1083 15.1084 15.4167 15.725L16.1875 17.2667C16.3417 17.575 16.1875 17.8834 16.0333 18.0375L14.8 19.425C14.3375 19.8875 14.8 20.8125 15.4167 20.6584L17.1125 20.5042C17.4208 20.35 17.7292 20.5042 17.8833 20.8125L18.8083 22.5084C19.1167 23.125 20.0417 22.9709 20.1958 22.3542L20.5042 20.5042C20.6583 20.1959 20.8125 19.8875 21.1208 19.8875L22.9708 19.5792C23.5875 19.425 23.7417 18.5 23.125 18.1917L21.4292 17.2667C21.275 17.1125 21.1208 16.8042 21.1208 16.4959L21.4292 14.6459C21.4292 14.0292 20.6583 13.5667 20.1958 14.0292L18.8083 15.2625C18.6542 15.5709 18.3458 15.5709 18.0375 15.4167Z"
			  fill={color}/>
	</svg>
);

export default LotteryIcon;

