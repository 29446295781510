import { EosActionBase } from "../../EosActionBase";
import { app, eos, world } from "../../../App";
import { EosAsset } from "@pro/common/eos";
import { StakingPoolModel } from "../../../models/StakingPoolModel";

export class StakeAction extends EosActionBase {
	constructor(private contract: string, private quantity: EosAsset, private pool: StakingPoolModel)
	{
		super("stake");
	}

	async onExecute(): Promise<void>
	{
		await eos.transfer(this.contract, app.chainConf.STAKING_ACCOUNT, this.quantity, `stake:${this.pool.poolId}`);
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdateBalance(this.pool.acceptContract, this.pool.acceptSymbol);
		await world.updatePool(this.pool.poolId);
	}
}