import { NeftyClaimAssetsModel } from "../../models/NeftyClaimAssetsModel";
import { AssetConf } from "@pro/common/conf";
import { UnpackingPaintingView } from "./UnpackingPaintingView";
import { UnpackingPuzzleView } from "./UnpackingPuzzleView";
import { ClaimNeftyAction } from "../../eos/actions/ClaimNeftyAction";
import { useState } from "react";
import { notificationService } from "../../App";

export const UnpackingView = (
	{
		neftyClaimAssets,
	}: {
		neftyClaimAssets: NeftyClaimAssetsModel,
	}
) => {
	const [btnReady, setBtnReady] = useState(true);
	const assets = neftyClaimAssets.assets;

	async function onClaimClick()
	{
		setBtnReady(false);
		new ClaimNeftyAction(neftyClaimAssets)
			.onError(() => setBtnReady(true))
			.onSuccess(() => notificationService.showInfo("Claimed successfully"))
			.executeAsync()
	}

	if (assets.length === 1 && assets[0].schemaName === AssetConf.PAINTINGS_SCHEMA_NAME)
		return <UnpackingPaintingView
			neftyClaimAssets={neftyClaimAssets}
			onClaimClick={onClaimClick}
			btnReady={btnReady}
		/>

	if (assets.length > 1 && assets[0].schemaName === AssetConf.PUZZLES_SCHEMA_NAME)
		return <UnpackingPuzzleView
			neftyClaimAssets={neftyClaimAssets}
			onClaimClick={onClaimClick}
			btnReady={btnReady}
		/>

	return null;
};