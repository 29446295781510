import { EosSymbol } from "../eos";

export enum EProjEnv {
	dev = "dev",
	stage = "stage",
	testnet = "testnet",
	prod = "prod",
}

export type TProjEnv = keyof typeof EProjEnv;

const SYS_TOKEN_WAX = new EosSymbol("WAX", 8);

export interface IChainConf {
	readonly PROJ_ENV: TProjEnv;
	readonly SYS_ACCOUNT: string;
	readonly SYS_SYMBOL: EosSymbol;

	readonly TKN_ACCOUNT: string;
	readonly TKN_SYMBOL: EosSymbol;

	readonly STAKING_ACCOUNT: string;
	readonly AA_ACCOUNT: string;
	readonly AP_ACCOUNT: string;
	readonly NP_ACCOUNT: string;

	readonly EXPO_ACCOUNT: string;
	readonly ORNG_CONTRACT: string;
	readonly BANK_ACCOUNT: string;
	readonly FUND_ACCOUNT: string;

	readonly COLLECTION_NAME: string;
	readonly COLLECTION_AUTHOR: string;
	readonly MARKET_FEE: number;

	readonly ATOMIC_API_ENDPOINT: string;
	readonly LOTTERY_CONF: { num: number, prize: number }[];
	readonly ATTEMPT_PRICE: number;
	readonly MAX_RAND_NUM: number;
}

const defaultConf: Omit<IChainConf, "PROJ_ENV"> = {
	SYS_SYMBOL: SYS_TOKEN_WAX,
	TKN_SYMBOL: new EosSymbol("ART", 4),

	TKN_ACCOUNT: "chainexpotok",
	STAKING_ACCOUNT: "pepperstake",
	AA_ACCOUNT: "atomicassets",
	AP_ACCOUNT: "atomicpacksx",
	NP_ACCOUNT: "neftyblocksp",

	COLLECTION_NAME: "chainexpoart",
	COLLECTION_AUTHOR: "chainexpoart",
	MARKET_FEE: 0.05,

	SYS_ACCOUNT: "eosio.token",
	EXPO_ACCOUNT: "chainexpoart",
	ORNG_CONTRACT: "orng.wax",
	BANK_ACCOUNT: "chainexpobnk",
	FUND_ACCOUNT: "thechainexpo",

	ATOMIC_API_ENDPOINT: "https://wax.api.atomicassets.io",

	LOTTERY_CONF: [
		{num: 5999, prize: 5},
		{num: 7999, prize: 25},
		{num: 9499, prize: 50},
		{num: 9949, prize: 200},
		{num: 9998, prize: 1000},
		{num: 9999, prize: 25000},
	],
	ATTEMPT_PRICE: 1000000000,
	MAX_RAND_NUM: 9999,
};

const chains: readonly IChainConf[] = [{
	PROJ_ENV: "dev",
	...defaultConf,
}, {
	PROJ_ENV: "stage",
	...defaultConf,
}, {
	PROJ_ENV: "prod",
	...defaultConf,
}, {
	PROJ_ENV: "testnet",
	...defaultConf,
	STAKING_ACCOUNT: "pepperstake1",
	NP_ACCOUNT: "neftyblpacks",
	ATOMIC_API_ENDPOINT: "https://test.wax.api.atomicassets.io",
}];

export class ChainConf {
	static get(projEnv: TProjEnv): IChainConf
	{
		let result = chains.find(it => it.PROJ_ENV === projEnv);
		if (!result)
			throw new Error(`ChainConf not found: ${projEnv}`);
		return result;
	}

	static get all(): readonly IChainConf[]
	{
		return chains;
	}
}