import React from "react";
import Styles from "./Layout.module.scss";
import HeadBase from "./HeadBase";
import { observer } from "mobx-react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = observer(({children}: React.HTMLAttributes<HTMLElement>) => {
	return (
		<div className={Styles.container}>
			<div className={Styles.layout}>
				<HeadBase/>
				<Header/>
				<main className={Styles.main}>
					{children}
				</main>
				<Footer/>
			</div>
		</div>
	)
});

export default Layout;