import React from 'react';
import Button from "./Button";
import TGChatIcon from "../../../icons/TGChatIcon";
import TelegramIcon from "../../../icons/Telegram";
import TwitterIcon from "../../../icons/TwitterIcon";
import DiscordIcon from "../../../icons/DiscordIcon";
import Styles from "./Socials.module.scss";
import classNames from "classnames";

const Socials: React.FC<React.HTMLAttributes<HTMLElement>> = ({className}) => {
	return (
		<div className={classNames(Styles.socials, className)}>
			<a href="https://t.me/ChainExpoArt" target="_blank"><Button icon={<TGChatIcon/>}></Button></a>
			<a href="https://t.me/ChainExpoArt_Chanel" target="_blank"><Button
				icon={<TelegramIcon/>}></Button></a>
			<a href="https://twitter.com/chainexpoart" target="_blank"><Button
				icon={<TwitterIcon/>}></Button></a>
			<a href="https://discord.gg/r6xPa73tHQ" target="_blank"><Button icon={<DiscordIcon/>}></Button></a>
		</div>
	);
};

export default Socials;
