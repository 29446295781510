import React from 'react';
import Styles from "./P2.module.scss";
import classNames from "classnames";

interface P2Props extends React.HTMLAttributes<HTMLElement> {
	isBold?: boolean
}

export const P2 = ({children, className, isBold}: P2Props) => (
	<p className={classNames(Styles.p2, className, isBold && Styles.bold)}>
		{children}
	</p>
);

export default P2;
