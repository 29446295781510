import React, { useState } from "react";
import Button from "../../../shared/Button";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import TokenSelectorView from "../../shared/TokenSelectorView";

const RewardToken = observer(() => {
	const contract_key = "reward_contract";
	const symbol_key = "reward_symbol";

	const [searchParams, setSearchParams] = useSearchParams();
	const [contract, setContract] = useState(searchParams.get(contract_key) || "");
	const [symbol, setSymbol] = useState(searchParams.get(symbol_key) || "");

	const nextStep = () => {
		searchParams.set("step", (parseInt(searchParams.get("step") || "0") + 1).toString());
		setSearchParams(searchParams);
	}

	return <div>
		<h1>Select token that will be distributed as reward</h1>
		<hr/>
		<TokenSelectorView acc={contract} sym={symbol}
						   selected={(a, b) => {
							   setContract(a);
							   setSymbol(b);
						   }}/>

		{contract && symbol &&
            <Button text={"continue"} onClick={() => {
				searchParams.set(contract_key, contract);
				searchParams.set(symbol_key, symbol);
				nextStep();
			}}/>}
	</div>
});

export default RewardToken;