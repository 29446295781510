import React from "react";
import AdminLayout from "../../shared/AdminLayout";
import ManagePoolHeader from "../shared/ManagePoolHeader";
import { useSearchParams } from "react-router-dom";
import { L } from "../../../l10n/L10n";
import AcceptToken from "./steps/AcceptToken";
import RewardToken from "./steps/RewardToken";
import AcceptNft from "./steps/AcceptNft";
import Configuration from "./steps/Configuration";
import Summary from "./steps/Summary";


const getContentByStep = (step: number) => {
	switch (step) {
		case 0:
			return <AcceptToken/>
		case 1:
			return <AcceptNft/>
		case 2:
			return <RewardToken/>
		case 3:
			return <Configuration/>
		case 4:
			return <Summary/>
		default:
			return <div>{L.errors.page_not_found}</div>;
	}
}

const CreatePoolPage = () => {
	const [searchParams] = useSearchParams();
	const step = parseInt(searchParams.get("step") || "0");

	return <AdminLayout content={<div>
		<h1>NEW POOL PAGE</h1>
		<ManagePoolHeader/>
		{getContentByStep(step)}
	</div>}/>
};

export default CreatePoolPage;