import Styles from "./Card.module.scss";
import React from "react";
import classNames from "classnames";
import ButtonLink from "../../../pages/landing/components/ButtonLink";
import ExternalIcon from "../../../icons/ExternalIcon";
import P3 from "../../typography/P3";
import {L} from "../../../l10n/L10n";

const PuzzleCard = (
	{
		img,
		url,
		count
	}: {
		img: string,
		count: number
		url: string,
	}
) => {
	return (
		<div className={classNames(Styles.card, Styles.puzzleCard)}>
			<div className={Styles.img}><img src={img} alt="img"/></div>
			{count == 0 ?
				<ButtonLink href={url}
							icon={<ExternalIcon/>}
							target={"_blank"}
							className={Styles.link}>
					{L.button.buy}
				</ButtonLink>
				:
				<P3 className={Styles.count}>x{count}</P3>
			}
		</div>
	);
}

export default PuzzleCard