export class PaintingPools {

	constructor(private ids: string[])
	{
	}

	pepperStakeURL(expo: number): string
	{
		return `https://pepperstake.online/pool/${this.ids[expo - 1]}`;
	}
}