import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { model } from "../../../App";
import AdminLayout from "../../shared/AdminLayout";
import { observer } from "mobx-react";
import Styles from "./AdminPoolUser.module.scss";
import TemplateMedia from "../../shared/TemplateMedia";
import PropertyView from "../../shared/PropertyView";
import { EosAsset } from "@pro/common/eos";
import Button from "../../shared/Button";
import { getPathById, PagePath } from "../../../helpers/PageHelper";

const AdminPoolUser = observer(() => {
	const navigate = useNavigate();
	const params = useParams();
	const pool = model.pools.items.get(parseInt(params["id"] || "-1"));

	if (!pool)
		return <AdminLayout content={<h1>Pool {params["id"]} have no users</h1>}/>

	const uPool = model.userPools.get(params["account"] || "", pool.poolId);

	if (!uPool)
		return <AdminLayout content={<h1>User {params["account"]} details don't found</h1>}/>

	const templates = model.getAcceptedTemplates(model.poolAssets.queryAll(pool.poolId));
	const stakedAssets = model.stakedAssets.queryAll(params["account"] || "");

	return <AdminLayout content={
		<div className={Styles.container}>
			{pool.acceptContract !== "" && <PropertyView className={Styles.prop} name={"Staked tokens"}
                                                         value={new EosAsset(uPool.staked_tokens, pool.acceptSymbol).toString()}/>}
			<PropertyView className={Styles.prop} name={"Staked assets"}
						  value={new EosAsset(uPool.staked_assets_power, pool.acceptSymbol).toString()}/>
			<PropertyView className={Styles.prop} name={"Total claimed"}
						  value={new EosAsset(uPool.total_claimed, pool.rewardSymbol).toString()}/>
			<PropertyView className={Styles.prop} name={"Last claimed period"}
						  value={uPool.next_claim_num - 1 < 0 ? "Not claimed yet" : uPool.next_claim_num - 1}/>

			{
				stakedAssets.length > 0 && <>
                    <br/>
                    <h2>Staked assets</h2>
					{
						stakedAssets.map(asset => {
							const template = templates.find(it => it.t.template_id === asset.templateId);
							return <div className={Styles.staked_asset}>
								<TemplateMedia t={template?.t}/>
							</div>
						})
					}
                </>
			}
			<hr/>
			<Button text={"back"} onClick={() => navigate(getPathById(PagePath.ADMIN_POOL_USERS, pool.poolId))}/>
		</div>
	}/>
});

export default AdminPoolUser;