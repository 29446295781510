import { app, model } from "../../App";
import P2 from "../typography/P2";
import P1 from "../typography/P1";
import React from "react";
import artImg from "../../images/art_token.png";
import Styles from "./WaxBalance.module.scss";
import { observer } from "mobx-react";

const ARTBalance = observer(() => {

	const getARTBalance = () => {
		const [value, token] = model.getBalance(app.chainConf.TKN_SYMBOL).formatNumberWithPrecision()
		return value ? <><P2>{value}</P2><img src={artImg} alt="ART"/><P1 isBold>{token}</P1></> : null
	};
	return (
		<div className={Styles.balance}>
			{getARTBalance()}
		</div>
	);
});

export default ARTBalance