import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import Styles from "./About.module.scss"
import CardStack from "../components/CardStack";
import Card, { CardContent, CardFooter, CardHeader } from "../components/Card";
import img1 from "../../../images/img_card1.png";
import img2 from "../../../images/img_card2.png";
import img3 from "../../../images/img_card3.png";
import ButtonLink from "../components/ButtonLink";
import { L } from "../../../l10n/L10n";
import P1 from "../../../components/typography/P1";
import ArrowExternal from "../../../icons/ArrowExternal";

const About = () => {
	return (
		<Container className={Styles.container} id="#about" fluid>
			<Heading title={L.landing.sections.about.title} subTitle={L.landing.sections.about.subtitle}/>
			<CardStack className={Styles.cards}>
				<Card img={img1}>
					<CardHeader>{L.landing.cards.card1.title}</CardHeader>
					<CardContent><P1>{L.landing.cards.card1.content}</P1></CardContent>
					<CardFooter>
						<ButtonLink href="#pools" icon={<ArrowExternal/>}>{L.landing.cards.card1.link}</ButtonLink>
					</CardFooter>
				</Card>
				<Card img={img2}>
					<CardHeader>{L.landing.cards.card2.title}</CardHeader>
					<CardContent><P1>{L.landing.cards.card2.content}</P1></CardContent>
					<CardFooter>
						<ButtonLink href="#how">{L.landing.cards.card2.link}</ButtonLink>
					</CardFooter>
				</Card>
				<Card img={img3}>
					<CardHeader>{L.landing.cards.card3.title}</CardHeader>
					<CardContent><P1>{L.landing.cards.card3.content}</P1></CardContent>
					<CardFooter>
						<ButtonLink href="#ticket">{L.landing.cards.card3.link}</ButtonLink>
					</CardFooter>
				</Card>
			</CardStack>
		</Container>
	);
};

export default About;