import React from 'react';
import Container from "../components/Container";
import Heading from "../components/Heading";
import Accordion from "../components/Accordion";
import Styles from "./FAQ.module.scss"
import { L } from "../../../l10n/L10n";

const FAQ = () => (
	<Container className={Styles.container} id="#faq" fluid>
		<Heading
			title={L.landing.sections.faq.title}
			subTitle={L.landing.sections.faq.subtitle}
		/>
		<div className={Styles.accordions}>
			{
				Object.values(L.landing.accordions).map(({title, text}) => (
					<Accordion key={title} title={title}>{text}</Accordion>
				))
			}
		</div>
	</Container>
)

export default FAQ;
