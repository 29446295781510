import { action, makeObservable, observable } from "mobx";
import { UserUnstakeModel } from "./UserUnstakeModel";

export class UserUnstakesTable {
	items = new Map<number, UserUnstakeModel>();

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItem: action,
			clearItems: action,
		});
	}

	updateItems(records: UserUnstakeModel[])
	{
		for (let r of records)
			this.items.set(r.id, r);
	}

	query(predicate: (it: UserUnstakeModel) => boolean): UserUnstakeModel[]
	{
		let result = [];
		for (let item of this.items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll()
	{
		return this.query(() => true);
	}

	removeItem(id: number)
	{
		this.items.delete(id);
	}

	clearItems()
	{
		this.items.clear();
	}
}