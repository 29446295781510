import { ImageSize, IPlayerPuzzle, IPoint } from "./types";
import { CanvasPuzzlePlayer } from "./CanvasPuzzlePlayer";

export class PuzzleCluster {
	get oldPos(): IPoint
	{
		return this._oldPos;
	}

	private _puzzlesMap: Map<string, IPlayerPuzzle> = new Map<string, IPlayerPuzzle>();
	private _oldPos: IPoint = {x: 0, y: 0};

	constructor(private _player: CanvasPuzzlePlayer, private _puzzles: IPlayerPuzzle[], private _pos: IPoint)
	{
		this.setPuzzlesMap();
	}

	savePos()
	{
		this._oldPos = {...this._pos};
	}

	draw()
	{
		let puzzles = this._puzzles.sort((a, b) => (a.x + a.y * 10) - (b.x + b.y * 10))

		for (let p of puzzles) {
			let images = this._player.images.get(p.puzzle_id)!;
			if (!images)
				continue;

			let topLeftPoint = this.getPuzzleBaseTopLeftPoint(p);
			if (
				(topLeftPoint.x + this._player.canvas.width / 2 / this._player.scale + images.get(ImageSize.large)!.width) < 0 ||
				(topLeftPoint.y + this._player.canvas.height / 2 / this._player.scale + images.get(ImageSize.large)!.height) < 0 ||
				topLeftPoint.x + p.leftOffset * this._player.scale > this._player.canvas.width / 2 / this._player.scale ||
				topLeftPoint.y + p.topOffset * this._player.scale > this._player.canvas.height / 2 / this._player.scale
			)
				continue;

			let positionX = (topLeftPoint.x + p.leftOffset) * this._player.scale;
			let positionY = (topLeftPoint.y + p.topOffset) * this._player.scale;

			this._player.context.globalAlpha = !this._player.painting || this._player.painting.includes(p.puzzle_id) ? 1 : 0.5;
			if (this._player.context.globalAlpha === 1 && this._player.activeClusters.length > 1 && this._player.activeClusters.includes(this))
				this._player.context.globalAlpha = 0.9;

			let largeImg = images.get(ImageSize.large)!;

			let mediumImg = images.get(ImageSize.medium)!;
			let newWidth = Math.ceil(largeImg.width * this._player.scale);
			let newHeight = Math.ceil(largeImg.height * this._player.scale);

			let img = images.get(ImageSize.small);

			if (!img || (mediumImg && newWidth > img.width * 1.5))
				img = mediumImg;

			if (!img || newWidth > largeImg.width * 1.5)
				img = largeImg;

			this._player.context.drawImage(img, positionX, positionY, newWidth, newHeight);
			// this._player.context.font = "bold 48px serif";
			// this._player.context.strokeText(p.puzzle_id.toString(), positionX, positionY)
		}
	}

	getPuzzleBaseTopLeftPoint(p: IPlayerPuzzle): IPoint
	{
		return {
			x: this._player.canvas_position.x + this.pos.x + this._player.map.puzzle_width * p.x,
			y: this._player.canvas_position.y + this.pos.y + this._player.map.puzzle_height * p.y
		}
	}

	getPuzzleRect(p: IPlayerPuzzle): { x: number, y: number, w: number, h: number }
	{
		let topLeftPoint = this.getPuzzleBaseTopLeftPoint(p);
		return {
			x: topLeftPoint.x,
			y: topLeftPoint.y,
			w: this._player.map.puzzle_width,
			h: this._player.map.puzzle_height
		};
	}


	getCollized(c: IPoint, w: number = 0, h: number = 0): IPlayerPuzzle | null
	{
		for (let p of this._puzzles) {
			let images = this._player.images.get(p.puzzle_id)!;
			let topLeftPoint = this.getPuzzleBaseTopLeftPoint(p);
			let positionX = (topLeftPoint.x + p.leftOffset);
			let positionY = (topLeftPoint.y + p.topOffset);

			if (
				c.x < positionX + images.get(ImageSize.large)!.width &&
				c.x + w > positionX &&
				c.y < positionY + images.get(ImageSize.large)!.height &&
				h + c.y > positionY
			) {
				return p;
			}
		}

		return null;
	}

	get pos(): IPoint
	{
		return this._pos;
	}

	get width(): number
	{
		let lastX = this._puzzles.sort((a, b) => b.x - a.x)[0].x;
		return (lastX + 1) * this._player.map.puzzle_width
	}

	get height(): number
	{
		let lastY = this._puzzles.sort((a, b) => b.y - a.y)[0].y;
		return (lastY + 1) * this._player.map.puzzle_height
	}

	set pos(v: IPoint)
	{
		this._pos = v;
	}

	split(): PuzzleCluster[]
	{
		return this._puzzles.map(it => this.getNewClusterFromPuzzle(it));
	}

	removePuzzle(p: IPlayerPuzzle): PuzzleCluster | undefined
	{
		let index = this._puzzles.indexOf(p);
		if (index !== -1) {
			let oX = p.x;
			let oY = p.y;
			this._puzzlesMap.delete(`${p.x}_${p.y}`);
			this._puzzles.splice(index, 1);
			let newCluster = this.getNewClusterFromPuzzle(p);

			let w = Math.floor(this._player.map.puzzle_width * 0.05);
			if (this._puzzlesMap.get(`${oX - 1}_${oY}`)) {
				newCluster.pos.x += w;
			}
			if (this._puzzlesMap.get(`${oX + 1}_${oY}`)) {
				newCluster.pos.x -= w;
			}
			if (this._puzzlesMap.get(`${oX}_${oY - 1}`)) {
				newCluster.pos.y += w;
			}
			if (this._puzzlesMap.get(`${oX}_${oY + 1}`)) {
				newCluster.pos.y -= w;
			}

			return newCluster;
		}
	}

	private getNewClusterFromPuzzle(p: IPlayerPuzzle): PuzzleCluster
	{
		let pos = this.getPuzzleBaseTopLeftPoint(p);

		pos.x -= this._player.canvas_position.x;
		pos.y -= this._player.canvas_position.y;

		return new PuzzleCluster(this._player, [{...p, x: 0, y: 0}], {...pos});
	}

	merge(cluster: PuzzleCluster, mp: IPlayerPuzzle, cp: IPlayerPuzzle, mergeOffsetX: number, mergeOffsetY: number)
	{
		this.pos = {x: Math.min(this.pos.x, cluster.pos.x), y: Math.min(this.pos.y, cluster.pos.y)};

		let newPuzzles: IPlayerPuzzle[] = cluster.puzzles;
		let oldX = cp.x;
		let oldY = cp.y;
		let newX = mp.x + mergeOffsetX;
		let newY = mp.y + mergeOffsetY;

		let xOffset = newX - oldX;
		let yOffset = newY - oldY;

		newPuzzles.forEach(it => {
			it.x += xOffset;
			it.y += yOffset;
		});

		let allPuzzles = this.puzzles.concat(newPuzzles);
		let minX = allPuzzles.sort((a, b) => a.x - b.x)[0].x;
		let minY = allPuzzles.sort((a, b) => a.y - b.y)[0].y;

		for (let p of allPuzzles) {
			if (minX < 0)
				p.x += Math.abs(minX);
			if (minY < 0)
				p.y += Math.abs(minY);
		}

		this._puzzles = allPuzzles;
		this.setPuzzlesMap();
	}

	private setPuzzlesMap()
	{
		this._puzzlesMap = new Map<string, IPlayerPuzzle>();
		for (let p of this._puzzles)
			this._puzzlesMap.set(`${p.x}_${p.y}`, p);
	}

	canMergeWith(cluster: PuzzleCluster): { mp: IPlayerPuzzle, cp: IPlayerPuzzle, xOffset: number, yOffset: number } | null
	{

		const w = this._player.map.puzzle_width * 0.2;

		for (let mp of this._puzzles) {
			let mpRect = this.getPuzzleRect(mp)
			for (let cp of cluster._puzzles) {
				let cpRect = cluster.getPuzzleRect(cp);
				if (Math.abs(mpRect.x - cpRect.x) <= w && Math.abs(mpRect.y - cpRect.y) <= w)
					return null;
			}
		}

		for (let mp of this._puzzles) {
			let mpRect = this.getPuzzleRect(mp)
			for (let cp of cluster._puzzles) {
				let cpRect = cluster.getPuzzleRect(cp);

				if (Math.abs(mpRect.x - (cpRect.x + cpRect.w)) < w && Math.abs(mpRect.y - cpRect.y) < w) {
					return {mp, cp, xOffset: -1, yOffset: 0};
				}
				if (Math.abs((mpRect.x + mpRect.w) - cpRect.x) < w && Math.abs(mpRect.y - cpRect.y) < w)
					return {mp, cp, xOffset: 1, yOffset: 0};
				if (Math.abs(mpRect.y - (cpRect.y + cpRect.h)) < w && Math.abs(mpRect.x - cpRect.x) < w)
					return {mp, cp, xOffset: 0, yOffset: -1};
				if (Math.abs((mpRect.y + mpRect.h) - cpRect.y) < w && Math.abs(mpRect.x - cpRect.x) < w) {
					return {mp, cp, xOffset: 0, yOffset: 1};
				}
			}
		}
		return null;
	}

	get puzzles(): IPlayerPuzzle[]
	{
		return this._puzzles;
	}
}
