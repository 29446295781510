import { AAsset, AFormat } from "@pro/common/contracts/atomicassets";
import { TUint64 } from "@pro/common/contracts/common_types";
import { EosAsset, EosSymbol, IEosAccount } from "@pro/common/eos";
import { sleep } from "@pro/common/utils";
import { deserialize, ObjectSchema } from "atomicassets";
import { app, eos, model } from "../App";
import { AtomicAssetModel } from "../models/AtomicAssetModel";
import { StakingPoolModel } from "../models/StakingPoolModel";
import { ITokenStat } from "@pro/common/contracts/staking";
import { StakingAssetModel } from "../models/StakingAssetModel";
import { AtomicTemplateModel } from "../models/AtomicTemplateModel";
import { PoolAssetModel } from "../models/PoolAssetModel";
import { PoolPeriodModel } from "../models/PoolPeriodModel";
import { UserPoolModel } from "../models/UserPoolModel";
import { UserUnstakeModel } from "../models/UserUnstakeModel";
import { PoolUserModel } from "../models/PoolUserModel";
import { PaintingModel } from "../models/PaintingModel";
import { LotteryModel } from "../models/LotteryModel";
import { NeftyClaimAssetsModel } from "../models/NeftyClaimAssetsModel";
import { INpClaimAsset } from "@pro/common/contracts/neftyblocksp";
import { IHpainting } from "@pro/common/contracts/expo";

const NUM_OF_RETRIES = 60;
const SLEEP_TIME = 500;

export class WorldService {

	async updateConfig()
	{
		let c = await eos.expoContract.getConfig();
		model.setConfig(c);
	}

	async updateAccount(name: string, force: boolean = false)
	{
		if (!force && model.data.accounts.get(name))
			return;

		let data: IEosAccount | Error = new Error("Account not found");

		try {
			data = await eos._eosApi.getAccount(name);
		} catch {
		}
		model.updateAccount(name, data);
	}

	async updateTokenStat(contract: string, force: boolean = false)
	{
		if (!force && model.data.tokens.get(contract))
			return;

		const scopes = await eos._eosApi.getScopes("stat", {code: contract});
		let stats = [];
		for (let r of scopes.rows) {
			const res = await eos._eosApi.getTable<ITokenStat>({
				code: contract,
				table: "stat",
				scope: r.scope,
				key_type: "symbol_code"
			});

			let data: ITokenStat | undefined = res.rows[0];
			if (data)
				stats.push(data)
		}
		model.updateTokenStat(contract, stats);
	}

	async updateBalance(contract: string, symbol: EosSymbol, force: boolean = false)
	{
		if (!force && model.getBalance(symbol).amount !== -1)
			return;
		const balance = await eos.getBalance(contract, symbol);
		model.setBalance(balance);
	}

	async mustUpdateBalance(contract: string, symbol: EosSymbol)
	{
		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			const balance = await eos.getBalance(contract, symbol);
			if (!model.getBalance(symbol).equals(balance)) {
				model.setBalance(balance);
				break;
			} else {
				await sleep(SLEEP_TIME);
			}
		}
	}

	async updateAllStakedAssets(user: string)
	{
		let assets = (await eos.stContract.getAllStakedAssets(user)).map(it => new StakingAssetModel(it));
		model.stakedAssets.updateItems(user, assets);
	}

	async updateAllUserPools()
	{
		let assets = await eos.stContract.getAllUserPools(model.userName);
		model.userPools.updateItems(model.userName, assets.map(it => new UserPoolModel(it)));
	}

	async updateUserPool(owner: string, pool_id: number)
	{
		let u_pool = await eos.stContract.findUserPool(owner, pool_id);
		if (u_pool)
			model.userPools.updateItems(model.userName, [new UserPoolModel(u_pool)])
	}

	async updateALlTokenBalances(force: boolean = false)
	{
		const tokens: { [account: string]: EosSymbol[] } = {};
		for (let p of (model.config?.market_config || []).map(it => it.value.price).flat())
			// for (let p of (model.config?.market_config || []).map(it=>it.value.price).concat(model.config?.lottery_config.map(it=>it.value.attempt_price) || []).flat())
		{
			let s = EosAsset.parse(p.quantity).symbol;
			if (tokens[p.contract] && tokens[p.contract].find(it => it.code === s.code))
				continue;

			tokens[p.contract] = [...(tokens[p.contract] || []), s]
		}

		const toLoad = Object.keys(tokens).map(acc => tokens[acc as keyof typeof tokens].map(code => {
			return {acc: acc, code: code}
		})).flat();

		const promises = Object.values(toLoad)
			.map(c => this.updateBalance(c.acc, c.code, force));
		return Promise.all(promises);
	}

	async loadUserPool(owner: string, pool_id: number)
	{
		return await eos.stContract.findUserPool(owner, pool_id);
	}

	async updateUserPaintings(owner: string)
	{
		let records = await eos.expoContract.getAllPaintings(owner);
		model.paintings.updateItems(records.map(it => new PaintingModel(it)))
	}

	async updateUserPainting(owner: string, painting_id: number)
	{
		let record = await eos.expoContract.getPainting(owner, painting_id);
		model.paintings.updateItems([new PaintingModel(record)])
	}

	async updateUserLottery(owner: string)
	{
		let record = await eos.expoContract.findLotteryByOwner(owner);
		model.lottery.updateItem(record ? new LotteryModel(record) : undefined);
	}

	async updateUserUnstakes(owner: string)
	{
		let records = await eos.stContract.getAllUserUnstake(owner);
		model.userUnstakes.updateItems(records.map(it => new UserUnstakeModel(it)))
	}

	async loadAtomicAssetModel(owner: string, assetID: string): Promise<AtomicAssetModel>
	{
		let asset = await eos.aaContract.getAsset(owner, assetID);
		return this.getAtomicAssetModel(asset);
	}

	async updateAtomicAssets(force: boolean = false)
	{
		if (!force && model.atomicAssets.count !== 0)
			return;

		let assets = await eos.aaContract.getAllAssets(model.userName);

		assets = assets.filter(it => it.collection_name === app.chainConf.COLLECTION_NAME && it.schema_name !== "promo");

		if (assets.length === 0)
			return;

		const models = await this.getAtomicAssetModels(assets);

		model.atomicAssets.updateItems(models);
	}

	async updateAllPools()
	{
		let pools = (await eos.stContract.getAllPools()).map(it => new StakingPoolModel(it));
		model.pools.updateItems(pools);
		for (let p of pools) {
			await this.updatePoolAssets(p.poolId)

			if (p.owner === model.userName) {
				this.updatePoolPeriods(p.poolId).catch();
				this.updatePoolUsers(p.poolId).catch();
			}
		}
	}

	async updatePool(poolId: number)
	{
		let pool = await eos.stContract.findPool(poolId);
		if (pool) {
			let poolModel = new StakingPoolModel(pool);
			model.pools.updateItems([poolModel]);
		}
	}

	async updatePoolAssets(poolId: number)
	{
		let pool = model.pools.items.get(poolId);
		if (pool) {
			let periods = await eos.stContract.getAllPoolAssets(poolId);
			model.poolAssets.updateItems(poolId, periods.map(it => new PoolAssetModel(it)));
		}
	}

	async updatePoolPeriods(poolId: number, lower: number = 0, upper: number | undefined = undefined)
	{
		let periods = await eos.stContract.getSelectedPoolPeriods(poolId, lower, upper);
		model.poolPeriods.updateItems(poolId, periods.map(it => new PoolPeriodModel(it)));
	}

	async updatePoolUsers(poolId: number)
	{
		let periods = await eos.stContract.getAllPoolUsers(poolId);
		model.poolUsers.updateItems(poolId, periods.map(it => new PoolUserModel(it)));
	}

	// async loadPoolPeriods(poolId: number, lower: number)
	// {
	//     let periods =  await eos.stContract.getSelectedPoolPeriods(poolId, lower);
	//     model.poolPeriods.updateItems(poolId, periods.map(it=>new PoolPeriodModel(it)));
	// }

	async updateNeftyClaimAssets()
	{
		let records = await eos.npContract.getClaimAssetsByUser(model.userName);
		records = records.filter(it => it.collection_name === app.chainConf.COLLECTION_NAME);
		const models = new Array<NeftyClaimAssetsModel>();
		for (let record of records) {
			// const assetIds = record.claims.map(it => it.claim[1].asset_id);
			// const assetModels = new Array<AtomicAssetModel>();
			// for (const assetId of assetIds) {
			// 	const asset = await eos.aaContract.getAsset(app.chainConf.NP_ACCOUNT, assetId);
			// 	const assetModel = await this.getAtomicAssetModel(asset);
			// 	assetModels.push(assetModel);
			// }
			// models.push(new NeftyClaimAssetsModel(record, assetModels));
			const neftyClaimModel = await this.getNeftyClaimAssetsModel(record);
			models.push(neftyClaimModel);
		}
		model.neftyClaimAssets.updateItems(models);
	}

	async mustUpdateNeftyClaimAssets(packAssetId: TUint64)
	{
		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			const record = await eos.npContract.getClaimAsset(packAssetId);
			if (record) {
				const neftyClaimModel = await this.getNeftyClaimAssetsModel(record);
				model.neftyClaimAssets.updateItems([neftyClaimModel]);
				break;
			} else {
				await sleep(SLEEP_TIME);
			}
		}
	}

	async getNeftyClaimAssetsModel(record: INpClaimAsset): Promise<NeftyClaimAssetsModel>
	{
		const assetIds = record.claims.map(it => it.claim[1].asset_id);
		const assetModels = new Array<AtomicAssetModel>();
		for (const assetId of assetIds) {
			const asset = await eos.aaContract.getAsset(app.chainConf.NP_ACCOUNT, assetId);
			const assetModel = await this.getAtomicAssetModel(asset);
			assetModels.push(assetModel);
		}
		return new NeftyClaimAssetsModel(record, assetModels)
	}

	async updateAtomicAsset(assetId: TUint64)
	{
		let asset = await eos.aaContract.getAsset(model.userName, assetId);
		const assetModel = await this.getAtomicAssetModel(asset);
		model.atomicAssets.updateItems([assetModel]);
	}

	async loadCollection(collectionName: string)
	{
		if (model.atomicTemplates._collections.get(collectionName))
			return;

		await this.updateAllSchemas(collectionName);
		await this.updateAllTemplates(collectionName);
	}

	async updateAllSchemas(collectionName: string)
	{
		const records = await eos.aaContract.getAllSchemas(collectionName);
		for (let r of records)
			model.atomicSchemas.updateItem(collectionName, r);
	}

	async updateAllTemplates(collectionName: string)
	{
		const records = await eos.aaContract.getAllTemplates(collectionName);
		let templates = records.map(it => {
			const schema = model.atomicSchemas.getItem(collectionName, it.schema_name)!;
			let data = WorldService.deserializeAtomicData(schema.format, it.immutable_serialized_data)
			return new AtomicTemplateModel(it, data);
		});

		model.atomicTemplates.updateCollection(collectionName, templates);
	}

	async updateHPaintings(paintingIds: number[])
	{
		const records: IHpainting[] = []
		for (let paintingId of paintingIds) {
			const painting = await eos.expoContract.getPaintingChecksum(paintingId);
			if (painting)
				records.push(painting);
		}
		model.hPaintings.updateItem(paintingIds, records);
	}

	private static async getTemplate(collectionName: string, templateId: TUint64): Promise<AtomicTemplateModel | undefined>
	{
		if (templateId === -1)
			return;

		let template = model.atomicTemplates.getItem(collectionName, templateId);
		if (template)
			return template;

		let record = await eos.aaContract.findTemplate(collectionName, templateId);
		if (record) {
			const schema = model.atomicSchemas.getItem(collectionName, record.schema_name)!;
			template = new AtomicTemplateModel(record, this.deserializeAtomicData(schema.format, record.immutable_serialized_data))
			model.atomicTemplates.updateItem(collectionName, template);
		}

		return template;
	}

	private static async getAtomicSchema(collection: string, schemaName: string)
	{
		let record = model.atomicSchemas.getItem(collection, schemaName);
		if (record)
			return record;

		record = await eos.aaContract.findSchema(collection, schemaName);
		if (record)
			model.atomicSchemas.updateItem(collection, record);

		return record;
	}

	private async getAtomicAssetModels(assets: AAsset[]): Promise<AtomicAssetModel[]>
	{
		const atomicModels = new Array<AtomicAssetModel>();

		for (const asset of assets) {
			const rpAssetModel = await this.getAtomicAssetModel(asset);
			atomicModels.push(rpAssetModel);
		}

		return atomicModels;
	}

	private async getAtomicAssetModel(asset: AAsset)
	{
		const immutableData = await this.getAtomicImmutableAssetData(asset);
		const mutableData = await this.getAtomicMutableAssetData(asset);

		return new AtomicAssetModel(
			asset,
			immutableData,
			mutableData,
		);
	}

	private async getAtomicMutableAssetData(asset: AAsset)
	{
		const schema = await WorldService.getAtomicSchema(asset.collection_name, asset.schema_name);
		if (!schema)
			throw new Error("Schema not found");

		return WorldService.deserializeAtomicData(schema.format, asset.mutable_serialized_data);
	}

	private async getAtomicImmutableAssetData(asset: AAsset)
	{
		if (app.useTemplateFIle)
			return app.assetConf.getByTemplateId(asset.template_id);

		const schema = await WorldService.getAtomicSchema(asset.collection_name, asset.schema_name);
		if (!schema)
			throw new Error("Schema not found");

		if (asset.template_id === -1)
			return WorldService.deserializeAtomicData(schema.format, asset.immutable_serialized_data);

		const template = await WorldService.getTemplate(asset.collection_name, asset.template_id);
		if (!template)
			throw new Error("Template not found");

		return WorldService.deserializeAtomicData(schema.format, template.immutable_serialized_data);
	}

	// private async getAtomicTemplateData(collectionName: string, templateId: number)
	// {
	// 	const template = await this.getTemplate(collectionName, templateId);
	// 	if (!template)
	// 		throw new Error("Template not found");
	//
	// 	const schema = await this.getAtomicSchema(collectionName, template.schema_name);
	// 	if (!schema)
	// 		throw new Error("Schema not found");
	//
	// 	return this.deserializeAtomicData(schema.format, template.immutable_serialized_data);
	// }

	// getLoadedAtomicTemplateData(collectionName: string, templateId: number) {
	//     const template = model.atomicTemplates.getItem(collectionName, templateId)!;
	//     const schema = model.atomicSchemas.getItem(collectionName, template.schema_name)!;
	//     return WorldService.deserializeAtomicData(schema.format, template.immutable_serialized_data)
	// }

	private static deserializeAtomicData(schemaFormat: AFormat[], data: Uint8Array)
	{
		const objectSchema = ObjectSchema(schemaFormat);
		return deserialize(data, objectSchema);
	}
}
