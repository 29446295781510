import { AAsset } from "@pro/common/contracts/atomicassets";
import { TUint64 } from "@pro/common/contracts/common_types";
import { makeLinkToAssetMedia } from "../helpers/image_helper";
import { TRarity } from "@pro/common/conf";

export class AtomicAssetModel {
	constructor(
		public readonly asset: AAsset,
		public readonly immutableData: { [id: string]: TUint64 },
		public readonly mutableData: { [id: string]: TUint64 },
	)
	{
	}

	getImmutableProp(propName: string)
	{
		return this.immutableData[propName];
	}

	getMutableProp(propName: string)
	{
		return this.mutableData[propName];
	}

	get assetId(): TUint64
	{
		return this.asset.asset_id;
	}

	get schemaName()
	{
		return this.asset.schema_name;
	}

	get name()
	{
		return this.getImmutableProp("name") as string;
	}

	get author()
	{
		return this.getImmutableProp("author") as string;
	}

	get expo()
	{
		return this.getImmutableProp("expo") as string;
	}

	get img()
	{
		return makeLinkToAssetMedia(this.getImmutableProp("img") as string);
	}

	get rarity(): TRarity
	{
		return this.getImmutableProp("rarity") as TRarity;
	}

	get paintingId(): number
	{
		return this.getImmutableProp("painting_id") as number;
	}
}
