import { action, makeObservable, observable } from "mobx";
import { PaintingModel } from "./PaintingModel";

export class PaintingsTable {
	items:{[key: number]: PaintingModel} = {};

	constructor()
	{
		makeObservable(this, {
			items: observable,
			updateItems: action,
			removeItem: action,
			clearItems: action,
		});
	}

	updateItems(records: PaintingModel[])
	{
		let newItems:{[key: number]: PaintingModel} = {};

		for (let r of records)
			newItems[r.id] = r;

		this.items = {...this.items, ...newItems};
	}

	query(predicate: (it: PaintingModel) => boolean): PaintingModel[]
	{
		let result = [];
		for (let key of Object.keys(this.items))
		{
			let item = this.items[key as unknown as number];
				if (predicate(item))
					result.push(item);
		}
		return result;
	}

	queryAll()
	{
		return this.query(() => true);
	}

	removeItem(id: number)
	{
		delete this.items[id]
	}

	clearItems()
	{
		this.items = {}
	}

	get(id:number):PaintingModel | undefined
	{
		return this.items[id];
	}
}