import React from 'react';

const RedoIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M16.0006 5.00001C17.5767 4.99795 19.1377 5.30746 20.5939 5.91075C22.05 6.51403 23.3725 7.39918 24.4854 8.51532L28 5.00001V14H19L22.3636 10.6363C21.5114 9.78258 20.4962 9.10891 19.3785 8.65531C18.2607 8.20171 17.0632 7.97744 15.857 7.99582C14.6509 8.0142 13.4607 8.27486 12.3573 8.76232C11.2539 9.24977 10.2598 9.95406 9.43394 10.8333C8.60811 11.7126 7.96746 12.7489 7.55008 13.8807C7.13269 15.0125 6.94709 16.2166 7.00428 17.4216C7.06148 18.6265 7.36032 19.8076 7.88303 20.8948C8.40575 21.9819 9.14168 22.9529 10.0471 23.75L8.06258 26C6.23446 24.3878 4.94066 22.2571 4.35334 19.8914C3.76602 17.5258 3.91304 15.0373 4.77482 12.7573C5.63661 10.4772 7.1723 8.5137 9.17754 7.12795C11.1828 5.7422 13.5625 4.99997 15.9999 5.00001H16.0006Z"
			fill={color}/>
	</svg>
);

export default RedoIcon;
