import { EosActionBase } from "../../EosActionBase";
import { eos, model, world } from "../../../App";
import { EosAsset } from "@pro/common/eos";
import { StakingPoolModel } from "../../../models/StakingPoolModel";
import { TUint64 } from "@pro/common/contracts/common_types";

export class UnstakeAction extends EosActionBase {
	constructor(private quantity: EosAsset, private pool: StakingPoolModel, private asset_ids: TUint64[] = [])
	{
		super("unstake");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("unstake", eos.userAuth, {
			pool_id: this.pool.poolId,
			quantity: this.quantity.toString(),
			owner: eos.user,
			asset_ids: this.asset_ids
		})
	}

	async onSelfUpdate(): Promise<void>
	{
		if ((this.pool.unstakeSec === 0 || this.pool.ended || !this.pool.started)) {
			if (this.quantity.amount > 0)
				await world.mustUpdateBalance(this.pool.acceptContract, this.pool.acceptSymbol);
			if (this.asset_ids.length > 0)
				await world.updateAtomicAssets(true);
		} else {
			await world.updateAllStakedAssets(model.userName)
		}

		if (this.asset_ids.length > 0) {
			model.stakedAssets.removeItems(model.userName, this.asset_ids);
		}

		await world.updatePool(this.pool.poolId);
		await world.updateUserPool(model.userName, this.pool.poolId);
		await world.updateUserUnstakes(model.userName);
	}
}