import React from "react";
import LandingHeader from "./sections/LandingHeader";
import LandingFooter from "./sections/LandingFooter";
import About from "./sections/About";
import Ticket from "./sections/Ticket";
import Profit from "./sections/Profit";
import Banner from "./sections/Banner";
import FAQ from "./sections/FAQ";
import Styles from "./LandingPage.module.scss"
import AreYouReady from "./sections/AreYouReady";
import useWindowSize from "../../hooks/useWindowSize";
import HowItWorks from "./sections/HowItWorks";
import HowItWorksSlider from "./sections/HowItWorksSlider";
import Partnership from "./sections/Partnership";
import CollectorPrize from "./sections/CollectorPrize";

const LandingPage = () => {
	const {windowWidth} = useWindowSize();

	return (
		<div className={Styles.landing}>
			<LandingHeader/>
			<Banner/>
			<About/>
			<Profit/>
			{windowWidth < 1025 ? <HowItWorks/> : <HowItWorksSlider/>}
			<CollectorPrize/>
			<Partnership/>
			<Ticket/>
			<FAQ/>
			<AreYouReady/>
			<LandingFooter/>
		</div>

	)
};

export default LandingPage;