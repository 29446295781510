import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { CanvasPuzzlePlayer } from "../../core/CanvasPuzzlePlayer";
import Styles from "./GalleryPage.module.scss";
import { app, eos, model } from "../../App";
import { useParams } from "react-router-dom";
import { FinishPaintingAction } from "../../eos/actions/FinishPaintingAction";
import { IPuzzlePlayerMap } from "../../core/types";
import { PuzzlePlayerController } from "../../core/PuzzlePlayerController";
import Toolbar from "./components/Tollbar";
import Menu from "./components/Menu";
import ShowLostIcon from "../../icons/ShowLostIcon";
import MoveIcon from "../../icons/MoveIcon";
import MagnetIcon from "../../icons/MagnetIcon";
import ArrangeIcon from "../../icons/ArrangeIcon";
import CenterIcon from "../../icons/CenterIcon";
import UndoIcon from "../../icons/UndoIcon";
import RedoIcon from "../../icons/RedoIcon";
import AddPuzzlesView from "../../components/puzzles/AddPuzzlesView";
import RemovePuzzlesView from "../../components/puzzles/RemovePuzzlesView";
import classNames from "classnames";
import Button from "../landing/components/Button";
import { L } from "../../l10n/L10n";
import HelpView from "../../components/puzzles/HelpView";
import Loading from "../../components/shared/Loading";
import CongratsPopup from "./components/CongratsPopup";
import Timer from "./components/Timer";

async function digestMessage(message: string)
{

	const msgUint8 = (new TextEncoder()).encode(message);
	const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

const GalleryPage = observer(() => {
	const params = useParams();
	const painting_conf = (model.config?.paintings_config || []).find(it => it.key === parseInt(params["id"] || "-1"));

	const [player, setPlayer] = useState<CanvasPuzzlePlayer>();
	const [loading, setLoading] = useState(0);
	const [canFinish, setCanFinish] = useState(false)
	const [isSuccessed, setIsSuccessed] = useState<number | undefined>()
	const [addPuzzlesMode, setAddPuzzlesMode] = useState(false)
	const [removePuzzlesMode, setRemovePuzzlesMode] = useState(false)
	const [movingMode, setMovingMode] = useState(false);
	const [showHint, setShowHint] = useState(false);
	const [canMoveForward, setCanMoveForward] = useState(false)
	const [canMoveBack, setCanMoveBack] = useState(false)
	const [toolsMode, setToolsMode] = useState(true);
	const [spentTime, setSpentTime] = useState(0);
	const [totalPuzzles, setTotalPuzzles] = useState(0);
	const [showMissedPuzzles, setShowMissedPuzzles] = useState(true);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const painting_identifier = painting_conf ? painting_conf.key : -1;
	let painting = model.paintings.get(painting_identifier);
	let neededPuzzles = model.atomicAssets.getPaintingNeededFromAvailable(painting?.depositedPuzzlesIds || [], painting_identifier);

	const handleResize = () => {
		if (player) {
			player!.resize();
			player!.draw();
		}
	};

	useEffect(() => {
		let controller: PuzzlePlayerController;
		if (player) {
			window.addEventListener("resize", handleResize);
			controller = new PuzzlePlayerController(canvasRef.current!, player);
		}

		return () => {
			if (player) {
				window.removeEventListener("resize", handleResize);
				controller.unsubscribe();
			}
		}
	}, [player]);

	useEffect(() => {
		if (player) {
			const isComposed = totalPuzzles > 0 && totalPuzzles === (painting?.depositedPuzzlesIds || []).length;
			setCanFinish(painting !== undefined && player.checksum !== "" && isComposed);
		}
	}, [painting?.depositedPuzzlesIds.length, player]);

	let initPlayerListeners = (player: CanvasPuzzlePlayer) => {
		player.onReady = () => {
			player.painting = painting?.depositedPuzzlesIds || [];
			const isComposed = totalPuzzles > 0 && totalPuzzles === (painting?.depositedPuzzlesIds || []).length;
			setCanFinish(painting !== undefined && player.checksum !== "" && isComposed);
			setSpentTime(player.timeSpent)
		}

		player.stateChanged = () => {
			setLoading(player.loadingPercent);
			setMovingMode(player.moving);
			setCanMoveForward(player.canMoveStep(1));
			setCanMoveBack(player.canMoveStep(-1));
			const isComposed = totalPuzzles > 0 && totalPuzzles === (painting?.depositedPuzzlesIds || []).length;
			setCanFinish(painting !== undefined && player.checksum !== "" && isComposed);
		}
	}

	useEffect(() => {
		if (painting_identifier === -1 || player)
			return;

		fetch(`/paintings/${painting_identifier}/map.json`).then(async (response) => {
			const map = (await response.json()) as IPuzzlePlayerMap;
			map.painting_id = painting_identifier;
			setTotalPuzzles(map.puzzles.length)
			const puzzlePlayer = new CanvasPuzzlePlayer(canvasRef.current!, map);
			initPlayerListeners(puzzlePlayer);
			setPlayer(puzzlePlayer);
			puzzlePlayer.init().catch();
		})
	}, [painting_identifier]);

	useEffect(() => {
		if (painting && player) {
			initPlayerListeners(player);
			player.painting = painting?.depositedPuzzlesIds || [];
		}
	}, [model.paintings.items, player])

	const missedPuzzlesHandler = (e: React.ChangeEvent<HTMLInputElement>) => setShowMissedPuzzles(e.target.checked)

	useEffect(() => {
		if (player) {
			player!.painting = showMissedPuzzles ? (painting?.depositedPuzzlesIds || []) : undefined
		}
	}, [showMissedPuzzles, player])
	// if (!painting)
	// 	return <h1>Loading...</h1>

	if (!model.config)
		return <Loading/>

	if (!painting_conf)
		return <h1>Config not found</h1>

	function onAddPuzzlesMode()
	{
		setRemovePuzzlesMode(false)
		setAddPuzzlesMode(true)
	}

	function onRemovePuzzlesMode()
	{
		setAddPuzzlesMode(false)
		setRemovePuzzlesMode(true)
	}

	return <>
		{loading < 100 && (
			<Loading loading={loading}/>
		)}
		{addPuzzlesMode && <AddPuzzlesView
            painting_id={painting_identifier}
            onClose={() => {
				setAddPuzzlesMode(false)
			}}/>}
		{removePuzzlesMode && <RemovePuzzlesView
            painting_id={painting_identifier}
            onClose={() => {
				setRemovePuzzlesMode(false)
			}}/>}
		{!toolsMode && <div className={Styles.tools_b}>
            <Button onClick={() => setToolsMode(true)}>show</Button>
        </div>}

		{
			loading === 100 && player && <Timer player={player} initialValue={spentTime}/>
		}

		{loading === 100 && toolsMode && ["dev", "stage"].includes(app.projEnv) &&
            <Toolbar className={Styles.temp}>
                <Button onClick={() => {
					player!.make(painting_identifier);
				}}>make</Button>

            </Toolbar>
		}

		<div className={classNames(Styles.canvas_container, movingMode && Styles.movable)}>
			<canvas className={loading < 100 ? Styles.loading_content : Styles.puzzles_container} ref={canvasRef}
					onWheel={(e) => {
						if (player)
							player.setScale((e.deltaY > 0 ? player!.scale * 1.05 : player!.scale * 0.95), e as unknown as MouseEvent);
					}}
			/>
		</div>
		{
			loading === 100 && (
				<Toolbar>
					<button className={Styles.button}>
						<input type="checkbox" checked={showHint}
							   onChange={() => setShowHint(prevState => !prevState)}/>
						<ShowLostIcon/>
					</button>
					<div className={Styles.button}>
						<input type="checkbox" checked={movingMode}
							   onChange={(e) => player!.moving = e.target.checked}/>
						<MoveIcon/>
					</div>
					<button className={Styles.button}>
						<input type={"checkbox"} onChange={(e) => player!.autoMagnet = e.target.checked}/>
						<MagnetIcon/>
					</button>
					<button onClick={() => player!.rearrange()} className={Styles.button}><ArrangeIcon/></button>
					<button onClick={() => player!.recenter()} className={Styles.button}><CenterIcon/></button>
					<span className={Styles.divider}/>
					<button onClick={() => player!.moveState(-1)} disabled={!canMoveBack} className={Styles.button}>
						<UndoIcon/></button>
					<button onClick={() => player!.moveState(1)} disabled={!canMoveForward} className={Styles.button}>
						<RedoIcon/></button>
					<span className={Styles.divider}/>
				</Toolbar>
			)
		}
		{
			player && (
				<Menu
					paintingConf={painting_conf}
					player={player}
					neededPuzzles={neededPuzzles}
					painting={painting}
					onAddPuzzlesMode={onAddPuzzlesMode}
					onRemovePuzzlesMode={onRemovePuzzlesMode}
					showMissedPuzzles={showMissedPuzzles}
					onShowMissedPuzzles={missedPuzzlesHandler}
				/>
			)
		}

		{
			canFinish && loading == 100 && (
				<Button
					className={Styles.compose}
					onClick={async () => {
						const hash = await eos.expoContract.getPaintingChecksum(painting_identifier);
						const painting_hash_core = await digestMessage(player!.checksum);
						const current_painting_hash = await digestMessage(`${painting_hash_core}${hash?.id}`);
						new FinishPaintingAction(current_painting_hash, painting_conf.key)
							.onSuccess(() => setIsSuccessed(hash?.id))
							.executeAsync();
					}}>
					{L.button.compose}
				</Button>
			)
		}
		{
			showHint && <HelpView painting_id={painting_identifier} onClose={() => setShowHint(false)}/>
		}
		{
			isSuccessed !== undefined && <CongratsPopup collector_template_id={isSuccessed === 0 ? painting_conf.value.collector_template_id : undefined}
														pack_template_id={painting_conf.value.pack_template_id}
														author={painting_conf.value.author}
			/>
		}
	</>
});

export default GalleryPage;
