import React from 'react';

const ChevronDownIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path fillRule="evenodd" clipRule="evenodd"
			  d="M6.16051 9.23657C6.40012 8.95251 6.82099 8.91962 7.10054 9.1631L12 13.4304L16.8995 9.1631C17.179 8.91962 17.5999 8.95251 17.8395 9.23657C18.0791 9.52063 18.0467 9.94829 17.7672 10.1918L12.4339 14.8369C12.1842 15.0544 11.8158 15.0544 11.5661 14.8369L6.23282 10.1918C5.95327 9.94829 5.92089 9.52063 6.16051 9.23657Z"
			  fill={color}/>
	</svg>
);

export default ChevronDownIcon;
