import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { model, world } from "../../../App";
import AdminLayout from "../../shared/AdminLayout";
import { observer } from "mobx-react";
import Styles from "./AdminPoolUsers.module.scss";
import Button from "../../shared/Button";
import { EosAsset } from "@pro/common/eos";
import { getPathById, PagePath } from "../../../helpers/PageHelper";

const AdminPoolUsers = observer(() => {
	const params = useParams();
	const navigate = useNavigate();
	const users = model.poolUsers.get(parseInt((params["id"] || "-1")));
	const pool = model.pools.items.get(parseInt(params["id"] || "-1"));
	if (!pool)
		return <AdminLayout content={<h1>Pool {params["id"]} not found</h1>}/>

	return <AdminLayout content={
		<>
			<h1>
				Pool #{pool.poolId} users
				<Button text={"refresh"} onClick={() => world.updatePoolUsers(pool.poolId)}/>
				<Button text={"back"} onClick={() => navigate(PagePath.ADMIN_POOLS)}/>
			</h1>
			<hr/>
			{
				users.length > 0 ? <table className={Styles.table}>
					<thead>
					<th>Account</th>
					{pool.acceptContract !== "" && <th>Tokens staked</th>}
					<th>Assets power staked</th>
					<th>Actions</th>
					</thead>
					<tbody>
					{users.map(it => <tr>
						<td>{it.account}</td>
						{pool.acceptContract !== "" &&
                            <td>{new EosAsset(it.staked_tokens, pool.acceptSymbol).toString()}</td>}
						<td>{it.staked_assets_power}</td>
						<td><Button text={"details"} onClick={() => {
							world.updateUserPool(it.account, pool.poolId).catch();
							world.updateAllStakedAssets(it.account).catch();
							navigate(getPathById(PagePath.ADMIN_POOL_USER, pool.poolId, it.account));
						}}/></td>
					</tr>)}
					</tbody>
				</table> : <h1>Pool {params["id"]} have no users</h1>
			}
		</>
	}/>
});

export default AdminPoolUsers;