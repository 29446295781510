import React from "react";
import "react-notifications-component/dist/theme.css";
import HeadBase from "./HeadBase";
import NotificationView from "./NotificationView";
import { PagePath } from "../../helpers/PageHelper";
import AuthView from "./AuthView";
import Styles from "./AdminLayout.module.scss";
import NavigationView, { INavigationLink } from "./NavigationView";

const AdminLayout = ({content}: { content: JSX.Element }) => {

	const links: INavigationLink[] = [
		{
			name: "chainexpo",
			path: PagePath.ROOT,
		}, {
			name: "My pools",
			path: PagePath.ADMIN_POOLS
		},
		{
			name: "Create pool",
			path: PagePath.NEW_POOL
		}
	];

	return <>
		<HeadBase/>
		<NotificationView/>
		<header>
			<NavigationView links={links}/>
			<AuthView/>
		</header>
		<div className={Styles.container}>
			{content}
		</div>
	</>
};

export default AdminLayout;