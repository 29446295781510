import { EosActionBase } from "../../EosActionBase";
import { eos, world } from "../../../App";
import { StakingPoolModel } from "../../../models/StakingPoolModel";

export class CleanupPoolAction extends EosActionBase {
	constructor(private pool: StakingPoolModel)
	{
		super("cleanup");
	}

	async onExecute(): Promise<void>
	{
		await eos.stContract.action("cleanpool", eos.userAuth, {pool_id: this.pool.poolId, limit: 100});
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.updatePool(this.pool.poolId);
	}
}