import React from 'react';
import Styles from "./H1.module.scss";
import classNames from "classnames";

export const H1 = ({children, className}: React.HTMLAttributes<HTMLElement>) => (
	<h1 className={classNames(Styles.h1, className)}>
		{children}
	</h1>
);

export default H1;
