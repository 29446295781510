import React from "react";
import { observer } from "mobx-react";
import { app, model, notificationService } from "../../App";
import TopNavigation from "../../components/shared/TopNavigation";
import CardStack from "../../components/shared/Card/CardStack";
import ShopCard from "../../components/shared/Card/ShopCard";
import { BuyAction } from "../../eos/actions/BuyAction";
import { IMarketConf } from "@pro/common/contracts/expo";
import { L } from "../../l10n/L10n";
import PageContainer from "../../components/shared/PageContainer";
import { makeLinkToAssetMedia } from "../../helpers/image_helper";
import Loading from "../../components/shared/Loading";
import ARTBalance from "../../components/shared/ARTBalance";
import P3 from "../../components/typography/P3";

const ShopPage = observer(() => {
	const [amounts, setAmounts] = React.useState<{ [key: number]: number | string }>({});
	const [balance] = model.getBalance(app.chainConf.TKN_SYMBOL).formatNumberWithPrecision();

	function getPackSortWeight(pack: IMarketConf)
	{
		return pack.value.limit - pack.value.total_sales <= 0
			? pack.value.start_at + 1_000_000_000
			: pack.value.start_at;
	}

	const sortedPacks = React.useMemo(() => {
		if (model.config?.market_config.length) {
			return model.config.market_config.slice()
				.sort((it1, it2) => getPackSortWeight(it1) - getPackSortWeight(it2));
		}
		return [];
	}, [model.config?.market_config])

	const onChangeValue = (value: number | string, template_id: number) =>
		setAmounts(prevState => ({
			...prevState,
			[template_id]: value
		}));

	if (!model.appDataLoaded)
		return <Loading/>;

	return (
		<PageContainer>
			<TopNavigation title={L.page.shop} withBalance={<><P3 isBold>{L.shared.balance}</P3><ARTBalance/></>}/>
			<CardStack>
				{
					sortedPacks.length > 0 ? sortedPacks.map((it) => {
						const template = app.useTemplateFIle
							? app.assetConf.getByTemplateId(it.key)
							: model.atomicTemplates.getItem(app.chainConf.COLLECTION_NAME, it.key)!;
						const {
							start_at,
							price: [price]
						} = model.config!.market_config.find(it => it.key === template.template_id)!.value;
						const updatedPrice = String(amounts[template.template_id]).length !== 0 ?
							Number(amounts[template.template_id]) * parseFloat(price.quantity.split(" ")[0]) :
							parseFloat(price.quantity.split(" ")[0]);
						const available = it.value.limit - it.value.total_sales;

						let errorMessage = ""

						if (amounts[template.template_id] < 1) {
							errorMessage = L.errors.invalid_quantity
						}

						if (parseFloat(balance.replace(/\s/g, '')) < updatedPrice) {
							errorMessage = L.errors.insufficient_funds
						}

						return (
							<ShopCard
								disabled={available < 1}
								key={template.template_id}
								img={makeLinkToAssetMedia(template.img)}
								id={String(template.template_id)}
								available={available > 0 ? available : 0}
								total={it.value.limit}
								onChangeValue={value => onChangeValue(value, template.template_id)}
								price={updatedPrice.toFixed(0)}
								token={price.quantity.split(" ")[1]}
								error={errorMessage}
								onClickBtn={() => {
									new BuyAction(it.key, Number(amounts[template.template_id]))
										.onSuccess(() => notificationService.showInfo(`${template.name} was successfully bought`))
										.executeAsync()
								}}
								startAt={start_at}
							/>
						)
					}) : <h2>No items </h2>
				}
			</CardStack>
		</PageContainer>
	)
});

export default ShopPage;