import React, { useState } from 'react';
import Styles from "./LandingHeader.module.scss"
import classNames from "classnames";
import CloseIcon from "../../../icons/CloseIcon";
import MenuIcon from "../../../icons/MenuIcon";
import useWindowSize from "../../../hooks/useWindowSize";
import Container from "../components/Container";
import { Link } from "react-router-dom";
import { L } from "../../../l10n/L10n";
import Socials from "../components/Socials";
import Button, { EButtonSize, EButtonType } from "../components/Button";
// import Button, { EButtonSize, EButtonType } from "../components/Button";

const LandingHeader = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const {windowWidth} = useWindowSize();
	const [background, setBackground] = useState(false);

	const onScroll = React.useCallback(
		() => setBackground(window.scrollY !== 0),
		[],
	);
	React.useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [onScroll]);

	React.useEffect(() => {
		if (isOpen && windowWidth && windowWidth > 1025) {
			setIsOpen(false)
		}
	}, [windowWidth])

	const menuHandler = React.useCallback((e) => {
		const element = document.getElementById(e.target.hash);
		if (windowWidth && windowWidth < 1025) {
			setIsOpen(prevState => !prevState)
		}
		if (element) {
			window.scrollTo(0, element.offsetTop - 84);
		}

	}, [windowWidth]);

	const scrollTo = React.useCallback((elementId: string) => {
		const element = document.getElementById(elementId);
		if (windowWidth && windowWidth < 1025) {
			setIsOpen(prevState => !prevState)
		}
		if (element) {
			window.scrollTo(0, element.offsetTop - 84);
		}

	}, [windowWidth]);

	const onClickLogo = () => window.scrollTo(0, 0);

	return (
		<div className={classNames(Styles.header, background && Styles.bg)}>
			<Container className={Styles.inner} fluid>
				<Link to="/" className={Styles.logo} onClick={onClickLogo}>
					<svg xmlns="http://www.w3.org/2000/svg" width="106" height="60" viewBox="0 0 106 60" fill="none">
						<g clipPath="url(#clip0_952_2339)">
							<path d="M60 0H0V60H60V0Z" fill="#FC7D64"/>
							<path d="M47.8676 23.2438H31.5476H26.8076V38.1598H31.5476V59.9998H59.9996V23.2438H47.8676Z"
								  fill="#FFD74D"/>
							<path
								d="M52.8987 41.5175C56.2173 41.5175 58.9076 38.8271 58.9076 35.5085C58.9076 32.1898 56.2173 29.4995 52.8987 29.4995C49.58 29.4995 46.8896 32.1898 46.8896 35.5085C46.8896 38.8271 49.58 41.5175 52.8987 41.5175Z"
								fill="#FC7D64"/>
							<path
								d="M28.3438 4.78198C24.1138 4.78198 20.2138 6.20398 17.1028 8.59798H31.5688V9.49798H16.0198C14.7538 10.635 13.6438 11.943 12.7318 13.389H31.5178V14.289H12.1978C11.5198 15.507 10.9768 16.812 10.5868 18.18H31.4998V19.08H10.3528C10.0438 20.418 9.88184 21.813 9.88184 23.244H52.9858V41.529C56.8588 41.529 59.9998 38.388 59.9998 34.515V4.78198H28.3438Z"
								fill="#282628"/>
							<path
								d="M37.6891 26.7873C34.6351 26.7873 31.9351 28.0563 30.2881 30.0003C31.9351 31.9443 34.6351 33.2133 37.6891 33.2133C40.7431 33.2133 43.4431 31.9443 45.0901 30.0003C43.4461 28.0563 40.7431 26.7873 37.6891 26.7873Z"
								fill="#FBFAF4"/>
							<path
								d="M37.6899 26.7873C36.6339 26.7873 35.6229 26.9373 34.6809 27.2163C34.4589 27.8613 34.3389 28.5573 34.3389 29.2773C34.3389 30.6663 34.7859 31.9503 35.5419 32.9973C36.2289 33.1383 36.9489 33.2133 37.6899 33.2133C40.7439 33.2133 43.4439 31.9443 45.0909 30.0003C43.4469 28.0563 40.7439 26.7873 37.6899 26.7873Z"
								fill="#282628"/>
							<path
								d="M37.6891 26.7873C34.6351 26.7873 31.9351 28.0563 30.2881 30.0003H45.0901C43.4461 28.0563 40.7431 26.7873 37.6891 26.7873Z"
								fill="#FC7D64"/>
							<path
								d="M15.9238 26.7873C18.9778 26.7873 21.6778 28.0563 23.3218 30.0003H12.6143C12.7404 31.111 13.1535 32.1346 13.7765 32.9973C14.4665 33.1383 15.1835 33.2133 15.9245 33.2133C18.9785 33.2133 21.6785 31.9443 23.3255 30.0003C21.6815 28.0563 18.9778 26.7873 15.9238 26.7873C15.9236 26.7873 15.924 26.7873 15.9238 26.7873Z"
								fill="#282628"/>
							<path
								d="M12.9153 27.2168C13.7853 26.9597 14.716 26.811 15.6845 26.7899C14.7174 26.8109 13.7855 26.9591 12.9153 27.2168C12.9152 27.217 12.9153 27.2167 12.9153 27.2168Z"
								fill="#282628"/>
							<path
								d="M31.497 55.62V54.72H47.448C48.624 53.568 49.644 52.26 50.478 50.829H31.545V49.929H50.97C51.597 48.705 52.089 47.403 52.431 46.038H31.563V45.138H52.632C52.863 43.974 52.983 42.768 52.983 41.535H16.893V30.255C16.893 26.382 13.752 23.241 9.879 23.241V33.849C9.879 38.091 6.438 41.532 2.196 41.532H0V60H34.524C39.078 60 43.245 58.353 46.464 55.62H31.497Z"
								fill="#3A9CFE"/>
							<path
								d="M73.1636 16.721C72.8698 17.6743 72.3283 18.4351 71.5334 19.0036C70.7385 19.5721 69.8255 19.8564 68.7944 19.8564C67.5098 19.8564 66.4153 19.4056 65.5138 18.5012C64.6123 17.5967 64.1602 16.4999 64.1602 15.2079C64.1602 13.9159 64.6123 12.8162 65.5138 11.9089C66.4153 10.9988 67.5098 10.5451 68.7944 10.5451C69.837 10.5451 70.7586 10.8322 71.5622 11.4093C72.3658 11.9864 72.913 12.7559 73.2126 13.7178H70.8681C70.6665 13.3072 70.39 12.9942 70.0328 12.7818C69.6757 12.5664 69.2552 12.4602 68.7713 12.4602C68.0772 12.4602 67.4868 12.7215 67.0029 13.244C66.519 13.7666 66.2742 14.4011 66.2742 15.1505C66.2742 15.9458 66.5248 16.6091 67.0231 17.1431C67.5213 17.6771 68.1377 17.947 68.8721 17.947C69.2782 17.947 69.6555 17.8379 70.0098 17.6168C70.3612 17.3958 70.6319 17.1 70.822 16.7268H73.1636V16.721Z"
								fill="#282628"/>
							<path
								d="M74.5352 7.92371H75.5835H76.6406V11.4495C77.2944 10.8351 78.0547 10.5336 78.9217 10.5422C79.9557 10.5422 80.7765 10.8896 81.3842 11.5873C81.9919 12.285 82.2972 13.2268 82.2972 14.4155V19.5635H81.2488H80.1918V14.8777C80.1918 14.0824 80.0449 13.4794 79.7483 13.0689C79.4516 12.6554 79.0196 12.4516 78.4493 12.4516C77.8416 12.4516 77.3894 12.6612 77.0899 13.0804C76.7903 13.4995 76.6377 14.1312 76.6377 14.9696V19.5635H75.5922H74.5352V7.92371Z"
								fill="#282628"/>
							<path
								d="M87.9243 19.8564C86.7232 19.8564 85.6979 19.4056 84.8482 18.5012C84.0015 17.5967 83.5752 16.4999 83.5752 15.2079C83.5752 13.9159 83.9986 12.8162 84.8482 11.9089C85.695 10.9988 86.7232 10.5451 87.93 10.5451C89.0965 10.5451 90.0844 10.9614 90.8966 11.7969V10.8351H92.8321V19.5635H91.8672H90.8966V18.6103C90.0758 19.4372 89.085 19.8506 87.9243 19.8564ZM88.2699 12.443C87.5354 12.443 86.922 12.7071 86.4266 13.2383C85.9312 13.7694 85.6864 14.4241 85.6864 15.2079C85.6864 15.986 85.9341 16.6406 86.4266 17.1661C86.9191 17.6944 87.5326 17.9585 88.2612 17.9585C88.9957 17.9585 89.6121 17.6944 90.1074 17.1661C90.6028 16.6378 90.8505 15.9831 90.8505 15.1993C90.8505 14.4155 90.6028 13.7608 90.1103 13.2325C89.6178 12.7071 89.0043 12.443 88.2699 12.443Z"
								fill="#282628"/>
							<path
								d="M94.4131 7.92371H96.5185V9.84453H94.4131V7.92371ZM94.4131 10.8351H95.4615H96.5185V19.5635H95.4701H94.4131V10.8351Z"
								fill="#282628"/>
							<path
								d="M98.2207 10.8351H99.2H100.179V11.5873C100.856 10.8897 101.668 10.5422 102.616 10.5422C103.653 10.5422 104.476 10.8925 105.087 11.5902C105.695 12.2908 106 13.2325 106 14.4212V19.5606H104.952H103.895V14.8921C103.895 14.0939 103.745 13.4852 103.448 13.0718C103.152 12.6554 102.717 12.4487 102.146 12.4487C101.539 12.4487 101.086 12.6583 100.781 13.0775C100.479 13.4967 100.326 14.1283 100.326 14.9667V19.5606H99.2777H98.2207V10.8351Z"
								fill="#282628"/>
							<path
								d="M68.6343 34.3045C67.3699 34.3045 66.2927 33.8538 65.3999 32.9493C64.507 32.0449 64.0635 30.9481 64.0635 29.6561C64.0635 28.3641 64.5099 27.2644 65.3999 26.3571C66.2927 25.4469 67.3699 24.9933 68.6343 24.9933C69.9045 24.9933 70.9845 25.4469 71.8745 26.3571C72.7674 27.2673 73.2109 28.3669 73.2109 29.6561C73.2109 29.9691 73.185 30.2763 73.1332 30.5777H66.3043C66.4223 30.9567 66.6211 31.3013 66.8976 31.6085C67.3757 32.1311 67.9719 32.3923 68.6862 32.3923C69.1268 32.3923 69.5329 32.289 69.8987 32.0851C70.2674 31.8813 70.5381 31.6056 70.7109 31.264H72.9316C72.6493 32.1913 72.1165 32.9292 71.3359 33.4805C70.5583 34.0289 69.6568 34.3045 68.6343 34.3045ZM71.0134 28.9038C70.9097 28.4416 70.7023 28.0396 70.3884 27.6979C69.916 27.1696 69.3284 26.9055 68.6314 26.9055C67.9316 26.9055 67.3469 27.1696 66.8745 27.6979C66.5721 28.0454 66.3619 28.4473 66.2438 28.9038H71.0134Z"
								fill="#282628"/>
							<path
								d="M76.8488 29.541L74.0579 25.2831H75.2791H76.5003L78.0757 27.9935L79.6685 25.2831H80.8781H82.0936L79.3113 29.5095L82.4651 34.0115H81.2238H79.9824L78.0988 30.9709L76.2007 34.0115H74.9709H73.7324L76.8488 29.541Z"
								fill="#282628"/>
							<path
								d="M83.2627 25.2833H84.2276H85.1982V26.2049C86.019 25.3895 86.9954 24.9847 88.1273 24.9904C89.3284 24.9904 90.3508 25.4441 91.1947 26.3542C92.0386 27.2644 92.4591 28.3641 92.4591 29.6532C92.4591 30.9453 92.0386 32.042 91.1947 32.9465C90.3508 33.8509 89.3255 34.3017 88.1187 34.3017C87.076 34.2959 86.1573 33.9514 85.3681 33.2652V36.9231H84.3197H83.2627V25.2833ZM87.7875 32.4067C88.5133 32.4067 89.1181 32.1425 89.6106 31.6142C90.1002 31.0859 90.3479 30.4342 90.3479 29.6561C90.3479 28.8723 90.1031 28.2148 89.6164 27.6865C89.1267 27.1553 88.5219 26.8911 87.7961 26.8911C87.0703 26.8911 86.4655 27.1553 85.9758 27.6836C85.4862 28.2119 85.2443 28.8665 85.2443 29.6504C85.2443 30.4342 85.4891 31.0888 85.9758 31.6171C86.4626 32.1425 87.0674 32.4067 87.7875 32.4067Z"
								fill="#282628"/>
							<path
								d="M94.6973 32.9494C93.7901 32.045 93.3379 30.9482 93.3379 29.6562C93.3379 28.3641 93.7901 27.2645 94.6973 26.3572C95.6046 25.447 96.7019 24.9933 97.9923 24.9933C99.2883 24.9933 100.389 25.447 101.296 26.3572C102.203 27.2673 102.655 28.367 102.655 29.6562C102.655 30.9482 102.203 32.045 101.296 32.9494C100.389 33.8538 99.2883 34.3046 97.9923 34.3046C96.7019 34.3046 95.6017 33.851 94.6973 32.9494ZM99.8097 31.6086C100.296 31.086 100.541 30.4342 100.541 29.6562C100.541 28.8781 100.296 28.2234 99.8097 27.698C99.32 27.1697 98.7152 26.9056 97.9894 26.9056C97.2693 26.9056 96.6674 27.1697 96.1777 27.698C95.6881 28.2263 95.4462 28.8781 95.4462 29.6562C95.4462 30.4342 95.691 31.086 96.1777 31.6086C96.6645 32.1311 97.2693 32.3924 97.9894 32.3924C98.7152 32.3924 99.3229 32.1311 99.8097 31.6086Z"
								fill="#282628"/>
							<path d="M103.889 31.9212H106V34.0114H103.889V31.9212Z" fill="#282628"/>
							<path
								d="M68.3491 49.9237C67.148 49.9237 66.1227 49.4729 65.273 48.5685C64.4263 47.6641 64 46.5673 64 45.2752C64 43.9832 64.4234 42.8835 65.273 41.9762C66.1198 41.0661 67.148 40.6124 68.3548 40.6124C69.5213 40.6124 70.5092 41.0287 71.3214 41.8643V40.9024H73.2569V49.6308H72.292H71.3214V48.6776C70.5006 49.5016 69.5098 49.9179 68.3491 49.9237ZM68.6947 42.5074C67.9602 42.5074 67.3468 42.7716 66.8514 43.3027C66.356 43.8339 66.1112 44.4885 66.1112 45.2724C66.1112 46.0505 66.3589 46.7051 66.8514 47.2305C67.3439 47.7588 67.9574 48.023 68.686 48.023C69.4205 48.023 70.0369 47.7588 70.5322 47.2305C71.0276 46.7022 71.2753 46.0476 71.2753 45.2637C71.2753 44.4799 71.0276 43.8253 70.5351 43.297C70.0426 42.7716 69.4291 42.5074 68.6947 42.5074Z"
								fill="#282628"/>
							<path
								d="M74.9902 40.9024H75.9695H76.9488V41.5627L77.0496 41.4249C77.4413 40.8823 78.0432 40.6095 78.8612 40.6095H78.9994V42.6078C78.409 42.648 77.9568 42.8031 77.6429 43.0701C77.2771 43.3888 77.0956 43.8884 77.0956 44.5775V49.6336H76.0473H74.9902V40.9024Z"
								fill="#282628"/>
							<path
								d="M80.3889 49.6308V42.7457H79.3779V40.9024H80.3889V37.9738H82.4943V40.9024H83.7673V42.7457H82.4943V49.6308H80.3889Z"
								fill="#282628"/>
						</g>
						<defs>
							<clipPath id="clip0_952_2339">
								<rect width="106" height="60" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				</Link>
				<div className={classNames(Styles.navigation, isOpen && Styles.opened)}>
					<div className={Styles.navigation_inner}>
						<nav className={Styles.menu}>
							<a href="#about" onClick={menuHandler} className={Styles.link}>
								{L.landing.sections.header.links.link1}
							</a>
							<a href="#how" onClick={menuHandler} className={Styles.link}>
								{L.landing.sections.header.links.link2}
							</a>
							<a href="#faq" onClick={menuHandler} className={Styles.link}>
								{L.landing.sections.header.links.link3}
							</a>
						</nav>
						<span className={Styles.stakeBtn}>
							<Button type={EButtonType.PRIMARY} size={EButtonSize.MEDIUM}
									onClick={() => scrollTo("pools")}
							>
								{L.landing.buttons.stake_your_nft}
							</Button>
						</span>
					</div>
					<Socials className={Styles.socials}/>
				</div>
				<button type="button" className={classNames(Styles.hamburger, isOpen && Styles.active)}
						onClick={menuHandler}>
					{isOpen ? <CloseIcon/> : <MenuIcon/>}
				</button>
			</Container>
		</div>
	);
};

export default LandingHeader;
