import React from 'react';
import Styles from "./P3.module.scss";
import classNames from "classnames";

interface P3Props extends React.HTMLAttributes<HTMLElement> {
	isBold?: boolean
}

export const P3 = ({children, className, isBold = true}: P3Props) => (
	<p className={classNames(Styles.p3, className, isBold && Styles.bold)}>
		{children}
	</p>
);

export default P3;
