import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { app, model, world } from "../../App";
import Styles from "./LotteryPage.module.scss";
import H2 from "../../components/typography/H2";
import P1 from "../../components/typography/P1";
import { BuyAttemptAction } from "../../eos/actions/BuyAttemptAction";
import Button, { EButtonType } from "../landing/components/Button";
import ButtonLink from "../landing/components/ButtonLink";
import LotterySlot from "./LotterySlot.ts";
import { PagePath } from "../../helpers/PageHelper";
import { ClaimLotteryAction } from "../../eos/actions/ClaimLotteryAction";
import classNames from "classnames";
import { L } from "../../l10n/L10n";
import LotteryPrize from "./LotteryPrize";
import { useNavigate } from "react-router-dom";
import WaxBalance from "../../components/shared/WaxBalance";
import ArtValue from "../../components/shared/ArtValue";
import tokenIcon from "../../images/art_token.png";


const LotteryPage = observer(() => {
	const navigate = useNavigate();
	let lottery = model.lottery.item;

	useEffect(() => {
		if (!lottery)
			return;

		const intervalId = setInterval(async () => {
			if (lottery!.awaitingResult) {
				await world.updateUserLottery(model.userName);
				await world.updateConfig();
			}
		}, 1000);
		return () => {
			if (intervalId)
				clearInterval(intervalId);
		};
	}, [model.lottery.item]);

	if (!lottery)
		return <h1>{L.lottery.lottery_not_found}</h1>

	let rows: JSX.Element[] = [];

	for (let i = 0; i < 8 * 8; i++) {
		rows.push(
			<LotterySlot lottery={lottery} pos={i} key={`cell_${i}`}/>
		)
	}

	return (
		<div className={Styles.container}>
			<div className={Styles.content}>
				<div className={Styles.tittle}>
					<H2>{L.lottery.lottery}</H2>
				</div>
				<div className={Styles.mobile}>
					<LotteryPrize lottery={lottery}/>
				</div>
				<div className={Styles.wrap}>
					<div className={Styles.cellsWrap}>
						{rows}
					</div>
					<div className={Styles.controls}>
						<div className={Styles.desktop}>
							<LotteryPrize lottery={lottery}/>
						</div>
						<P1>{L.lottery.scratch_your_lottery}</P1>
						<div className={Styles.tryWrap}>
							<P1 className={classNames({[Styles.disabled]: !lottery?.canTry})}>
								{L.lottery.tries_used}<b>{lottery.record.used_attempts}/{lottery.triesCanBeUsed}</b>
							</P1>
							<div>
								<hr/>
							</div>
							<div className={Styles.waxBalance}>
								{L.shared.balance} <WaxBalance/>
							</div>
							<div className={Styles.wrapRow}>
								<div>
									<P1 className={classNames({[Styles.disabled]: !lottery.canBuyAttempts})}>
										{L.lottery.bought_tries}
										<b>{lottery.boughtTries}/{lottery.totalTriesCanBeBought}</b>
									</P1>
									<P1 isBold className={Styles.price}>
										{L.lottery.price} {model.lotteryAttemptPrice.toString()}
									</P1>
								</div>
								<Button
									onClick={() => {
										new BuyAttemptAction(lottery!.record.painting_id).executeAsync();
									}}
									disabled={!lottery.canBuyAttempts}
									type={EButtonType.SECONDARY}>
									{L.lottery.buy_try}
								</Button>
							</div>
						</div>
						<div className={Styles.reward}>
							<P1>{L.lottery.your_reward}</P1>
							<ArtValue value={(lottery!.record.total_prizes * 10 ** app.chainConf.TKN_SYMBOL.precision).toString()} img={tokenIcon} symbol={app.chainConf.TKN_SYMBOL.code.toString()}/>
							<Button type={EButtonType.PRIMARY} onClick={() => new ClaimLotteryAction().onSuccess(() => {
								navigate(PagePath.EXPOS)
							}).executeAsync()}
									disabled={!lottery.canClaim}>{L.lottery.claim_reward}</Button>
						</div>
						<ButtonLink path={PagePath.EXPOS} className={Styles.link}>{L.lottery.play_later}</ButtonLink>
					</div>
				</div>
			</div>
		</div>
	)
});

export default LotteryPage;