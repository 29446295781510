import React from 'react';

const FullscreenIcon = ({color = "currentColor"}: { color?: string }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M3.44807 10.7065C3.83212 10.7065 4.20067 10.5543 4.47218 10.2835C4.74373 10.0127 4.89631 9.64535 4.89631 9.26217V6.931L9.22948 11.2525C9.4993 11.531 9.87019 11.6901 10.2586 11.6935C10.6471 11.6969 11.0206 11.5443 11.2954 11.2705C11.57 10.9965 11.7228 10.624 11.7195 10.2367C11.7161 9.84916 11.5568 9.47946 11.2773 9.21018L6.94413 4.88871H9.28161C9.79907 4.88871 10.2771 4.61338 10.5359 4.16654C10.7945 3.71969 10.7945 3.16902 10.5359 2.72218C10.2771 2.27533 9.79907 2 9.28161 2H3.44826C3.06464 2.0015 2.6972 2.15432 2.42586 2.42471C2.15453 2.69532 2.00151 3.06177 2 3.44435V9.26194C2 9.64517 2.15258 10.0125 2.42414 10.2833C2.69569 10.5541 3.06407 10.7065 3.44807 10.7065Z"
			fill={color}/>
		<path
			d="M28.5515 21.2649C28.1674 21.2649 27.7989 21.4171 27.5274 21.6879C27.2558 21.9587 27.1032 22.326 27.1032 22.7092V25.0404L22.7701 20.7159C22.5003 20.4371 22.1294 20.2783 21.741 20.2751C21.3524 20.2716 20.979 20.424 20.7042 20.698C20.4296 20.9719 20.2768 21.3443 20.28 21.7319C20.2835 22.1192 20.4427 22.4891 20.7223 22.7582L25.0554 27.0807H22.7179C22.2005 27.0807 21.7225 27.3561 21.4637 27.8029C21.205 28.2498 21.205 28.8004 21.4637 29.2473C21.7225 29.6941 22.2005 29.9694 22.7179 29.9694H28.5513C28.9349 29.9679 29.3024 29.8151 29.5737 29.5447C29.845 29.2741 29.998 28.9077 29.9996 28.5251V22.7092C29.9996 22.3259 29.847 21.9586 29.5754 21.6878C29.3039 21.417 28.9355 21.2649 28.5515 21.2649Z"
			fill={color}/>
		<path
			d="M10.7645 28.5511C10.7645 28.1681 10.6119 27.8005 10.3404 27.5297C10.0688 27.2589 9.70048 27.1068 9.31626 27.1068H6.97878L11.3119 22.7853C11.6671 22.4184 11.8022 21.8918 11.6671 21.3998C11.5318 20.908 11.1464 20.5237 10.6533 20.3888C10.16 20.254 9.63199 20.3885 9.26432 20.7428L4.93093 25.0644V22.738C4.93093 22.222 4.65486 21.7452 4.2068 21.4871C3.75874 21.2292 3.20658 21.2292 2.75853 21.4871C2.31047 21.7452 2.03439 22.2219 2.03439 22.738V28.5556C2.03461 28.7447 2.07211 28.9317 2.14452 29.1065C2.29258 29.4601 2.57426 29.7412 2.92857 29.8893C3.10422 29.9622 3.29258 29.9998 3.48267 30H9.31602C9.70115 30 10.0701 29.8472 10.3419 29.5753C10.6137 29.3034 10.7658 28.9349 10.7645 28.5511Z"
			fill={color}/>
		<path
			d="M29.1058 2.15769C28.9302 2.08483 28.7418 2.04721 28.5515 2.047H22.7182C22.2007 2.047 21.7227 2.32233 21.4639 2.76918C21.2053 3.21602 21.2053 3.76669 21.4639 4.21353C21.7227 4.66038 22.2007 4.93571 22.7182 4.93571H25.0556L20.7225 9.25717C20.443 9.52627 20.2837 9.89616 20.2802 10.2835C20.277 10.671 20.4298 11.0434 20.7044 11.3173C20.9792 11.5913 21.3526 11.7437 21.7412 11.7403C22.1296 11.7371 22.5005 11.5782 22.7703 11.2995L27.1035 6.978V9.30916C27.1035 9.82522 27.3795 10.3019 27.8276 10.5601C28.2757 10.818 28.8278 10.818 29.2759 10.5601C29.7239 10.3019 30 9.82522 30 9.30916V3.49156C29.9998 3.20592 29.9149 2.92692 29.7556 2.68944C29.5966 2.45215 29.3702 2.26687 29.1058 2.15769Z"
			fill={color}/>
	</svg>

);

export default FullscreenIcon;
