import {TUint64} from "@pro/common/contracts/common_types";
import {EosApi, EosUtils, IEosAction, IEosAuth, IEosTransactResult, IFindTableParams} from "@pro/common/eos";
import {deserialize, ObjectSchema} from "atomicassets";
import {AAsset, ACollection, ASchema, ATemplate, TActionName, TActionParam} from "./aa_types";

//noinspection JSUnusedGlobalSymbols
export class AAContract {
    private readonly _api: EosApi;
    private readonly _account: string;

    constructor(api: EosApi, account: string) {
        this._account = account;
        this._api = api;
    }

    action<N extends TActionName>(name: N,
                                  auth: IEosAuth | IEosAuth[],
                                  data: TActionParam<N>): Promise<IEosTransactResult | null> {
        return this._api.transact([this.makeAction(name,auth,data)]);
    }

    actions<N extends TActionName>(actions: { name: N, data: TActionParam<N> }[],
                                   auth: IEosAuth | IEosAuth[]): Promise<IEosTransactResult | null> {
        return this._api.transact(
            actions.map((d) => this.makeAction(d.name, auth, d.data))
        );
    }

    findCollection(name: string) {
        return this._api.findRecord<ACollection>(name, {
            code: this._account,
            scope: this._account,
            table: "collections",
            key_type: "name",
        });
    }

    findSchema(collection: string, schemaName: string) {
        return this._api.findRecord<ASchema>(schemaName, {
            code: this._account,
            scope: collection,
            table: "schemas",
            key_type: "name",
        });
    }

    findTemplate(collection: string, templateId: TUint64): Promise<ATemplate | undefined> {
        return this._api.findRecord<ATemplate>(templateId, {
            code: this._account,
            scope: collection,
            table: "templates",
            key_type: "i64",
        });
    }

    async findLastTemplate(collection: string): Promise<ATemplate | undefined> {
        let response = await this._api.getTable<ATemplate>({
            code: this._account,
            scope: collection,
            table: "templates",
            key_type: "i64",
            reverse: true,
            limit: 1,
        });
        return response.rows[0];
    }

    findAsset(owner: string, assetId: TUint64) {
        return this._api.findRecord<AAsset>(assetId, {
            code: this._account,
            scope: owner,
            table: "assets",
            key_type: "i64",
        });
    }

    getAsset(owner: string, assetId: TUint64) {
        return this._api.getRecord<AAsset>(assetId, {
            code: this._account,
            scope: owner,
            table: "assets",
            key_type: "i64",
        });
    }

    getAllSchemas(collection: string,
                  params?: Pick<IFindTableParams, "lower_bound">): Promise<ASchema[]> {
        return this._api.getFullTable({
            code: this._account,
            scope: collection,
            table: "schemas",
            lower_bound: params?.lower_bound,
            key_type: "name",
        });
    }


    getAllTemplates(collection: string): Promise<ATemplate[]> {
        return this._api.getFullTable({
            code: this._account,
            scope: collection,
            table: "templates",
            key_type: "i64",
        });
    }

    getAllAssets(account: string,
                 params?: Pick<IFindTableParams, "lower_bound">): Promise<AAsset[]> {
        return this._api.getFullTable({
            code: this._account,
            scope: account,
            table: "assets",
            lower_bound: params?.lower_bound,
            key_type: "i64",
        });
    }

    async findLastAsset(account: string): Promise<AAsset | undefined> {
        let result = await this.api.getTable<AAsset>({
            code: this._account,
            scope: account,
            table: "assets",
            key_type: "i64",
            reverse: true,
            limit: 1,
        });
        return result.rows[0];
    }

    async getLastAsset(account: string): Promise<AAsset> {
        let asset = await this.findLastAsset(account);
        if (!asset)
            throw new Error(`no records found for scope: ${account}`);
        return asset;
    }

    get active(): IEosAuth {
        return {actor: this._account, permission: "active"};
    }

    get account() {
        return this._account;
    }

    get api() {
        return this._api;
    }

    getAssetTemplateData(asset: AAsset) {
        return this.deserializeADataByAsset(asset, (t) => t.immutable_serialized_data as any as Uint8Array);
    }

    getAssetMutableData(asset: AAsset) {
        return this.deserializeADataByAsset(asset, (_) => asset.mutable_serialized_data as any as Uint8Array);
    }

    getTemplateData(template: ATemplate, schema: ASchema) {
        return this.deserializeAData(template, schema, (t) => t.immutable_serialized_data as any as Uint8Array);
    }

    async deserializeADataByAsset(asset: AAsset, getData: (t: ATemplate) => Uint8Array) {
        let template = await this.findTemplate(asset.collection_name, asset.template_id);
        if (!template)
            throw new Error("Template not found");

        let schema = await this.findSchema(asset.collection_name, template.schema_name);
        if (!schema)
            throw new Error("Schema not found");

        return this.deserializeAData(template, schema, getData);
    }

    async deserializeAData(template: ATemplate, schema: ASchema, getData: (t: ATemplate) => Uint8Array) {

        return {
            schema_name: schema.schema_name,
            ...deserialize(
                getData(template),
                ObjectSchema(schema.format))
        };
    }

    static makeAssetRecord(props?: Partial<AAsset>): AAsset {
        let model: AAsset = {
            asset_id: 0,
            collection_name: "",
            schema_name: "",
            template_id: 0,
            backed_tokens: [],
            immutable_serialized_data: new Uint8Array(),
            mutable_serialized_data: new Uint8Array(),
            ram_payer: "",
        };
        return Object.assign(model, props);
    }

    makeAction<N extends TActionName>(name: N,
                                      auth: IEosAuth | IEosAuth[],
                                      data: TActionParam<N>): IEosAction {
        return {
            name: name,
            account: this._account,
            authorization: EosUtils.castAuth(auth),
            data: data,
        };
    }
}
